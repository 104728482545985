import React from "react";

import Dialog from "@mui/material/Dialog";
import Container from "@mui/system/Container";
import Slide from "@mui/material/Slide";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Stack } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ShareSharedPackDialog = ({
  shareSharedPackDialogIsOpen,
  setShareSharedPackDialogIsOpen,
  shareableLink,
}) => {
  const [snackbarIsOpen, setSnackbarIsOpen] = React.useState(false);
  const handleClose = () => {
    setShareSharedPackDialogIsOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarIsOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={shareSharedPackDialogIsOpen}
      TransitionComponent={Transition}
      key={shareableLink}
    >
      <Container sx={{ width: "500px", height: "220px" }}>
        <DialogTitle sx={{ marginLeft: "-25px" }}>
          Share
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px", marginLeft: "0px" }}>
          <Typography
            sx={{
              color: "#D0D0D0",
              fontStyle: "italic",
              marginTop: "10px",
              width: "430px",
            }}
          >
            Below is the link to share this pack. Anyone with this link will be
            able to see any future updates to this pack.
          </Typography>
          <Stack direction="row" sx={{ marginTop: "20px" }}>
            <IconButton
              sx={{ height: "40px", width: "40px" }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `packwizard.com/s/${shareableLink}`
                );
                setSnackbarIsOpen(true);
              }}
            >
              <ContentCopyIcon />
            </IconButton>
            <TextField
              sx={{ width: "380px", marginLeft: "5px" }}
              size="small"
              value={`packwizard.com/s/${shareableLink}`}
              inputProps={{ readOnly: true }}
            />
          </Stack>
        </DialogContent>
      </Container>
      <Snackbar
        open={snackbarIsOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "300px", color: "white" }}
        >
          Copied to clipboard
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ShareSharedPackDialog;
