// const itemTemplate = {
//   label: "",
//   description: "",
//   started: false,
//   priority: 1,
// };

export const issuesAndFeatures = [
  {
    name: "Known Issues",
    items: [],
  },
  {
    name: "Planned Features",
    items: [
      {
        label: "Unit Conversion (Category)",
        description:
          "It can be incredibly tedious to update all columns to a new unit. This feature will allow you to convert an individual category to a new unit.",
        started: false,
        priority: 1,
      },
      {
        label: "Import Gear Closet from CSV",
        description:
          "One goal of PackShrink is to avoid how much manual date entry is needed compared to other similar gear visualization sites. A user shouldnt have to manually add items to their gear closet if they already have them in a csv",
        started: false,
        priority: 1,
      },
      {
        label: "Import Pack contents into gear closet",
        description:
          "One goal of PackShrink is to avoid how much manual date entry is needed compared to other similar gear visualization sites. A user shouldnt have to manually add items to their gear closet item by item. This will allow a user to import all of the items in a pack into their gear closet.",
        started: true,
        priority: 2,
      },
      {
        label: "Explore adding description to the table",
        description:
          "The description is a powerful field, it should be readily available in the table without needing to be dropped down",
        started: false,
        priority: 1,
      },
      {
        label: "Gear Closet filtering and search",
        description:
          "Gear closets can get large, adding a quick search function will help users find items in their gear closet quickly",
        started: false,
        priority: 1,
      },
      {
        label: "Color key for donut visualization",
        description:
          "The donut visualization requires you to manually move your cursor over each individual piece to show the color, include a key so users can immediately understand what each piece represents",
        started: false,
        priority: 1,
      },
      {
        label: "More popular packs",
        description:
          "Add more packs to the popular pack list to give folks more insight into different gear lists",
        started: true,
        priority: 0,
      },
      {
        label: "Add View Count to shared packs",
        description: "Just a cute little homage to the early internet days",
        started: false,
        priority: 0,
      },
    ],
  },
];

export const releaseNotes = [
  {
    date: "March 14, 2023",
    items: [
      "Fixed gear closet bug where you couldnt change price in the dropdown",
      "Fixed bug where category free form did not work when creating new item",
      "Fixed bug where category free form was sporading in gear closet edit",
    ],
  },
  {
    date: "March 13, 2023",
    items: [
      "Client-side logging",
      "Continued upcoming feature development",
      "Admin tooling",
    ],
  },
  {
    date: "March 11, 2023",
    items: [
      "Some code refactoring",
      "Development on upcoming features (stay tuned)",
      "When a user enters a link in their personal pack, a link icon will now appear and allow a user to visit that link",
      "When a user has an image link and an item url, the image will now be clickable and link the user to their item url",
    ],
  },
  {
    date: "March 7, 2023",
    items: [
      "Users can now choose to import items from lighterpack link into gear closet on import",
      "Added functionality to lighterpack import to select where columns go",
      "Fixed bug where you could not select a different pack",
      "Lighterpack imports now use the lighterpacks units",
      "Fixed multiple issues with lighterpack imports (image urls not working, not respecting price, etc)",
      "Added support for kilos. Converting a pack to metric will now set weight to kilo if an item is over 2000 grams",
      "Overall pack unit (for summary row + table) now saved and the unit you select for the pack will be the same unit users see when you share your pack",
    ],
  },
  {
    date: "March 4, 2023",
    items: [
      "Locally store a users preferred overall pack unit",
      "Added lightmode, accessible from side nav",
      "New Logo thats a bit better for light mode but the whole design will probably change",
    ],
  },
  {
    date: "March 3, 2023",
    items: [
      "UI polishing, keeping the app centered at all times, even when opening/closing menu",
      "Fixed issue where dropdown in item autocomplete would show gear closet items not in your closet",
      "Bulk add items from gear closet to category",
    ],
  },
  {
    date: "March 2, 2023",
    items: [
      "Huge mobile overhaul. Still lots to do but should be a much better experience on mobile",
      "Update release notes with most recent feedback",
    ],
  },
  {
    date: "March 1, 2023",
    items: [
      "Fixed issue where default pack would not be labeled as such while looking at a shared pack",
      "Updated amazon links to link to a users regional amazon site or the nearest one to them",
      "Fixed issue where shared links needed the www. subdomain, packshrink.com/s/yourId works again",
      "Added ability to convert items in a pack to a new unit",
      "Added ability to add item from pack to gear closet",
      "Added ability to convert a whole pack to imperial units",
    ],
  },
  {
    date: "February 28, 2023",
    items: [
      "Fixed bug where lighterpack import was not working with kg",
      "Added abiltiy in pack options menu to convert a pack to metric",
      "Added release notes page to keep users informed regarding upcoming feature development",
      "Some mobile optimization improvements",
      "Additional item types",
      "Added items from brands such as JetBoil, MSR, BearVault and more",
    ],
  },
  {
    date: "February 27, 2023",
    items: [
      "Identified issue with linking packs, updated the value in the dialog to include www while we work on a permanent fix",
      "Some mobile optimization improvements",
      "Server optimization improvements",
      "Gear closet bug fix where items that have been updated were not searchable with new item name and brand",
    ],
  },
  {
    date: "February 26, 2023",
    items: [
      "Improved user experience for updating and adding rows",
      "Added grid visualization and redesigned visualizing by category vs item",
    ],
  },
  {
    date: "February 25, 2023",
    items: ["Bug Fixes"],
  },
  {
    date: "February 24, 2023",
    items: ["Launched PackShrink!"],
  },
];
