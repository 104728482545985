import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminContainer from "./AdminContainer";
import GearManagerContainer from "./GearManagerContainer";
import LivePricingAuditContainer from "./AdminTools/LivePricing/LivePricingAuditContainer";
import ReviewManagerContainer from "./AdminTools/ReviewManagerContainer";
import ArticleBuilderContainer from "./AdminTools/ArticleBuilderContainer";

const AdminRouter = ({ darkMode }) => {
  return (
    <Routes>
      <Route path="/" element={<AdminContainer darkMode={darkMode} />}></Route>
      <Route
        path="/gearManager"
        element={<GearManagerContainer darkMode={darkMode} />}
      ></Route>
      <Route
        path="/livepricingaudit"
        element={<LivePricingAuditContainer darkMode={darkMode} />}
      ></Route>
      <Route
        path="/reviews"
        element={<ReviewManagerContainer darkMode={darkMode} />}
      ></Route>
      <Route
        path="/articleBuilder"
        element={<ArticleBuilderContainer isDarkMode={darkMode} />}
      ></Route>
    </Routes>
  );
};

export default AdminRouter;
