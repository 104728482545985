import React from "react";

import { Container, Stack } from "@mui/system";
import { getColorWithMode } from "../../constants/colors";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import BackpackTable from "./BackpackTable";
import { fetchBackpacks } from "../../api/gear";
import BackpackFilterBar from "./BackpackFilterBar";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useCallback } from "react";
import debounce from "lodash.debounce";

const fetchFilterValues = (rows) => {
  const brands = new Set();
  const types = new Set();

  let lowestWeight = Number.MAX_SAFE_INTEGER;
  let highestWeight = 0;
  let lowestPrice = Number.MAX_SAFE_INTEGER;
  let highestPrice = 0;
  let lowestMaxLoad = Number.MAX_SAFE_INTEGER;
  let highestMaxLoad = 0;
  let lowestVolume = Number.MAX_SAFE_INTEGER;
  let highestVolume = 0;

  rows.forEach((row) => {
    brands.add(row.brand);
    types.add(row.type);
    if (row.weight < lowestWeight) lowestWeight = row.weight;
    if (row.weight > highestWeight) highestWeight = row.weight;
    if (row.price < lowestPrice) lowestPrice = row.price;
    if (row.price > highestPrice) highestPrice = row.price;
    if (Number(row.maxLoad) && Number(row.maxLoad) < lowestMaxLoad)
      lowestMaxLoad = Number(row.maxLoad);
    if (Number(row.maxLoad) && Number(row.maxLoad) > highestMaxLoad)
      highestMaxLoad = Number(row.maxLoad);
    if (Number(row.volume) < lowestVolume) lowestVolume = Number(row.volume);
    if (Number(row.volume) > highestVolume) highestVolume = Number(row.volume);
  });
  return {
    brands: [...brands].sort(),
    types: [...types].sort(),
    weights: {
      min: Math.floor(lowestWeight),
      max: Math.ceil(highestWeight),
    },
    prices: {
      min: Math.floor(lowestPrice),
      max: Math.ceil(highestPrice),
    },
    maxLoad: {
      min: lowestMaxLoad,
      max: highestMaxLoad,
    },
    volume: {
      min: Math.floor(lowestVolume),
      max: Math.ceil(highestVolume),
    },
  };
};

const BackpackContainer = ({
  darkMode,
  setAddToGCSnackbarIsOpen,
  setReportSnackBarIsOpen,
  handleAddToPackMenu,
  gearText,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const [backpacks, setBackpacks] = React.useState([]);
  const [baseFilters, setBaseFilters] = React.useState(null);
  const [userFilters, setUserFilters] = React.useState({});
  const [filteredBackpacks, setFilteredBackpacks] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [useMetric, setUseMetric] = React.useState(true);
  const [useMetricVolume, setUseMetricVolume] = React.useState(true);
  const [searchText, setSearchText] = React.useState("");
  const [filtersAreOpen, setFiltersAreOpen] = React.useState(isDesktopOrLaptop);

  const handleSetSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleSetSearchText, 300),
    []
  );

  const handleSetSelectedItems = (items) => {
    setSelectedItems(items);
  };

  React.useEffect(() => {
    (async () => {
      const { data } = await fetchBackpacks();
      const newFilters = fetchFilterValues(data);
      setBaseFilters(newFilters);
      setUserFilters({ brand: newFilters.brands });
      setBackpacks(data);
      setFilteredBackpacks(data);
    })();
  }, []);

  React.useEffect(() => {
    const filteredDownBackpacks = backpacks.filter(
      (pack) =>
        `${pack.formattedBrand.toLowerCase()} ${pack.name.toLowerCase()}`.includes(
          searchText.toLowerCase()
        ) &&
        (!userFilters.price ||
          !pack.price ||
          (pack.price >= userFilters.price[0] &&
            pack.price <= userFilters.price[1])) &&
        (!userFilters.weight ||
          !pack.weight ||
          (pack.weight >= userFilters.weight[0] &&
            pack.weight <= userFilters.weight[1])) &&
        (!userFilters.maxLoad ||
          !pack.maxLoad ||
          (pack.maxLoad >= userFilters.maxLoad[0] &&
            pack.maxLoad <= userFilters.maxLoad[1])) &&
        (!userFilters.volume ||
          !pack.volume ||
          (pack.volume >= userFilters.volume[0] &&
            pack.volume <= userFilters.volume[1])) &&
        userFilters.brand.includes(pack.brand) &&
        (!userFilters.framed ||
          (!userFilters.framed.includes("framed") &&
            pack.framed.toLowerCase() === "yes") ||
          (!userFilters.framed.includes("frameless") &&
            pack.framed.toLowerCase() === "no")) &&
        (!userFilters.adjustableTorso ||
          (!userFilters.adjustableTorso.includes("yes") &&
            pack.adjustableTorso.toLowerCase() === "yes") ||
          (!userFilters.adjustableTorso.includes("no") &&
            pack.adjustableTorso.toLowerCase() === "no"))
    );
    setFilteredBackpacks(filteredDownBackpacks);
  }, [userFilters, backpacks, searchText]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>Backpacking packs</title>
        <link
          rel="canonical"
          href="https://www.packwizard.com/gear/backpacks"
        />
        <meta
          name="keywords"
          content="backpacking pack comparison tool, pack, comparison, pack specs, backpack specs, cheapest backpacking packs, pack price tracker"
        />
        <meta
          name="description"
          content="Compare pack specs to find your ideal backpacking backpack, complete with live price tracking to make sure you are getting the best deal out there."
        />
      </Helmet>
      <Container
        sx={{
          width: "100%",
          backgroundColor: getColorWithMode(darkMode, "title"),
        }}
      >
        <Stack
          textAlign="center"
          alignItems="center"
          sx={{ padding: "20px 10px 20px 10px" }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              fontFamily: "Helvetica",
              color: "white",
            }}
          >
            Backpacks
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Helvetica",
              color: "white",
              maxWidth: "700px",
            }}
          >
            Discover backpacking packs that fit all of your needs. Sort and
            filter by all of the most important features of a pack and use our
            live price tracker to find the best deals on your next backpack
          </Typography>
        </Stack>
      </Container>
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Stack
          sx={isMobile ? {} : { width: filtersAreOpen ? "240px" : "10px" }}
        >
          <Button
            sx={
              isMobile
                ? { width: "260px", fontSize: "18px" }
                : { width: "240px" }
            }
            onClick={() => {
              setFiltersAreOpen(!filtersAreOpen);
            }}
          >{`${
            filtersAreOpen ? "Hide Filters/Settings" : "Show Filters/Settings"
          }`}</Button>
          <Collapse
            in={filtersAreOpen}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            <Container
              sx={{
                width: isMobile ? "100%" : "240px",
                height: "100%",
                padding: "5px 5px 5px 5px",
                marginTop: "20px",
              }}
            >
              <BackpackFilterBar
                darkMode={darkMode}
                baseFilters={baseFilters}
                userFilters={userFilters}
                setUserFilters={setUserFilters}
                useMetric={useMetric}
                setUseMetric={setUseMetric}
                useMetricVolume={useMetricVolume}
                setUseMetricVolume={setUseMetricVolume}
              />
            </Container>
          </Collapse>
        </Stack>
        <Container sx={{ marginTop: filtersAreOpen ? "0px" : "20px" }}>
          <Stack>
            <Stack direction={isMobile ? "column" : "row"} alignItems={"left"}>
              <Container
                sx={{
                  width: "300px",
                  height: "50px",
                  padding: "0px 0px 0px 0px",
                  marginLeft: "0px",
                }}
              >
                <Typography
                  sx={{
                    height: "20px",
                    paddingLeft: "5px",
                    marginTop: "20px",
                    fontFamily: "Helvetica",
                  }}
                >
                  {`${filteredBackpacks.length} Backpacks`}
                </Typography>
              </Container>
              <TextField
                size="small"
                sx={{
                  marginTop: isMobile ? "0px" : "5px",
                  paddingBottom: isMobile ? "5px" : "0px",

                  marginLeft: isMobile ? "0px" : "calc(100% - 600px)",
                  width: "300px",
                }}
                placeholder="search"
                onChange={debouncedChangeHandler}
              ></TextField>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                height: "40px",
                maxWidth: "100%",
                whiteSpace: "nowrap",
              }}
            >
              <Button
                sx={{ width: "80px" }}
                disabled={selectedItems.length <= 1 || selectedItems.length > 3}
                onClick={() => {
                  window.location.assign(
                    `/gear/backpacks/compare?products=${selectedItems.join(
                      ","
                    )}`
                  );
                }}
              >
                Compare
              </Button>
              {selectedItems.length ? (
                <Divider
                  sx={{
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    height: "20px",
                  }}
                  orientation="vertical"
                />
              ) : (
                <></>
              )}
              <Box
                sx={{
                  maxWidth: "100%",
                  textOverflow: "nowrap",
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
              >
                {selectedItems.map((itemId) => {
                  const itemToRender = backpacks.find(
                    ({ id }) => itemId === id
                  );
                  return (
                    <Chip
                      sx={{ marginLeft: "5px" }}
                      variant="outlined"
                      label={`${itemToRender.formattedBrand} ${
                        itemToRender.name
                      } ${
                        itemToRender.variant ? `(${itemToRender.variant})` : ""
                      }`}
                      onDelete={() => {
                        handleSetSelectedItems(
                          selectedItems.filter(
                            (itemId) => itemId !== itemToRender.id
                          )
                        );
                      }}
                    />
                  );
                })}
              </Box>
            </Stack>
            <BackpackTable
              backpacks={filteredBackpacks}
              handleSetSelectedItems={handleSetSelectedItems}
              useMetric={useMetric}
              useMetricVolume={useMetricVolume}
              selectedItems={selectedItems}
              setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
              setReportSnackBarIsOpen={setReportSnackBarIsOpen}
              handleAddToPackMenu={handleAddToPackMenu}
              darkMode={darkMode}
              gearText={gearText}
            />
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

export default BackpackContainer;
