import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { Link } from "@mui/material";
import { NestedDropdown } from "mui-nested-menu";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import BackpackIcon from "@mui/icons-material/Backpack";
import FlagIcon from "@mui/icons-material/Flag";
import { addItemToGearClosetWithId, getUserIsLoggedIn } from "../../utils";
import { getColumns } from "../../utils/gearComparison/columns";
import { useMediaQuery } from "react-responsive";
import ReportSpecsDialog from "./ReportSpecsDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const gearTypeToGearExploreMap = {
  insulatedJacket: "insulated-jacket",
  stove: "stove",
};

const GenericGearTable = ({
  gear,
  handleSetSelectedItems,
  useMetric,
  useMetricDimensions,
  selectedItems,
  setAddToGCSnackbarIsOpen,
  setReportSnackBarIsOpen,
  handleAddToPackMenu,
  gearType,
  gearText,
  darkMode,
}) => {
  const [reportIsOpen, setReportIsOpen] = React.useState(false);
  const [itemToReportId, setItemToReportId] = React.useState(null);

  const isMobile = useMediaQuery({ maxWidth: 898 });

  const handleAddToGearCloset = (id, type) => {
    addItemToGearClosetWithId(id, type);
    setAddToGCSnackbarIsOpen(true);
  };

  const handleFlagData = (id) => {
    setItemToReportId(id);
    setReportIsOpen(true);
  };

  const createMenuItemsData = (
    id,
    image,
    formattedBrand,
    name,
    variant,
    type
  ) => {
    return {
      label: "",
      items: [
        {
          label: "Add to Gear Closet",
          leftIcon: <MeetingRoomIcon />,
          disabled: !getUserIsLoggedIn(),
          callback: () => {
            handleAddToGearCloset(id, type);
          },
        },
        {
          label: "Add to Pack",
          leftIcon: <BackpackIcon />,
          disabled: !getUserIsLoggedIn(),
          callback: () => {
            handleAddToPackMenu(id, image, formattedBrand, name, variant, type);
          },
        },
        {
          label: "Report Incorrect Specs",
          leftIcon: <FlagIcon />,
          callback: () => {
            handleFlagData(id, type);
          },
        },
      ],
    };
  };

  const columns = React.useMemo(() => {
    return [
      ...getColumns(gearType, {
        useMetric,
        useMetricDimensions,
        gearText,
        darkMode,
      }),
      {
        field: "view",
        headerName: "",
        width: 60,
        editable: false,
        headerAlign: "left",
        headerClassName: "lastcolumnSeparator",
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box
            sx={{
              width: "70px",
              height: "30px",
              lineHeight: "30px",
              textAlign: "center",
              "&:hover": { opacity: "0.8" },
            }}
          >
            <Link
              sx={{ textDecoration: "none" }}
              size="small"
              href={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
            >
              VIEW
            </Link>
          </Box>
        ),
      },
      {
        field: "more",
        headerName: "",
        width: 60,
        editable: false,
        headerAlign: "left",
        headerClassName: "lastcolumnSeparator",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <NestedDropdown
            ButtonProps={{
              endIcon: <MoreVertIcon />,
              sx: {
                minWidth: "32px",
                width: "32px",
                paddingRight: "16px",
              },
            }}
            menuItemsData={createMenuItemsData(
              params.row.id,
              params.row.imageUrl,
              params.row.formattedBrand,
              params.row.name,
              params.row.variant,
              gearType
            )}
            MenuProps={{ elevation: 3 }}
          />
        ),
      },
    ];
  }, [useMetric, useMetricDimensions]);

  return (
    <>
      <ReportSpecsDialog
        gearType={gearType}
        reportIsOpen={reportIsOpen}
        itemToReportId={itemToReportId}
        setReportIsOpen={setReportIsOpen}
        setReportSnackBarIsOpen={setReportSnackBarIsOpen}
      />
      <DataGridPro
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
          width: "100%",
          marginTop: "5px",
        }}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "weight", sort: "asc" }],
          },
        }}
        density="comfortable"
        rows={gear}
        columns={columns}
        disableSelectionOnClick
        selectionModel={selectedItems}
        pagination
        checkboxSelection
        keepNonExistentRowsSelected
        disableColumnMenu
        getRowHeight={() => "auto"}
        onSelectionModelChange={(itemIds) => {
          if (itemIds.length > 3) return;
          handleSetSelectedItems(itemIds);
        }}
        components={{
          NoRowsOverlay: () => (
            <Box
              sx={{
                textAlign: "center",
                verticalAlign: "center",
                lineHeight: "72px",
                color: "#999",
              }}
            >
              No Items
            </Box>
          ),
        }}
      />
    </>
  );
};

export default GenericGearTable;
