const AffiliateMap = {
  amazon: {
    baseUrl: "?tag=packshrink-20",
    rule: "stripAndPrependUrl",
    formattedName: "Amazon",
    brandImage: "",
    domain: "amazon",
    stripFunction: (url) => {
      return url.split("?")[0];
    },
  },
  backcountry: {
    baseUrl: "https://www.backcountry.tnu8.net/c/4174852/358742/5311?u=",
    rule: "appendUrl",
    formattedName: "Backcountry",
    brandImage: "",
    domain: "backcountry",
  },
  bearvault: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=4fcdcf87-ad58-4a86-9324-cf8453cc86e5&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "BearVault",
    brandImage: "",
    domain: "bearvault",
  },
  blackdiamondequipment: {
    baseUrl:
      "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=bd711266-995f-47d1-aae8-6ef1317d1770&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Black Diamond Equipment",
    brandImage: "",
    domain: "blackdiamondequipment",
  },
  bobsstores: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=7fb08abd-0c24-4255-a816-f056b1945721&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Bob's Stores",
    brandImage: "",
    domain: "bobstores",
  },
  campsaver: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=358fd954-c6b8-44a1-9ca9-aaad445d2dfb&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Campsaver",
    brandImage: "",
    domain: "campsaver",
  },
  cnocoutdoors: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=014f2c2f-7334-473e-95aa-fd74821c174c&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "CNOC Outdoors",
    brandImage: "",
    domain: "cnocoutdoors",
  },
  decathlon: {
    baseUrl:
      "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=0ce15e77-676b-474a-a764-ec31b4d41757&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Decathlon",
    brandImage: "",
    domain: "decathlon",
  },
  ems: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=5ceec2e2-f8b0-423c-9d96-15c0c08ca1b8&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "EMS",
    brandImage: "",
    domain: "ems",
  },
  enlightenedequipment: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=8510cca6-d1a8-443d-b0ef-e95f2ad73b27&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Enlightened Equipment",
    brandImage: "",
    domain: "enlightenedequipment",
  },
  exped: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=e5ff97ca-3441-446a-a2fd-85687f3db1de&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Exped",
    brandImage: "",
    domain: "expedusa",
  },
  featheredfriends: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=5ac877db-7518-47c1-bc2d-2cb57c4b0bea&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Feathered Friends",
    brandImage: "",
    domain: "featheredfriends",
  },
  featherstone: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=a21a0323-1f0b-4f1a-810b-ae4ea5c19ff4&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Featherstone",
    brandImage: "",
    domain: "featherstoneoutdoor",
  },
  garagegrowngear: {
    baseUrl:
      "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=8aa14f51-1fb2-4c30-b785-1c6c0a96cd31&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Garage Grown Gear",
    brandImage: "",
    domain: "garagegrowngear",
  },
  gcioutdoor: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=5020432b-2ebc-4015-908e-7af6f97d8f72&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "GCI Outdoor",
    brandImage: "",
    domain: "gcioutdoor",
  },
  gsioutdoors: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=1a64b70d-fe3a-45e5-b615-c5eb61724a4e&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "GSI Outdoors",
    brandImage: "",
    domain: "gsioutdoors",
  },
  hydrapak: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=94694d3b-afad-4638-ba6b-0c73f94713e8&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "HydraPak",
    brandImage: "",
    domain: "hydrapak",
  },
  hyperlite: {
    baseUrl:
      "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=47cd1998-e608-4548-aa0f-3d33e964ad3a&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Hyperlite Mountain Gear",
    brandImage: "",
    domain: "hyperlitemountaingear.",
  },
  kelty: {
    baseUrl:
      "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=d2422cad-e6d2-438d-9cf3-5b696abee977&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Kelty",
    brandImage: "",
    domain: "kelty",
  },
  klymit: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=128eaf09-afe7-4caa-a482-b6119a342339&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Klymit",
    brandImage: "",
    domain: "klymit",
  },
  moosejaw: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=61fff111-8514-4179-a87e-5da7e9fb1264&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Moosejaw",
    brandImage: "",
    domain: "moosejaw",
  },
  mountainsmith: {
    baseUrl:
      "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=5d36d298-9b1f-406b-8180-c12c75d37ccf&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Mountainsmith",
    brandImage: "",
    domain: "mountainsmith",
  },
  msr: {
    baseUrl:
      "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=7b4b8013-2a48-4540-bdb3-c614a9ba234d&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "MSR",
    brandImage: "",
    domain: "msrgear",
  },
  nemo: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=1b339602-9965-401c-a9c7-5f25dda2d3a5&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "NEMO Equipment",
    brandImage: "",
    domain: "nemoequipment",
  },
  outdoorgearexchange: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=e6e60426-9a5a-42de-870f-eda57a58e7b8&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Outdoor Gear Exchange",
    brandImage: "",
    domain: "gearx",
  },
  outdoorvitals: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=7e589116-9b06-4f1d-beee-aa18820b0454&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Outdoor Vitals",
    brandImage: "",
    domain: "outdoorvitals",
  },
  outdoorplay: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=95f93ecc-b5f1-436c-9c8b-0c48826bb3ff&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "outdoorplay",
    brandImage: "",
    domain: "outdoorplay",
  },
  packtowl: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=a80a3a70-3d77-4b66-9376-bef381f3d6ff&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "PackTowl",
    brandImage: "",
    domain: "packtowl",
  },
  platypus: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=623d133d-616d-4f82-9709-1d3f4d358f6b&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Platypus",
    brandImage: "",
    domain: "platy",
  },
  rei: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=e295c418-295a-447c-b265-734e25f82503&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "REI",
    brandImage: "",
    domain: "rei",
  },
  salomon: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=af5b8e7d-f369-4b4a-846b-641c1a9480fb&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Salomon",
    brandImage: "",
    domain: "salomon",
  },
  sealline: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=abec9dc7-2676-4324-aa5d-d3c3a0cfed2c&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Seal Line Gear",
    brandImage: "",
    domain: "seallinegear",
  },
  seatosummit: {
    baseUrl:
      "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=31b24f74-cc43-486e-af62-2378a089ed77&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Sea to Summit",
    brandImage: "",
    domain: "seatosummit",
  },
  sierradesigns: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=8377f025-608d-4a3a-855b-b38039ee9529&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Sierra Designs",
    brandImage: "",
    domain: "sierradesigns",
  },
  sixmoondesigns: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=55fa5d10-f33e-4501-b549-d42a402e30a4&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Six Moon Designs",
    brandImage: "",
    domain: "sixmoondesigns",
  },
  slingfin: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=61303a0f-648d-430c-ac2e-2c40efe62c0c&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "SlingFin",
    brandImage: "",
    domain: "slingfin",
  },
  ultimatedirection: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=8fa2d005-2fb7-4fd3-97c9-dba729b4b9ee&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "",
    brandImage: "",
    domain: "",
  },
  thermarest: {
    baseUrl:
      "https://www.avantlink.com/click.php?tt=cl&merchant_id=7709108a-b467-4795-a111-fbfeffd18a72&website_id=3e91feaa-3be7-458d-bbb7-f762d9971aa7&url=",
    rule: "appendUrl",
    formattedName: "Therm-a-Rest",
    brandImage: "",
    domain: "thermarest",
  },
  zpacks: {
    baseUrl: "?aff=145",
    rule: "prependUrl",
    formattedName: "Zpacks",
    brandImage: "",
    domain: "zpacks",
  },
};
export default AffiliateMap;
