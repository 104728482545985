import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import { externalLinkWrapper } from "../utils/links";
import { useMediaQuery } from "react-responsive";

const GGGBuyNowButton = ({ link, width, isDesktopOrLaptop }) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const REIIcon = (
    <Icon
      sx={{
        textAlign: "center",
        width: "64px",
        height: "44px",
        marginRight: "-5px",
        marginTop: "10px",
        color: "white",
      }}
    >
      <img
        style={{ width: "100%" }}
        src="https://cdn.shopify.com/s/files/1/0537/1177/files/ggg-logo_3x_9074e10b-c943-4bdc-9c87-c8be58c7c9e6_230x.png?v=1679436198"
        alt="GGG"
      />
    </Icon>
  );

  return (
    <Button
      variant="filled"
      sx={{
        backgroundColor: "#213236",
        width,
        color: "white",
        textTransform: "none",
        padding: "0px",
        height: isMobile ? "55px" : isDesktopOrLaptop ? "40px" : "35px",
        marginTop: "10px",
        paddingTop: "5px",
        fontFamily: "Roboto, sans-serif",
        fontSize: isMobile ? "20px" : "",
        "&:hover": {
          opacity: 0.8,
          backgroundColor: "#213236",
        },
      }}
      endIcon={REIIcon}
      onClick={() => {
        externalLinkWrapper(link);
      }}
    >
      View on
    </Button>
  );
};
export default GGGBuyNowButton;
