import { Box, Container, Typography } from "@mui/material";
import { fetchGearFilterTableData } from "../../utils";
import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getColumnsForArticleGearFilterModule } from "../../utils/gearComparison/columns";

const ArticleFilteredGearModule = ({ data, isDarkMode }) => {
  const [tableData, setTableData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        const gearType = data[2].value;
        const gearLimit = data[1].value;
        const filters = data[3].value;
        const newTableData = await fetchGearFilterTableData({
          gearType,
          gearLimit,
          filters,
        });
        setColumns(
          getColumnsForArticleGearFilterModule(gearType, {
            useMetric: true,
            darkMode: isDarkMode,
          })
        );
        setTableData(newTableData);
      } catch (error) {
        console.log(error);
        console.log("Error getting table data");
      }
    })();
  }, []);

  return (
    <>
      <Typography variant="h5">{data[0].value}</Typography>
      <DataGridPro
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
          marginTop: "5px",
        }}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "weight", sort: "asc" }],
          },
        }}
        density="comfortable"
        rows={tableData}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        hideFooter
        getRowHeight={() => "auto"}
        components={{
          NoRowsOverlay: () => (
            <Box
              sx={{
                textAlign: "center",
                verticalAlign: "center",
                lineHeight: "72px",
                color: "#999",
              }}
            >
              No Items
            </Box>
          ),
        }}
      />
    </>
  );
};

export default ArticleFilteredGearModule;
