import React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { handleUpdatePassword } from "../auth";
import { isValidPassword } from "../utils/validationUtils";
import { DialogContentText } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

const ResetPasswordDialog = ({
  resetPasswordIsOpen,
  resetCode,
  handleSetUser,
  email,
}) => {
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const [doPasswordsMatch, setDoPasswordsMatch] = React.useState(true);

  const [isOpen, setIsOpen] = React.useState(resetPasswordIsOpen);
  const [values, setValues] = React.useState({
    email,
    password: "",
    verifyPassword: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    if (prop === "password" || prop === "verifyPassword") {
      setIsPasswordValid(true);
      setDoPasswordsMatch(true);
    }

    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    if (isValidPassword(values.password)) {
      if (values.password === values.verifyPassword) {
        const changePasswordResponse = await handleUpdatePassword(
          values,
          resetCode
        );
        handleSetUser(changePasswordResponse);
        handleClose();
      } else {
        setDoPasswordsMatch(false);
      }
    } else {
      setIsPasswordValid(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} disableScrollLock>
      <DialogTitle style={{ width: "380px", textAlign: "center" }}>
        Reset Password
      </DialogTitle>
      <DialogContentText
        style={{
          width: "360px",
          fontSize: "14px",
          marginTop: "20px",
          marginLeft: "30px",
        }}
      >
        Please enter your new password
      </DialogContentText>
      <DialogContent
        style={{ height: "350px", width: "390px", marginTop: "-10px" }}
      >
        <FormControl
          sx={{ m: 1, width: "25ch", marginTop: "20px" }}
          variant="standard"
        >
          <InputLabel htmlFor="standard-adornment-password">
            New Password
          </InputLabel>
          <Input
            error={!isPasswordValid}
            style={{ width: "336px" }}
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {!isPasswordValid ? (
            <FormHelperText
              style={{ width: "330px" }}
              id="component-error-text"
            >
              Passwords must be 8 and 72 characters long and contain at least 1
              uppercase letter, one lowercase letter and a number
            </FormHelperText>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl
          sx={{ m: 1, width: "25ch", marginTop: "10px" }}
          variant="standard"
        >
          <InputLabel htmlFor="standard-adornment-password">
            Verify New Password
          </InputLabel>
          <Input
            error={!doPasswordsMatch}
            style={{ width: "336px" }}
            type={values.showPassword ? "text" : "password"}
            value={values.verifyPassword}
            onChange={handleChange("verifyPassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {!doPasswordsMatch ? (
            <FormHelperText
              style={{ width: "330px" }}
              id="component-error-text"
            >
              Passwords do not match
            </FormHelperText>
          ) : (
            <></>
          )}
        </FormControl>
        <Button
          variant="contained"
          style={{ width: "336px", marginLeft: "5px", marginTop: "30px" }}
          onClick={() => handleSubmit()}
        >
          Reset your Password
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordDialog;
