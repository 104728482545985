import { validateUsername } from "../utils";

export const isValidEmail = (email) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

// 8-72 characters, one numeric digit, one uppercase, one lowercase
export const isValidPassword = (password) =>
  password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,72}$/);

export const validateSocialUrl = (socialType, url) => {
  if (url === "") return true;
  if (socialType === "facebook") {
    return url.toLowerCase().includes("facebook.com");
  }
  if (socialType === "instagram") {
    return url.toLowerCase().includes("instagram.com");
  }
  if (socialType === "tiktok") {
    return url.toLowerCase().includes("tiktok.com");
  }
  if (socialType === "youtube") {
    return (
      url.toLowerCase().includes("youtube.com") ||
      url.toLowerCase().includes("youtu.be")
    );
  }
  return false;
};

export const isValidUsername = async (username, email) => {
  if (!username || username === "") {
    return true;
  }
  const { isValid } = await validateUsername(username, email);
  return Boolean(isValid);
};

export const isValidUrl = (url) => {
  const pattern = new RegExp(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|[www\\.]?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?", // fragment locator
    "i"
  );
  return pattern.test(url);
};
