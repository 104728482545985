import React from "react";

import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { Typography } from "@mui/material";

const SkeletonPack = () => {
  return (
    <Stack sx={{ maxHeight: "1100px" }}>
      <Typography variant="h1" sx={{ width: "100%" }}>
        <Skeleton animation="wave" />
      </Typography>
      {[0, 1, 2, 3, 4].map((i) => (
        <React.Fragment key={i}>
          <Typography variant="h3" key={i} sx={{ width: "100%" }}>
            <Skeleton animation="wave" key={`${i}-skeleton-header`} />
          </Typography>
          {[0, 1, 2, 3, 4, 5].map((j) => (
            <Typography variant="body" key={`${i}${j}`} sx={{ width: "100%" }}>
              <Skeleton animation="wave" key={`${j}-skeleton-body`} />
            </Typography>
          ))}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default SkeletonPack;
