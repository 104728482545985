import React from "react";
import {
  Avatar,
  Card,
  Divider,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { fetchGearMetrics } from "../../utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NormalDistributionWeightGraph from "./NormalDistributionWeightGraph";
import { useMediaQuery } from "react-responsive";
import { getColorWithMode } from "../../constants/colors";

const gearTypeToPathMap = {
  tent: "tent",
  sleepingPad: "sleeping-pad",
  sleepingBag: "sleeping-bag",
  backpack: "backpacks",
  insulatedJacket: "insulated-jacket",
  stove: "stove",
};

const getProductLinkPath = (gearType, productId) => {
  const typePath = gearTypeToPathMap[gearType];
  return `/gear/${typePath}/${productId}`;
};

const getTextForPopularInfo = (gearType) => {
  let productType = "products";
  if (gearType === "tent") {
    productType = "tents";
  }
  if (gearType === "sleepingPad") {
    productType = "sleeping pads";
  }
  if (gearType === "sleepingBag") {
    productType = "sleeping bags";
  }
  if (gearType === "backpack") {
    productType = "backpacks";
  }
  if (gearType === "insulatedJacket") {
    productType = "Insulated Jackets";
  }
  if (gearType === "stove") {
    productType = "stoves";
  }

  return `These are the most viewed ${productType} on PackWizard`;
};

const getTextForSimilarInfo = (gearType) => {
  if (gearType === "tent") {
    return "The displayed tents are the lightest tents in our database with similar capacity and type to the tent you are viewing";
  }
  if (gearType === "sleepingPad") {
    return "The displayed sleeping pads are the lightest sleeping pads in our database with similar length and r-value to the sleeping pad you are viewing";
  }
  if (gearType === "sleepingBag") {
    return "The displayed sleeping bags are the lightest sleeping bags in our database with similar length and comfort rating to the sleeping bag you are viewing";
  }
  if (gearType === "backpack") {
    return "The displayed backpacks are the lightest packs in our database with similar volume to the pack you are viewing.";
  }
  if (gearType === "insulatedJacket") {
    return "The displayed jackets are the lightest jackets in our database with similar features to the jacket you are viewing.";
  }
  if (gearType === "stove") {
    return "The displayed stoves are the lightest stoves in our database with similar features to the stove you are viewing.";
  }
};

const GearAnalyticsContainer = ({
  gearType,
  productId,
  gearText,
  darkMode,
  stackModules,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const [gearMetrics, setGearMetrics] = React.useState({});

  React.useEffect(() => {
    (async () => {
      const fetchedMetrics = await fetchGearMetrics(gearType, productId);
      setGearMetrics(fetchedMetrics);
    })();
  }, [gearType, productId]);

  return (
    <Stack
      direction={isDesktopOrLaptop && !stackModules ? "row" : "column"}
      alignItems="center"
      spacing={1}
      sx={{
        padding: "10px 10px 10px 10px",
        width: "100% !important",
      }}
    >
      <NormalDistributionWeightGraph
        gearType={gearType}
        productId={productId}
        gearMetrics={gearMetrics}
        darkMode={darkMode}
      />
      <Stack
        direction={stackModules ? "column" : "row"}
        spacing={1}
        justifyContent="space-evenly"
        sx={{ width: "100% !important" }}
      >
        <Paper sx={{ height: "380px", minWidth: "300px" }}>
          <Stack spacing={1.5} sx={{ padding: "5px 5px 0px 5px" }}>
            <Stack direction="row">
              <Typography
                textAlign="center"
                sx={{ textTransform: "capitalize", width: "100%" }}
              >{`Similar ${gearText}s`}</Typography>
              <Tooltip title={getTextForSimilarInfo(gearType)}>
                <InfoOutlinedIcon
                  size="small"
                  sx={{
                    marginLeft: "-40px",
                    "&:hover": { opacity: 0.8, cursor: "pointer" },
                  }}
                />
              </Tooltip>
            </Stack>
            <Divider />
            {gearMetrics.similarGear ? (
              <Stack gap={0.5}>
                {gearMetrics.similarGear.slice(0, 7).map((product) => (
                  <Link
                    sx={{ textDecoration: "none" }}
                    href={getProductLinkPath(gearType, product._id)}
                  >
                    <Card
                      sx={{
                        height: "42px",
                        "&:hover": { opacity: 0.7, cursor: "pointer" },
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Avatar
                          alt={product.name}
                          variant="square"
                          src={product.imageUrl}
                        />
                        <Stack sx={{ width: "100%" }}>
                          <Tooltip
                            title={`${product.name}${
                              product.variant ? ` (${product.variant})` : ""
                            }`}
                          >
                            <Typography
                              textAlign="left"
                              variant="subtitle2"
                              sx={{
                                maxWidth: "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: getColorWithMode(darkMode, "lightText"),
                              }}
                            >
                              {`${product.name}${
                                product.variant ? ` (${product.variant})` : ""
                              }`}
                            </Typography>
                          </Tooltip>
                          <Typography
                            textAlign="left"
                            variant="subtitle2"
                            sx={{
                              maxWidth: "200px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              marginTop: "-5px",
                              color: getColorWithMode(darkMode, "lightShadow"),
                            }}
                          >
                            {product.formattedBrand}
                          </Typography>
                        </Stack>
                        <Divider
                          variant="middle"
                          orientation="vertical"
                          flexItem
                        />
                        <Stack
                          sx={{
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <Typography variant="subtitle2">
                            {`${product.weight.toFixed(0)}g`}
                          </Typography>
                          <Divider />
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: getColorWithMode(darkMode, "lightShadow"),
                            }}
                          >
                            {product.price
                              ? `$${product.price.toFixed(0)}`
                              : "-"}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Card>
                  </Link>
                ))}
              </Stack>
            ) : (
              <Stack justifyContent="center" sx={{ height: "250px" }}>
                <Typography textAlign="center">
                  No Popular Items Found
                </Typography>
              </Stack>
            )}
          </Stack>
        </Paper>
        <Paper sx={{ height: "380px", minWidth: "300px" }}>
          <Stack spacing={1.5} sx={{ padding: "5px 5px 0px 5px" }}>
            <Stack direction="row">
              <Typography
                textAlign="center"
                sx={{ textTransform: "capitalize", width: "100%" }}
              >{`Popular ${gearText}s`}</Typography>
              <Tooltip title={getTextForPopularInfo(gearType)}>
                <InfoOutlinedIcon
                  size="small"
                  sx={{
                    marginLeft: "-40px",
                    "&:hover": { opacity: 0.8, cursor: "pointer" },
                  }}
                />
              </Tooltip>
            </Stack>
            <Divider />
            {gearMetrics.mostViewed ? (
              <Stack gap={0.5}>
                {gearMetrics.mostViewed.slice(0, 7).map((product) => (
                  <Link
                    sx={{ textDecoration: "none" }}
                    href={getProductLinkPath(gearType, product._id)}
                  >
                    <Card
                      sx={{
                        height: "42px",
                        "&:hover": { opacity: 0.7, cursor: "pointer" },
                      }}
                    >
                      <Stack direction="row" gap={1}>
                        <Avatar
                          alt={product.name}
                          variant="square"
                          src={product.imageUrl}
                        />
                        <Stack sx={{ width: "100%" }}>
                          <Tooltip
                            title={`${product.name}${
                              product.variant ? ` (${product.variant})` : ""
                            }`}
                          >
                            <Typography
                              textAlign="left"
                              variant="subtitle2"
                              sx={{
                                maxWidth: "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: getColorWithMode(darkMode, "lightText"),
                              }}
                            >
                              {`${product.name}${
                                product.variant ? ` (${product.variant})` : ""
                              }`}
                            </Typography>
                          </Tooltip>
                          <Typography
                            textAlign="left"
                            variant="subtitle2"
                            sx={{
                              maxWidth: "200px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              marginTop: "-5px",
                              color: getColorWithMode(darkMode, "lightShadow"),
                            }}
                          >
                            {product.formattedBrand}
                          </Typography>
                        </Stack>
                        <Divider
                          variant="middle"
                          orientation="vertical"
                          flexItem
                        />
                        <Stack
                          sx={{
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <Typography variant="subtitle2">
                            {`${product.weight.toFixed(0)}g`}
                          </Typography>
                          <Divider />
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: getColorWithMode(darkMode, "lightShadow"),
                            }}
                          >
                            {product.price
                              ? `$${product.price.toFixed(0)}`
                              : "-"}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Card>
                  </Link>
                ))}
              </Stack>
            ) : (
              <Stack justifyContent="center" sx={{ height: "250px" }}>
                <Typography textAlign="center">
                  No Popular Items Found
                </Typography>
              </Stack>
            )}
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default GearAnalyticsContainer;
