import { Link, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useMediaQuery } from "react-responsive";

const ExploreGearCard = ({ link, imageUrl, text }) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });

  return (
    <Link href={link} sx={{ textDecoration: "none" }}>
      <Paper
        sx={{
          height: !isMobile ? "200px" : "250px",
          width: !isMobile ? "250px" : "300px",
          margin: "0 auto",
          position: "relative",
          top: "0",
          transition: "top ease 0.25s",
          "&:hover": {
            top: "-10px",
            cursor: "pointer",
            opacity: "0.8",
          },
        }}
      >
        <Stack>
          <Box
            sx={{
              width: !isMobile ? "250px" : "300px",
              height: !isMobile ? "150px" : "200px",
              position: "relative",
            }}
          >
            <img
              style={{
                maxWidth: !isMobile ? "150px" : "280px",
                maxHeight: !isMobile ? "130px" : "180px",
                position: "absolute",
                margin: "auto",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
              }}
              src={imageUrl}
              alt={text}
            ></img>
          </Box>
          <Typography sx={{ textAlign: "center", fontWeight: "500" }}>
            {text}
          </Typography>
        </Stack>
      </Paper>
    </Link>
  );
};

export default ExploreGearCard;
