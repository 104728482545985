import React from "react";

import { Container, Stack } from "@mui/system";
import { getColorWithMode } from "../../constants/colors";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import SleepingPadTable from "./SleepingPadTable";
import { fetchSleepingPads } from "../../api/gear";
import SleepingPadFilterBar from "./SleepingPadFilterBar";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useCallback } from "react";
import debounce from "lodash.debounce";

const fetchFilterValues = (rows) => {
  const brands = new Set();
  const types = new Set();

  let lowestWeight = Number.MAX_SAFE_INTEGER;
  let lowestPrice = Number.MAX_SAFE_INTEGER;
  let highestPrice = 0;

  rows.forEach((row) => {
    brands.add(row.brand);
    types.add(row.type);
    if (row.weight < lowestWeight) lowestWeight = row.weight;
    if (row.price < lowestPrice) lowestPrice = row.price;
    if (row.price > highestPrice) highestPrice = row.price;
  });
  return {
    brands: [...brands].sort(),
    types: [...types].sort(),
    weights: {
      min: Math.floor(lowestWeight),
      max: Math.ceil(2500),
    },
    prices: {
      min: Math.floor(lowestPrice),
      max: Math.ceil(highestPrice),
    },
    minimumRValue: 0,
    widths: [20, 52],
    lengths: [42, 85],
  };
};

const SleepingPadContainer = ({
  darkMode,
  setAddToGCSnackbarIsOpen,
  setReportSnackBarIsOpen,
  handleAddToPackMenu,
  gearText,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const [sleepingPads, setSleepingPads] = React.useState([]);
  const [baseFilters, setBaseFilters] = React.useState(null);
  const [userFilters, setUserFilters] = React.useState({});
  const [filteredSleepingPads, setFilteredSleepingPads] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [showBrand, setShowBrand] = React.useState(true);
  const [useMetric, setUseMetric] = React.useState(true);
  const [useMetricDimensions, setUseMetricDimensions] = React.useState(false);
  const [filtersAreOpen, setFiltersAreOpen] = React.useState(isDesktopOrLaptop);

  const handleSetSelectedItems = (items) => {
    setSelectedItems(items);
  };

  React.useEffect(() => {
    (async () => {
      const { data } = await fetchSleepingPads();
      const newFilters = fetchFilterValues(data);
      setBaseFilters(newFilters);
      setUserFilters({ brand: newFilters.brands });
      setSleepingPads(data);
      setFilteredSleepingPads(data);
    })();
  }, []);

  const [searchText, setSearchText] = React.useState("");

  const handleSetSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleSetSearchText, 300),
    []
  );

  React.useEffect(() => {
    const filteredDownSleepingPads = sleepingPads.filter(
      (pad) =>
        `${pad.formattedBrand.toLowerCase()} ${pad.name.toLowerCase()}`.includes(
          searchText.toLowerCase()
        ) &&
        (!userFilters.price ||
          (pad.price >= userFilters.price[0] &&
            pad.price <= userFilters.price[1])) &&
        (!userFilters.weight ||
          (pad.weight >= userFilters.weight[0] &&
            (pad.weight <= userFilters.weight[1] ||
              userFilters.weight[1] === 2500))) &&
        userFilters.brand.includes(pad.brand) &&
        (!userFilters.type || !userFilters.type.includes(pad.type)) &&
        (!userFilters.variant || !userFilters.variant.includes(pad.variant)) &&
        (!userFilters.lengths ||
          (pad.length >= userFilters.lengths[0] &&
            pad.length <= userFilters.lengths[1])) &&
        (!userFilters.widths ||
          (pad.width >= userFilters.widths[0] &&
            pad.width <= userFilters.widths[1])) &&
        (!userFilters.minimumRValue || userFilters.minimumRValue <= pad.rValue)
    );
    setFilteredSleepingPads(filteredDownSleepingPads);
  }, [userFilters, sleepingPads, searchText]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>Sleeping Pads</title>
        <link
          rel="canonical"
          href="https://www.packwizard.com/gear/sleeping-pads"
        />
        <meta
          name="keywords"
          content="sleeping pad comparison tool, sleeping, pad, comparison, exped comparison, therm-a-rest comparison, big agnes comparison, sleeping mat, sleeping pad specs, cheapest sleeping pads, sleeping pad price tracker"
        />
        <meta
          name="description"
          content="Compare sleeping pad specs to find your ideal sleeping pad, complete with live price tracking to make sure you are getting the best deal out there."
        />
      </Helmet>
      <Container
        sx={{
          width: "100%",
          backgroundColor: getColorWithMode(darkMode, "title"),
        }}
      >
        <Stack
          textAlign="center"
          alignItems="center"
          sx={{ padding: "20px 10px 20px 10px" }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              fontFamily: "Helvetica",
              color: "white",
            }}
          >
            Sleeping Pads
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Helvetica",
              color: "white",
              maxWidth: "720px",
            }}
          >
            Discover sleeping pads that fit all of your needs. Sort and filter
            by all of the most important features of a sleeping pad and use our
            live price tracker to find the best deals on your next sleeping pad
          </Typography>
        </Stack>
      </Container>
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Stack
          sx={isMobile ? {} : { width: filtersAreOpen ? "240px" : "10px" }}
        >
          <Button
            sx={
              isMobile
                ? { width: "260px", fontSize: "18px" }
                : { width: "240px" }
            }
            onClick={() => {
              setFiltersAreOpen(!filtersAreOpen);
            }}
          >{`${
            filtersAreOpen ? "Hide Filters/Settings" : "Show Filters/Settings"
          }`}</Button>
          <Collapse
            in={filtersAreOpen}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            <Container
              sx={{
                width: isMobile ? "100%" : "240px",
                height: "100%",
                padding: "5px 5px 5px 5px",
                marginTop: "20px",
              }}
            >
              <SleepingPadFilterBar
                darkMode={darkMode}
                baseFilters={baseFilters}
                userFilters={userFilters}
                setUserFilters={setUserFilters}
                useMetric={useMetric}
                setUseMetric={setUseMetric}
                useMetricDimensions={useMetricDimensions}
                setUseMetricDimensions={setUseMetricDimensions}
              />
            </Container>
          </Collapse>
        </Stack>
        <Container sx={{ marginTop: filtersAreOpen ? "0px" : "20px" }}>
          <Stack>
            <Stack
              sx={{ height: isMobile ? "70px" : "50px" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Container
                sx={{
                  width: "300px",
                  height: "50px",
                  padding: "0px 0px 0px 0px",
                  marginLeft: "0px",
                }}
              >
                <Typography
                  variant={isMobile ? "h6" : "body1"}
                  sx={{
                    height: "20px",
                    paddingLeft: "5px",
                    marginTop: "20px",
                    fontFamily: "Helvetica",
                  }}
                >
                  {`${filteredSleepingPads.length} Sleeping Pads`}
                </Typography>
              </Container>
              <TextField
                size={isMobile ? "large" : "small"}
                sx={{
                  marginTop: isMobile ? "0px" : "5px",
                  paddingBottom: isMobile ? "5px" : "0px",

                  marginLeft: isMobile ? "0px" : "calc(100% - 600px)",
                  width: "300px",
                }}
                placeholder="Search"
                onChange={debouncedChangeHandler}
              ></TextField>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              sx={{ height: "40px", maxWidth: "100%", whiteSpace: "nowrap" }}
            >
              <Button
                sx={{ width: "80px" }}
                disabled={selectedItems.length <= 1 || selectedItems.length > 3}
                onClick={() => {
                  window
                    .open(
                      `/gear/sleeping-pads/compare?products=${selectedItems.join(
                        ","
                      )}`,
                      "_blank"
                    )
                    .focus();
                }}
              >
                Compare
              </Button>
              {selectedItems.length ? (
                <Divider
                  sx={{
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    height: "20px",
                  }}
                  orientation="vertical"
                />
              ) : (
                <></>
              )}
              <Box
                sx={{
                  maxWidth: "100%",
                  textOverflow: "nowrap",
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
              >
                {selectedItems.map((itemId) => {
                  const itemToRender = sleepingPads.find(
                    ({ id }) => itemId === id
                  );
                  return (
                    <Chip
                      sx={{ marginLeft: "5px" }}
                      variant="outlined"
                      label={`${itemToRender.formattedBrand} ${
                        itemToRender.name
                      } ${
                        itemToRender.variant ? `(${itemToRender.variant})` : ""
                      }`}
                      onDelete={() => {
                        handleSetSelectedItems(
                          selectedItems.filter(
                            (itemId) => itemId !== itemToRender.id
                          )
                        );
                      }}
                    />
                  );
                })}
              </Box>
            </Stack>
            <SleepingPadTable
              sleepingPads={filteredSleepingPads}
              handleSetSelectedItems={handleSetSelectedItems}
              useMetric={useMetric}
              useMetricDimensions={useMetricDimensions}
              selectedItems={selectedItems}
              setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
              setReportSnackBarIsOpen={setReportSnackBarIsOpen}
              handleAddToPackMenu={handleAddToPackMenu}
              darkMode={darkMode}
              gearText={gearText}
            />
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

export default SleepingPadContainer;
