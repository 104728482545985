import React from "react";
import { Route, Routes } from "react-router-dom";
import ArticleContainer from "./ArticleContainer";
import ArticleMainContainer from "./ArticleMainContainer";
import SubNavBar from "./SubNavBar";
import { fetchArticlePaths } from "../utils";

const ArticleRouter = ({ isSideNavOpen, isDarkMode }) => {
  const [articlePaths, setArticlePaths] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const paths = await fetchArticlePaths();
      setArticlePaths(paths);
    })();
  }, []);
  return (
    <>
      <SubNavBar darkMode={isDarkMode} />
      <Routes>
        <Route
          path="/"
          element={
            <ArticleMainContainer
              articles={articlePaths}
              isDarkMode={isDarkMode}
            />
          }
        />
        {articlePaths.map(({ _id, path }) => (
          <Route
            key={path}
            path={`/${path}`}
            element={
              <ArticleContainer
                articleId={_id}
                isSideNavOpen={isSideNavOpen}
                isDarkMode={isDarkMode}
              />
            }
          />
        ))}
      </Routes>
    </>
  );
};

export default ArticleRouter;
