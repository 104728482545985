import FORMAT_KEYS from "./formatKeys.json";
import CHILDREN_KEYS from "./childrenKeys.json";

export default {
  [CHILDREN_KEYS.SEO_TITLE]: {
    format: FORMAT_KEYS.TEXT,
    key: "title",
    label: "SEO Title",
    value: "",
  },
  [CHILDREN_KEYS.SEO_PATH]: {
    format: FORMAT_KEYS.TEXT,
    key: "path",
    label: `Article Path (ex: "/this-is-my-chosen-path")`,
    value: "",
  },
  [CHILDREN_KEYS.SEO_DESCRIPTION]: {
    format: FORMAT_KEYS.TEXT,
    key: "description",
    label: "SEO Description",
    value: "",
  },
  [CHILDREN_KEYS.SEO_IMAGE]: {
    format: FORMAT_KEYS.URL,
    key: "image",
    label: "SEO image",
    value: "",
  },
  [CHILDREN_KEYS.SEO_KEYWORDS]: {
    format: FORMAT_KEYS.TEXT,
    key: "keywords",
    label: "SEO Keywords",
    value: "",
  },
  [CHILDREN_KEYS.INTRO_TITLE]: {
    format: FORMAT_KEYS.TEXT,
    key: "title",
    label: "Article Title",
    value: "",
  },
  [CHILDREN_KEYS.INTRO_SUBTITLE]: {
    format: FORMAT_KEYS.TEXT,
    key: "subtitle",
    label: "Subtitle",
    value: "",
  },
  [CHILDREN_KEYS.INTRO_IMAGE]: {
    format: FORMAT_KEYS.URL,
    key: "headerImage",
    label: "Header Image",
    value: "",
  },
  [CHILDREN_KEYS.HTML]: {
    format: FORMAT_KEYS.RAW_HTML,
    key: "html",
    label: "HTML",
    value: "",
  },
  [CHILDREN_KEYS.GEAR_TYPE]: {
    format: FORMAT_KEYS.GEAR_TYPE,
    key: "gearType",
    label: "Gear Type",
    value: "",
  },
  [CHILDREN_KEYS.GEAR_ID]: {
    format: FORMAT_KEYS.GEAR_ID,
    key: "id",
    label: "Gear Id",
    value: "",
  },
  [CHILDREN_KEYS.HEADER]: {
    format: FORMAT_KEYS.TEXT,
    key: "header",
    label: "Header",
    value: "This is an example header",
  },
  [CHILDREN_KEYS.PRODUCT_NAME]: {
    format: FORMAT_KEYS.TEXT,
    key: "product",
    label: "Product Name",
    value: "Name of product",
  },
  [CHILDREN_KEYS.DESCRIPTION]: {
    format: FORMAT_KEYS.TEXT,
    key: "description",
    label: "Description",
    value: "This is an example description",
  },
  [CHILDREN_KEYS.GEAR_FILTER]: {
    format: FORMAT_KEYS.GEAR_FILTER,
    key: "gear_filter",
    label: "Filter",
    value: [],
  },
  [CHILDREN_KEYS.LIMIT]: {
    format: FORMAT_KEYS.INTEGER,
    key: "gear_limit",
    label: "Gear Limit",
    value: 0,
  },
};
