import articleKeys from "../../constants/articleKeys.json";

const ARTICLE_TYPES = {
  BRAND_GEAR_TYPE: "BRAND_GEAR_TYPE",
  GEAR_NICHE_HAND_CRAFTED: "GEAR_NICHE_HAND_CRAFTED",
  GEAR_FILTER: "GEAR_FILTER",
};

const ARTICLE_ADD_ONS = {
  SHOW_DEALS: "SHOW_DEALS",
};

const ARTICLES = {
  [articleKeys.SMALL_2P_TENTS]: {
    articleType: ARTICLE_TYPES.GEAR_NICHE_HAND_CRAFTED,
    addOns: {
      showDeals: ARTICLE_ADD_ONS.SHOW_DEALS,
    },
    date: "June 28, 2024",
    gearType: "tent",
    title: "Small 2 Person Tents",
    subtitle:
      "Bigger isn't always better and the best things come in small packages. This is especially true when you're choosing a tent for your backpacking journey. A tent with a smaller footprint will open up more room in your pack and will often times give you more selection in campsite location.",
    headerImage:
      "https://www.gearpatrol.com/wp-content/uploads/sites/2/2021/12/Tiny-Tent-Gear-Patrol-Lead-Full-jpg.webp",
    getArticleHtml: () => {
      return (
        <>
          <h2>Small 2 Person Tents at Every Price Point</h2>
          <p>
            We've all been there, you've been hiking for hours, you finally get
            to your campsite when you're suddenly faced with the realization
            that there is just no good spot to set up your tent. You walk around
            the area looking for a spot to pitch your tent and there are rocks
            everywhere, roots protruding out of the ground, maybe some puddles
            from recent rains. Finally, in a forest of uneven rubble, you find
            it, a small sliver of flat, soft, ground. You open your pack, pull
            out your tent, lay down your footprint, and your tent is
            <i> just </i>
            too big to pitch properly.
          </p>
          <p>
            Let's be honest, as backpackers and/or wilderness campers, we often
            don't have the luxury of a nice, flat, 12'x12' groomed patch of
            sand, specifically provided for you to pitch your tent. We often
            find ourselves compromising our sleeping location, finding a "good
            enough" spot to lay down for the night and dealing with some
            "natural acupuncture". Heck, I've even tried to convince myself the
            softball sized root jamming into my back would actually
            <i> help </i> with my back pain when it was dead center of the only
            spot I could manage to setup my tent.
          </p>
          <p>
            If my rant on my poor campsite planning does not resonate with you,
            there is still value to taking the smallest tent that works for you.
            While a larger tent might be more comfortable to roll around in,
            size comes at a cost. I'd gladly lose the ability to breakdance in
            my tent in exchange for some weight savings and more room in my
            pack.
          </p>
          <p>
            Below, you'll find our personal favorite backpacking and camping
            tents that will sleep 2 people while being relatively small in size.
            We have tried to include a tent at each pricepoint. If these tents
            dont quite suit your needs, head on over to our tent comparison tool
            where you can filter tents down by their capacity, footprint, setup
            type, weight, price and more:{" "}
            <a href="https://www.packwizard.com/gear/tents">
              Tent Comparison Tool
            </a>
          </p>
        </>
      );
    },
    gearList: [
      {
        gearType: "tent",
        id: "6456e2b97b1724060ff591bf",
        header: "The Best Budget Tent Trekking Pole Tent",
        product: "3FUL Lanshan 2",
        description:
          'The 3FUL Lanshan 2 is one of the best bang for your buck tents out there. For around ~$150 (but is frequently found on sale), it is one of the best budget tents out there. The Lanshan 2 is my most used tent as I have personally put hundreds of miles on it and have slept dozens of nights with it and I truly think it is one of the highest value tents out there and a tent I recommend to most beginner backpackers. At 43" wide it will sleep 2 people with regular width sleeping pads with a little bit of room to breathe and at just 1100 grams, it is super light for the price. I personally bring it on solo trips, but if my wife does join me on a trip, we have no issues getting a good nights rest together.',
      },
      {
        gearType: "tent",
        id: "6456e2b97b1724060ff5913d",
        header: "The Lightest Free-Standing Tent that won't break the bank",
        product: "Naturehike Cloud Up 2 Ultralight",
        description:
          'The Naturehike Cloud Up 2 is one of the lightest free-standing tents on the market, and the lightest we have found under $850, which is impressive given it retails for $260, but regularly goes on sale for up to 50% off. While the previous two tents we mentioned require trekking poles to setup, this tent comes with everything you need to get it pitched. It is pushing the limit of what I would call a "small" tent, at 47" at the narrowest point and 52" at the widest, but that just means one lucky sleeper gets to use a wide sleeping pad.',
      },
      {
        gearType: "tent",
        id: "6456e2b97b1724060ff59101",
        header: "Ultralight that fits 2 wide sleeping pads",
        product: "NEMO Hornet Elite OSMO Ultralight Backpacking Tent",
        description: `The NEMO Hornet Elite OSMO's featherlight trail weight of 779g and it's 50" width make it an awesome choice for those who don't want to trade grams for comfort. It's becoming more and more common for many of us to sacrifice a few more grams to be able to sleep more comfortably on a wide pad. With the NEMO Hornet elite, you can get back some of those grams. Did we mention it's semi free-standing too? So if you prefer to hike without Trekking Poles, look no further than the NEMO Hornet Elite Osmo.`,
      },
      {
        gearType: "tent",
        id: "6456e2b97b1724060ff590db",
        header: "The Fan Favorite - All Arounder",
        product: "MSR Hubba Hubba 2-Person Backpacking Tent",
        description: `The MSR Hubba Hubba 2-Person Backpacking Tent is one of the most commonly used tents on the market, and for good reason. Walk around Yosemite and it feels like half of the tents are Hubba Hubba's. It is frequently on sale for ~50% off, and if you can get it for a good price, it is a fantastic tent and hits the perfect price point of comfort, weight, price and quality. At 50" it is just wide enough for two wide sleeping pads, and although it is a bit on the heavier side at 1300 grams, the 40" peak height makes it feel quite roomy, so the amount of tent you get for the weight is quite remarkable.`,
      },
      {
        gearType: "tent",
        id: "65e7f32658f8fc0b623ef350",
        header: "The Enthusiasts Tent",
        product: "Zpacks Duplex Lite",
        description:
          'The Zpacks Duplex is the tent of choice for the ultralight enthusiast looking for the lightest possible 2 person tent. At under 15 ounces, it is the lightest 2 person tent on the market at the time of writing this article. It is also one of the narrowest 2 person tents on the market at just 40" wide so if "being small" is a critical part of your tent choice, the ZPacks Duplex has that nailed. While it does technically sleep 2, you had better be quite close with your sleeping buddy, because this tent will make you close as you will only barely be able to fit two, regular-width, sleeping pads side by side.',
      },
    ],
    seo: {
      title: "Small 2 Person Tents at Every Price Point",
      url: "https://www.packwizard.com/article/small-2-person-tents",
      description:
        "Our guide to the smallest 2 person tents at each price point. When it comes to tents, bigger isn't always better. Having the smallest tent possible is beneficial for several reasons.",
      image:
        "https://www.gearpatrol.com/wp-content/uploads/sites/2/2021/12/Tiny-Tent-Gear-Patrol-Lead-Full-jpg.webp",
      keywords:
        "small 2 person tents, small 2 man tents, small 2 person tents at every price point, zpacks duplex lite, 3ful lanshan 2, naturehike cloud up 2, nemo hornet elite osmo, MSR hubba hubba",
    },
  },
};

export const getArticleData = (key) => {
  return ARTICLES[key];
};

export const getArticleMetadata = (article) => {
  const { date, path, title, subtitle, headerImage } = article;
  return { date, title, subtitle, headerImage, path };
};
