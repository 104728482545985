import React from "react";

import {
  Avatar,
  Button,
  Divider,
  FormLabel,
  ImageList,
  ImageListItem,
  Link,
  List,
  ListItem,
  Paper,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ASPECT_REVIEW_MAP } from "./constants";
import ImageDisplayDialog from "./ImageDisplayDialog";
import FlagIcon from "@mui/icons-material/Flag";
import RecommendIcon from "@mui/icons-material/Recommend";
import {
  getUserIsLoggedIn,
  markReviewAsReported,
  markReviewAsHelpful,
} from "../../utils";
import { getWeightInGrams, getFormattedWeightInUnit } from "../../utils";

const formatTrailWeight = (weight, unit) => {
  const weightInGrams = getWeightInGrams(weight, unit);
  return `${getFormattedWeightInUnit(
    weightInGrams,
    false
  )} / ${getFormattedWeightInUnit(weightInGrams, true)}`;
};

const ProductReviewCard = ({
  reviewValues,
  gearType,
  isPreview,
  isUsersReview,
}) => {
  const [imagePreviewIsOpen, setImagePreviewIsOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const { authorDetails } = reviewValues;
  const [userHasReported, setUserHasReported] = React.useState(
    reviewValues.userHasReported
  );
  const [userHasMarkedAsHelpful, setUserHasMarkedAsHelpful] = React.useState(
    reviewValues.userHasMarkedAsHelpful
  );

  const handleClose = () => {
    setImagePreviewIsOpen(false);
    setSelectedImage(null);
  };

  const reportReview = async () => {
    await markReviewAsReported(reviewValues._id);
    setUserHasReported(true);
  };

  const markAsHelpful = async () => {
    await markReviewAsHelpful(reviewValues._id);
    setUserHasMarkedAsHelpful(true);
  };

  return (
    <>
      <ImageDisplayDialog
        imagePreviewIsOpen={imagePreviewIsOpen}
        handleClose={handleClose}
        image={selectedImage}
        isPreview={isPreview}
      />
      <Paper elevation={2} sx={{ padding: "10px 10px 10px 10px" }}>
        <Stack direction="row" spacing={2} sx={{ height: "100%" }}>
          <Stack
            sx={{ width: "100px", height: "auto", maxHeight: "300px" }}
            justifyContent="center"
            alignItems="center"
          >
            <Link
              sx={{ textDecoration: "none" }}
              href={`/user/${encodeURIComponent(authorDetails.username)}`}
              target="_blank"
            >
              <Stack alignItems="center">
                <Avatar
                  alt={authorDetails.username}
                  src={authorDetails.avatar}
                />
                <Typography variant="subtitle2">
                  {`@${authorDetails.username}`}
                </Typography>
              </Stack>
            </Link>
            <Typography variant="subtitle2">
              {!isPreview && reviewValues.reviewDate
                ? new Date(reviewValues.reviewDate).toLocaleDateString()
                : new Date().toLocaleDateString()}
            </Typography>
          </Stack>
          <Divider orientation="vertical" sx={{ height: "auto" }} />
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Stack direction="row" sx={{ width: "100%", paddingTop: "10px" }}>
              <FormLabel sx={{ width: "185px" }}>Overall Rating: </FormLabel>
              <Rating
                key={reviewValues.starRating}
                value={reviewValues.starRating}
                readOnly
              />
            </Stack>
            {reviewValues.aspects &&
            Object.keys(reviewValues.aspects).length ? (
              <Stack>
                {ASPECT_REVIEW_MAP[gearType].map((aspect) => (
                  <Stack direction="row">
                    <FormLabel sx={{ fontSize: "14px", width: "200px" }}>
                      {aspect.label}
                    </FormLabel>
                    <Rating
                      key={reviewValues.aspects[aspect.key]}
                      value={reviewValues.aspects[aspect.key]}
                      readOnly
                      size="small"
                    ></Rating>
                  </Stack>
                ))}
              </Stack>
            ) : (
              <></>
            )}
            {reviewValues.usage && reviewValues.usage.length ? (
              <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
                {`This reviewer states they have used this item for ${reviewValues.usage} at the time of this review and they`}
                <span
                  style={{
                    color:
                      reviewValues.wouldRecommend === "true" ? "green" : "red",
                  }}
                >
                  {reviewValues.wouldRecommend === "true"
                    ? " would "
                    : " would not "}
                </span>
                {`recommend this item.`}
              </Typography>
            ) : (
              <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
                {`This reviewer states they ${
                  reviewValues.wouldRecommend === "true" ? "would" : "would not"
                } recommend this item`}
              </Typography>
            )}
            <Stack direction="row">
              {reviewValues.positives &&
              reviewValues.positives.some((value) => value.length > 0) ? (
                <Stack sx={{ width: "50%" }}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <AddIcon color="success" />
                    <Typography variant="body1" sx={{ color: "grey" }}>
                      Positives
                    </Typography>
                  </Stack>
                  <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    {reviewValues.positives.map((pos) =>
                      pos.length > 0 ? (
                        <ListItem>
                          <Typography
                            multiline
                            sx={{
                              display: "list-item",
                              maxWidth: "100%",
                              wordBreak: "break-word",
                              lineBreak: "strict",
                              hyphens: "auto",
                            }}
                          >
                            {pos}
                          </Typography>
                        </ListItem>
                      ) : (
                        <></>
                      )
                    )}
                  </List>
                </Stack>
              ) : (
                <></>
              )}
              {reviewValues.negatives &&
              reviewValues.negatives.some((value) => value.length > 0) ? (
                <Stack sx={{ width: "50%" }}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <RemoveIcon color="error" />
                    <Typography variant="body1" sx={{ color: "grey" }}>
                      Negatives
                    </Typography>
                  </Stack>
                  <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    {reviewValues.negatives.map((neg) =>
                      neg.length > 0 ? (
                        <ListItem>
                          <Typography
                            multiline
                            sx={{
                              display: "list-item",
                              maxWidth: "100%",
                              wordBreak: "break-word",
                              lineBreak: "strict",
                              hyphens: "auto",
                            }}
                          >
                            {neg}
                          </Typography>
                        </ListItem>
                      ) : (
                        <></>
                      )
                    )}
                  </List>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
            {reviewValues.review.length || reviewValues.reviewTitle.length ? (
              <Stack sx={{ paddingTop: "15px" }} spacing={1}>
                <Typography variant="body1" sx={{ color: "grey" }}>
                  Review
                </Typography>
                <Typography
                  multiline
                  variant="h6"
                  sx={{
                    whiteSpace: "pre-line",
                    paddingLeft: "10px",
                    fontWeight: "500",
                  }}
                >
                  {reviewValues.reviewTitle}
                </Typography>
                <Divider />
                <Typography
                  multiline
                  variant="body1"
                  sx={{ whiteSpace: "pre-line", paddingLeft: "10px" }}
                >
                  {reviewValues.review}
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
            {reviewValues.weight > 0 ? (
              <Stack direction="row" spacing={1}>
                <Tooltip title="This is the weight that the user reports they measured including only the materials required to take the item on trail.">
                  <Typography variant="body1" sx={{ color: "grey" }}>
                    Measured Trail Weight:
                  </Typography>
                </Tooltip>
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: "pre-line",
                    paddingLeft: "10px",
                    fontWeight: "500",
                  }}
                >
                  {formatTrailWeight(reviewValues.weight, reviewValues.unit)}
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
            {reviewValues.images && reviewValues.images.length ? (
              <>
                <FormLabel>Images</FormLabel>
                <ImageList cols={3} sx={{ maxWidth: "450px" }}>
                  {reviewValues.images.map((image, index) => (
                    <ImageListItem
                      key={image.name}
                      sx={{
                        objectFit: "contain",
                        maxWidth: "150px",
                        maxHeight: "150px",
                        border: "1px solid grey",
                        borderRadius: "5px",
                        "&:hover": {
                          opacity: 0.8,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setImagePreviewIsOpen(true);
                        setSelectedImage(image);
                      }}
                    >
                      <img
                        srcSet={
                          isPreview && typeof image !== "string"
                            ? `${URL.createObjectURL(image)}`
                            : image
                        }
                        src={
                          isPreview && typeof image !== "string"
                            ? `${URL.createObjectURL(image)}`
                            : image
                        }
                        alt={image.title}
                        loading="lazy"
                        style={{
                          objectFit: "contain",
                          maxWidth: "150px",
                          maxHeight: "100px",
                        }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </>
            ) : (
              <></>
            )}
            {reviewValues.foundHelpful ? (
              <>
                <Divider />
                <Typography>{`${reviewValues.foundHelpful} ${
                  reviewValues.foundHelpful > 1 ? "hikers" : "hiker"
                } found this review helpful`}</Typography>
              </>
            ) : (
              <></>
            )}
            {getUserIsLoggedIn() && !isPreview && !isUsersReview ? (
              <Stack direction="row" spacing={2}>
                <Button
                  size="small"
                  startIcon={<RecommendIcon sx={{ marginTop: "-4px" }} />}
                  onClick={markAsHelpful}
                  disabled={userHasMarkedAsHelpful}
                >
                  Helpful
                </Button>
                <Button
                  size="small"
                  color="error"
                  startIcon={<FlagIcon sx={{ marginTop: "-4px" }} />}
                  onClick={reportReview}
                  disabled={userHasReported}
                >
                  Report
                </Button>
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default ProductReviewCard;
