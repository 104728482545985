import { Button, Container, Divider, Slider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../../constants/colors";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery } from "react-responsive";

const formatBrand = (brand) => {
  if (brand === "Nemo" || brand === "nemo") return "NEMO";
  if (brand === "thermarest") return "Therm-a-Rest";
  if (brand === "rei") return "REI Co-op";
  if (brand === "north face") return "The North Face";
  if (brand === "hyperlite") return "Hyperlite Mountain Gear";
  if (brand === "msr") return "MSR";
  if (brand === "3ful") return "3FUL";
  return brand
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

const SleepingBagFilterBar = ({
  darkMode,
  baseFilters,
  userFilters,
  setUserFilters,
  useMetric,
  setUseMetric,
  useCelsius,
  setUseCelsius,
  setUseMetricDimensions,
  useMetricDimensions,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  if (!baseFilters) return <></>;

  const handleChangeFilter = (type, value, toggled) => {
    const newFilters = { ...userFilters };
    if (type === "price") {
      newFilters.price = value;
    } else if (type === "weight") {
      newFilters.weight = value;
    } else if (type === "brand") {
      if (toggled) {
        const brandSet = new Set(newFilters.brand);
        brandSet.add(value);
        newFilters.brand = [...brandSet];
      } else {
        const brandSet = new Set(newFilters.brand);
        brandSet.delete(value);
        newFilters.brand = [...brandSet];
      }
    } else if (type === "group") {
      if (toggled) {
        const groupSet = new Set(newFilters.group);
        groupSet.add(value);
        newFilters.group = [...groupSet];
      } else {
        const groupSet = new Set(newFilters.group);
        groupSet.delete(value);
        newFilters.group = [...groupSet];
      }
    } else if (type === "width") {
      if (toggled) {
        const widthSet = new Set(newFilters.width);
        widthSet.add(value);
        newFilters.width = [...widthSet];
      } else {
        const widthSet = new Set(newFilters.width);
        widthSet.delete(value);
        newFilters.width = [...widthSet];
      }
    } else if (type === "insulationType") {
      if (toggled) {
        const insulationTypeSet = new Set(newFilters.insulationType);
        insulationTypeSet.add(value);
        newFilters.insulationType = [...insulationTypeSet];
      } else {
        const insulationTypeSet = new Set(newFilters.insulationType);
        insulationTypeSet.delete(value);
        newFilters.insulationType = [...insulationTypeSet];
      }
    } else if (type === "type") {
      if (toggled) {
        const typeSet = new Set(newFilters.type);
        typeSet.add(value);
        newFilters.type = [...typeSet];
      } else {
        const typeSet = new Set(newFilters.type);
        typeSet.delete(value);
        newFilters.type = [...typeSet];
      }
    } else if (type === "gender") {
      if (toggled) {
        const genderSet = new Set(newFilters.gender);
        genderSet.add(value);
        newFilters.gender = [...genderSet];
      } else {
        const genderSet = new Set(newFilters.gender);
        genderSet.delete(value);
        newFilters.gender = [...genderSet];
      }
    } else if (type === "fitsTo") {
      newFilters.fitsTo = value;
    } else if (type === "width") {
      newFilters.widths = value;
    } else if (type === "comfortRating") {
      newFilters.comfortRating = value;
    } else if (type === "limitRating") {
      newFilters.limitRating = value;
    }
    setUserFilters(newFilters);
  };

  const fitsToMarks = [
    {
      value: baseFilters.fitsTo.min,
      label: useMetricDimensions
        ? `${Math.floor(baseFilters.fitsTo.min * 2.54)}cm`
        : `${baseFilters.fitsTo.min}"`,
    },
    {
      value: baseFilters.fitsTo.max,
      label: useMetricDimensions
        ? `${Math.ceil(baseFilters.fitsTo.max * 2.54)}cm`
        : `${baseFilters.fitsTo.max}"`,
    },
  ];

  const weightMarks = [
    {
      value: baseFilters.weights.min,
      label: useMetric
        ? `${baseFilters.weights.min}g`
        : `${Math.floor(baseFilters.weights.min / 28)}oz`,
    },
    {
      value: baseFilters.weights.max,
      label: useMetric
        ? "2kg+"
        : `${Math.floor(baseFilters.weights.max / 28)}oz+`,
    },
  ];

  const priceMarks = [
    {
      value: baseFilters.prices.min,
      label: `$${baseFilters.prices.min}`,
    },
    {
      value: baseFilters.prices.max,
      label: `$${baseFilters.prices.max}+`,
    },
  ];

  const comfortRating = [
    {
      value: baseFilters.comfortRating.min,
      label: useCelsius
        ? `${Math.floor((baseFilters.comfortRating.min - 32) * (5 / 9))}\xB0 C`
        : `${baseFilters.comfortRating.min}\xB0 F`,
    },
    {
      value: baseFilters.comfortRating.max,
      label: useCelsius
        ? `${Math.floor((baseFilters.comfortRating.max - 32) * (5 / 9))}\xB0 C`
        : `${baseFilters.comfortRating.max}\xB0 F`,
    },
  ];

  const limitRating = [
    {
      value: baseFilters.limitRating.min,
      label: useCelsius
        ? `${Math.floor((baseFilters.limitRating.min - 32) * (5 / 9))}\xB0 C`
        : `${baseFilters.limitRating.min}\xB0 F`,
    },
    {
      value: baseFilters.limitRating.max,
      label: useCelsius
        ? `${Math.floor((baseFilters.limitRating.max - 32) * (5 / 9))}\xB0 C`
        : `${baseFilters.limitRating.max}\xB0 F`,
    },
  ];

  return (
    <Container
      sx={{
        backgroundColor: `${getColorWithMode("base", darkMode)} !important`,
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        direction={isMobile ? "row" : "column"}
        sx={{ width: "100%" }}
        justifyContent={"space-between"}
      >
        <Box sx={{ minWidth: "200px" }}>
          <FormControl
            sx={{ width: "100%", paddingBottom: "15px" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Settings</FormLabel>
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetric}
                  onChange={() => setUseMetric(!useMetric)}
                />
              }
              label="Metric Weights"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetricDimensions}
                  onChange={() => setUseMetricDimensions(!useMetricDimensions)}
                />
              }
              label="Metric Dimensions"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useCelsius}
                  onChange={() => setUseCelsius(!useCelsius)}
                />
              }
              label="Metric Temperatures"
            />
          </FormControl>
        </Box>
        <Stack>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Price</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.prices}
                valueLabelFormat={(value) => {
                  if (value === 1500) return "$1500+";
                  return `$${value}`;
                }}
                defaultValue={[baseFilters.prices.min, baseFilters.prices.max]}
                marks={priceMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("price", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Weight</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  if (value === 5000) {
                    return useMetric ? `5kg+` : `${Math.floor(value / 28)}oz+`;
                  }
                  return useMetric
                    ? `${value}g`
                    : `${Math.floor(value / 28)}oz`;
                }}
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.weights}
                defaultValue={[
                  baseFilters.weights.min,
                  baseFilters.weights.max,
                ]}
                marks={weightMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("weight", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Comfort Rating</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) =>
                  useCelsius
                    ? `${Math.floor((value - 32) * (5 / 9))}\xB0 C`
                    : `${value}\xB0 F`
                }
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.comfortRating}
                step={1}
                defaultValue={[
                  baseFilters.comfortRating.min,
                  baseFilters.comfortRating.max,
                ]}
                marks={comfortRating}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("comfortRating", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Limit Rating</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) =>
                  useCelsius
                    ? `${Math.floor((value - 32) * (5 / 9))}\xB0 C`
                    : `${value}\xB0 F`
                }
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.limitRating}
                step={1}
                defaultValue={[
                  baseFilters.limitRating.min,
                  baseFilters.limitRating.max,
                ]}
                marks={limitRating}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("limitRating", value);
                }}
              />
            </FormControl>
          </Box>
          <Box
            sx={{ minWidth: "200px", paddingTop: "7px", paddingBottom: "15px" }}
          >
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Fits To</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                valueLabelFormat={(value) =>
                  useMetricDimensions
                    ? `${Math.floor(value * 2.54)}cm`
                    : `${value}"`
                }
                size="small"
                min={baseFilters.fitsTo.min}
                max={baseFilters.fitsTo.max}
                step={0.5}
                defaultValue={[baseFilters.fitsTo.min, baseFilters.fitsTo.max]}
                marks={fitsToMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("fitsTo", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Type</FormLabel>
              <Divider />
              <FormGroup>
                <FormControlLabel
                  key="sleeping-bag-type-filter"
                  control={
                    <Checkbox
                      checked={userFilters.group.includes("sleeping-bags")}
                      name="Sleeping Bags"
                    />
                  }
                  label="Sleeping Bags"
                  onChange={(e, value) => {
                    handleChangeFilter("group", "sleeping-bags", value);
                  }}
                />
                <FormControlLabel
                  key="quilt-type-filter"
                  control={
                    <Checkbox
                      checked={userFilters.group.includes("quilts")}
                      name="Quilts"
                    />
                  }
                  label="Quilts"
                  onChange={(e, value) => {
                    handleChangeFilter("group", "quilts", value);
                  }}
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Gender</FormLabel>
              <Divider />
              <FormGroup>
                <FormControlLabel
                  key="gender-men-filter"
                  control={
                    <Checkbox
                      checked={userFilters.gender.includes("men")}
                      name="Men's"
                    />
                  }
                  label="Men's"
                  onChange={(e, value) => {
                    handleChangeFilter("gender", "men", value);
                  }}
                />
                <FormControlLabel
                  key="gender-women-filter"
                  control={
                    <Checkbox
                      checked={userFilters.gender.includes("women")}
                      name="Women's"
                    />
                  }
                  label="Women's"
                  onChange={(e, value) => {
                    handleChangeFilter("gender", "women", value);
                  }}
                />
                <FormControlLabel
                  key="gender-unisex-filter"
                  control={
                    <Checkbox
                      checked={userFilters.gender.includes("unisex")}
                      name="Unisex"
                    />
                  }
                  label="Unisex"
                  onChange={(e, value) => {
                    handleChangeFilter("gender", "unisex", value);
                  }}
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Width</FormLabel>
              <Divider />
              <FormGroup>
                <FormControlLabel
                  key="slim-filter"
                  control={
                    <Checkbox
                      checked={userFilters.width.includes("slim")}
                      name="Slim"
                    />
                  }
                  label="Slim"
                  onChange={(e, value) => {
                    handleChangeFilter("width", "slim", value);
                  }}
                />
                <FormControlLabel
                  key="regular-filter"
                  control={
                    <Checkbox
                      checked={userFilters.width.includes("regular")}
                      name="Regular"
                    />
                  }
                  label="Regular"
                  onChange={(e, value) => {
                    handleChangeFilter("width", "regular", value);
                  }}
                />
                <FormControlLabel
                  key="wide-filter"
                  control={
                    <Checkbox
                      checked={userFilters.width.includes("wide")}
                      name="Wide"
                    />
                  }
                  label="Wide"
                  onChange={(e, value) => {
                    handleChangeFilter("width", "wide", value);
                  }}
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Insulation Type</FormLabel>
              <Divider />
              <FormGroup>
                <FormControlLabel
                  key={`down-filter`}
                  control={
                    <Checkbox
                      checked={userFilters.insulationType.includes("down")}
                      name="Down"
                    />
                  }
                  label="Down"
                  onChange={(e, value) => {
                    handleChangeFilter("insulationType", "down", value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  key={`synthetic-filter`}
                  control={
                    <Checkbox
                      checked={userFilters.insulationType.includes("synthetic")}
                      name="Synthetic"
                    />
                  }
                  label="Synthetic"
                  onChange={(e, value) => {
                    handleChangeFilter("insulationType", "synthetic", value);
                  }}
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Shape</FormLabel>
              <Divider />
              <Button
                sx={{
                  justifyContent: "left",
                  paddingLeft: "0px",
                  width: "150px",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  const newFilters = { ...userFilters };
                  if (userFilters.type.length !== baseFilters.types.length) {
                    newFilters.type = baseFilters.types;
                  } else {
                    newFilters.type = [];
                  }
                  setUserFilters(newFilters);
                }}
              >
                {userFilters.type.length !== baseFilters.types.length
                  ? "Select All"
                  : "Deselect All"}
              </Button>
              <FormGroup>
                {baseFilters.types.map((type) => (
                  <FormControlLabel
                    key={`${type}-shape-filter`}
                    control={
                      <Checkbox
                        checked={userFilters.type.includes(type.toLowerCase())}
                        name={type}
                      />
                    }
                    label={formatBrand(type)}
                    onChange={(e, value) => {
                      handleChangeFilter("type", type.toLowerCase(), value);
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Stack>
        <Box sx={{ minWidth: "200px" }}>
          <FormControl
            sx={{ width: "100%" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Brand</FormLabel>
            <Divider />
            <Button
              sx={{
                justifyContent: "left",
                paddingLeft: "0px",
                width: "150px",
                "&:hover": {
                  opacity: 0.8,
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => {
                const newFilters = { ...userFilters };
                if (userFilters.brand.length !== baseFilters.brands.length) {
                  newFilters.brand = baseFilters.brands;
                } else {
                  newFilters.brand = [];
                }
                setUserFilters(newFilters);
              }}
            >
              {userFilters.brand.length !== baseFilters.brands.length
                ? "Select All"
                : "Deselect All"}
            </Button>
            <FormGroup>
              {baseFilters.brands.map((brand) => (
                <FormControlLabel
                  key={`${brand}-brand-filter`}
                  control={
                    <Checkbox
                      checked={userFilters.brand.includes(brand)}
                      name={brand}
                    />
                  }
                  label={formatBrand(brand)}
                  onChange={(e, value) => {
                    handleChangeFilter("brand", brand, value);
                  }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Stack>
    </Container>
  );
};

export default SleepingBagFilterBar;
