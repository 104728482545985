import React from "react";

import Card from "@mui/material/Card";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const NewFeaturePopup = () => {
  /**
   * TODO:
   * Next time this is updated, set a value of the current date of release, and check if date in browser is < date of release
   */
  const userHasDismissed = localStorage.getItem("newFeaturesIsDismissed");
  let shouldOpen = false;
  if (userHasDismissed) {
    if (new Date(userHasDismissed) >= new Date("12/20/23")) {
      shouldOpen = false;
    }
  }
  const [open, setOpen] = React.useState(shouldOpen);
  const handleClose = () => {
    setOpen(false);
    const dateOfClose = new Date(Date.now()).toLocaleString().split(",")[0];
    localStorage.setItem("newFeaturesIsDismissed", dateOfClose);
  };

  return open ? (
    <Card
      sx={{
        height: "350px",
        width: "420px",
        right: 20,
        bottom: 20,
        position: "fixed",
        zIndex: 1000,
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      }}
    >
      <Box sx={{ paddingTop: "10px", paddingLeft: "10px" }}>
        <Stack>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Box sx={{ width: "340px", height: "40px" }}>
              <Typography variant="h5" sx={{ paddingTop: "5px" }}>
                New Features
              </Typography>
            </Box>
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography sx={{ paddingLeft: "10px" }} variant="subtitle2">
            December 20, 2023
          </Typography>
          <Box
            style={{
              marginLeft: "-15px",
              marginTop: "-10px",
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            <ul>
              <li>
                <Typography variant="h7">Gear Closet Updates</Typography>
                <ul style={{ marginLeft: "-25px" }}>
                  <li style={{ listStyleType: "decimal", fontSize: "14px" }}>
                    <Typography variant="subtitle2" style={{ color: "grey" }}>
                      Gear closet has a new look that should look quite a bit
                      better on mobile!
                    </Typography>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "14px" }}>
                    <Typography variant="subtitle2" style={{ color: "grey" }}>
                      We've also added "bins" to allow you to categorize your
                      gear closet into groups that you can then add to packs or
                      use to keep track of gear in other ways.
                    </Typography>
                  </li>
                  <li style={{ listStyleType: "decimal", fontSize: "14px" }}>
                    <Typography variant="subtitle2" style={{ color: "grey" }}>
                      If you've imported multiple packs from lighterpack in the
                      past, you may have noticed your gear closet might contain
                      duplicate items. We've now added the ability to ignore
                      duplicates upon import, as well as clean out your gear
                      closet by removing duplicates with a single click.
                    </Typography>
                  </li>
                </ul>
              </li>
            </ul>
          </Box>
        </Stack>
      </Box>
    </Card>
  ) : (
    <></>
  );
};

export default NewFeaturePopup;
