import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { Tooltip } from "@mui/material";

// Unfortunately, ToggleButtonGroup uses React.children.map instead of context
// so wrapping with Tooltip breaks ToggleButton functionality.
// This is a workaround.
const TooltipToggleButton = ({ children, title, ...props }) => (
  <Tooltip title={title}>
    <ToggleButton {...props}>{children}</ToggleButton>
  </Tooltip>
);

export default TooltipToggleButton;
