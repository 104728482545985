import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { Container } from "@mui/system";
import { useParams } from "react-router-dom";
import { getColorWithMode } from "../constants/colors";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import OverallSummaryRow from "./OverallSummaryRow";
import SideContainer from "./SideContainer";
import ShareTableContainer from "./ShareTableContainer";
import {
  getLastUpdatedAtText,
  getSharedPackWithId,
  getUserIsLoggedIn,
  getWeightInGrams,
  getFormattedWeightInUnit,
  handleUserPackVote,
} from "../utils";
import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import SkeletonPack from "./SkeletonPack";
import PackDescription from "./PackDescription";
import KebabMenu from "./KebabMenu";
import { Helmet } from "react-helmet";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TimerIcon from "@mui/icons-material/Timer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DURATION_SETTINGS, SEASON_SETTINGS } from "../constants/packSettings";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ShareSharedPackDialog from "./ShareSharedPackDialog";
import PersonIcon from "@mui/icons-material/Person";
import momenttz from "moment-timezone";

const formatDate = (date) => {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // subtract date because server returns time since update
  const updatedAt = Date.now() - date;
  return momenttz(updatedAt).tz(timeZoneString).format("MMM DD, YYYY");
};

const getDisplayChips = (tags, isMobile) => {
  const { duration, season, trail } = tags;
  return [
    trail && trail.length ? (
      <Chip
        key={trail}
        icon={<LocationOnIcon />}
        color="info"
        variant="outlined"
        size={isMobile ? "large" : "small"}
        label={trail}
        sx={{ maxWidth: "180px", overflowX: "ellipsis", whitespace: "nowrap" }}
      />
    ) : null,
    season && season.length ? (
      <Chip
        key={season}
        icon={<CalendarMonthIcon />}
        color="warning"
        variant="outlined"
        size={isMobile ? "large" : "small"}
        label={SEASON_SETTINGS[season]}
      />
    ) : null,
    duration && duration.length ? (
      <Chip
        key={duration}
        icon={<TimerIcon />}
        color="secondary"
        variant="outlined"
        size={isMobile ? "large" : "small"}
        label={DURATION_SETTINGS[duration]}
      />
    ) : null,
  ].filter((chip) => chip);
};

const getGenericChips = (tags, isMobile) => {
  const { tag0, tag1, tag2 } = tags;
  return [tag0, tag1, tag2]
    .map((tag) => {
      if (tag) {
        return (
          <Chip
            key={tag}
            size={isMobile ? "large" : "small"}
            color="success"
            variant="outlined"
            label={tag}
          />
        );
      } else {
        return null;
      }
    })
    .filter((chip) => chip);
};

const getDefaultTags = (userTags = []) => {
  const tagMap = userTags.reduce(
    (acc, { type, value }) => {
      acc[type] = value;
      return acc;
    },
    {
      duration: "",
      season: "",
      trail: "",
      tag0: "",
      tag1: "",
      tag2: "",
    }
  );
  return tagMap;
};

const ShareContainer = ({
  setMainAlertDetails,
  setMainAlertIsOpen,
  isDarkMode,
  handleSelectNewPack,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const { id } = useParams();
  const [packName, setPackName] = React.useState("");
  const [tableId, setTableId] = React.useState(null);
  const [packDescription, setPackDescription] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [unit, setUnit] = React.useState("lb");
  const [hasError, setHasError] = React.useState(false);
  const [packVisualizationToggleState, setPackVisualizationToggleState] =
    React.useState(false);
  const [packIsLoading, setPackIsLoading] = React.useState(true);
  const [tags, setTags] = React.useState({});
  const [voteCount, setVoteCount] = React.useState(1);
  const [packIsUpvoted, setPackIsUpvoted] = React.useState(false);
  const [packIsDownvoted, setPackIsDownvoted] = React.useState(false);
  const [affiliateLinksAreOff, setAffiliateLinksAreOff] = React.useState(false);
  const [shareSharedPackDialogIsOpen, setShareSharedPackDialogIsOpen] =
    React.useState(false);
  const [shareableId, setShareableId] = React.useState("");
  const [sideNavIsOpen, setSideNavIsOpen] = React.useState(true);
  const [packOwner, setPackOwner] = React.useState(true);
  const [lastUpdated, setLastUpdated] = React.useState(null);
  const [overridingUnit, setOverridingUnit] = React.useState(null);

  useEffect(() => {
    (async () => {
      const packData = await getSharedPackWithId(id);
      if (packData.error) {
        setHasError(true);
      } else {
        if (packData.packUnit) setUnit(packData.packUnit);
        setPackName(packData.packName);
        setTableData(packData.tableData);
        setPackDescription(packData.packDescription);
        setTableId(packData.tableId);
        setTags(getDefaultTags(packData.tags));
        setVoteCount(packData.voteCount || 0);
        const userUpvoteList = packData.userUpvotes || [];
        const userDownvoteList = packData.userDownvotes || [];
        setPackIsUpvoted(userUpvoteList.includes(packData.tableId));
        setPackIsDownvoted(userDownvoteList.includes(packData.tableId));
        setAffiliateLinksAreOff(packData.hasAffiliateLinksTurnedOff || false);
        setShareableId(packData.shareableId);
        setPackOwner(packData.username);
        setLastUpdated(packData.lastUpdated);
      }
      setPackIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    const newTableData = tableData.map((data) => ({
      ...data,
      summaryRowUnit: unit,
    }));
    setTableData(newTableData);
  }, [unit]);

  const { weightInGrams, price } = tableData.reduce(
    (acc, curr) => {
      const { summedRowWeight, summedRowPrice } = Object.values(
        curr.rows
      ).reduce(
        (acc, curr) => {
          return {
            summedRowWeight:
              acc.summedRowWeight +
              getWeightInGrams(curr.weight, curr.unit) * curr.quantity,
            summedRowPrice: acc.summedRowPrice + curr.price * curr.quantity,
          };
        },
        { summedRowWeight: 0, summedRowPrice: 0 }
      );
      return {
        weightInGrams: acc.weightInGrams + summedRowWeight,
        price: acc.price + summedRowPrice,
      };
    },
    {
      weightInGrams: 0,
      price: 0,
    }
  );

  const handleSetPackToImperial = () => {
    setOverridingUnit("IMPERIAL");
    setUnit("lb");
  };

  const handleSetPackToMetric = () => {
    setOverridingUnit("METRIC");
    setUnit("kg");
  };

  const handleSetTableData = (tableIndex, key, value) => {
    const newData = [...tableData];
    newData[tableIndex][key] = value;
    setTableData(newData);
  };

  const getColorForUpvote = (packIsUpvoted, packIsDownvoted) => {
    if (packIsUpvoted) return isDarkMode ? "#4caf50" : "#1b5e20";
    if (packIsDownvoted) return isDarkMode ? "#ff9800" : "#e65100";
    return "";
  };

  const getUpvoteCount = (count = 0, packIsUpvoted, packIsDownvoted) => {
    let finalCount = count > 1 ? count : 1;
    if (packIsUpvoted) finalCount += 1;
    if (packIsDownvoted) finalCount -= 1;
    return finalCount;
  };

  const handleVote = (voteType, packId) => {
    if (voteType === "upvote") {
      if (packIsUpvoted) {
        setPackIsUpvoted(false);
        handleUserPackVote("UPVOTE", packId);
      } else {
        setPackIsUpvoted(true);
        setPackIsDownvoted(false);
        handleUserPackVote("UPVOTE", packId);
      }
    } else if (voteType === "downvote") {
      if (packIsDownvoted) {
        setPackIsDownvoted(false);
        handleUserPackVote("DOWNVOTE", packId);
      } else {
        setPackIsUpvoted(false);
        setPackIsDownvoted(true);
        handleUserPackVote("DOWNVOTE", packId);
      }
    }
  };

  const setShareSharedPackIsOpen = () => {
    setShareSharedPackDialogIsOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>{packName}</title>
        <meta
          property="og:title"
          content={`${packName} - ${getFormattedWeightInUnit(
            weightInGrams,
            false
          )} / ${getFormattedWeightInUnit(weightInGrams, true)}`}
        />
        <meta property="og:description" content={packDescription || ""} />
        <link rel="canonical" href={`https://www.packwizard.com/s/${id}`} />
        <meta
          property="og:image"
          content={`/api/image/packPreview?shareableId=${id}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${packName} - ${getFormattedWeightInUnit(
            weightInGrams,
            false
          )} / ${getFormattedWeightInUnit(weightInGrams, true)}`}
        />
        <meta name="twitter:description" content={packDescription || ""} />
        <meta
          name="twitter:image"
          content={`/api/image/packPreview?shareableId=${id}`}
        />
        <meta
          name="keywords"
          content={`${packName ? packName.split(" ").join(",") : ""}`}
        />
        <meta name="description" content={`${packName} - ${packDescription}`} />
      </Helmet>
      <ShareSharedPackDialog
        shareSharedPackDialogIsOpen={shareSharedPackDialogIsOpen}
        setShareSharedPackDialogIsOpen={setShareSharedPackDialogIsOpen}
        shareableLink={shareableId}
      />
      <Container
        fixed
        style={{
          backgroundColor: getColorWithMode(isDarkMode, "base"),
          float: "right",
          width: isDesktopOrLaptop ? "auto" : "100%",
          maxWidth: "100vw",
          minHeight: "920px",
          minWidth: "750px",
        }}
      >
        <Stack
          justifyContent="space-between"
          alignContent={isMobile ? "center" : ""}
          alignItems={"center"}
          sx={{
            minHeight: "720px",
            width: isDesktopOrLaptop ? "1140px" : "auto",
            maxWidth: "100vw",
            margin: "0 auto",
          }}
        >
          <Button
            size={isMobile ? "large" : "small"}
            sx={{ marginTop: "10px" }}
            onClick={() => {
              setSideNavIsOpen(!sideNavIsOpen);
            }}
          >
            {sideNavIsOpen ? "Hide " : "Show "} Pack Stats & Item Suggestions
          </Button>
          <Box>
            <Collapse in={sideNavIsOpen}>
              <SideContainer
                key={`${JSON.stringify(tableData)}`}
                tableData={tableData}
                unit={unit}
                toggleState={packVisualizationToggleState}
                setToggleState={setPackVisualizationToggleState}
                packIsLoading={packIsLoading}
                darkMode={isDarkMode}
              />
            </Collapse>
          </Box>
          <Stack sx={{ width: "100%", marginLeft: "10px" }}>
            {hasError ? (
              <Container sx={{ width: "805px" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    textAlign: "center",
                    marginTop: "200px",
                  }}
                >
                  There is no pack associated with this link
                </Typography>
              </Container>
            ) : (
              <>
                <Container
                  fixed
                  sx={{
                    width: "100%",
                    overflowX: "hidden",
                    float: "left",
                    marginLeft: "0px",
                    minHeight: sideNavIsOpen ? "475px" : "785px",
                    maxWidth: "100vw",
                  }}
                >
                  {packIsLoading ? (
                    <SkeletonPack />
                  ) : (
                    <Stack sx={{ width: "100%", maxWidth: "100vw" }}>
                      <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        alignItems="center"
                        sx={{ width: "calc(100% + 10px)" }}
                      >
                        <Typography
                          variant="h1"
                          fontSize={isMobile ? "32px" : "30px"}
                        >
                          {packName}
                        </Typography>
                        <Stack direction="row">
                          <Paper
                            sx={{
                              height: isMobile ? "60px" : "48px",
                            }}
                          >
                            <Stack>
                              <Typography
                                textAlign="center"
                                variant="subtitle2"
                                sx={{
                                  textDecoration: "underline",
                                  opacity: 0.8,
                                }}
                              >
                                Rate
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={0.5}
                                justifyContent="space-between"
                                sx={{ height: "100%", marginTop: "-7px" }}
                                alignItems="center"
                              >
                                <IconButton
                                  size={isMobile ? "large" : "small"}
                                  sx={{ borderRadius: "0px" }}
                                  disabled={!getUserIsLoggedIn()}
                                  onClick={() => {
                                    handleVote("upvote", tableId);
                                  }}
                                >
                                  <ThumbUpOffAltIcon
                                    color={packIsUpvoted ? "success" : ""}
                                  />
                                </IconButton>
                                <Box
                                  sx={{
                                    color: getColorForUpvote(
                                      packIsUpvoted,
                                      packIsDownvoted
                                    ),
                                  }}
                                >
                                  {getUpvoteCount(
                                    voteCount,
                                    packIsUpvoted,
                                    packIsDownvoted
                                  )}
                                </Box>
                                <IconButton
                                  size={isMobile ? "large" : "small"}
                                  sx={{ borderRadius: "0px" }}
                                  disabled={!getUserIsLoggedIn()}
                                  onClick={() => {
                                    handleVote("downvote", tableId);
                                  }}
                                >
                                  <ThumbDownOffAltIcon
                                    color={packIsDownvoted ? "warning" : ""}
                                  />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </Paper>
                          <KebabMenu
                            isSharedPack
                            tableId={tableId}
                            setMainAlertDetails={setMainAlertDetails}
                            setMainAlertIsOpen={setMainAlertIsOpen}
                            handleSelectNewPack={handleSelectNewPack}
                            setShareSharedPackIsOpen={setShareSharedPackIsOpen}
                            handleSetPackToMetric={handleSetPackToMetric}
                            handleSetPackToImperial={handleSetPackToImperial}
                          />
                        </Stack>
                      </Stack>
                      {isMobile ? (
                        <Stack
                          direction="row"
                          spacing={0}
                          justifyContent="flex-end"
                          sx={{ marginTop: "0px" }}
                        >
                          <Typography variant="subtitle2">
                            {`Last updated: ${formatDate(lastUpdated)}`}
                          </Typography>
                        </Stack>
                      ) : (
                        <></>
                      )}
                      <Stack
                        direction="row"
                        sx={{
                          marginTop: "5px !important",
                          height: isMobile ? "50px" : "40px",
                        }}
                        spacing={1}
                      >
                        <Stack
                          direction="row"
                          sx={{ width: "100%" }}
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ maxWidth: "100vw", overflowX: "scroll" }}
                          >
                            {packOwner ? (
                              <Chip
                                key={`${packOwner}-chip`}
                                icon={<PersonIcon />}
                                variant="outlined"
                                size={isMobile ? "large" : "small"}
                                label={`@${packOwner}`}
                                onClick={() => {
                                  window.location.assign(`/user/${packOwner}`);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            {getDisplayChips(tags, isMobile)}
                            {getGenericChips(tags, isMobile)}
                          </Stack>
                          {!isMobile ? (
                            <Stack
                              direction="row"
                              spacing={0}
                              sx={{ marginTop: "0px" }}
                            >
                              <Typography variant="subtitle2">
                                {`Last updated: ${formatDate(lastUpdated)}`}
                              </Typography>
                            </Stack>
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </Stack>
                      <PackDescription
                        packDescription={packDescription}
                        shareable
                        darkMode={isDarkMode}
                      />
                      <Stack
                        spacing={2}
                        sx={{ maxWidth: "100vw", marginTop: "5px" }}
                      >
                        {tableData.map((individualTableData, tableIndex) => (
                          <ShareTableContainer
                            overridingUnit={overridingUnit}
                            key={tableIndex}
                            tableData={individualTableData}
                            tableIndex={tableIndex}
                            handleSetTableData={handleSetTableData}
                            isDarkMode={isDarkMode}
                            affiliateLinksAreOff={affiliateLinksAreOff}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </Container>
                <OverallSummaryRow
                  isShared
                  tableData={tableData}
                  unit={unit}
                  setUnit={setUnit}
                  darkMode={isDarkMode}
                  weightInGrams={weightInGrams}
                  price={price}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default ShareContainer;
