import { Divider, Stack, Typography } from "@mui/material";
import React from "react";

import GearBin from "./GearBin";

const GearBinContainer = ({
  gearBins,
  handleDeleteFromBin,
  handleDeleteBin,
  handleFilterToBin,
  handleAddBinToPack,
  handleUpdateBinText,
  darkMode,
}) => {
  return (
    <Stack spacing={1} sx={{ paddingBottom: "20px", paddingTop: "20px" }}>
      <Typography variant="h6">Gear Bins</Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: "#BBB",
          fontStyle: "italic",
          maxWidth: "800px",
        }}
      >
        Select items from your gear closet and create bins to manage and add
        your gear more effectively
      </Typography>
      <Divider />
      <Stack
        spacing={1}
        textAlign="center"
        sx={{ maxHeight: "300px", overflowY: "scroll" }}
      >
        {gearBins.length === 0 ? (
          <Typography>
            You have no gear bins. Select items from your gear closet and add
            them to a bin to start using gear bins.
          </Typography>
        ) : (
          gearBins.map((bin, index) => (
            <GearBin
              key={bin.id}
              bin={bin}
              binIndex={index}
              handleDeleteFromBin={handleDeleteFromBin}
              handleDeleteBin={handleDeleteBin}
              handleFilterToBin={handleFilterToBin}
              handleAddBinToPack={handleAddBinToPack}
              handleUpdateBinText={handleUpdateBinText}
              darkMode={darkMode}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};

export default GearBinContainer;
