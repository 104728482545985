import { Container } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../constants/colors";
import DynamicArticleGenerator from "./Articles/DynamicArticleGenerator";

const ArticleContainer = ({ articleId, isDarkMode }) => {
  return (
    <Container
      sx={{
        minWidth: "360px",
        width: "100%",
        maxWidth: "1440px",
        backgroundColor: getColorWithMode(isDarkMode, "base"),
        minHeight: "calc(100vh - 50px)",
        whiteSpace: "wrap",
        padding: "0px 0px 0px 0px",
      }}
    >
      <DynamicArticleGenerator articleId={articleId} isDarkMode={isDarkMode} />
    </Container>
  );
};

export default ArticleContainer;
