import React from "react";
import { Doughnut } from "react-chartjs-2";
import { getWeightInGrams } from "../utils";
import * as _ from "lodash";
import { Chart, ArcElement } from "chart.js";
import { Box } from "@mui/system";
import { Paper, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";

Chart.register(ArcElement);

const getLabelFormat = (index, data) => {
  if (!data.length) return "";
  const { weight, unit, item, quantity } = data[index];
  return `${item} (${(weight * quantity).toFixed(2)}${unit})`;
};

const DoughnutChart = ({
  labels,
  packDataArray,
  colorArray,
  sliceLabelInfo,
  setSliceLabelInfo,
  isDesktopOrLaptop,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const handleSetSliceLabelInfo = (index, data) => {
    if (!data.length)
      setSliceLabelInfo({
        label: "",
        weight: "",
      });
    const { weight, unit, item, quantity } = data[index];
    const newLabel = item;
    const newWeight = `(${(weight * quantity).toFixed(2)}${unit})`;
    if (
      newLabel !== sliceLabelInfo.label ||
      newWeight !== sliceLabelInfo.weight
    ) {
      setSliceLabelInfo({ label: newLabel, weight: newWeight });
    }
  };
  const data = packDataArray.map(
    ({ weight, unit, quantity }) => getWeightInGrams(weight, unit) * quantity
  );
  return (
    <Stack
      sx={{
        height: isDesktopOrLaptop ? "220px" : isMobile ? "275px" : "180px",
        width: isDesktopOrLaptop ? "220px" : isMobile ? "275px" : "180px",
        padding: "0px 0px 0px 0px",
      }}
      justifyContent="center"
      justifyItems="center"
      alignItems={"center"}
      alignContent={"center"}
    >
      {sliceLabelInfo.label.length > 0 && (
        <Paper
          elevation={5}
          sx={{
            position: "absolute",
            height: isMobile ? "40px" : "30px",
            width: isDesktopOrLaptop ? "210px" : isMobile ? "240px" : "170px",
          }}
        >
          <Stack
            direction="row"
            sx={{
              width: "100%",
              margin: "0 auto",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                maxWidth: "160px",
                fontSize: isMobile ? "18px" : "14px",
                lineHeight: isMobile ? "38px" : "30px",
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                marginLeft: "10px",
              }}
            >
              {`${sliceLabelInfo.label} `}
            </Typography>
            <Typography
              sx={{
                width: "80px",
                fontSize: isMobile ? "18px" : "14px",
                lineHeight: isMobile ? "38px" : "30px",
                whiteSpace: "nowrap",
                paddingLeft: "5px",
              }}
            >
              {`${sliceLabelInfo.weight}`}
            </Typography>
          </Stack>
        </Paper>
      )}
      <Doughnut
        data={{
          labels: labels,
          datasets: [
            {
              data,
              borderColor: ["rgba(255,206,86,0.2)"],
              backgroundColor: colorArray,
              pointBackgroundColor: "rgba(255,206,86,0.2)",
              hoverOffset: 4,
            },
          ],
        }}
        options={{
          animation: {
            duration: 2500,
          },
          onHover: function (e, item) {
            if (item.length) {
              let dataPointIndex = item[0].index;
              handleSetSliceLabelInfo(dataPointIndex, packDataArray);
            }
          },
        }}
      />
    </Stack>
  );
};

export default DoughnutChart;
