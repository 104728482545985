import * as React from "react";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { handleSignIn } from "../auth";
import FormHelperText from "@mui/material/FormHelperText";

const SignInForm = ({
  setIsLogin,
  handleSetUserAndClose,
  setForgotPasswordFormIsOpen,
}) => {
  const [credentialError, setCredentialError] = React.useState(false);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickSignUp = () => {
    setIsLogin(false);
  };

  const handleSubmit = async () => {
    const signInResponse = await handleSignIn(values);
    const { email, token } = signInResponse;
    if (email && token) {
      handleSetUserAndClose(signInResponse);
    } else {
      setCredentialError(true);
    }
  };

  const handleClickForgotPassword = () => {
    setForgotPasswordFormIsOpen(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <DialogTitle
        style={{
          width: "400px",
          textAlign: "center",
          paddingTop: "30px",
          fontWeight: "bold",
        }}
      >
        LOGIN
      </DialogTitle>
      <DialogContent
        style={{ width: "400px", height: "380px", paddingTop: "30px" }}
      >
        <DialogContentText></DialogContentText>
        <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
          <TextField
            error={credentialError}
            label="Email"
            defaultValue=""
            style={{ width: "336px" }}
            variant="standard"
            onChange={handleChange("email")}
          />
        </FormControl>
        <FormControl
          sx={{ m: 1, width: "25ch", marginTop: "30px" }}
          variant="standard"
        >
          <InputLabel htmlFor="standard-adornment-password">
            Password
          </InputLabel>
          <Input
            error={credentialError}
            style={{ width: "336px" }}
            type={values.showPassword ? "text" : "password"}
            onKeyPress={handleKeyPress}
            value={values.password}
            onChange={handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {credentialError ? (
          <FormHelperText
            error
            style={{ marginLeft: "10px", width: "300px" }}
            id="component-error-text"
          >
            Invalid Credentials
          </FormHelperText>
        ) : (
          <></>
        )}
        <Button
          variant="contained"
          style={{ width: "340px", marginLeft: "5px", marginTop: "30px" }}
          onClick={() => handleSubmit()}
        >
          LOGIN
        </Button>
        <Button
          variant="text"
          style={{
            float: "right",
            marginTop: "0px",
            color: "white",
            textTransform: "none",
          }}
          onClick={handleClickForgotPassword}
        >
          Forgot your password?
        </Button>
        {/*
                <Divider style={{ marginTop: '50px', marginLeft: '4px', width: '300px', fontStyle: 'italic' }}>or</Divider>
                <GoogleSignInButton handleSetUserAndClose={handleSetUserAndClose} />
                 */}
        <Button
          variant="text"
          style={{
            width: "336px",
            marginLeft: "5px",
            textAlign: "center",
            marginTop: "40px",
            color: "white",
            textTransform: "none",
          }}
          onClick={handleClickSignUp}
        >
          Don't have an account? Sign Up
        </Button>
      </DialogContent>
    </>
  );
};

export default SignInForm;
