import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import Grid from "@mui/material/Grid";
import { getItemsForAutocomplete, getWeightInGrams } from "../utils";
import _ from "lodash";

const getVariant = (variant) => {
  if (variant && variant.length > 0) {
    return ` (${variant})`;
  } else {
    return "";
  }
};

const formatDisplayWeight = (displayWeight, unit) => {
  if (!displayWeight || displayWeight === 0) return "";
  const weightInGrams = unit
    ? getWeightInGrams(Number(displayWeight), unit)
    : Number(displayWeight);
  const metric =
    weightInGrams > 1000
      ? `${(weightInGrams / 1000).toFixed(2)}kg`
      : `${weightInGrams}g`;
  const pounds = Math.floor(weightInGrams / 454);
  const ounces = Math.floor((weightInGrams % 454) / 28);
  const imperial = pounds === 0 ? `${ounces}oz` : `${pounds}lb ${ounces}oz`;
  return `${metric} / ${imperial}`;
};

const ItemLookupAutocomplete = ({
  handleSetItemWithAutocomplete,
  handleUpdateItemToBeAdded,
  isGearCloset,
}) => {
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  const fetch = React.useMemo(
    () =>
      debounce(async (request, callback) => {
        const items = await getItemsForAutocomplete(request);
        callback(items);
      }, 800),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ inputValue }, (items) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (items) {
          newOptions = [...newOptions, ...items];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      sx={{ width: isGearCloset ? "245px" : "340px" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.item
      }
      freeSolo
      getOptionDisabled={(option) => option.isDisabled === true}
      groupBy={(option) => option.storageType}
      variant="outlined"
      size="small"
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No Items"
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.target.blur();
        }
        event.stopPropagation();
      }}
      onChange={(event, newValue) => {
        // typing
        handleSetItemWithAutocomplete(newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        // fires when an item is selected but not when clicks away
        setInputValue(newInputValue);
        handleUpdateItemToBeAdded("item", newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="Item" fullWidth />}
      renderOption={(props, option) => {
        return (
          <li {...props} key={_.uniqueId("item_")}>
            <Grid container direction="column">
              <Grid item>{`${option.brand ? `${option.brand} ` : ""}${
                option.item
              }${getVariant(option.variant)}`}</Grid>
              <Grid sx={{ fontSize: "14px" }} item>
                {formatDisplayWeight(option.displayWeight, option.unit)}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default ItemLookupAutocomplete;
