import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

import { fetchItemForItemDisplay } from "../../api/gear";
import { buildUrl } from "../../utils/gear";
import { useMediaQuery } from "react-responsive";
import PackWizardCompareLinkButton from "../PackWizardCompareLinkButton";
import ItemRetailerModule from "../GearComparison/ItemRetailerModule";

const ITEM_TYPE_TO_COMPARE_TYPE = {
  sleepingPad: "PACKWIZARD_SP",
  tent: "PACKWIZARD_TENT",
  backpack: "PACKWIZARD_PACK",
  sleepingBag: "PACKWIZARD_SB",
  insulatedJacket: "PACKWIZARD_IJ",
};

const LoadingState = () => {
  return <Paper>Loading...</Paper>;
};

const ArticleGearModule = ({ gear, isDarkMode }) => {
  const { gearType, id, header, product, description } = gear;
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const isTablet = useMediaQuery({ maxWidth: 1000 });
  const [showFullTable, setShowFullTable] = React.useState(false);

  const [item, setItem] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      if (gearType && gearType.length && id && id.length) {
        const fetchedItem = await fetchItemForItemDisplay(gearType, id);
        setItem(fetchedItem);
      }
    })();
  }, [id, gearType]);
  if (!item) return <LoadingState />;
  if (item.error) return <></>;
  return (
    <>
      <h2>{header}</h2>
      <Paper sx={{ paddingBottom: "20px" }}>
        <Container>
          <Stack>
            <Stack
              direction={isMobile ? "column" : "row"}
              gap={isMobile ? 1 : 2}
              alignItems={isMobile ? "center" : "left"}
            >
              <Box
                sx={{
                  marginTop: "10px",
                  textAlign: "center",
                  backgroundColor: "white",
                  width: "240px",
                  minWidth: "240px",
                  height: "240px",
                  overflow: "hidden",
                  position: "relative",
                  borderRadius: "5px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    borderRadius: "5px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxHeight: 240,
                    maxWidth: 240,
                    objectFit: "cover",
                    "&:hover": {
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                  alt={`${item.brand} ${item.name}`}
                  src={item.imageUrl}
                  onClick={() => {
                    window.open(buildUrl(gearType, id), "_blank").focus();
                  }}
                />
              </Box>
              <Stack sx={{ paddingTop: "5px" }} gap={2}>
                <Typography fontSize="24px" variant="h2">
                  {product}
                </Typography>
                <Typography>{description}</Typography>
              </Stack>
            </Stack>
            <Divider sx={{ paddingTop: "20px" }} />
            <br />
            <Container>
              <Stack
                direction={isMobile || isTablet ? "column" : "row"}
                gap={2}
                justifyContent={"center"}
              >
                <Stack sx={{ width: "100%" }}>
                  <Typography
                    variant="h6"
                    sx={{ width: "100%", textAlign: "center" }}
                  >
                    Specifications & Materials
                  </Typography>
                  <TableContainer
                    sx={{
                      overflow: "hidden",
                      maxHeight: showFullTable
                        ? "100%"
                        : `${
                            item.retailers.length
                              ? item.retailers.length * 60 + 100
                              : 300
                          }px`,
                      ...(!showFullTable
                        ? {
                            WebkitBoxShadow:
                              "inset 0px -10px 10px -10px rgba(0,0,0,0.19)",
                            MozBoxShadow:
                              "inset 0px -10px 10px -10px rgba(0,0,0,0.19)",
                            boxShadow:
                              "inset 0px -10px 10px -10px rgba(0,0,0,0.19)",
                          }
                        : {}),
                    }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Spec</TableCell>
                          <TableCell>Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(item.techSpecs || []).map(({ label, value }) => (
                          <TableRow key={`${product}-${label}`}>
                            <TableCell>
                              <b>{label} </b>
                            </TableCell>
                            <TableCell>
                              {value && value.length ? value : "-"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Button
                    onClick={() => setShowFullTable(!showFullTable)}
                  >{`Show ${showFullTable ? "Less" : "More"}`}</Button>
                </Stack>
                {!isMobile && !isTablet && (
                  <Divider flexItem orientation="vertical" />
                )}
                <Stack
                  sx={{ width: "100%" }}
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <ItemRetailerModule
                    darkMode={isDarkMode}
                    item={item}
                    dynamicWidth="100%"
                    packwizardUrl={buildUrl(gearType, id)}
                  />
                  <Typography>For price history, comparisons & more</Typography>
                  <PackWizardCompareLinkButton
                    id={id}
                    width={isMobile ? "300px" : "240px"}
                    compareType={ITEM_TYPE_TO_COMPARE_TYPE[gearType]}
                    text="View on PackWizard"
                  />
                </Stack>
              </Stack>
            </Container>
          </Stack>
        </Container>
      </Paper>
    </>
  );
};

export default ArticleGearModule;
