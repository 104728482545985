const Categories = [
  "500ml pot",
  "750ml pot",
  "1-person tent",
  "2-person tent",
  "3-person tent",
  "20l pack",
  "25l pack",
  "30l pack",
  "35l pack",
  "40l pack",
  "45l pack",
  "50l pack",
  "55l pack",
  "60l pack",
  "65l pack",
  "70l pack",
  "75l pack",
  "80l pack",
  "85l pack",
  "0f sleeping bag",
  "5f sleeping bag",
  "10f sleeping bag",
  "15f sleeping bag",
  "20f sleeping bag",
  "25f sleeping bag",
  "30f sleeping bag",
  "35f sleeping bag",
  "40f sleeping bag",
  "45f sleeping bag",
  "50f sleeping bag",
  "55f sleeping bag",
  "assorted stuff sacks",
  "beanie",
  "bear spray",
  "bidet",
  "bivy",
  "boots",
  "buff",
  "camera",
  "camera accessory",
  "camera equipment",
  "camera lens",
  "chapstick",
  "cleats",
  "cold soak jar",
  "dry bag",
  "first aid kit",
  "foam pad",
  "fuel",
  "gloves",
  "hammock",
  "headlamp",
  "hoodie",
  "hip belt",
  "ice axe",
  "iphone",
  "knife",
  "medical",
  "microphone",
  "mosquito headnet",
  "lighter",
  "pants",
  "pillow",
  "plastic water bottle",
  "pocket knife",
  "powerbank",
  "rain jacket",
  "shorts",
  "sleeping pad",
  "socks",
  "spork",
  "stove",
  "sun gloves",
  "sunscreen",
  "sunscreen stick",
  "swiss army knife",
  "tarp",
  "therapy",
  "tights",
  "toothbrush",
  "torrid jacket",
  "towel",
  "trailrunners",
  "trekking poles",
  "tripod",
  "trowel",
  "water filter",
  "water bladder",
];

export default Categories;
