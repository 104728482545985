import React from "react";

import {
  Box,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getUsersPublicPacks } from "../utils";
import { SEASON_SETTINGS } from "../constants/packSettings";

const getChips = ({ tag0, tag1, tag2 }, index) => {
  const tags = [tag0, tag1, tag2];
  const chips = tags
    .map((tag) =>
      tag && tag.length > 0 ? (
        <Chip
          key={`${index}-${tag}`}
          size="small"
          color="success"
          variant="outlined"
          label={tag}
          sx={{ margin: "1px" }}
        />
      ) : null
    )
    .filter((chip) => chip);
  return chips.length ? chips : "-";
};

const UserPublicPacksModule = ({ username }) => {
  const [usersPublicPacks, setUserPublicPacks] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (username) {
        const usersPacks = await getUsersPublicPacks(username);
        setUserPublicPacks(usersPacks);
      }
    })();
  }, [username]);
  return (
    <Paper
      sx={{ width: "100%", maxWidth: "700px", padding: "10px 10px 20px 10px" }}
      elevation={6}
    >
      <Stack alignItems="center">
        <Typography variant="h5">Public Packs</Typography>
        <Box sx={{ width: "100%" }}>
          {usersPublicPacks.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell align="center">Season</TableCell>
                  <TableCell align="center">Trail</TableCell>
                  <TableCell align="center">Tags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersPublicPacks.map((pack, index) => (
                  <TableRow
                    onClick={() => {
                      window.location.assign(`/s/${pack.shareableLink}`);
                    }}
                    sx={{
                      "&:hover": {
                        opacity: "0.6",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell>{pack.packName}</TableCell>
                    <TableCell align="center">
                      {pack.season ? SEASON_SETTINGS[pack.season] : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {pack.trail ? pack.trail : "-"}
                    </TableCell>
                    <TableCell>{getChips(pack, index)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography sx={{ marginTop: "20px" }}>
              This user has no public packs
            </Typography>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};

export default UserPublicPacksModule;
