import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { Avatar, Link, Stack, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";

import { NestedDropdown } from "mui-nested-menu";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import BackpackIcon from "@mui/icons-material/Backpack";
import FlagIcon from "@mui/icons-material/Flag";
import { addItemToGearClosetWithId, getUserIsLoggedIn } from "../../utils";
import ReportSpecsDialog from "./ReportSpecsDialog";
import { useMediaQuery } from "react-responsive";
import RetailerPreview from "./RetailerPreview";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const SleepingPadTable = ({
  sleepingPads,
  handleSetSelectedItems,
  useMetric,
  useMetricDimensions,
  selectedItems,
  setAddToGCSnackbarIsOpen,
  setReportSnackBarIsOpen,
  handleAddToPackMenu,
  gearText,
  darkMode,
}) => {
  const [reportIsOpen, setReportIsOpen] = React.useState(false);
  const [itemToReportId, setItemToReportId] = React.useState(null);

  const isMobile = useMediaQuery({ maxWidth: 898 });

  const handleAddToGearCloset = (id, type) => {
    addItemToGearClosetWithId(id, type);
    setAddToGCSnackbarIsOpen(true);
  };

  const handleFlagData = (id) => {
    setItemToReportId(id);
    setReportIsOpen(true);
  };

  const createMenuItemsData = (id, image, formattedBrand, name, variant) => {
    return {
      label: "",
      items: [
        {
          label: "Add to Gear Closet",
          leftIcon: <MeetingRoomIcon />,
          disabled: !getUserIsLoggedIn(),
          callback: () => {
            handleAddToGearCloset(id, "sleepingPad");
          },
        },
        {
          label: "Add to Pack",
          leftIcon: <BackpackIcon />,
          disabled: !getUserIsLoggedIn(),
          callback: () => {
            handleAddToPackMenu(
              id,
              image,
              formattedBrand,
              name,
              variant,
              "sleepingPad"
            );
          },
        },
        {
          label: "Report Incorrect Specs",
          leftIcon: <FlagIcon />,
          callback: () => {
            handleFlagData(id);
          },
        },
      ],
    };
  };

  const columns = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        width: 320,
        flex: 1,
        minWidth: 320,
        editable: false,
        headerAlign: "left",
        headerClassName: "firstcolumnSeparator",
        renderCell: (params) => (
          <Stack sx={{ maxWidth: "100%", textAlign: "left" }}>
            <Stack direction="row">
              <Avatar
                variant="square"
                alt={params.row.name}
                sx={{ width: 40, height: 40, marginTop: "5px" }}
                src={params.row.imageUrl}
              ></Avatar>
              <Link
                sx={{
                  textDecoration: "none",
                  lineHeight: "40px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  paddingLeft: "5px",
                  "&:hover": {
                    opacity: "0.8",
                    cursor: "pointer",
                  },
                }}
                href={`/gear/sleeping-pad/${params.row._id}`}
              >
                <Stack>
                  <Typography variant="subtitle1">{params.row.name}</Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ textDecoration: "none", color: "grey" }}
                  >
                    {params.row.formattedBrand}
                  </Typography>
                </Stack>
              </Link>
            </Stack>
          </Stack>
        ),
      },
      {
        field: "weight",
        headerName: "Weight",
        width: 100,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) =>
          useMetric
            ? `${params.row.weight}g`
            : `${(params.row.weight / 28).toFixed(1)}oz`,
      },
      {
        field: "rValue",
        headerName: "R-Value",
        width: 70,
        editable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "width",
        headerName: "Width",
        width: 80,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) =>
          useMetricDimensions
            ? `${Math.round(params.row.width * 2.54)}cm`
            : `${params.row.width}"`,
      },
      {
        field: "length",
        headerName: "Length",
        width: 80,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) =>
          useMetricDimensions
            ? `${Math.round(params.row.length * 2.54)}cm`
            : `${params.row.length}"`,
      },
      {
        field: "type",
        headerName: "Type",
        width: 140,
        editable: false,
        headerAlign: "left",
      },
      {
        field: "price",
        headerName: "Price",
        width: 160,
        editable: false,
        headerAlign: "left",
        renderCell: (params) => {
          if (
            (!params.row.lowestPrice && params.row.price === 0) ||
            (params.row.lowestPrice === 0 && params.row.price === 0)
          ) {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ minWidth: "100%" }}
              >
                -
              </Stack>
            );
          }
          if (!params.row.price && !params.row.lowestPrice)
            return (
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ minWidth: "100%" }}
              >
                -
              </Stack>
            );
          if (
            params.row.lowestPrice &&
            params.row.price > params.row.lowestPrice
          ) {
            return (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
              >
                <Stack textAlign="center" sx={{ width: "60px" }}>
                  <Typography fontSize="14px">
                    {params.row.lowestPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    sx={{ textDecoration: "line-through" }}
                  >
                    {params.row.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                </Stack>
                <RetailerPreview
                  item={params.row}
                  gearText={gearText}
                  darkMode={darkMode}
                  packwizardUrl={`/gear/sleeping-pad/${params.row._id}`}
                />
              </Stack>
            );
          }
          if (params.row.lowestPrice > 0) {
            return (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
              >
                <Stack textAlign="center" sx={{ width: "60px" }}>
                  <Typography fontSize="14px">
                    {params.row.lowestPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                </Stack>
                <RetailerPreview
                  item={params.row}
                  gearText={gearText}
                  darkMode={darkMode}
                  packwizardUrl={`/gear/sleeping-pad/${params.row._id}`}
                />
              </Stack>
            );
          }
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Stack textAlign="center" sx={{ width: "60px" }}>
                <Typography fontSize="14px">
                  {params.row.price > 0 || !params.row.lowestPrice
                    ? params.row.price.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : params.row.lowestPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                </Typography>
              </Stack>
              <RetailerPreview
                item={params.row}
                gearText={gearText}
                darkMode={darkMode}
                packwizardUrl={`/gear/sleeping-pad/${params.row._id}`}
              />
            </Stack>
          );
        },
      },
      {
        field: "view",
        headerName: "",
        width: 60,
        editable: false,
        headerAlign: "left",
        headerClassName: "lastcolumnSeparator",
        renderCell: (params) => (
          <Box
            sx={{
              width: "70px",
              height: "30px",
              lineHeight: "30px",
              textAlign: "center",
              "&:hover": { opacity: "0.8" },
            }}
          >
            <Link
              sx={{ textDecoration: "none" }}
              size="small"
              href={`/gear/sleeping-pad/${params.row._id}`}
            >
              VIEW
            </Link>
          </Box>
        ),
      },
      {
        field: "more",
        headerName: "",
        width: 80,
        editable: false,
        headerAlign: "left",
        headerClassName: "lastcolumnSeparator",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <NestedDropdown
            ButtonProps={{
              endIcon: <MoreVertIcon />,
              sx: {
                minWidth: "32px",
                width: "32px",
                paddingRight: "16px",
              },
            }}
            menuItemsData={createMenuItemsData(
              params.row.id,
              params.row.imageUrl,
              params.row.formattedBrand,
              params.row.name,
              params.row.variant
            )}
            MenuProps={{ elevation: 3 }}
          />
        ),
      },
    ];
  }, [useMetric, useMetricDimensions]);

  return (
    <>
      <ReportSpecsDialog
        gearType="sleepingPad"
        reportIsOpen={reportIsOpen}
        itemToReportId={itemToReportId}
        setReportIsOpen={setReportIsOpen}
        setReportSnackBarIsOpen={setReportSnackBarIsOpen}
      />
      <DataGridPro
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
          width: "100%",
          marginTop: "5px",
        }}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "weight", sort: "asc" }],
          },
        }}
        rows={sleepingPads}
        columns={columns}
        density="comfortable"
        disableColumnMenu
        getRowHeight={() => "auto"}
        disableSelectionOnClick
        selectionModel={selectedItems}
        pagination
        checkboxSelection
        keepNonExistentRowsSelected
        onSelectionModelChange={(itemIds) => {
          if (itemIds.length > 3) return;
          handleSetSelectedItems(itemIds);
        }}
        components={{
          NoRowsOverlay: () => (
            <Box
              sx={{
                textAlign: "center",
                verticalAlign: "center",
                lineHeight: "72px",
                color: "#999",
              }}
            >
              No Items
            </Box>
          ),
        }}
      />
    </>
  );
};

export default SleepingPadTable;
