import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import React from "react";
import {
  fetchReportsForAdminPanel,
  setStatusForReportInAdminPanel,
} from "../../utils";
import CancelIcon from "@mui/icons-material/Cancel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CheckCheckIcon } from "lucide-react";
import { GridCloseIcon } from "@mui/x-data-grid";

const TabPanel = (props) => {
  const { children, value, index, reportItems, handleUpdateStatus, ...other } =
    props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index &&
        (value === "unresolved" ? (
          <Stack spacing={1}>
            {reportItems.map((report) => (
              <Paper
                key={report.reportId}
                elevation={8}
                sx={{ height: "100px", padding: "10px 10px 10px 10px" }}
              >
                <Stack spacing={1}>
                  <Typography>{`${report.formattedBrand || report.brand} ${
                    report.name
                  }`}</Typography>
                  {report.specs.length ? (
                    <Stack direction="row" spacing={1}>
                      {report.specs.map((spec) =>
                        spec ? <Chip label={spec} size="small" /> : <></>
                      )}
                    </Stack>
                  ) : (
                    <Typography textAlign="center" variant="subtitle2">
                      No Specs Specified
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    color="success"
                    startIcon={<CheckCheckIcon />}
                    onClick={() => {
                      handleUpdateStatus(report.reportId, "RESOLVED");
                    }}
                  >
                    Mark as Resolved
                  </Button>
                </Stack>
              </Paper>
            ))}
          </Stack>
        ) : (
          <Stack spacing={1}>
            {reportItems.map((report) => (
              <Paper
                elevation={8}
                key={report.reportId}
                sx={{ height: "100px", padding: "10px 10px 10px 10px" }}
              >
                <Stack spacing={1}>
                  <Typography>{`${report.formattedBrand || report.brand} ${
                    report.name
                  }`}</Typography>
                  {report.specs.length ? (
                    <Stack direction="row" spacing={1}>
                      {report.specs.map((spec) =>
                        spec ? <Chip label={spec} size="small" /> : <></>
                      )}
                    </Stack>
                  ) : (
                    <Typography textAlign="center" variant="subtitle2">
                      No Specs Specified
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    color="error"
                    startIcon={<GridCloseIcon />}
                    onClick={() => {
                      handleUpdateStatus(report.reportId, "UNRESOLVE");
                    }}
                  >
                    Unresolve
                  </Button>
                </Stack>
              </Paper>
            ))}
          </Stack>
        ))}
    </div>
  );
};

const AdminReportViewer = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [resolvedReports, setResolvedReports] = React.useState([]);
  const [reports, setReports] = React.useState([]);
  const [tabValue, setTabValue] = React.useState("unresolved");

  const handleFetchReports = async () => {
    const reports = await fetchReportsForAdminPanel();
    const { resolved, unresolved } = reports.reduce(
      (acc, curr) => {
        if (curr.resolved) acc.resolved.push(curr);
        if (!curr.resolved) acc.unresolved.push(curr);
        return acc;
      },
      { unresolved: [], resolved: [] }
    );
    setReports(unresolved);
    setResolvedReports(resolved);
  };

  React.useEffect(() => {
    (async () => {
      await handleFetchReports();
    })();
  }, []);

  const handleClick = (currentTarget) => {
    if (anchorEl) {
      setIsOpen(false);
      setAnchorEl(null);
    } else {
      setIsOpen(true);
      setAnchorEl(currentTarget);
    }
  };

  const handleUpdateStatus = async (reportId, newStatus) => {
    await setStatusForReportInAdminPanel(reportId, newStatus);
    handleFetchReports();
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Popper open={isOpen} anchorEl={anchorEl} placement="bottom-end">
        <Paper sx={{ width: "auto", padding: "10px 10px 10px 10px" }}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Stack>
          <Tabs
            value={tabValue}
            onChange={(e, value) => {
              setTabValue(value);
            }}
          >
            <Tab
              value="unresolved"
              label={`Unresolved (${reports.length})`}
              wrapped
            />
            <Tab
              value="resolved"
              label={`Resolved (${resolvedReports.length})`}
              wrapped
            />
          </Tabs>
          <TabPanel
            value={tabValue}
            index={"unresolved"}
            reportItems={reports}
            handleUpdateStatus={handleUpdateStatus}
          ></TabPanel>
          <TabPanel
            value={tabValue}
            index={"resolved"}
            reportItems={resolvedReports}
            handleUpdateStatus={handleUpdateStatus}
          ></TabPanel>
        </Paper>
      </Popper>
      <IconButton
        onClick={(e) => {
          handleClick(e.currentTarget);
        }}
        size="large"
      >
        <Badge
          badgeContent={reports.length}
          color={"error"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <FlagIcon />
        </Badge>
      </IconButton>
    </>
  );
};

export default AdminReportViewer;
