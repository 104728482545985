import React from "react";

import { Container, Stack } from "@mui/system";
import { getColorWithMode } from "../../constants/colors";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { fetchInsulatedJackets, fetchStoves } from "../../api/gear";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useCallback } from "react";
import debounce from "lodash.debounce";
import {
  getFilterValuesWithCategory,
  productPassesAllFilters,
} from "../../utils/gearComparison/filters";
import GenericGearTable from "./GenericGearTable";
import GenericFilterBar from "./GenericFilterBar";
import { filterConfigMap } from "../../constants/gearComparison/filterConfig";

const capitalizeTitle = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const gearTypeToGearCompareRoute = {
  insulatedJacket: "insulated-jackets",
  stove: "stoves",
};

const gearTypeToGearText = {
  insulatedJacket:
    "Discover insulated jackets that fit all of your needs. Sort and filter by all of the most important features of a jacket and use our live price tracker to find the best deals on your next down jacket",
  stove:
    "Discover stoves that fit all of your needs. Sort and filter by all of the most important features of a stove and use our live price tracker to find the best deals on your next stove",
};

const GenericGearTableContainer = ({
  darkMode,
  setAddToGCSnackbarIsOpen,
  setReportSnackBarIsOpen,
  handleAddToPackMenu,
  gearType,
  gearText,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const [gear, setGear] = React.useState([]);
  const [baseFilters, setBaseFilters] = React.useState(null);
  const [userFilters, setUserFilters] = React.useState({});
  const [filteredGear, setFilteredGear] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [useMetric, setUseMetric] = React.useState(true);
  const [useCelsius, setUseCelsius] = React.useState(false);
  const [useMetricDimensions, setUseMetricDimensions] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filtersAreOpen, setFiltersAreOpen] = React.useState(isDesktopOrLaptop);

  const handleSetSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleSetSearchText, 300),
    []
  );

  const handleSetSelectedItems = (items) => {
    setSelectedItems(items);
  };

  const fetchMap = {
    insulatedJacket: fetchInsulatedJackets,
    stove: fetchStoves,
  };

  React.useEffect(() => {
    (async () => {
      const { data } = await fetchMap[gearType]();
      const { baseFilters: newBaseFilters, userFilters: newUserFilters } =
        getFilterValuesWithCategory(gearType, data);
      setBaseFilters(newBaseFilters);
      setUserFilters(newUserFilters);
      setGear(data);
      setFilteredGear(data);
    })();
  }, []);

  React.useEffect(() => {
    const filterConfig = filterConfigMap[gearType];
    const filteredDownGear = gear.filter((product) => {
      return productPassesAllFilters(
        product,
        userFilters,
        searchText,
        filterConfig
      );
    });
    setFilteredGear(filteredDownGear);
  }, [userFilters, gear, searchText, gearType]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>{`${capitalizeTitle(gearText)}s`}</title>
        <link
          rel="canonical"
          href={`https://www.packwizard.com/gear/${gearTypeToGearCompareRoute[gearType]}`}
        />
        <meta
          name="keywords"
          content={`${gearText} comparison tool, ${gearText}, comparison, ${gearText} specs, quilt specs, cheapest backpacking ${gearText}s, ${gearText} price tracker`}
        />
        <meta
          name="description"
          content={`Compare ${gearText} specs to find your ideal ${gearText}, complete with live price tracking to make sure you are getting the best deal out there.`}
        />
      </Helmet>
      <Container
        sx={{
          width: "100%",
          backgroundColor: getColorWithMode(darkMode, "title"),
        }}
      >
        <Stack
          textAlign="center"
          alignItems="center"
          sx={{ padding: "20px 10px 20px 10px" }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              fontFamily: "Helvetica",
              color: "white",
            }}
          >
            {`${capitalizeTitle(gearText)}s`}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Helvetica",
              color: "white",
              maxWidth: "700px",
            }}
          >
            {gearTypeToGearText[gearType]}
          </Typography>
        </Stack>
      </Container>
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Stack
          sx={isMobile ? {} : { width: filtersAreOpen ? "240px" : "10px" }}
        >
          <Button
            sx={
              isMobile
                ? { width: "260px", fontSize: "18px" }
                : { width: "240px" }
            }
            onClick={() => {
              setFiltersAreOpen(!filtersAreOpen);
            }}
          >{`${
            filtersAreOpen ? "Hide Filters/Settings" : "Show Filters/Settings"
          }`}</Button>
          <Collapse
            in={filtersAreOpen}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            <Container
              sx={{
                width: isMobile ? "100%" : "240px",
                height: "100%",
                padding: "5px 5px 5px 5px",
                marginTop: "20px",
              }}
            >
              <GenericFilterBar
                gearType={gearType}
                darkMode={darkMode}
                baseFilters={baseFilters}
                userFilters={userFilters}
                setUserFilters={setUserFilters}
                useMetric={useMetric}
                setUseMetric={setUseMetric}
                useMetricDimensions={useMetricDimensions}
                setUseMetricDimensions={setUseMetricDimensions}
                useCelsius={useCelsius}
                setUseCelsius={setUseCelsius}
              />
            </Container>
          </Collapse>
        </Stack>
        <Container
          sx={{
            marginTop: filtersAreOpen ? "0px" : "20px",
          }}
        >
          <Stack>
            <Stack
              sx={{ height: isMobile ? "70px" : "50px" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant={isMobile ? "h6" : "body1"}
                sx={{
                  paddingLeft: "5px",
                  fontFamily: "Helvetica",
                }}
              >
                {`${filteredGear.length} ${capitalizeTitle(gearText)}s`}
              </Typography>
              <TextField
                size={isMobile ? "large" : "small"}
                sx={{
                  width: "300px",
                }}
                placeholder="Search"
                onChange={debouncedChangeHandler}
              ></TextField>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                height: "40px",
                maxWidth: "100%",
                whiteSpace: "nowrap",
              }}
            >
              <Button
                sx={{ width: "80px" }}
                disabled={selectedItems.length <= 1 || selectedItems.length > 3}
                onClick={() => {
                  window.location.assign(
                    `/gear/${
                      gearTypeToGearCompareRoute[gearType]
                    }/compare?products=${selectedItems.join(",")}`
                  );
                }}
              >
                Compare
              </Button>
              {selectedItems.length ? (
                <Divider
                  sx={{
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    height: "20px",
                  }}
                  orientation="vertical"
                />
              ) : (
                <></>
              )}
              <Box
                sx={{
                  maxWidth: "100%",
                  textOverflow: "nowrap",
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
              >
                {selectedItems.map((itemId) => {
                  const itemToRender = gear.find(({ id }) => itemId === id);
                  return (
                    <Chip
                      sx={{ marginLeft: "5px" }}
                      variant="outlined"
                      label={`${itemToRender.formattedBrand} ${
                        itemToRender.name
                      } ${
                        itemToRender.variant ? `(${itemToRender.variant})` : ""
                      }`}
                      onDelete={() => {
                        handleSetSelectedItems(
                          selectedItems.filter(
                            (itemId) => itemId !== itemToRender.id
                          )
                        );
                      }}
                    />
                  );
                })}
              </Box>
            </Stack>
            <GenericGearTable
              gear={filteredGear}
              handleSetSelectedItems={handleSetSelectedItems}
              selectedItems={selectedItems}
              useMetric={useMetric}
              useMetricDimensions={useMetricDimensions}
              useCelsius={useCelsius}
              setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
              setReportSnackBarIsOpen={setReportSnackBarIsOpen}
              handleAddToPackMenu={handleAddToPackMenu}
              gearType={gearType}
              gearText={gearText}
              darkMode={darkMode}
            />
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

export default GenericGearTableContainer;
