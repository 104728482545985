const USAGE_QUESTIONS = {
  MILES: {
    label: "How far have you hiked with this item?",
    options: [
      "0 Miles / 0 km",
      "1-25 Miles / 1-40 km",
      "25-100 Miles / 40-160 km",
      "100-250 Miles / 160-400 km",
      "250+ Miles / 400+ km",
    ],
  },
  NIGHTS: {
    label: "How many nights have you used this item?",
    options: [
      "0 Nights",
      "1-3 Nights",
      "4-7 Nights",
      "7-14 Nights",
      "14+ Nights",
    ],
  },
  TIMES: {
    label: "How many times have you used this item?",
    options: [
      "0 times",
      "1-10 times",
      "10-25 times",
      "25-100 times",
      "100+ times",
    ],
  },
};

export const GEAR_TYPE_USAGE_MAP = {
  sleepingBag: USAGE_QUESTIONS.NIGHTS,
  sleepingPad: USAGE_QUESTIONS.NIGHTS,
  tent: USAGE_QUESTIONS.NIGHTS,
  backpack: USAGE_QUESTIONS.MILES,
  insulatedJacket: USAGE_QUESTIONS.MILES,
  stove: USAGE_QUESTIONS.TIMES,
};

export const ASPECT_REVIEW_MAP = {
  sleepingBag: [
    {
      key: "WARMTH",
      label: "Warmth",
    },
    {
      key: "COMFORT",
      label: "Comfort",
    },
    {
      key: "FEATURES",
      label: "Features",
    },
  ],
  sleepingPad: [
    {
      key: "COMFORT",
      label: "Comfort",
    },
    {
      key: "EASE_OF_USE",
      label: "Ease of Use",
    },
    {
      key: "WARMTH",
      label: "Warmth",
    },
    {
      key: "DURABILITY",
      label: "Durability",
    },
  ],
  tent: [
    {
      key: "EASE_OF_USE",
      label: "Ease of Use",
    },
    {
      key: "WEATHER_RESISTANCE",
      label: "Weather Resistance",
    },
    {
      key: "DURABILITY",
      label: "Durability",
    },
    {
      key: "SIZE",
      label: "Size",
    },
  ],
  backpack: [
    {
      key: "EASE_OF_USE",
      label: "Ease of Use",
    },
    {
      key: "COMFORT",
      label: "Comfort",
    },
    {
      key: "DURABILITY",
      label: "Durability",
    },
    {
      key: "FEATURES",
      label: "Features",
    },
  ],
  insulatedJacket: [
    {
      key: "COMFORT",
      label: "Comfort",
    },
    {
      key: "DURABILITY",
      label: "Durability",
    },
    {
      key: "FEATURES",
      label: "Features",
    },
  ],
  stove: [
    {
      key: "EASE_OF_USE",
      label: "Ease of Use",
    },
    {
      key: "DURABILITY",
      label: "Durability",
    },
  ],
};
