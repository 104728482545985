import { Button, Container, Divider, Slider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../../constants/colors";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery } from "react-responsive";

const formatBrand = (brand) => {
  if (brand === "Nemo") return "NEMO";
  if (brand === "thermarest") return "Therm-a-Rest";
  if (brand === "sea to summit") return "Sea to Summit";
  if (brand === "rei") return "REI Co-op";
  if (brand === "big agnes") return "Big Agnes";
  if (brand === "exped") return "Exped";
  if (brand === "klymit") return "Klymit";
  return brand;
};

const SleepingPadFilterBar = ({
  darkMode,
  baseFilters,
  userFilters,
  setUserFilters,
  useMetric,
  setUseMetric,
  setUseMetricDimensions,
  useMetricDimensions,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });

  if (!baseFilters) return <></>;

  const handleChangeFilter = (type, value, toggled) => {
    const newFilters = { ...userFilters };
    if (type === "price") {
      newFilters.price = value;
    } else if (type === "weight") {
      newFilters.weight = value;
    } else if (type === "brand") {
      if (toggled) {
        const brandSet = new Set(newFilters.brand);
        brandSet.add(value);
        newFilters.brand = [...brandSet];
      } else {
        const brandSet = new Set(newFilters.brand);
        brandSet.delete(value);
        newFilters.brand = [...brandSet];
      }
    } else if (type === "type") {
      if (toggled) {
        const typeSet = new Set(newFilters.type);
        typeSet.delete(value);
        newFilters.type = [...typeSet];
      } else {
        if (!newFilters.type || newFilters.type.length === 0) {
          newFilters.type = [value];
        } else {
          newFilters.type.push(value);
        }
      }
    } else if (type === "variant") {
      if (toggled) {
        const variantSet = new Set(newFilters.variant);
        variantSet.delete(value);
        newFilters.variant = [...variantSet];
      } else {
        if (!newFilters.variant || newFilters.variant.length === 0) {
          newFilters.variant = [value];
        } else {
          newFilters.variant.push(value);
        }
      }
    } else if (type === "lengths") {
      newFilters.lengths = value;
    } else if (type === "width") {
      newFilters.widths = value;
    } else if (type === "rValue") {
      newFilters.minimumRValue = value;
    }
    setUserFilters(newFilters);
  };

  const widthMarks = [
    {
      value: 20,
      label: useMetricDimensions ? `${Math.floor(20 * 2.54)}cm` : `${20}"`,
    },
    {
      value: 52,
      label: useMetricDimensions ? `${Math.ceil(52 * 2.54)}cm` : `${52}"`,
    },
  ];

  const lengthMarks = [
    {
      value: 42,
      label: useMetricDimensions ? `${Math.floor(42 * 2.54)}cm` : `${42}"`,
    },
    {
      value: 85,
      label: useMetricDimensions ? `${Math.ceil(85 * 2.54)}cm` : `${85}"`,
    },
  ];

  const weightMarks = [
    {
      value: baseFilters.weights.min,
      label: useMetric
        ? `${baseFilters.weights.min}g`
        : `${Math.floor(baseFilters.weights.min / 28)}oz`,
    },
    {
      value: baseFilters.weights.max,
      label: useMetric
        ? `${baseFilters.weights.max}g+`
        : `${Math.floor(baseFilters.weights.max / 28)}oz+`,
    },
  ];

  const priceMarks = [
    {
      value: baseFilters.prices.min,
      label: `$${baseFilters.prices.min}`,
    },
    {
      value: baseFilters.prices.max,
      label: `$${baseFilters.prices.max}`,
    },
  ];

  const rValueMarks = [
    {
      value: 0,
      label: 0,
    },
    {
      value: 8,
      label: 8,
    },
  ];
  return (
    <Container
      sx={{
        backgroundColor: `${getColorWithMode("base", darkMode)} !important`,
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        direction={isMobile ? "row" : "column"}
        sx={{ width: "100%" }}
        justifyContent={"space-between"}
      >
        <Box sx={{ minWidth: "200px" }}>
          <FormControl
            sx={{ width: "100%", paddingBottom: "15px" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Settings</FormLabel>
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetric}
                  onChange={() => setUseMetric(!useMetric)}
                />
              }
              label="Metric Weights"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetricDimensions}
                  onChange={() => setUseMetricDimensions(!useMetricDimensions)}
                />
              }
              label="Metric Dimensions"
            />
          </FormControl>
        </Box>
        <Stack>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Price</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.prices}
                valueLabelFormat={(value) => `$${value}`}
                defaultValue={[baseFilters.prices.min, baseFilters.prices.max]}
                marks={priceMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("price", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Weight</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  if (value === 2500) {
                    return useMetric
                      ? `${value}g+`
                      : `${Math.floor(value / 28)} oz+`;
                  }

                  return useMetric
                    ? `${value}g`
                    : `${Math.floor(value / 28)} oz`;
                }}
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.weights}
                defaultValue={[
                  baseFilters.weights.min,
                  baseFilters.weights.max,
                ]}
                marks={weightMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("weight", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Minimum R-Value</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                track="inverted"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                min={0}
                max={8}
                step={0.5}
                defaultValue={0}
                marks={rValueMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("rValue", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Width</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                valueLabelFormat={(value) =>
                  useMetricDimensions
                    ? `${Math.floor(value * 2.54)}cm`
                    : `${value}"`
                }
                min={20}
                max={52}
                step={0.5}
                defaultValue={[20, 52]}
                marks={widthMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("width", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Length</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                valueLabelFormat={(value) =>
                  useMetricDimensions
                    ? `${Math.floor(value * 2.54)}cm`
                    : `${value}"`
                }
                size="small"
                min={42}
                max={85}
                step={0.5}
                defaultValue={[42, 85]}
                marks={lengthMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("lengths", value);
                }}
              />
            </FormControl>
          </Box>
        </Stack>
        <Stack>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Type</FormLabel>
              <Divider />
              <FormGroup>
                {baseFilters.types.map((type) => (
                  <FormControlLabel
                    key={`${type}-filter`}
                    control={<Checkbox defaultChecked name={type} />}
                    label={type}
                    onChange={(e, value) => {
                      handleChangeFilter("type", type, value);
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Brand</FormLabel>
              <Divider />
              <Button
                sx={{
                  justifyContent: "left",
                  paddingLeft: "0px",
                  width: "150px",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  const newFilters = { ...userFilters };
                  if (userFilters.brand.length !== baseFilters.brands.length) {
                    newFilters.brand = baseFilters.brands;
                  } else {
                    newFilters.brand = [];
                  }
                  setUserFilters(newFilters);
                }}
              >
                {userFilters.brand.length !== baseFilters.brands.length
                  ? "Select All"
                  : "Deselect All"}
              </Button>
              <FormGroup>
                {baseFilters.brands.map((brand) => (
                  <FormControlLabel
                    key={`${brand}-brand-filter`}
                    control={
                      <Checkbox
                        checked={userFilters.brand.includes(brand)}
                        name={brand}
                      />
                    }
                    label={formatBrand(brand)}
                    onChange={(e, value) => {
                      handleChangeFilter("brand", brand, value);
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default SleepingPadFilterBar;
