import React from "react";

import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/system/Box";
import { useMediaQuery } from "react-responsive";

const LoadingItemSuggestion = ({ isDesktopOrLaptop }) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const widthToUse = isDesktopOrLaptop ? 220 : isMobile ? 600 : 300;

  return (
    <Stack sx={{ minHeight: isMobile ? "400px" : "272px" }}>
      <Box>
        <Skeleton
          width={isDesktopOrLaptop ? 180 : isMobile ? 360 : 120}
          height={isDesktopOrLaptop ? 180 : isMobile ? 200 : 120}
          variant="square"
          sx={{ margin: "0 auto", marginTop: "40px" }}
        ></Skeleton>
      </Box>
      <Box sx={{ marginTop: "30px" }}>
        <Skeleton
          width={widthToUse}
          height={20}
          sx={{ margin: "0 auto" }}
        ></Skeleton>
        <Skeleton
          width={widthToUse}
          height={20}
          sx={{ margin: "0 auto" }}
        ></Skeleton>
        {isDesktopOrLaptop ? (
          <>
            <Skeleton
              width={widthToUse}
              height={20}
              sx={{ margin: "0 auto" }}
            ></Skeleton>
            <Skeleton
              width={widthToUse}
              height={20}
              sx={{ margin: "0 auto" }}
            ></Skeleton>
          </>
        ) : (
          <></>
        )}
      </Box>
    </Stack>
  );
};

export default LoadingItemSuggestion;
