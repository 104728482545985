import React from "react";

import { Avatar, Box, Link, Stack, Typography } from "@mui/material";
import RetailerPreview from "../../components/GearComparison/RetailerPreview";
import GEAR_TYPES from "../../constants/gearTypes.json";

const getFormattedMeasurement = (measurementInInches) => {
  if (!measurementInInches) return null;
  const splitM = String(measurementInInches).split("/");
  if (splitM.length === 1) {
    return `${Math.round(measurementInInches * 2.54)}cm`;
  } else {
    const m1 = Number(splitM[0].trim());
    const m2 = Number(splitM[1].trim());
    return `${Math.round(m1 * 2.54)}/${Math.round(m2 * 2.54)}cm`;
  }
};

const gearTypeToGearExploreMap = {
  [GEAR_TYPES.INSULATED_JACKET]: "insulated-jacket",
  [GEAR_TYPES.SLEEPING_PAD]: "sleeping-pad",
  [GEAR_TYPES.SLEEPING_BAG]: "sleeping-bag",
  [GEAR_TYPES.TENT]: "tent",
  [GEAR_TYPES.BACKPACK]: "backpacks",
  [GEAR_TYPES.STOVE]: "stove",
};

const getGenderFormat = (gender) => {
  if (gender === "Mens") return " (M)";
  if (gender === "Womens") return " (W)";
  return "";
};

const GET_ADDITIONAL_COLUMNS = {
  [GEAR_TYPES.SLEEPING_PAD]: (useMetric) =>
    getColumnsForSleepingPads(useMetric),
  [GEAR_TYPES.TENT]: (useMetric) => getColumnsForTents(useMetric),
  [GEAR_TYPES.BACKPACK]: (useMetricVolume, useMetric) =>
    getColumnsForBackpacks(useMetricVolume, useMetric),
  [GEAR_TYPES.INSULATED_JACKET]: (useMetric) =>
    getColumnsForInsulatedJackets(useMetric),
  [GEAR_TYPES.SLEEPING_BAG]: (useMetric, useCelsius) =>
    getColumnsForSleepingBags(useMetric, useCelsius),
};

const getColumnsForTents = (useMetricDimensions, gearText, darkMode) => [
  {
    field: "capacity",
    headerName: "Sleeps",
    width: 70,
    editable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "minimumWidth",
    headerName: "Width",
    width: 70,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.width
        ? useMetricDimensions
          ? getFormattedMeasurement(params.row.width)
          : `${params.row.width}"`
        : "-",
  },
  {
    field: "length",
    headerName: "Length",
    width: 70,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.length
        ? useMetricDimensions
          ? `${Math.round(params.row.length * 2.54)}cm`
          : `${params.row.length}"`
        : "-",
  },
  {
    field: "height",
    headerName: "Height",
    width: 70,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.height
        ? useMetricDimensions
          ? `${Math.round(params.row.height * 2.54)}cm`
          : `${params.row.height}"`
        : "-",
  },
  {
    field: "setup",
    headerName: "Setup",
    width: 120,
    editable: false,
    headerAlign: "left",
  },
];

const getColumnsForBackpacks = (useMetricVolume, useMetric) => [
  {
    field: "volume",
    headerName: "Volume",
    width: 70,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.volume
        ? useMetricVolume
          ? `${params.row.volume}L`
          : `${Math.floor(Number(params.row.volume) * 61.024)}cu in`
        : "-",
  },
  {
    field: "framed",
    headerName: "Frame",
    width: 100,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.framed.toLowerCase() === "yes" ? `Framed` : "Frameless",
  },
  {
    field: "maxLoad",
    headerName: "Max Load",
    width: 90,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.maxLoad
        ? useMetric
          ? `${((params.row.maxLoad * 454) / 1000).toFixed(1)}kg`
          : `${params.row.maxLoad}lbs`
        : "-",
  },
  {
    field: "adjustableTorso",
    headerName: "Adjustable Torso",
    width: 130,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.adjustableTorso.toLowerCase() === "yes" ? `Yes` : "No",
  },
];

const getColumnsForInsulatedJackets = (useMetric) => [
  {
    field: "fillPower",
    headerName: "Fill Power",
    width: 85,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.fillPower ? `${params.row.fillPower}FP` : "-",
  },
  {
    field: "fillWeightInGrams",
    headerName: "Fill Weight",
    width: 90,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.fillWeightInGrams
        ? useMetric
          ? params.row.fillWeightInGrams > 1000
            ? `${(params.row.fillWeightInGrams / 1000).toFixed(1)}kg`
            : `${Math.floor(params.row.fillWeightInGrams)}g`
          : `${(params.row.fillWeightInGrams / 28).toFixed(1)}oz`
        : "-",
  },
  {
    field: "warmth",
    headerName: "Warmth",
    width: 70,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.warmth && params.row.warmth > 1
        ? params.row.warmth.toFixed(1)
        : "-",
    sortingOrder: ["desc", "asc", null],
  },
  {
    field: "warmthToWeight",
    headerName: "Warmth / Weight",
    width: 130,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.warmthToWeight ? params.row.warmthToWeight.toFixed(2) : "-",
    sortingOrder: ["desc", "asc", null],
  },
  {
    field: "insulationType",
    headerName: "Insulation",
    width: 90,
    editable: false,
    headerAlign: "center",
    align: "center",
  },
];

const getColumnsForSleepingBags = (useMetricDimensions, useCelsius) => [
  {
    field: "type",
    headerName: "Shape",
    width: 100,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Stack alignItems="center" textAlign="center">
        <Typography variant="subtitle2" sx={{ textTransform: "capitalize" }}>
          {params.row.type}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ textDecoration: "none", color: "grey" }}
        >
          {params.row.variant}
        </Typography>
      </Stack>
    ),
  },
  {
    field: "fitsTo",
    headerName: "Fits To",
    width: 80,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.fitsTo
        ? useMetricDimensions
          ? `${Math.floor(params.row.fitsTo * 2.54)}cm`
          : `${Math.floor(params.row.fitsTo / 12)}'${Math.floor(
              params.row.fitsTo % 12
            )}"`
        : "-",
  },
  {
    field: "insulationType",
    headerName: "Insulation",
    width: 100,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      params.row.insulationType
        ? params.row.insulationType.toLowerCase() === "down"
          ? "Down"
          : "Synthetic"
        : "-",
  },
  {
    field: "comfortRating",
    headerName: "Comfort Rating",
    width: 100,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      !isNaN(params.row.comfortRating) && params.row.comfortRating !== null
        ? useCelsius
          ? `${Math.round((params.row.comfortRating - 32) * (5 / 9))}\xB0 C`
          : `${params.row.comfortRating}\xB0 F`
        : "-",
  },
  {
    field: "limitRating",
    headerName: "Limit Rating",
    width: 100,
    editable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      !isNaN(params.row.limitRating) && params.row.limitRating !== null
        ? useCelsius
          ? `${Math.round((params.row.limitRating - 32) * (5 / 9))}\xB0 C`
          : `${params.row.limitRating}\xB0 F`
        : "-",
  },
];

const getColumnsForSleepingPads = (useMetricDimensions) => {
  return [
    {
      field: "rValue",
      headerName: "R-Value",
      width: 70,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "width",
      headerName: "Width",
      width: 80,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        useMetricDimensions
          ? `${Math.round(params.row.width * 2.54)}cm`
          : `${params.row.width}"`,
    },
    {
      field: "length",
      headerName: "Length",
      width: 80,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        useMetricDimensions
          ? `${Math.round(params.row.length * 2.54)}cm`
          : `${params.row.length}"`,
    },
    {
      field: "type",
      headerName: "Type",
      width: 140,
      editable: false,
      headerAlign: "left",
    },
  ];
};
// const ADDITIONAL_COLUMNS = {
//   [GEAR_TYPES.INSULATED_JACKET]: [],
//   [GEAR_TYPES.SLEEPING_PAD]: ,
//   [GEAR_TYPES.SLEEPING_BAG]: [],
//   [GEAR_TYPES.TENT]: [],
//   [GEAR_TYPES.BACKPACK]: [],
// };

const getInsulatedJacketColumns = (
  gearType,
  { useMetric, gearText, darkMode }
) => {
  return [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      flex: 1,
      minWidth: 200,
      maxWidth: 320,
      editable: false,
      headerAlign: "left",
      headerClassName: "firstcolumnSeparator",
      renderCell: (params) => (
        <Stack sx={{ maxWidth: "100%", textAlign: "left" }}>
          <Stack direction="row">
            <Avatar
              variant="square"
              alt={params.row.name}
              sx={{ width: 40, height: 40, marginTop: "5px" }}
              src={params.row.imageUrl}
            ></Avatar>
            <Link
              sx={{
                textDecoration: "none",
                lineHeight: "40px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingLeft: "5px",
                "&:hover": {
                  opacity: "0.8",
                  cursor: "pointer",
                },
              }}
              href={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
            >
              <Stack>
                <Typography variant="subtitle1">{`${
                  params.row.name
                }${getGenderFormat(params.row.gender)}`}</Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textDecoration: "none", color: "grey" }}
                >
                  {params.row.formattedBrand}
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </Stack>
      ),
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 100,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.weight
          ? useMetric
            ? params.row.weight > 1000
              ? `${(params.row.weight / 1000).toFixed(1)}kg`
              : `${Math.floor(params.row.weight)}g`
            : `${(params.row.weight / 28).toFixed(1)}oz`
          : "-",
    },
    {
      field: "fillPower",
      headerName: "Fill Power",
      width: 85,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.fillPower ? `${params.row.fillPower}FP` : "-",
    },
    {
      field: "fillWeightInGrams",
      headerName: "Fill Weight",
      width: 90,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.fillWeightInGrams
          ? useMetric
            ? params.row.fillWeightInGrams > 1000
              ? `${(params.row.fillWeightInGrams / 1000).toFixed(1)}kg`
              : `${Math.floor(params.row.fillWeightInGrams)}g`
            : `${(params.row.fillWeightInGrams / 28).toFixed(1)}oz`
          : "-",
    },
    {
      field: "warmth",
      headerName: "Warmth",
      width: 70,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.warmth && params.row.warmth > 1
          ? params.row.warmth.toFixed(1)
          : "-",
      sortingOrder: ["desc", "asc", null],
    },
    {
      field: "warmthToWeight",
      headerName: "Warmth / Weight",
      width: 130,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.warmthToWeight ? params.row.warmthToWeight.toFixed(2) : "-",
      sortingOrder: ["desc", "asc", null],
    },
    {
      field: "insulationType",
      headerName: "Insulation",
      width: 90,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "price",
      headerName: "Price",
      width: 160,
      editable: false,
      headerAlign: "left",
      valueGetter: (params) => {
        if (params.row.lowestPrice) return params.row.lowestPrice;
        if (params.row.price) return params.row.price;
        return Number.MAX_SAFE_INTEGER;
      },
      renderCell: (params) => {
        if (
          (!params.row.lowestPrice && params.row.price === 0) ||
          (params.row.lowestPrice === 0 && params.row.price === 0)
        ) {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ minWidth: "100%" }}
            >
              -
            </Stack>
          );
        }
        if (!params.row.price && !params.row.lowestPrice)
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ minWidth: "100%" }}
            >
              -
            </Stack>
          );
        if (
          params.row.lowestPrice &&
          params.row.price > params.row.lowestPrice
        ) {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Stack textAlign="center" sx={{ width: "60px" }}>
                <Typography fontSize="14px">
                  {params.row.lowestPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
                <Typography
                  fontSize="12px"
                  sx={{ textDecoration: "line-through" }}
                >
                  {params.row.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Stack>
              <RetailerPreview
                item={params.row}
                gearText={gearText}
                darkMode={darkMode}
                packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
              />
            </Stack>
          );
        }
        if (params.row.lowestPrice > 0) {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Stack textAlign="center" sx={{ width: "60px" }}>
                <Typography fontSize="14px">
                  {params.row.lowestPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Stack>
              <RetailerPreview
                item={params.row}
                gearText={gearText}
                darkMode={darkMode}
                packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
              />
            </Stack>
          );
        }
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            <Stack textAlign="center" sx={{ width: "60px" }}>
              <Typography fontSize="14px">
                {params.row.price > 0 || !params.row.lowestPrice
                  ? params.row.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : params.row.lowestPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
              </Typography>
            </Stack>
            <RetailerPreview
              item={params.row}
              gearText={gearText}
              darkMode={darkMode}
              packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
            />
          </Stack>
        );
      },
    },
  ];
};

const getStoveColumns = (gearType, { useMetric, gearText, darkMode }) => {
  return [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      flex: 1,
      minWidth: 200,
      maxWidth: 320,
      editable: false,
      headerAlign: "left",
      headerClassName: "firstcolumnSeparator",
      renderCell: (params) => (
        <Stack sx={{ maxWidth: "100%", textAlign: "left" }}>
          <Stack direction="row">
            <Avatar
              variant="square"
              alt={params.row.name}
              sx={{ width: 40, height: 40, marginTop: "5px" }}
              src={params.row.imageUrl}
            ></Avatar>
            <Link
              sx={{
                textDecoration: "none",
                lineHeight: "40px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingLeft: "5px",
                "&:hover": {
                  opacity: "0.8",
                  cursor: "pointer",
                },
              }}
              href={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
            >
              <Stack>
                <Typography variant="subtitle1">{`${
                  params.row.name
                }${getGenderFormat(params.row.gender)}`}</Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textDecoration: "none", color: "grey" }}
                >
                  {params.row.formattedBrand}
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </Stack>
      ),
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 100,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.weight
          ? useMetric
            ? params.row.weight > 1000
              ? `${(params.row.weight / 1000).toFixed(1)}kg`
              : `${Math.floor(params.row.weight)}g`
            : `${(params.row.weight / 28).toFixed(1)}oz`
          : "-",
    },
    {
      field: "minPotSize",
      headerName: "Min. Pot Size",
      width: 150,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.minPotSize <= 750 ? `${params.row.minPotSize}ml` : ">750",
    },
    {
      field: "btuPerHour",
      headerName: "BTU/hr",
      width: 90,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "piezoIgniter",
      headerName: "Has Igniter",
      width: 90,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.piezoIgniter.toUpperCase() === "YES" ? "Yes" : "No",
    },
    {
      field: "regulator",
      headerName: "Regulator",
      width: 90,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.piezoIgniter.toUpperCase() === "YES" ? "Yes" : "No",
    },
    {
      field: "price",
      headerName: "Price",
      width: 160,
      editable: false,
      headerAlign: "left",
      valueGetter: (params) => {
        if (params.row.lowestPrice) return params.row.lowestPrice;
        if (params.row.price) return params.row.price;
        return Number.MAX_SAFE_INTEGER;
      },
      renderCell: (params) => {
        if (
          (!params.row.lowestPrice && params.row.price === 0) ||
          (params.row.lowestPrice === 0 && params.row.price === 0)
        ) {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ minWidth: "100%" }}
            >
              -
            </Stack>
          );
        }
        if (!params.row.price && !params.row.lowestPrice)
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ minWidth: "100%" }}
            >
              -
            </Stack>
          );
        if (
          params.row.lowestPrice &&
          params.row.price > params.row.lowestPrice
        ) {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Stack textAlign="center" sx={{ width: "60px" }}>
                <Typography fontSize="14px">
                  {params.row.lowestPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
                <Typography
                  fontSize="12px"
                  sx={{ textDecoration: "line-through" }}
                >
                  {params.row.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Stack>
              <RetailerPreview
                item={params.row}
                gearText={gearText}
                darkMode={darkMode}
                packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
              />
            </Stack>
          );
        }
        if (params.row.lowestPrice > 0) {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Stack textAlign="center" sx={{ width: "60px" }}>
                <Typography fontSize="14px">
                  {params.row.lowestPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Stack>
              <RetailerPreview
                item={params.row}
                gearText={gearText}
                darkMode={darkMode}
                packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
              />
            </Stack>
          );
        }
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            <Stack textAlign="center" sx={{ width: "60px" }}>
              <Typography fontSize="14px">
                {params.row.price > 0 || !params.row.lowestPrice
                  ? params.row.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : params.row.lowestPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
              </Typography>
            </Stack>
            <RetailerPreview
              item={params.row}
              gearText={gearText}
              darkMode={darkMode}
              packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
            />
          </Stack>
        );
      },
    },
  ];
};

const columnMap = {
  [GEAR_TYPES.INSULATED_JACKET]: getInsulatedJacketColumns,
  [GEAR_TYPES.STOVE]: getStoveColumns,
};

export const getColumns = (gearType, filters) => {
  return columnMap[gearType](gearType, filters);
};

export const getColumnsForArticleGearFilterModule = (
  gearType,
  { useMetric, darkMode, gearText }
) => {
  return [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      flex: 1,
      minWidth: 200,
      editable: false,
      headerAlign: "left",
      headerClassName: "firstcolumnSeparator",
      renderCell: (params) => (
        <Stack sx={{ maxWidth: "100%", textAlign: "left" }}>
          <Stack direction="row">
            <Avatar
              variant="square"
              alt={params.row.name}
              sx={{ width: 40, height: 40, marginTop: "5px" }}
              src={params.row.imageUrl}
            ></Avatar>
            <Link
              sx={{
                textDecoration: "none",
                lineHeight: "40px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingLeft: "5px",
                "&:hover": {
                  opacity: "0.8",
                  cursor: "pointer",
                },
              }}
              href={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
            >
              <Stack>
                <Typography variant="subtitle1">{params.row.name}</Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textDecoration: "none", color: "grey" }}
                >
                  {params.row.formattedBrand}
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </Stack>
      ),
    },
    ...GET_ADDITIONAL_COLUMNS[gearType](useMetric),
    {
      field: "weight",
      headerName: "Weight",
      width: 100,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.weight
          ? useMetric
            ? params.row.weight > 1000
              ? `${(params.row.weight / 1000).toFixed(1)}kg`
              : `${Math.floor(params.row.weight)}g`
            : `${(params.row.weight / 28).toFixed(1)}oz`
          : "-",
    },
    {
      field: "price",
      headerName: "Price",
      width: 160,
      editable: false,
      headerAlign: "center",
      valueGetter: (params) => {
        if (params.row.lowestPrice) return params.row.lowestPrice;
        if (params.row.price) return params.row.price;
        return Number.MAX_SAFE_INTEGER;
      },
      renderCell: (params) => {
        if (
          (!params.row.lowestPrice && params.row.price === 0) ||
          (params.row.lowestPrice === 0 && params.row.price === 0)
        ) {
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ minWidth: "100%" }}
            >
              -
            </Stack>
          );
        }
        if (!params.row.price && !params.row.lowestPrice)
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ minWidth: "100%" }}
            >
              -
            </Stack>
          );
        if (
          params.row.lowestPrice &&
          params.row.price > params.row.lowestPrice
        ) {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Stack textAlign="center" sx={{ width: "60px" }}>
                <Typography fontSize="14px">
                  {params.row.lowestPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
                <Typography
                  fontSize="12px"
                  sx={{ textDecoration: "line-through" }}
                >
                  {params.row.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Stack>
              <RetailerPreview
                item={params.row}
                gearText={gearText}
                darkMode={darkMode}
                packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
              />
            </Stack>
          );
        }
        if (params.row.lowestPrice > 0) {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
            >
              <Stack textAlign="center" sx={{ width: "60px" }}>
                <Typography fontSize="14px">
                  {params.row.lowestPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </Stack>
              <RetailerPreview
                item={params.row}
                gearText={gearText}
                darkMode={darkMode}
                packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
              />
            </Stack>
          );
        }
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            <Stack textAlign="center" sx={{ width: "60px" }}>
              <Typography fontSize="14px">
                {params.row.price > 0 || !params.row.lowestPrice
                  ? params.row.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : params.row.lowestPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
              </Typography>
            </Stack>
            <RetailerPreview
              item={params.row}
              gearText={gearText}
              darkMode={darkMode}
              packwizardUrl={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
            />
          </Stack>
        );
      },
    },
    {
      field: "view",
      headerName: "",
      width: 60,
      editable: false,
      headerAlign: "left",
      headerClassName: "lastcolumnSeparator",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            width: "70px",
            height: "30px",
            lineHeight: "30px",
            textAlign: "center",
            "&:hover": { opacity: "0.8" },
          }}
        >
          <Link
            sx={{ textDecoration: "none" }}
            size="small"
            href={`/gear/${gearTypeToGearExploreMap[gearType]}/${params.row._id}`}
          >
            VIEW
          </Link>
        </Box>
      ),
    },
  ];
};
