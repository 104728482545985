import { Button, Container, Divider, Slider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../../constants/colors";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery } from "react-responsive";

const formatBrand = (brand) => {
  if (brand === "Nemo" || brand === "nemo") return "NEMO";
  if (brand === "thermarest") return "Therm-a-Rest";
  if (brand === "rei") return "REI Co-op";
  if (brand === "north face") return "The North Face";
  if (brand === "hyperlite") return "Hyperlite Mountain Gear";
  if (brand === "msr") return "MSR";
  if (brand === "3ful") return "3FUL";
  return brand
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

const TentFilterBar = ({
  darkMode,
  baseFilters,
  userFilters,
  setUserFilters,
  useMetric,
  setUseMetric,
  setUseMetricDimensions,
  useMetricDimensions,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  if (!baseFilters) return <></>;

  const handleChangeFilter = (type, value, toggled) => {
    const newFilters = { ...userFilters };
    if (type === "price") {
      newFilters.price = value;
    } else if (type === "weight") {
      newFilters.weight = value;
    } else if (type === "brand") {
      if (toggled) {
        const brandSet = new Set(newFilters.brand);
        brandSet.add(value);
        newFilters.brand = [...brandSet];
      } else {
        const brandSet = new Set(newFilters.brand);
        brandSet.delete(value);
        newFilters.brand = [...brandSet];
      }
    } else if (type === "setup") {
      if (toggled) {
        const setupSet = new Set(newFilters.setup);
        setupSet.add(value);
        newFilters.setup = [...setupSet];
      } else {
        const setupSet = new Set(newFilters.setup);
        setupSet.delete(value);
        newFilters.setup = [...setupSet];
      }
    } else if (type === "doubleWalled") {
      if (toggled) {
        const doubleWalledSet = new Set(newFilters.doubleWalled);
        doubleWalledSet.add(value);
        newFilters.doubleWalled = [...doubleWalledSet];
      } else {
        const doubleWalledSet = new Set(newFilters.doubleWalled);
        doubleWalledSet.delete(value);
        newFilters.doubleWalled = [...doubleWalledSet];
      }
    } else if (type === "lengths") {
      newFilters.lengths = value;
    } else if (type === "width") {
      newFilters.widths = value;
    } else if (type === "capacity") {
      newFilters.capacity = value;
    }
    setUserFilters(newFilters);
  };

  const widthMarks = [
    {
      value: 20,
      label: useMetricDimensions ? `${Math.floor(20 * 2.54)}cm` : `${20}"`,
    },
    {
      value: 60,
      label: useMetricDimensions ? `${Math.ceil(60 * 2.54)}cm+` : `${60}"+`,
    },
  ];

  const lengthMarks = [
    {
      value: baseFilters.lengths.min,
      label: useMetricDimensions
        ? `${Math.floor(baseFilters.lengths.min * 2.54)}cm`
        : `${baseFilters.lengths.min}"`,
    },
    {
      value: baseFilters.lengths.max,
      label: useMetricDimensions
        ? `${Math.ceil(baseFilters.lengths.max * 2.54)}cm+`
        : `${baseFilters.lengths.max}"+`,
    },
  ];

  const weightMarks = [
    {
      value: baseFilters.weights.min,
      label: useMetric
        ? `${baseFilters.weights.min}g`
        : `${Math.floor(baseFilters.weights.min / 28)}oz`,
    },
    {
      value: baseFilters.weights.max,
      label: useMetric
        ? "5kg+"
        : `${Math.floor(baseFilters.weights.max / 28)}oz+`,
    },
  ];

  const priceMarks = [
    {
      value: baseFilters.prices.min,
      label: `$${baseFilters.prices.min}`,
    },
    {
      value: baseFilters.prices.max,
      label: `$${baseFilters.prices.max}+`,
    },
  ];

  const capacityMarks = [
    {
      value: baseFilters.capacity.min,
      label: `${baseFilters.capacity.min}`,
    },
    {
      value: baseFilters.capacity.max,
      label: `${baseFilters.capacity.max}`,
    },
  ];

  return (
    <Container
      sx={{
        backgroundColor: `${getColorWithMode("base", darkMode)} !important`,
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        direction={isMobile ? "row" : "column"}
        sx={{ width: "100%" }}
        justifyContent={"space-between"}
      >
        <Box sx={{ minWidth: "200px" }}>
          <FormControl
            sx={{ width: "100%", paddingBottom: "15px" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Settings</FormLabel>
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetric}
                  onChange={() => setUseMetric(!useMetric)}
                />
              }
              label="Metric Weights"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetricDimensions}
                  onChange={() => setUseMetricDimensions(!useMetricDimensions)}
                />
              }
              label="Metric Dimensions"
            />
          </FormControl>
        </Box>
        <Stack>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Price</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.prices}
                valueLabelFormat={(value) => {
                  if (value === 1500) return "$1500+";
                  return `$${value}`;
                }}
                defaultValue={[baseFilters.prices.min, baseFilters.prices.max]}
                marks={priceMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("price", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Weight</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  if (value === 5000) {
                    return useMetric ? `5kg+` : `${Math.floor(value / 28)}oz+`;
                  }
                  return useMetric
                    ? `${value}g`
                    : `${Math.floor(value / 28)}oz`;
                }}
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.weights}
                defaultValue={[
                  baseFilters.weights.min,
                  baseFilters.weights.max,
                ]}
                marks={weightMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("weight", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Sleeps</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value}`}
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.capacity}
                step={1}
                defaultValue={[
                  baseFilters.capacity.min,
                  baseFilters.capacity.max,
                ]}
                marks={capacityMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("capacity", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Width</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                valueLabelFormat={(value) =>
                  useMetricDimensions
                    ? `${Math.floor(value * 2.54)}cm`
                    : `${value}"`
                }
                min={20}
                max={60}
                step={1}
                defaultValue={[20, 60]}
                marks={widthMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("width", value);
                }}
              />
            </FormControl>
          </Box>
          <Box
            sx={{ minWidth: "200px", paddingTop: "7px", paddingBottom: "15px" }}
          >
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Length</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                valueLabelFormat={(value) =>
                  useMetricDimensions
                    ? `${Math.floor(value * 2.54)}cm`
                    : `${value}"`
                }
                size="small"
                min={baseFilters.lengths.min}
                max={baseFilters.lengths.max}
                step={0.5}
                defaultValue={[
                  baseFilters.lengths.min,
                  baseFilters.lengths.max,
                ]}
                marks={lengthMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("lengths", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Setups</FormLabel>
              <Divider />
              <Button
                sx={{
                  justifyContent: "left",
                  paddingLeft: "0px",
                  width: "150px",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  const newFilters = { ...userFilters };
                  if (userFilters.setup.length !== baseFilters.setups.length) {
                    newFilters.setup = baseFilters.setups;
                  } else {
                    newFilters.setup = [];
                  }
                  setUserFilters(newFilters);
                }}
              >
                {userFilters.setup.length !== baseFilters.setups.length
                  ? "Select All"
                  : "Deselect All"}
              </Button>
              <FormGroup>
                {baseFilters.setups.map((setup) => (
                  <FormControlLabel
                    key={`${setup}-setup-filter`}
                    control={
                      <Checkbox
                        checked={userFilters.setup.includes(setup)}
                        name={setup}
                      />
                    }
                    label={setup}
                    onChange={(e, value) => {
                      handleChangeFilter("setup", setup, value);
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Double Walled</FormLabel>
              <Divider />
              <Button
                sx={{
                  justifyContent: "left",
                  paddingLeft: "0px",
                  width: "150px",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  const newFilters = { ...userFilters };
                  if (
                    userFilters.doubleWalled.length !==
                    baseFilters.doubleWalled.length
                  ) {
                    newFilters.doubleWalled = baseFilters.setups;
                  } else {
                    newFilters.doubleWalled = [];
                  }
                  setUserFilters(newFilters);
                }}
              >
                {userFilters.doubleWalled.length !==
                baseFilters.doubleWalled.length
                  ? "Select All"
                  : "Deselect All"}
              </Button>
              <FormGroup>
                {baseFilters.doubleWalled.map((doubleWalled) => (
                  <FormControlLabel
                    key={`${doubleWalled}-dw-filter`}
                    control={
                      <Checkbox
                        checked={userFilters.doubleWalled.includes(
                          doubleWalled
                        )}
                        name={doubleWalled}
                      />
                    }
                    label={doubleWalled}
                    onChange={(e, value) => {
                      handleChangeFilter("doubleWalled", doubleWalled, value);
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Stack>
        <Box sx={{ minWidth: "200px" }}>
          <FormControl
            sx={{ width: "100%" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Brand</FormLabel>
            <Divider />
            <Button
              sx={{
                justifyContent: "left",
                paddingLeft: "0px",
                width: "150px",
                "&:hover": {
                  opacity: 0.8,
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => {
                const newFilters = { ...userFilters };
                if (userFilters.brand.length !== baseFilters.brands.length) {
                  newFilters.brand = baseFilters.brands;
                } else {
                  newFilters.brand = [];
                }
                setUserFilters(newFilters);
              }}
            >
              {userFilters.brand.length !== baseFilters.brands.length
                ? "Select All"
                : "Deselect All"}
            </Button>
            <FormGroup>
              {baseFilters.brands.map((brand) => (
                <FormControlLabel
                  key={`${brand}-brand-filter`}
                  control={
                    <Checkbox
                      checked={userFilters.brand.includes(brand)}
                      name={brand}
                    />
                  }
                  label={formatBrand(brand)}
                  onChange={(e, value) => {
                    handleChangeFilter("brand", brand, value);
                  }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Stack>
    </Container>
  );
};

export default TentFilterBar;
