import { Dialog } from "@mui/material";

const ImageDisplayDialog = ({
  image,
  handleClose,
  imagePreviewIsOpen,
  isPreview,
}) => {
  return (
    <Dialog onClose={handleClose} open={imagePreviewIsOpen}>
      {image ? (
        <img
          srcSet={isPreview ? `${URL.createObjectURL(image)}` : image}
          src={isPreview ? `${URL.createObjectURL(image)}` : image}
          alt={image.title}
          loading="lazy"
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            maxWidth: "750px",
            maxHeight: "750px",
          }}
        />
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default ImageDisplayDialog;
