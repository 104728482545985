import { Markup } from "interweave";
import ArticleGearModule from "../Articles/ArticleGearModule";
import MODULE_KEYS from "../Articles/constants/moduleKeys.json";
import ArticleFilteredGearModule from "../AdminTools/ArticleFilteredGearModule";
import { Stack } from "@mui/material";

const buildCustomHTML = (customData, isDarkMode) => {
  const finalData = [];
  customData.forEach(({ type, children }) => {
    switch (type) {
      case MODULE_KEYS.RAW_HTML:
        children.forEach(({ format, value }) => {
          finalData.push(
            <Markup content={value} key={JSON.stringify(value)} />
          );
        });
        break;
      case MODULE_KEYS.GEAR_CARD:
        const gearCardProps = {};
        children.forEach((child) => {
          const { key, value } = child;
          gearCardProps[key] = value;
        });
        finalData.push(
          <ArticleGearModule
            key={JSON.stringify(gearCardProps)}
            gear={gearCardProps}
            isDarkMode={isDarkMode}
          />
        );
        break;
      case MODULE_KEYS.FILTERED_GEAR:
        finalData.push(
          <ArticleFilteredGearModule
            key={JSON.stringify(children)}
            data={children}
            isDarkMode={isDarkMode}
          />
        );
        break;
    }
  });
  return <Stack gap={1}>{finalData}</Stack>;
};

export const buildArticleData = (seoData, introData, customData) => {
  return {
    seo: seoData,
    getArticleHtml: (isDarkMode) => buildCustomHTML(customData, isDarkMode),
    ...introData,
  };
};
