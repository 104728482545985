import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { getOptionsForGearTypeAutocomplete } from "../../utils";
import { FormControl } from "@mui/material";

export default function AutocompleteByGearType({
  gearId,
  gearType,
  changeHandler,
  disabled,
}) {
  const [value, setValue] = React.useState(gearId);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const getOptions = React.useCallback(
    debounce((gearType, query) => {
      (async () => {
        try {
          const options = await getOptionsForGearTypeAutocomplete(
            gearType,
            query
          );
          setOptions(options);
        } catch (error) {
          console.log(error);
        }
      })();
    }),
    1000
  );

  React.useEffect(() => {
    getOptions(gearType, inputValue);
  }, [gearType, inputValue]);

  return (
    <FormControl sx={{ width: "600px" }}>
      <Autocomplete
        disabled={disabled}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.label
        }
        options={options}
        autoComplete
        value={value}
        noOptionsText="No Items"
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          changeHandler(newValue.id);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Item" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li key={option.id} {...props}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Box key={option.id} component="span">
                    {option.label}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {option.id}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      {disabled ? (
        <Typography variant="subtitle2" color="error">
          Requires gear type to be selected
        </Typography>
      ) : (
        <></>
      )}
    </FormControl>
  );
}
