import {
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../constants/colors";
import { getUserIsLoggedIn, submitNewsletterEmail } from "../utils";
import { isValidEmail } from "../utils/validationUtils";
import PackSelector from "./PackSelector";
import { useMediaQuery } from "react-responsive";
import FeaturedGearList from "./FeaturedGearList";
import ExploreGearCard from "./ExploreGearCard";
import SubNavBar from "./SubNavBar";
import { logUserInteraction } from "../api/logging";
import { Helmet } from "react-helmet";
import LoginButton from "./LoginButton";
import SignUpButton from "./SignUpButton";

const HomeContainer = ({
  darkMode,
  packName,
  handleSetAuthenticationIsOpen,
}) => {
  const [newsletterEmail, setNewsletterEmail] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [userDidSubscribe, setUserDidSubscribe] = React.useState(false);
  logUserInteraction("session", "home");

  const handleSubmitNewsletterEmail = () => {
    if (isValidEmail(newsletterEmail)) {
      submitNewsletterEmail(newsletterEmail);
      setUserDidSubscribe(true);
    } else {
      setInvalidEmail(true);
    }
  };

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  return (
    <>
      <Helmet>
        <title>{packName}</title>
        <meta property="og:title" content={`PackWizard`} />
        <meta property="og:description" content={""} />
        <meta property="og:image" content={`/pack_wizard.png`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"PackWizard"} />
        <meta
          name="twitter:description"
          content={
            "PackWizard helps backpackers achieve ultralight status with tools to optimize their gear and receive lighter equipment suggestions."
          }
        />
        <link rel="canonical" href="https://www.packwizard.com" />

        <meta name="twitter:image" content={`/pack_wizard.png`} />
        <meta
          name="keywords"
          content={`${packName ? packName.split(" ").join(",") : ""}`}
        />
        <meta
          name="description"
          content={
            "PackWizard helps backpackers achieve ultralight status with tools to optimize their gear and receive lighter equipment suggestions."
          }
        />
      </Helmet>
      <Container
        sx={{
          width: "100%",
          height: "100%",
          padding: "0px 0px 0px 0px",
          fontFamily: "Roboto",
        }}
      >
        <SubNavBar darkMode={darkMode} />
        {/* <Container
          sx={{
            width: "100%",
            height: "360px",
            backgroundColor: getColorWithMode(darkMode, "secondary"),
            background:
              "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url('/home.jpg')",
            backgroundPositionY: "40%",
            backgroundSize: "cover",
          }}
        >
          <Stack
            sx={{ width: "100%", height: "360px" }}
            justifyContent="center"
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{
                color: "white",
                textShadow: "#000 1px 0 10px",
                fontFamily: `"Righteous", san-serif`,
              }}
            >
              The most comprehensive backpacking gear research tool on the
              internet
            </Typography>
          </Stack>
        </Container> */}
        <Stack>
          <Container
            sx={{
              padding: "50px 20px 50px 20px",
            }}
          >
            <Stack
              gap={3}
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                justifyContent="center"
                sx={{ width: "100%", maxWidth: "600px" }}
              >
                <Typography
                  textAlign={isMobile ? "center" : "left"}
                  variant="h4"
                  sx={{ fontFamily: '"Righteous", san-serif' }}
                >
                  Spend Less Time Planning & Spend More Time Outside
                </Typography>
                <Typography
                  variant="subtitle1"
                  textAlign={isMobile ? "center" : "left"}
                >
                  Build gearlists in minutes, not hours. Find your next piece of
                  gear instantly. View gear lists for inspiration & more.
                </Typography>
                <br />
                {getUserIsLoggedIn() ? (
                  <></>
                ) : (
                  <>
                    <Stack>
                      <Stack
                        gap={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: "50px" }}
                      >
                        <LoginButton
                          handleSetAuthenticationIsOpen={
                            handleSetAuthenticationIsOpen
                          }
                        />
                        <SignUpButton
                          handleSetAuthenticationIsOpen={
                            handleSetAuthenticationIsOpen
                          }
                        />
                      </Stack>
                    </Stack>
                    <Stack gap={1}>
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                        color="grey"
                      >
                        Completely free (and always will be).
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                        color="grey"
                      >
                        or
                      </Typography>
                      <Link
                        variant="subtitle2"
                        textAlign="center"
                        underline="none"
                        href="/packs"
                      >
                        Try without even signing up
                      </Link>
                    </Stack>
                  </>
                )}
              </Stack>
              <Link href="/packs">
                <Paper
                  sx={{
                    borderRadius: "10px",
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      maxWidth: "800px",
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "10px 10px 5px 5px",
                    }}
                    src={
                      darkMode
                        ? "https://storage.googleapis.com/pack_wizard/static_images/hero_dark_short.png"
                        : "https://storage.googleapis.com/pack_wizard/static_images/hero_light_short.png"
                    }
                  ></Box>
                </Paper>
              </Link>
            </Stack>
          </Container>
          <Container
            sx={{
              padding: "20px 20px 50px 20px",
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{ fontFamily: '"Righteous", san-serif' }}
            >
              Research Gear & Find Deals
            </Typography>
            <br />
            <Stack
              gap={3}
              direction={isDesktopOrLaptop ? "row" : "column"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                gap={3}
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Link
                  href="/gear"
                  underline="none"
                  sx={{
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "400px",
                      textDecoration: "none",
                      textAlign: "center",
                      color: getColorWithMode(darkMode, "text"),
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    Browse thousands of pieces of gear and filter & sort by
                    qualities that matter to you
                  </Typography>

                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Stack gap={1}>
                      <Box
                        component="img"
                        sx={{
                          width: "100%",
                          maxWidth: "400px",
                          objectFit: "contain",
                          borderRadius: "0px 0px 5px 5px",
                        }}
                        src={
                          darkMode
                            ? "https://storage.googleapis.com/pack_wizard/static_images/gear_list_dark.png"
                            : "https://storage.googleapis.com/pack_wizard/static_images/gear_list_light.png"
                        }
                      />
                    </Stack>
                  </Paper>
                </Link>
                <Link
                  href="/gear"
                  underline="none"
                  sx={{
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "400px",
                      width: "100%",
                      textDecoration: "none",
                      textAlign: "center",
                      color: getColorWithMode(darkMode, "text"),
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    View Gear Specs, Retailers, Live Pricing & Price history
                    updated every day
                  </Typography>
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Stack gap={1}>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "400px",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "0px 0px 5px 5px",
                        }}
                        src={
                          darkMode
                            ? "https://storage.googleapis.com/pack_wizard/static_images/gear_dark.png"
                            : "https://storage.googleapis.com/pack_wizard/static_images/gear_light.png"
                        }
                      />
                    </Stack>
                  </Paper>
                </Link>
              </Stack>
              <Stack
                gap={3}
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Link
                  href="/gear"
                  underline="none"
                  sx={{
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "400px",
                      width: "100%",
                      textDecoration: "none",
                      textAlign: "center",
                      color: getColorWithMode(darkMode, "text"),
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    See how gear stacks up against similar gear & read reviews
                  </Typography>
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Stack gap={1}>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "400px",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "0px 0px 5px 5px",
                        }}
                        src={
                          darkMode
                            ? "https://storage.googleapis.com/pack_wizard/static_images/gear_insights_dark.png"
                            : "https://storage.googleapis.com/pack_wizard/static_images/gear_insights_light.png"
                        }
                      />
                    </Stack>
                  </Paper>
                </Link>
                <Link
                  href="/gear"
                  underline="none"
                  sx={{
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "400px",
                      textDecoration: "none",
                      textAlign: "center",
                      color: getColorWithMode(darkMode, "text"),
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    Find the best deals on gear across the internet, updated
                    every day
                  </Typography>
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Stack gap={1}>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "400px",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "0px 0px 5px 5px",
                        }}
                        src={
                          darkMode
                            ? "https://storage.googleapis.com/pack_wizard/static_images/deals_dark.png"
                            : "https://storage.googleapis.com/pack_wizard/static_images/deals_light.png"
                        }
                      />
                    </Stack>
                  </Paper>
                </Link>
              </Stack>
            </Stack>
          </Container>
          <Container
            sx={{
              padding: "20px 20px 50px 20px",
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{ fontFamily: '"Righteous", san-serif' }}
            >
              Create & Share Gearlists
            </Typography>
            <br />
            <Stack
              gap={3}
              direction={isDesktopOrLaptop ? "row" : "column"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                gap={3}
                justifyContent="space-between"
                alignItems="center"
              >
                <Link
                  href="/packs"
                  underline="none"
                  sx={{
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "400px",
                      textDecoration: "none",
                      textAlign: "center",
                      color: getColorWithMode(darkMode, "text"),
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    Create gear lists lightning fast with autofill from a
                    database of 10,000+ items
                  </Typography>

                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Stack gap={1}>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "400px",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "0px 0px 5px 5px",
                        }}
                        src={
                          darkMode
                            ? "https://storage.googleapis.com/pack_wizard/static_images/autocompolete_dark.png"
                            : "https://storage.googleapis.com/pack_wizard/static_images/autocomplete_light.png"
                        }
                      />
                    </Stack>
                  </Paper>
                </Link>
                <Link
                  href="/packs"
                  underline="none"
                  sx={{
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "400px",
                      width: "100%",
                      textDecoration: "none",
                      textAlign: "center",
                      color: getColorWithMode(darkMode, "text"),
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    Set tags for your gear or hike, share your list, and monitor
                    your gear lists viewcount
                  </Typography>
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Stack gap={1}>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "400px",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "0px 0px 5px 5px",
                        }}
                        src={
                          darkMode
                            ? "https://storage.googleapis.com/pack_wizard/static_images/analytics_dark.png"
                            : "https://storage.googleapis.com/pack_wizard/static_images/analytics_light.png"
                        }
                      />
                    </Stack>
                  </Paper>
                </Link>
              </Stack>
              <Stack
                direction="row"
                gap={3}
                justifyContent="space-between"
                alignItems="center"
              >
                <Link
                  href="/packs"
                  underline="none"
                  sx={{
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "400px",
                      textDecoration: "none",
                      textAlign: "center",
                      color: getColorWithMode(darkMode, "text"),
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    Get automated gear suggestions, showing how much weight you
                    can save
                  </Typography>
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Stack gap={1}>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "400px",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "0px 0px 5px 5px",
                        }}
                        src={
                          darkMode
                            ? "https://storage.googleapis.com/pack_wizard/static_images/suggest_dark.png"
                            : "https://storage.googleapis.com/pack_wizard/static_images/suggest_light.png"
                        }
                      />
                    </Stack>
                  </Paper>
                </Link>
                <Link
                  href="/browse"
                  underline="none"
                  sx={{
                    position: "relative",
                    top: "0",
                    transition: "top ease 0.25s",
                    "&:hover": {
                      top: "-10px",
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "400px",
                      textDecoration: "none",
                      textAlign: "center",
                      color: getColorWithMode(darkMode, "text"),
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    Browse other user's packs. Filter by location, season,
                    weight, distance, activities & more
                  </Typography>
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Stack gap={1}>
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "400px",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "0px 0px 5px 5px",
                        }}
                        src={
                          darkMode
                            ? "https://storage.googleapis.com/pack_wizard/static_images/browse_dark.png"
                            : "https://storage.googleapis.com/pack_wizard/static_images/browse_light.png"
                        }
                      />
                    </Stack>
                  </Paper>
                </Link>
              </Stack>
            </Stack>
          </Container>
          <Paper
            sx={{ width: "100%", height: isMobile ? "350px" : "390px" }}
            elevation={3}
          >
            <Typography
              variant="h4"
              sx={{ padding: "10px", textAlign: "center" }}
              fontFamily="'Righteous', sans-serif"
            >
              Featured Packs
            </Typography>
            <FeaturedGearList isDarkMode={darkMode} />
          </Paper>
        </Stack>
        <Container
          sx={{
            width: "100%",
            backgroundColor: getColorWithMode(darkMode, "base"),
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            fontFamily="'Righteous', sans-serif"
            sx={{ width: "100%", padding: "20px" }}
          >
            Find your next piece of gear
          </Typography>
          <Typography
            variant="body"
            sx={{
              width: "100%",
              textAlign: "center",
              padding: "20px",
            }}
          >
            Find, compare and research gear across hundreds of brands. Quickly
            compare specs and features with live pricing data for your favorite
            retailers.
          </Typography>
          {isDesktopOrLaptop ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: "20px",
              }}
              gap={1}
            >
              <Stack gap={1} direction="row">
                <ExploreGearCard
                  link="/gear/tents"
                  imageUrl="/xmid2.png"
                  text="Tents"
                />
                <ExploreGearCard
                  link="/gear/sleeping-pads"
                  imageUrl="/helix.png"
                  text="Sleeping Pads"
                />

                <ExploreGearCard
                  link="/gear/backpacks"
                  imageUrl="/reiflash.png"
                  text="Backpacks"
                />
              </Stack>
              <Stack gap={1} direction="row">
                <ExploreGearCard
                  link="/gear/sleeping-bags"
                  imageUrl="/ee_down.png"
                  text="Sleeping Bags and Quilts"
                />
                <ExploreGearCard
                  link="/gear/insulated-jackets"
                  imageUrl="/cumulus.png"
                  text="Insulated Jackets"
                />
                <ExploreGearCard
                  link="/gear/stoves"
                  imageUrl="/pocketrocket.png"
                  text="Stoves"
                />
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: "20px",
              }}
              gap={1}
            >
              <Stack gap={1} direction="row">
                <ExploreGearCard
                  link="/gear/tents"
                  imageUrl="/xmid2.png"
                  text="Tents"
                />
                <ExploreGearCard
                  link="/gear/sleeping-pads"
                  imageUrl="/helix.png"
                  text="Sleeping Pads"
                />
              </Stack>
              <Stack gap={1} direction="row">
                <ExploreGearCard
                  link="/gear/backpacks"
                  imageUrl="/reiflash.png"
                  text="Backpacks"
                />
                <ExploreGearCard
                  link="/gear/sleeping-bags"
                  imageUrl="/ee_down.png"
                  text="Sleeping Bags and Quilts"
                />
              </Stack>
              <Stack gap={1} direction="row">
                <ExploreGearCard
                  link="/gear/insulated-jackets"
                  imageUrl="/cumulus.png"
                  text="Insulated Jackets"
                />
                <ExploreGearCard
                  link="/gear/stoves"
                  imageUrl="/pocketrocket.png"
                  text="Stoves"
                />
              </Stack>
            </Stack>
          )}
        </Container>
        <Container
          sx={{
            width: "100%",
            minHeight: "300px",
            height: isMobile ? "300px" : "calc(100vh - 950px)",
            bottom: "0px",
            backgroundColor: getColorWithMode(darkMode, "base"),
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          <Typography
            variant="h5"
            fontFamily="'Roboto Condensed', sans-serif"
            sx={{ width: "100%", padding: "20px" }}
          >
            Want to stay updated on when we add new gear? Join our newsletter!
          </Typography>
          <Stack>
            <Typography
              variant="body"
              sx={{
                width: "100%",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              Join our weekly newsletter to find out when we add new gear, as
              well as receive updates about deals and sales on all of your
              favorite gear.
            </Typography>
          </Stack>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Stack sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <TextField
                sx={{ width: "360px" }}
                placeholder="Email"
                error={invalidEmail}
                onChange={(e) => {
                  setInvalidEmail(false);
                  setNewsletterEmail(e.target.value);
                  setUserDidSubscribe(false);
                }}
              ></TextField>
              {userDidSubscribe ? (
                <Button disabled>Success</Button>
              ) : (
                <Button onClick={handleSubmitNewsletterEmail}>Submit</Button>
              )}
            </Stack>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default HomeContainer;
