import React from "react";

import Dialog from "@mui/material/Dialog";
import Container from "@mui/system/Container";
import Slide from "@mui/material/Slide";
import GearClosetDataTable from "./GearClosetDataTable";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GearBinContainer from "./GearBinContainer";
import {
  DialogContent,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import CreateIcon from "@mui/icons-material/Create";
import { createNewBin, deleteUserBin, getBins, updateUserBin } from "../utils";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const GearCloset = ({
  gearClosetIsOpen,
  setGearClosetIsOpen,
  handleSetAuthenticationIsOpen,
  handleAddGearFromGearCloset,
  handleAddBinToPack,
  tableData,
  darkMode,
}) => {
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [binAnchorEl, setBinAnchorEl] = React.useState(null);
  const [moreToolsAnchorEl, setMoreToolsAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [deleteDuplicateIsOpen, setDeleteDuplicateIsOpen] =
    React.useState(false);
  const [gearBins, setGearBins] = React.useState([]);
  const [gearRows, setGearRows] = React.useState([]);
  const [filteredGearRows, setFilteredGearRows] = React.useState([]);
  const [isBeingFilteredToBin, setIsBeingFilteredToBin] = React.useState(false);

  const handleSetSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const debouncedChangeHandler = React.useCallback(
    debounce(handleSetSearchText, 300),
    []
  );

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMoreToolsClick = (e) => {
    setMoreToolsAnchorEl(e.currentTarget);
  };

  const handleMoreToolsClose = () => {
    setMoreToolsAnchorEl(null);
  };

  const handleAddToCategoryClose = () => {
    setAnchorEl(null);
  };

  const handleAddToCategory = (categoryIndex) => {
    handleAddGearFromGearCloset(categoryIndex, selectedItems);
    handleAddToCategoryClose();
  };

  const handleRemoveDuplicates = () => {
    setDeleteDuplicateIsOpen(true);
    handleMoreToolsClose();
  };

  const handleClose = () => {
    setSelectedItems([]);
    setGearClosetIsOpen(false);
  };

  const handleAddToBin = (e) => {
    setBinAnchorEl(e.currentTarget);
  };

  const handleAddToBinClose = () => {
    setBinAnchorEl(null);
  };

  const handleCreateBin = async () => {
    const selectedItemIds = selectedItems.map(({ _id }) => _id);
    const newBin = await createNewBin(selectedItemIds);
    newBin.contents = selectedItems;
    newBin.id = newBin._id;
    if (newBin) {
      const newGearBins = [...gearBins, newBin];
      setGearBins(newGearBins);
      handleAddToBinClose();
    }
  };

  const handleAddToExistingBin = async (index) => {
    const currentBins = [...gearBins];
    const newContents = [...gearBins[index].contents, ...selectedItems];
    const { finalContents } = newContents.reduce(
      (acc, curr) => {
        if (!acc.seenContents[curr.id]) {
          acc.finalContents.push(curr);
          acc.seenContents[curr.id] = true;
        }
        return acc;
      },
      {
        finalContents: [],
        seenContents: {},
      }
    );
    currentBins[index].contents = finalContents;
    setGearBins(currentBins);
    const selectedItemIds = finalContents.map(({ _id }) => _id);
    const binId = currentBins[index].id;
    await updateUserBin(binId, { contents: selectedItemIds });
    handleAddToBinClose();
  };

  const handleDeleteFromBin = async (binIndex, itemId) => {
    const currentBins = [...gearBins];
    const newContents = gearBins[binIndex].contents.filter(
      ({ id }) => id !== itemId
    );
    currentBins[binIndex].contents = newContents;
    const binId = currentBins[binIndex].id;
    const newItemIds = newContents.map(({ _id }) => _id);
    await updateUserBin(binId, { contents: newItemIds });
    setGearBins(currentBins);
  };

  const handleDeleteBin = async (binId) => {
    const newBins = gearBins.filter((bin) => bin.id !== binId);
    await deleteUserBin(binId);
    setGearBins(newBins);
  };

  const handleUpdateBinText = (binIndex, key, text) => {
    const newBins = [...gearBins];
    newBins[binIndex][key] = text;
    setGearBins(newBins);
  };

  const handleFilterToBin = (binTitle, contents) => {
    const filteredIdSet = new Set(contents.map(({ id }) => id));
    const filteredToBin = gearRows.filter((gearRow) =>
      filteredIdSet.has(gearRow.id)
    );
    setFilteredGearRows(filteredToBin);
    setIsBeingFilteredToBin(binTitle);
  };

  const clearBinFilter = () => {
    setFilteredGearRows(gearRows);
    setIsBeingFilteredToBin(null);
  };

  React.useEffect(() => {
    (async () => {
      const usersGearBins = await getBins();
      if (usersGearBins) {
        const hydratedBins = usersGearBins.reduce((acc, curr) => {
          const bin = { ...curr };
          const idSet = new Set(bin.contents);
          const idGcClosetMap = {};
          gearRows.forEach((row) => {
            if (idSet.has(row.id)) {
              idGcClosetMap[row.id] = row;
            }
          });
          const hydratedContent = bin.contents
            .map((id) => idGcClosetMap[id])
            .filter((row) => row);
          acc.push({ id: bin._id, ...bin, contents: hydratedContent });
          return acc;
        }, []);
        setGearBins(hydratedBins);
      }
    })();
  }, [gearRows]);

  return (
    <Dialog
      onClose={handleClose}
      open={gearClosetIsOpen}
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={{
          maxWidth: "1000px",
          width: "calc(100vw - 60px)",
          padding: "0px 0px 0px 0px",
        }}
      >
        <Container
          sx={{
            maxHeight: "100vh",
            marginTop: "20px",
          }}
        >
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Gear Closet</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#BBB",
                fontStyle: "italic",
                maxWidth: "800px",
                paddingTop: "10px",
              }}
            >
              Here you can create, view and manage your gear, so you can add it
              to your pack faster.
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <Button
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  variant="contained"
                  size="small"
                  disabled={
                    selectedItems.length === 0 || tableData.length === 0
                  }
                >
                  {"Add to Pack"}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleAddToCategoryClose}
                >
                  {tableData.map((category, index) => (
                    <MenuItem
                      key={`${category.title}-${index}`}
                      onClick={() => {
                        handleAddToCategory(index);
                      }}
                    >
                      {category.title}
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  onClick={handleAddToBin}
                  endIcon={<KeyboardArrowDownIcon />}
                  variant="contained"
                  size="small"
                  disabled={
                    selectedItems.length === 0 || tableData.length === 0
                  }
                >
                  {"Add to Bin"}
                </Button>
                <Menu
                  anchorEl={binAnchorEl}
                  open={Boolean(binAnchorEl)}
                  onClose={handleAddToBinClose}
                >
                  <MenuItem
                    key={`create-bin-menu-item`}
                    onClick={() => {
                      handleCreateBin();
                    }}
                  >
                    <ListItemIcon>
                      <CreateIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Create New Bin</ListItemText>
                  </MenuItem>
                  {gearBins.map((bin, index) => (
                    <MenuItem
                      key={`${bin.title}-${index}`}
                      onClick={() => {
                        handleAddToExistingBin(index);
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText>{bin.title}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  onClick={handleMoreToolsClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  variant="contained"
                  size="small"
                >
                  {"More Actions"}
                </Button>
                <Menu
                  anchorEl={moreToolsAnchorEl}
                  open={Boolean(moreToolsAnchorEl)}
                  onClose={handleMoreToolsClose}
                >
                  <MenuItem
                    key="remove-duplicates"
                    onClick={handleRemoveDuplicates}
                  >
                    <ListItemIcon>
                      <DeleteSweepIcon />
                    </ListItemIcon>
                    <ListItemText primary="Remove Duplicates From Gear Closet" />
                  </MenuItem>
                </Menu>
              </Stack>
              <TextField
                size={"small"}
                sx={{
                  width: "300px",
                }}
                placeholder="Search"
                onChange={debouncedChangeHandler}
              ></TextField>
            </Stack>
            <GearClosetDataTable
              handleSetAuthenticationIsOpen={handleSetAuthenticationIsOpen}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              darkMode={darkMode}
              searchText={searchText}
              deleteDuplicateIsOpen={deleteDuplicateIsOpen}
              setDeleteDuplicateIsOpen={setDeleteDuplicateIsOpen}
              gearRows={gearRows}
              setGearRows={setGearRows}
              filteredGearRows={filteredGearRows}
              setFilteredGearRows={setFilteredGearRows}
            />
            {isBeingFilteredToBin ? (
              <Typography variant="subtitle2" color="error">
                {`Your Gear Closet is Currently being filtered to the contents of ${isBeingFilteredToBin}. `}
                <Link
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  onClick={clearBinFilter}
                >
                  Click to Remove Filter
                </Link>
              </Typography>
            ) : (
              <></>
            )}
            <GearBinContainer
              gearBins={gearBins}
              handleDeleteFromBin={handleDeleteFromBin}
              handleDeleteBin={handleDeleteBin}
              handleFilterToBin={handleFilterToBin}
              handleAddBinToPack={handleAddBinToPack}
              darkMode={darkMode}
              handleUpdateBinText={handleUpdateBinText}
            />
          </Stack>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default GearCloset;
