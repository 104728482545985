import React from "react";
import Button from "@mui/material/Button";

const LoginButton = ({ handleSetAuthenticationIsOpen }) => (
  <Button
    variant="outlined"
    onClick={() => {
      handleSetAuthenticationIsOpen("LOGIN");
    }}
  >
    Login
  </Button>
);

export default LoginButton;
