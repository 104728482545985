import React from "react";

import { Container, Stack } from "@mui/system";
import { getColorWithMode } from "../../constants/colors";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import TentTable from "./TentTable";
import { fetchTents } from "../../api/gear";
import TentFilterBar from "./TentFilterBar";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useCallback } from "react";
import debounce from "lodash.debounce";

const fetchFilterValues = (rows) => {
  const brands = new Set();
  const types = new Set();
  const setups = new Set();
  const doubleWalled = new Set();

  let lowestWeight = Number.MAX_SAFE_INTEGER;
  let lowestPrice = Number.MAX_SAFE_INTEGER;
  let shortestLength = Number.MAX_SAFE_INTEGER;
  let longestLength = 0;
  let lowestCapacity = Number.MAX_SAFE_INTEGER;
  let highestCapacity = 0;

  rows.forEach((row) => {
    brands.add(row.brand);
    types.add(row.type);
    setups.add(row.setup);
    doubleWalled.add(row.doubleWalled);
    if (row.weight < lowestWeight) lowestWeight = row.weight;
    if (row.price < lowestPrice) lowestPrice = row.price;
    if (row.length && row.length < shortestLength) shortestLength = row.length;
    if (row.length && row.length > longestLength) longestLength = row.length;
    if (Number(row.capacity) < lowestCapacity)
      lowestCapacity = Number(row.capacity);
    if (Number(row.capacity) > highestCapacity)
      highestCapacity = Number(row.capacity);
  });
  return {
    brands: [...brands].sort(),
    types: [...types].sort(),
    setups: [...setups].sort(),
    doubleWalled: [...doubleWalled].sort(),
    weights: {
      min: Math.floor(lowestWeight),
      max: 5000,
    },
    prices: {
      min: Math.floor(lowestPrice),
      max: 1500,
    },
    lengths: {
      min: shortestLength,
      max: longestLength,
    },
    capacity: {
      min: lowestCapacity,
      max: highestCapacity,
    },
  };
};

const TentContainer = ({
  darkMode,
  setAddToGCSnackbarIsOpen,
  setReportSnackBarIsOpen,
  handleAddToPackMenu,
  gearText,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const [tents, setTents] = React.useState([]);
  const [baseFilters, setBaseFilters] = React.useState(null);
  const [userFilters, setUserFilters] = React.useState({});
  const [filteredTents, setFilteredTents] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [useMetric, setUseMetric] = React.useState(true);
  const [useMetricDimensions, setUseMetricDimensions] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filtersAreOpen, setFiltersAreOpen] = React.useState(isDesktopOrLaptop);

  const handleSetSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleSetSearchText, 300),
    []
  );

  const handleSetSelectedItems = (items) => {
    setSelectedItems(items);
  };

  React.useEffect(() => {
    (async () => {
      const { data } = await fetchTents();
      const newFilters = fetchFilterValues(data);
      setBaseFilters(newFilters);
      setUserFilters({
        brand: newFilters.brands,
        setup: newFilters.setups,
        doubleWalled: newFilters.doubleWalled,
        widths: [20, 60],
      });
      setTents(data);
      setFilteredTents(data);
    })();
  }, []);

  React.useEffect(() => {
    const filteredDownTents = tents.filter(
      (tent) =>
        `${tent.formattedBrand.toLowerCase()} ${tent.name.toLowerCase()}`.includes(
          searchText.toLowerCase()
        ) &&
        (!userFilters.price ||
          !tent.price ||
          (tent.price >= userFilters.price[0] &&
            (tent.price <= userFilters.price[1] ||
              userFilters.price[1] === 1500))) &&
        (!userFilters.weight ||
          !tent.weight ||
          (tent.weight >= userFilters.weight[0] &&
            (tent.weight <= userFilters.weight[1] ||
              userFilters.weight[1] === 5000))) &&
        userFilters.brand.includes(tent.brand) &&
        userFilters.setup.includes(tent.setup) &&
        userFilters.doubleWalled.includes(tent.doubleWalled) &&
        (!userFilters.lengths ||
          !tent.length ||
          (tent.length >= userFilters.lengths[0] &&
            tent.length <= userFilters.lengths[1])) &&
        (!userFilters.widths ||
          (tent.minimumWidth >= userFilters.widths[0] &&
            (tent.minimumWidth <= userFilters.widths[1] ||
              userFilters.widths[1] === 60))) &&
        (!userFilters.capacity ||
          (Number(tent.capacity) >= userFilters.capacity[0] &&
            Number(tent.capacity) <= userFilters.capacity[1]))
    );
    setFilteredTents(filteredDownTents);
  }, [userFilters, tents, searchText]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>Backpacking Tents</title>
        <link rel="canonical" href="https://www.packwizard.com/gear/tents" />
        <meta
          name="keywords"
          content="backpacking tent comparison tool, tent, comparison, tent specs, cheapest backpacking tents, tent price tracker"
        />
        <meta
          name="description"
          content="Compare tent specs to find your ideal backpacking tent, complete with live price tracking to make sure you are getting the best deal out there."
        />
      </Helmet>
      <Container
        sx={{
          width: "100%",
          backgroundColor: getColorWithMode(darkMode, "title"),
        }}
      >
        <Stack
          textAlign="center"
          alignItems="center"
          sx={{ padding: "20px 10px 20px 10px" }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              fontFamily: "Helvetica",
              color: "white",
            }}
          >
            Tents
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Helvetica",
              color: "white",
              maxWidth: "650px",
            }}
          >
            Discover tents that fit all of your needs. Sort and filter by all of
            the most important features of a tent or tarp and use our live price
            tracker to find the best deals on your next shelter
          </Typography>
        </Stack>
      </Container>
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Stack
          sx={isMobile ? {} : { width: filtersAreOpen ? "240px" : "10px" }}
        >
          <Button
            sx={
              isMobile
                ? { width: "260px", fontSize: "18px" }
                : { width: "240px" }
            }
            onClick={() => {
              setFiltersAreOpen(!filtersAreOpen);
            }}
          >{`${
            filtersAreOpen ? "Hide Filters/Settings" : "Show Filters/Settings"
          }`}</Button>
          <Collapse
            in={filtersAreOpen}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            <Container
              sx={{
                width: isMobile ? "100%" : "240px",
                height: "100%",
                padding: "5px 5px 5px 5px",
                marginTop: "20px",
              }}
            >
              <TentFilterBar
                darkMode={darkMode}
                baseFilters={baseFilters}
                userFilters={userFilters}
                setUserFilters={setUserFilters}
                useMetric={useMetric}
                setUseMetric={setUseMetric}
                useMetricDimensions={useMetricDimensions}
                setUseMetricDimensions={setUseMetricDimensions}
              />
            </Container>
          </Collapse>
        </Stack>
        <Container
          sx={{
            marginTop: filtersAreOpen ? "0px" : "20px",
          }}
        >
          <Stack>
            <Stack
              sx={{ height: isMobile ? "70px" : "50px" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant={isMobile ? "h6" : "body1"}
                sx={{
                  paddingLeft: "5px",
                  fontFamily: "Helvetica",
                }}
              >
                {`${filteredTents.length} Tents`}
              </Typography>
              <TextField
                size={isMobile ? "large" : "small"}
                sx={{
                  width: "300px",
                }}
                placeholder="Search"
                onChange={debouncedChangeHandler}
              ></TextField>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                height: "40px",
                maxWidth: "100%",
                whiteSpace: "nowrap",
              }}
            >
              <Button
                sx={{ width: "80px" }}
                disabled={selectedItems.length <= 1 || selectedItems.length > 3}
                onClick={() => {
                  window.location.assign(
                    `/gear/tents/compare?products=${selectedItems.join(",")}`
                  );
                }}
              >
                Compare
              </Button>
              {selectedItems.length ? (
                <Divider
                  sx={{
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    height: "20px",
                  }}
                  orientation="vertical"
                />
              ) : (
                <></>
              )}
              <Box
                sx={{
                  maxWidth: "100%",
                  textOverflow: "nowrap",
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
              >
                {selectedItems.map((itemId) => {
                  const itemToRender = tents.find(({ id }) => itemId === id);
                  return (
                    <Chip
                      sx={{ marginLeft: "5px" }}
                      variant="outlined"
                      label={`${itemToRender.formattedBrand} ${
                        itemToRender.name
                      } ${
                        itemToRender.variant ? `(${itemToRender.variant})` : ""
                      }`}
                      onDelete={() => {
                        handleSetSelectedItems(
                          selectedItems.filter(
                            (itemId) => itemId !== itemToRender.id
                          )
                        );
                      }}
                    />
                  );
                })}
              </Box>
            </Stack>
            <TentTable
              tents={filteredTents}
              handleSetSelectedItems={handleSetSelectedItems}
              selectedItems={selectedItems}
              useMetric={useMetric}
              useMetricDimensions={useMetricDimensions}
              setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
              setReportSnackBarIsOpen={setReportSnackBarIsOpen}
              handleAddToPackMenu={handleAddToPackMenu}
              gearText={gearText}
              darkMode={darkMode}
            />
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

export default TentContainer;
