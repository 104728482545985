export const DURATION_SETTINGS = {
  NONE: "",
  DAYTRIP: "Daytrip",
  ONE_NIGHTER: "1 Night",
  TWO_TO_THREE_DAY: "2-3 days",
  THREE_TO_FIVE_DAYS: "3-5 days",
  FIVE_TO_SEVEN_DAYS: "5-7 days",
  ONE_TO_WEEKS: "1-2 weeks",
  TWO_PLUS_WEEKS: "2+ weeks",
};

export const SEASON_SETTINGS = {
  NONE: "",
  SPRING: "Spring",
  SUMMER: "Summer",
  FALL: "Fall",
  WINTER: "Winter",
  THREE_SEASON: "3-Season",
  FOUR_SEASON: "4-Season",
};

export const TAG_LIST = [
  "Backpacking",
  "Biking",
  "Bird Watching",
  "Comfort",
  "Couples",
  "Cross-country Skiing",
  "Fastpacking",
  "Fishing",
  "Hunting",
  "Mountaineering",
  "Rock Climbing",
  "Running",
  "Section Hike",
  "Snowshoeing",
  "Thru-Hike",
];

export const TRAIL_LIST = [
  "Allegheny Front Trail",
  "Allegheny Trail",
  "Alta Via 2",
  "American Discovery Trail",
  "Angels Landing",
  "Annapurna Circuit",
  "Appalachian Trail (AT)",
  "Arizona Trail",
  "Art Loeb Trail",
  "Backbone Trail",
  "Baker Trail",
  "Bartram Trail",
  "Batona Trail",
  "Bay Area Ridge Trail",
  "Bay Circuit Trail & Greenway",
  "Benton MacKaye Trail",
  "Bigfoot Trail",
  "Black Forest Trail",
  "Bonneville Shoreline Trail",
  "Border Route Trail",
  "Buckeye Trail",
  "Bucktail Path",
  "C&O Canal Trail",
  "California Coastal Trail",
  "Camino de Santiago",
  "Catamount Trail",
  "Chief Ladiga Trail",
  "Chilkoot Trail",
  "Chuck Keiper Trail",
  "Cohos Trail",
  "Colorado Trail",
  "Conestoga Trail System",
  "Continental Divide Trail (CDT)",
  "Copper Ridge Loop",
  "Cordillera Huayhuash",
  "Corvallis to the Sea Trail",
  "Cowboy Trail",
  "Coyote Gulch",
  "Cranberry Lake 50",
  "Cumberland Trail",
  "Des Plaines River Trail",
  "Desolation Wilderness",
  "Domomite High Route (Alta Via 1)",
  "Donut Hole Trail",
  "Duncan Ridge Trail",
  "East Coast Greenway",
  "Eastern Continental Trail",
  "Everest Base Camp",
  "Finger Lakes Trail",
  "Florida Keys Overseas Heritage Trail",
  "Florida Trail",
  "Fonta Flora State Trail",
  "Foothills Trail",
  "Fox River Trail (Illinois)",
  "GR 20",
  "Gandy Dancer State Trail",
  "George S. Mickelson Trail",
  "Gerard Hiking Trail",
  "Gerry Connolly Cross County Trail",
  "Grand Canyon Rim-to-Rim",
  "Grand Enchantment Trail",
  "Grand Teton Loop",
  "Great Allegheny Passage",
  "Great Divide Trail (GDT)",
  "Great Eastern Trail",
  "Great Himalayas",
  "Great Western Loop",
  "Great Western Trail",
  "Great Western Trail (Illinois)",
  "Greenbrier River Trail",
  "Greenstone Ridge Trail",
  "Hardangervidda Plateau Traverse",
  "Hayduke Trail",
  "Hernández-Capron Trail",
  "High Sierra Trail",
  "Highline National Recreation Trail",
  "Horse-Shoe Trail",
  "Ice Age Trail",
  "Idaho Centennial Trail",
  "Iditarod Trail",
  "Illinois Prairie Path",
  "Inca Trail to Machu Picchu",
  "International Appalachian Trail",
  "Iron Horse Regional Trail",
  "John Muir Trail (JMT)",
  "Jordan Trail",
  "Kalalau Trail",
  "Katy Trail",
  "Kekekabic Trail",
  "Kepler Track",
  "Kilimanjaro",
  "Knobstone Trail",
  "Kungsleden",
  "Lake Okeechobee Scenic Trail",
  "Laugavegur-Skogar Trail",
  "Laurel Highlands Hiking Trail",
  "Little Miami Scenic Trail",
  "Lone Star Hiking Trail",
  "Long Path",
  "Long Range Traverse",
  "Long Trail",
  "Loyalsock Trail",
  "Maah Daah Hey Trail",
  "Mahlerweg, Painter's Path",
  "Mardi Himal Trek",
  "Mason-Dixon Trail",
  "Massanutten Trail",
  "Mattabesett Trail",
  "Metacomet-Monadnock Trail",
  "Michigan Shore-to-Shore Trail",
  "Mid State Trail (Pennsylvania)",
  "Midstate Trail (Massachusetts)",
  "Milford Track",
  "Monadnock-Sunapee Greenway",
  "Mountains-to-Sea Trail",
  "Natchez Trace Trail",
  "New England Trail",
  "New River Trail State Park",
  "North Country Trail",
  "North Umpqua Trail",
  "North-South Trail (RI)",
  "Northville-Placid Trail",
  "OC&E Woods Line State Trail",
  "Ocean to Lake Trail",
  "Ohio to Erie Trail",
  "Olympic Discovery Trail",
  "Oregon Coast Trail",
  "Oregon Desert Trail",
  "Oregon Skyline Trail",
  "Ouachita National Recreation Trail",
  "Overmountain Victory National Historic Trail",
  "Ozark Highlands Trail",
  "Ozark Trail",
  "Pacific Crest Trail (PCT)",
  "Pacific Northwest Trail",
  "Palmetto Trail",
  "Paumanok Path",
  "Peaks of the Balkans",
  "Pieterpad",
  "Pinhoti Trail",
  "Potomac Heritage Trail",
  "Quehanna Trail",
  "Rachel Carson Trail",
  "Razorback Regional Greenway",
  "Rheinsteig",
  "River to River Trail",
  "Robert Frost Trail",
  "Rock Island Trail (Missouri)",
  "Rockwall Trail",
  "Rota Vicentina",
  "Routeburn Track",
  "Salkantay Trail",
  "San Francisco Bay Trail",
  "Sauratown Trail",
  "Sawtooth Loop Trail",
  "Sheltowee Trace Trail",
  "Sierra High Route",
  "Silver Comet Trail",
  "Silver Moccasin Trail",
  "Skyline Trail",
  "Slovenia Mountain Trail",
  "Standing Stone Trail",
  "Superior Hiking Trail",
  "Susquehannock Trail System",
  "Tahoe Rim Trail",
  "Tahoe-Yosemite Trail",
  "Tecumseh Trail",
  "The Narrows",
  "The Pennine Way",
  "Theodore Solomons Trail",
  "Timberline Trail",
  "Tongariro Alpine Crossing",
  "Tonquin Valley",
  "Torres del Paine",
  "Toubkal Circuit",
  "Tour du Mont Blanc",
  "Trail of the Coeur d'Alenes",
  "Trans-Catalina Trail",
  "Traumpfad, Dream Way",
  "Tunnel Hill State Trail",
  "Tuscarora Trail",
  "Uinta Highline Trail",
  "Uwharrie Trail",
  "Via di Francesco",
  "Virginia Capital Trail",
  "Virginia Creeper Trail",
  "Wabash Cannonball Trail",
  "Walker's Haute Route",
  "Warrior Trail",
  "Washington & Old Dominion Railroad Trail",
  "Waterloo-Pinckney Trail",
  "West Coast Trail (WCT)",
  "West Highland Way",
  "West Rim Trail",
  "Wicklow Way",
  "Willard Munger State Trail",
  "Wind River High Route",
  "Wonderland Trail",
];
