export const submitNewsletterEmail = ({ newsletterEmail }) => {
  fetch("/api/newsletter/subscribe", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ newsletterEmail }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
