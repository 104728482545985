import React from "react";

import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import WEIGHT_TYPES from "../constants/weightTypes.json";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const MobileHandleWeightChangeMenu = ({
  isCategoryBase,
  params,
  handleChangeWeightType,
  weightType,
  darkMode,
  handleDeleteRow,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const getIconColor = (isDisabled, isSelected) => {
    if (isDisabled && isSelected) return "#608da2";
    if (isDisabled) return "grey";
    if (!isDisabled && isSelected) return "#00a2e4";
    return darkMode ? "white" : "#AAA";
  };

  const handleClose = () => {
    setMenuIsOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setMenuIsOpen(true);
  };

  return (
    <>
      <IconButton fontSize="40px" onClick={handleClick}>
        <MoreHorizIcon
          sx={{
            color:
              weightType === WEIGHT_TYPES.WORN ||
              weightType === WEIGHT_TYPES.CONSUMABLE
                ? "#00a2e4"
                : "",
          }}
        />
      </IconButton>
      <Menu open={menuIsOpen} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          disabled={!isCategoryBase}
          onClick={() => {
            handleChangeWeightType(params.row.id, WEIGHT_TYPES.WORN);
          }}
        >
          <ListItemIcon>
            <CheckroomIcon
              sx={{
                color: getIconColor(
                  !isCategoryBase,
                  weightType === WEIGHT_TYPES.WORN
                ),
              }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{
              color: getIconColor(
                !isCategoryBase,
                weightType === WEIGHT_TYPES.WORN
              ),
            }}
          >
            {weightType === WEIGHT_TYPES.WORN ? "Unmark " : "Mark "} as Worn
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={!isCategoryBase}
          onClick={() => {
            handleChangeWeightType(params.row.id, WEIGHT_TYPES.CONSUMABLE);
          }}
        >
          <ListItemIcon>
            <RestaurantMenuIcon
              sx={{
                color: getIconColor(
                  !isCategoryBase,
                  weightType === WEIGHT_TYPES.CONSUMABLE
                ),
              }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{
              color: getIconColor(
                !isCategoryBase,
                weightType === WEIGHT_TYPES.CONSUMABLE
              ),
            }}
          >
            {weightType === WEIGHT_TYPES.CONSUMABLE ? "Unmark " : "Mark "} as
            Consumable
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteRow(params.row.id);
          }}
        >
          <ListItemIcon>
            <DeleteForeverIcon sx={{ color: "#f44030" }} />
          </ListItemIcon>
          <ListItemText sx={{ color: "#f44030" }}>Delete Item</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default MobileHandleWeightChangeMenu;
