import { getLocalUser } from "../utils";

export const getUserProfile = async ({ username }) => {
  return fetch(`/api/profile?username=${username}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUsersPublicPacks = async ({ username }) => {
  return fetch(`/api/profile/publicPacks?username=${username}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const validateUsername = async ({ username, email }) => {
  return fetch(
    `/api/profile/validateUsername?username=${username}&email=${email}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserPhoto = async ({ type, file }) => {
  const user = getLocalUser();
  const formData = new FormData();
  formData.append("file", file.data);
  return fetch(`/api/profile/updatePhoto?type=${type}`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserProfile = async ({ profileSettings }) => {
  const user = getLocalUser();
  return fetch(`/api/profile/updateProfile`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify(profileSettings),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const permanentlyUpdateUsername = async ({ username }) => {
  const user = getLocalUser();
  return fetch(`/api/profile/updateUsername?username=${username}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUsersPackAnalytics = async () => {
  const user = getLocalUser();
  return fetch(`/api/profile/accountAnalytics`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getLoggedInUserDetails = async () => {
  const user = getLocalUser();
  return fetch(`/api/profile/loggedInUserDetails`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
