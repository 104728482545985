import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { fetchDataForPackDisplay } from "../utils";
import { Box, Stack } from "@mui/system";
import { Link } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const PackDisplayPaperModule = ({ shareId, isDarkMode }) => {
  const [specs, setSpecs] = React.useState({
    title: "",
    weight: "",
    price: "",
    imageList: [],
    gearList: [],
  });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  React.useEffect(() => {
    (async () => {
      const packData = await fetchDataForPackDisplay(shareId);
      setSpecs(packData);
    })();
  }, [shareId]);
  return (
    <Paper
      elevation={5}
      sx={{
        width: isMobile ? "250px" : "320px",
        height: isMobile ? "260px" : "300px",
        borderRadius: "5px",
        position: "relative",
        top: "0",
        transition: "top ease 0.25s",
        "&:hover": {
          top: "-10px",
          cursor: "pointer",
          opacity: "0.8",
        },
      }}
    >
      <Link
        href={`/s/${shareId}`}
        sx={{
          textDecoration: "none",
          color: isDarkMode ? "white" : "",
        }}
      >
        <Paper
          sx={{
            margin: "10px 10px 10px 10px",
            backgroundColor: "white",
            width: isMobile ? "230px" : "300px",
            height: isMobile ? "160px" : "200px",
            borderRadius: "5px 5px 5px 5px",
          }}
          elevation={1}
        >
          <Stack direction="row">
            <Box
              sx={{
                width: isMobile ? "150px" : "190px",
                height: isMobile ? "150px" : "190px",
                position: "relative",
              }}
            >
              <img
                style={{
                  maxWidth: isMobile ? "130px" : "170px",
                  maxHeight: isMobile ? "130px" : "170px",
                  position: "absolute",
                  margin: "auto",
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                }}
                alt={specs.gearList[0] ? specs.gearList[0].title : ""}
                src={specs.imageList[0]}
              ></img>
            </Box>
            <Stack>
              <Box
                sx={{
                  width: isMobile ? "80px" : "100px",
                  height: isMobile ? "80px" : "100px",
                  position: "relative",
                }}
              >
                <img
                  style={{
                    maxWidth: isMobile ? "70px" : "90px",
                    maxHeight: isMobile ? "70px" : "90px",
                    position: "absolute",
                    margin: "auto",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                  }}
                  alt={specs.gearList[1] ? specs.gearList[1].title : ""}
                  src={specs.imageList[1]}
                ></img>
              </Box>
              <Box
                sx={{
                  width: isMobile ? "80px" : "100px",
                  height: isMobile ? "80px" : "100px",
                  position: "relative",
                }}
              >
                <img
                  style={{
                    maxWidth: isMobile ? "70px" : "90px",
                    maxHeight: isMobile ? "70px" : "90px",
                    position: "absolute",
                    margin: "auto",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                  }}
                  alt={specs.gearList[2] ? specs.gearList[2].title : ""}
                  src={specs.imageList[2]}
                ></img>
              </Box>
            </Stack>
          </Stack>
        </Paper>
        <Stack
          justifyContent={"flex-end"}
          sx={{
            padding: "5px 5px 5px 5px",
            height: "80px",
            borderRadius: "5px",
          }}
          spacing={1}
        >
          <Stack sx={{ height: "47px" }} justifyContent={"flex-end"}>
            <Typography
              variant="h7"
              sx={{
                fontWeight: "800",
              }}
            >
              {specs.title}
            </Typography>
          </Stack>
          <Table sx={{ opacity: "0.8" }}>
            <TableBody>
              <TableRow>
                <TableCell align="left" sx={{ padding: "0", border: "0px" }}>
                  <Typography>{`${specs.weight}`}</Typography>
                </TableCell>
                <TableCell align="right" sx={{ padding: "0", border: "0px" }}>
                  <Typography>{`${Number(specs.price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Link>
    </Paper>
  );
};

export default PackDisplayPaperModule;
