import React from "react";

import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import REIBuyNowButton from "./REIBuyNowButton";
import GGGBuyNowButton from "./GGGBuyNowButton";
import SettingsIcon from "@mui/icons-material/Settings";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import SuggestionSettings from "./SuggestionSettings";
import { externalLinkWrapper } from "../utils/links";
import PackWizardCompareLinkButton from "./PackWizardCompareLinkButton";
import { getColorWithMode } from "../constants/colors";
import { useMediaQuery } from "react-responsive";

const SuggestedItemCarousel = ({
  suggestedItems,
  weightsInGrams,
  setTimeOfLastSettingsUpdate,
  suggestionSettingsOpen,
  setSuggestionSettingsOpen,
  isDesktopOrLaptop,
  darkMode,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [itemIndex, setItemIndex] = React.useState(0);
  const { suggestedItem } = suggestedItems[itemIndex];
  const packLighterByPercentage =
    (weightsInGrams.baseInGrams - suggestedItems[itemIndex].weightSavings) /
    weightsInGrams.baseInGrams;

  const getBuyButton = () => {
    const widthBasedOnScreen = isDesktopOrLaptop
      ? "220px"
      : isMobile
      ? "355px"
      : "175px";
    if (suggestedItem.url && suggestedItem.purchaseWebsite) {
      switch (suggestedItem.purchaseWebsite) {
        case "REI":
          return (
            <REIBuyNowButton
              link={suggestedItem.url}
              width={widthBasedOnScreen}
            />
          );
        case "GGG":
          return (
            <GGGBuyNowButton
              link={suggestedItem.url}
              width={widthBasedOnScreen}
            />
          );
        case "PACKWIZARD_SP":
          return (
            <PackWizardCompareLinkButton
              id={suggestedItem.productItemNumber}
              width={widthBasedOnScreen}
              compareType="PACKWIZARD_SP"
            />
          );
        case "PACKWIZARD_TENT":
          return (
            <PackWizardCompareLinkButton
              id={suggestedItem.productItemNumber}
              width={widthBasedOnScreen}
              compareType="PACKWIZARD_TENT"
            />
          );
        case "PACKWIZARD_PACK":
          return (
            <PackWizardCompareLinkButton
              id={suggestedItem.productItemNumber}
              width={widthBasedOnScreen}
              compareType="PACKWIZARD_PACK"
            />
          );
        case "PACKWIZARD_SB":
          return (
            <PackWizardCompareLinkButton
              id={suggestedItem.productItemNumber}
              width={widthBasedOnScreen}
              compareType="PACKWIZARD_SB"
            />
          );
        case "PACKWIZARD_IJ":
          return (
            <PackWizardCompareLinkButton
              id={suggestedItem.productItemNumber}
              width={widthBasedOnScreen}
              compareType="PACKWIZARD_IJ"
            />
          );
        default:
          return <></>;
      }
    }
  };

  return (
    <Stack>
      <Collapse in={suggestionSettingsOpen} orientation="horizontal">
        <Paper
          elevation={5}
          sx={{
            zIndex: "2",
            width: "247px",
            marginLeft: "2px",
            height: "220px",
            position: "absolute",
          }}
        >
          <IconButton
            sx={{ right: "0px", top: "0px", position: "absolute" }}
            onClick={() => {
              setSuggestionSettingsOpen(false);
              setTimeOfLastSettingsUpdate(Date.now());
            }}
          >
            <CloseIcon />
          </IconButton>
          <SuggestionSettings
            setTimeOfLastSettingsUpdate={setTimeOfLastSettingsUpdate}
          />
        </Paper>
      </Collapse>
      <Stack direction="row">
        <Tooltip
          title={
            <>
              <p
                style={{
                  paddingBottom: "-5px",
                }}
              >
                Suggestions are based on the item types of the gear in your pack
                and your gears weight.
              </p>
              <p
                style={{
                  fontStyle: "italic",
                  textDecoration: "underline",
                  paddingTop: "0px",
                  paddingBottom: "-5px",
                  color: "#BBB",
                }}
              >
                Note
              </p>
              <p style={{ marginTop: "-10px" }}>
                For the best suggestions, try to use the provided item types in
                the dropdown as opposed to entering your own, when possible.
              </p>
              <p>
                You should always do your due diligence on suggested items. The
                item suggested to you might be a variant that is not your size
                or does not serve your needs appropriately.
              </p>
            </>
          }
        >
          <IconButton
            sx={{
              height: isMobile ? "60px" : "40px",
              width: isMobile ? "60px" : "40px",
            }}
          >
            <HelpOutlineIcon
              sx={{
                height: isMobile ? "40px" : "20px",
                width: isMobile ? "40px" : "20px",
              }}
            />
          </IconButton>
        </Tooltip>
        <Typography
          sx={{
            width: "100%",
            color: getColorWithMode(darkMode, "text"),
            textAlign: "center",
            marginTop: "15px",
            paddingBottom: "5px",
            fontSize: isMobile ? "24px" : "14px",
          }}
        >
          Suggested Pack Upgrades
        </Typography>
        <IconButton
          sx={{
            height: isMobile ? "60px" : "40px",
            width: isMobile ? "60px" : "40px",
          }}
          onClick={() => {
            setSuggestionSettingsOpen(!suggestionSettingsOpen);
          }}
        >
          <SettingsIcon
            sx={{
              height: isMobile ? "40px" : "20px",
              width: isMobile ? "40px" : "20px",
            }}
          />
        </IconButton>
      </Stack>
      <Stack spacing={0} direction="row" sx={{ width: "100%" }}>
        <Container sx={{ padding: "0px 0px 0px 0px" }}>
          <IconButton
            sx={{
              width: isMobile ? "80px" : "35px",
              height: isMobile ? "300px" : "231px",
              borderRadius: "0px",
            }}
            aria-label="previous"
            disabled={itemIndex === 0}
            onClick={() => setItemIndex(itemIndex - 1)}
          >
            <ChevronLeftIcon fontSize={isMobile ? "large" : ""} />
          </IconButton>
        </Container>
        <Stack
          sx={{
            minWidth: isMobile ? "525px" : "294px",
            height: isMobile ? "400px" : "231px",
          }}
        >
          <Stack direction="row">
            <Paper
              elevation={2}
              sx={{
                minWidth: isMobile ? "180px" : "130px",
                maxWidth: isMobile ? "180px" : "130px",
                height: isMobile ? "180px" : "130px",
                backgroundColor: "white",
              }}
            >
              {suggestedItem.imageUrl ? (
                <img
                  className="suggested-item-image"
                  alt={suggestedItem.item}
                  style={{
                    height: isMobile ? "178px" : "128px",
                    width: isMobile ? "178px" : "128px",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                  onClick={() => {
                    if (suggestedItem.url) {
                      externalLinkWrapper(suggestedItem.url);
                    }
                  }}
                  src={suggestedItem.imageUrl}
                />
              ) : (
                <Box
                  style={{
                    height: isMobile ? "180px" : "130px",
                    width: isMobile ? "180px" : "130px",
                    backgroundColor: "#333",
                    textAlign: "center",
                    lineHeight: isMobile ? "180px" : "130px",
                  }}
                >
                  No Image Available
                </Box>
              )}
            </Paper>
            <Stack
              sx={{
                width: isMobile ? "400px" : "180px",
                marginTop: "5px",
                paddingLeft: "5px",
              }}
            >
              <Stack direction="row" sx={{ justifyContent: "center" }}>
                <Tooltip title={`${suggestedItem.brand} ${suggestedItem.item}`}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      width: isMobile ? "270px" : "170px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontSize: isMobile ? "20px" : "14px",
                    }}
                  >
                    {suggestedItem.item}
                  </Typography>
                </Tooltip>
                <Typography
                  sx={{
                    color: "#23C552",
                    marginLeft: "3px",
                    fontSize: isMobile ? "20px" : "14px",
                  }}
                >
                  {`(${Number(suggestedItems[itemIndex].newWeight).toFixed(
                    0
                  )}g)`}
                </Typography>
              </Stack>
              <Paper
                elevation={3}
                sx={{
                  marginTop: "5px",
                  width: isMobile ? "355px" : "100%",
                  height: isMobile ? "75px" : "100%",
                }}
              >
                <Tooltip
                  title={`This upgrade would reduce your pack weight by ${Number(
                    suggestedItems[itemIndex].weightSavings
                  ).toFixed(0)} grams. Yielding a ${Number(
                    (1 - packLighterByPercentage) * 100
                  ).toFixed(1)}% lower base weight.`}
                >
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: isMobile ? "20px" : "14px",
                        textDecoration: "underline",
                      }}
                    >
                      Weight Reduction
                    </Typography>
                    <Typography
                      sx={{
                        color: "#23C552",
                        textAlign: "center",
                        lineHeight: "30px",
                        marginLeft: "4px",
                        fontSize: isMobile ? "20px" : "14px",
                      }}
                    >{`${Number(
                      suggestedItems[itemIndex].weightSavings
                    ).toFixed(0)}g (${Number(
                      (1 - packLighterByPercentage) * 100
                    ).toFixed(1)}%)`}</Typography>
                  </Stack>
                </Tooltip>
              </Paper>
              {getBuyButton()}
            </Stack>
          </Stack>
          <Container sx={{ width: "100%", paddingTop: "5px" }}>
            <Divider style={{ fontSize: isMobile ? "20px" : "14px" }}>
              Specs
            </Divider>
            <Container sx={{ height: "70px", padding: "0px" }}>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: "0px",
                  boxShadow: "inset 0 -10px 7px rgba(99, 99, 99, 0.2)",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: isMobile ? "120px" : "70px",
                    overflowX: "hidden",
                  }}
                >
                  <Table
                    aria-label="simple table"
                    size={isMobile ? "large" : "small"}
                  >
                    <TableBody>
                      <TableRow
                        key={"price"}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          width: "190px",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: isMobile ? "18px" : "12px",
                            maxWidth: isMobile ? "180px" : "95px",
                          }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: isMobile ? "18px" : "12px",
                            maxWidth: isMobile ? "180px" : "95px",
                          }}
                          align="right"
                        >{`$${suggestedItem.price}`}</TableCell>
                      </TableRow>
                      <TableRow
                        key={"itemType"}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          width: "190px",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: isMobile ? "18px" : "12px",
                            maxWidth: isMobile ? "180px" : "95px",
                          }}
                        >
                          Item Type
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: isMobile ? "18px" : "12px",
                            maxWidth: isMobile ? "180px" : "95px",
                          }}
                          align="right"
                        >
                          {suggestedItem.category}
                        </TableCell>
                      </TableRow>
                      {suggestedItem.techSpecs.map((row) => (
                        <TableRow
                          key={row.key}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: isMobile ? "18px" : "12px",
                              maxWidth: isMobile ? "180px" : "95px",
                            }}
                          >
                            {row.key}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: isMobile ? "18px" : "12px",
                              maxWidth: isMobile ? "180px" : "95px",
                            }}
                          >
                            {row.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Container>
          </Container>
        </Stack>
        <Container>
          <IconButton
            sx={{
              width: isMobile ? "80px" : "35px",
              height: isMobile ? "300px" : "231px",
              borderRadius: "0px",
              padding: "0px 0px 0px 0px",
            }}
            aria-label="next"
            disabled={itemIndex === suggestedItems.length - 1}
            onClick={() => setItemIndex(itemIndex + 1)}
          >
            <ChevronRightIcon fontSize={isMobile ? "large" : ""} />
          </IconButton>
        </Container>
      </Stack>
    </Stack>
  );
};

export default SuggestedItemCarousel;
