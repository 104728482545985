import React from "react";

import { Container, Stack } from "@mui/system";
import { getColorWithMode } from "../../constants/colors";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import SleepingBagTable from "./SleepingBagTable";
import { fetchSleepingBags } from "../../api/gear";
import SleepingBagFilterBar from "./SleepingBagFilterBar";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useCallback } from "react";
import debounce from "lodash.debounce";

const fetchFilterValues = (rows) => {
  const brands = new Set();
  const types = new Set();

  let lowestWeight = Number.MAX_SAFE_INTEGER;
  let lowestPrice = Number.MAX_SAFE_INTEGER;
  let shortestFitsTo = Number.MAX_SAFE_INTEGER;
  let longestFitsTo = 0;
  let lowestComfortRating = Number.MAX_SAFE_INTEGER;
  let highestComfortRating = -100;
  let lowestLimitRating = Number.MAX_SAFE_INTEGER;
  let highestLimitRating = -100;

  rows.forEach((row) => {
    brands.add(row.brand);
    types.add(row.type.toLowerCase());
    if (row.weight < lowestWeight) lowestWeight = row.weight;
    if (row.price < lowestPrice) lowestPrice = row.price;
    if (row.fitsTo && row.fitsTo < shortestFitsTo) shortestFitsTo = row.fitsTo;
    if (row.fitsTo && row.fitsTo > longestFitsTo) longestFitsTo = row.fitsTo;
    if (!isNaN(row.comfortRating)) {
      if (Number(row.comfortRating) < lowestComfortRating)
        lowestComfortRating = Number(row.comfortRating);
      if (Number(row.comfortRating) > highestComfortRating)
        highestComfortRating = Number(row.comfortRating);
    }
    if (!isNaN(row.limitRating)) {
      if (Number(row.limitRating) < lowestLimitRating)
        lowestLimitRating = Number(row.limitRating);
      if (Number(row.limitRating) > highestLimitRating)
        highestLimitRating = Number(row.limitRating);
    }
  });
  return {
    brands: [...brands].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    ),
    types: [...types].sort(),
    weights: {
      min: Math.floor(lowestWeight),
      max: 2000,
    },
    prices: {
      min: Math.floor(lowestPrice),
      max: 1000,
    },
    fitsTo: {
      min: shortestFitsTo,
      max: longestFitsTo,
    },
    comfortRating: {
      min: lowestComfortRating,
      max: highestComfortRating,
    },
    limitRating: {
      min: lowestLimitRating,
      max: highestLimitRating,
    },
    group: ["sleeping-bags", "quilts"],
    width: ["slim", "regular", "wide"],
    insulationTypes: ["down", "synthetic"],
    genders: ["men", "women", "unisex"],
  };
};

const SleepingBagContainer = ({
  darkMode,
  setAddToGCSnackbarIsOpen,
  setReportSnackBarIsOpen,
  handleAddToPackMenu,
  gearText,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const [sleepingBags, setSleepingBags] = React.useState([]);
  const [baseFilters, setBaseFilters] = React.useState(null);
  const [userFilters, setUserFilters] = React.useState({});
  const [filteredSleepingBags, setFilteredSleepingBags] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [useMetric, setUseMetric] = React.useState(true);
  const [useCelsius, setUseCelsius] = React.useState(false);
  const [useMetricDimensions, setUseMetricDimensions] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filtersAreOpen, setFiltersAreOpen] = React.useState(isDesktopOrLaptop);

  const handleSetSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleSetSearchText, 300),
    []
  );

  const handleSetSelectedItems = (items) => {
    setSelectedItems(items);
  };

  React.useEffect(() => {
    (async () => {
      const { data } = await fetchSleepingBags();
      const newFilters = fetchFilterValues(data);
      setBaseFilters(newFilters);
      setUserFilters({
        brand: newFilters.brands,
        type: newFilters.types,
        group: ["sleeping-bags", "quilts"],
        insulationType: ["down", "synthetic"],
        gender: ["men", "women", "unisex"],
        width: ["slim", "regular", "wide"],
      });
      setSleepingBags(data);
      setFilteredSleepingBags(data);
    })();
  }, []);

  React.useEffect(() => {
    const filteredDownBags = sleepingBags.filter((sleepingBag) => {
      let priceToUse =
        sleepingBag.lowestPrice && sleepingBag.lowestPrice !== 0
          ? sleepingBag.lowestPrice
          : sleepingBag.price;
      priceToUse = priceToUse && priceToUse > 0 ? priceToUse : null;
      return (
        `${sleepingBag.formattedBrand.toLowerCase()} ${sleepingBag.name.toLowerCase()}`.includes(
          searchText.toLowerCase()
        ) &&
        ((userFilters.group.includes("quilts") &&
          sleepingBag.type.toLowerCase().includes("quilt")) ||
          (userFilters.group.includes("sleeping-bags") &&
            !sleepingBag.type.toLowerCase().includes("quilt"))) &&
        ((userFilters.insulationType.includes("down") &&
          sleepingBag.insulationType.toLowerCase().includes("down")) ||
          (userFilters.insulationType.includes("synthetic") &&
            !sleepingBag.insulationType.toLowerCase().includes("down"))) &&
        (!userFilters.price ||
          (priceToUse >= userFilters.price[0] &&
            priceToUse <= userFilters.price[1]) ||
          userFilters.price[1] === 1000) &&
        (!userFilters.price || priceToUse) &&
        (!userFilters.weight ||
          !sleepingBag.weight ||
          (sleepingBag.weight >= userFilters.weight[0] &&
            (sleepingBag.weight <= userFilters.weight[1] ||
              userFilters.weight[1] === 2000))) &&
        userFilters.brand.includes(sleepingBag.brand) &&
        userFilters.width &&
        ((sleepingBag.variant.toLowerCase().includes("slim") &&
          userFilters.width.includes("slim")) ||
          ((sleepingBag.variant.toLowerCase().includes("wide") ||
            sleepingBag.variant.toLowerCase().includes("broad")) &&
            userFilters.width.includes("wide")) ||
          (!sleepingBag.variant.toLowerCase().includes("slim") &&
            !sleepingBag.variant.toLowerCase().includes("wide") &&
            !sleepingBag.variant.toLowerCase().includes("broad") &&
            userFilters.width.includes("regular"))) &&
        userFilters.type.includes(sleepingBag.type.toLowerCase()) &&
        (!userFilters.fitsTo ||
          !sleepingBag.fitsTo ||
          (sleepingBag.fitsTo >= userFilters.fitsTo[0] &&
            sleepingBag.fitsTo <= userFilters.fitsTo[1])) &&
        (!userFilters.widths ||
          (sleepingBag.minimumWidth >= userFilters.widths[0] &&
            sleepingBag.minimumWidth <= userFilters.widths[1])) &&
        (!userFilters.comfortRating ||
          (Number(sleepingBag.comfortRating) >= userFilters.comfortRating[0] &&
            Number(sleepingBag.comfortRating) <=
              userFilters.comfortRating[1])) &&
        (!userFilters.limitRating ||
          (Number(sleepingBag.limitRating) >= userFilters.limitRating[0] &&
            Number(sleepingBag.limitRating) <= userFilters.limitRating[1])) &&
        ((userFilters.gender.includes("men") &&
          sleepingBag.name.toLowerCase().includes("men") &&
          !sleepingBag.name.toLowerCase().includes("women")) ||
          (userFilters.gender.includes("women") &&
            sleepingBag.name.toLowerCase().includes("women")) ||
          (userFilters.gender.includes("unisex") &&
            !sleepingBag.name.toLowerCase().includes("men")))
      );
    });
    setFilteredSleepingBags(filteredDownBags);
  }, [userFilters, sleepingBags, searchText]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>Sleeping Bags & Quilts</title>
        <meta
          name="keywords"
          content="sleeping bag and quilt comparison tool, sleeping bag and quilt, comparison, sleeping bag specs, quilt specs, cheapest backpacking sleeping bags and quilts, sleeping bag price tracker, quilt price tracker"
        />
        <link
          rel="canonical"
          href="https://www.packwizard.com/gear/sleeping-bags"
        />

        <meta
          name="description"
          content="Compare sleeping bag specs to find your ideal sleeping bag, complete with live price tracking to make sure you are getting the best deal out there."
        />
      </Helmet>
      <Container
        sx={{
          width: "100%",
          backgroundColor: getColorWithMode(darkMode, "title"),
        }}
      >
        <Stack
          textAlign="center"
          alignItems="center"
          sx={{ padding: "20px 10px 20px 10px" }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              fontFamily: "Helvetica",
              color: "white",
            }}
          >
            Sleeping Bags & Quilts
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Helvetica",
              color: "white",
              maxWidth: "820px",
            }}
          >
            Discover sleeping bags and quilts that fit all of your needs. Sort
            and filter by all of the most important features of sleeping bags
            and quilts and use our live price tracker to find the best deals on
            your next sleeping bag or quilt
          </Typography>
        </Stack>
      </Container>
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Stack
          sx={isMobile ? {} : { width: filtersAreOpen ? "240px" : "10px" }}
        >
          <Button
            sx={
              isMobile
                ? { width: "260px", fontSize: "18px" }
                : { width: "240px" }
            }
            onClick={() => {
              setFiltersAreOpen(!filtersAreOpen);
            }}
          >{`${
            filtersAreOpen ? "Hide Filters/Settings" : "Show Filters/Settings"
          }`}</Button>
          <Collapse
            in={filtersAreOpen}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            <Container
              sx={{
                width: isMobile ? "100%" : "240px",
                height: "100%",
                padding: "5px 5px 5px 5px",
                marginTop: "20px",
              }}
            >
              <SleepingBagFilterBar
                darkMode={darkMode}
                baseFilters={baseFilters}
                userFilters={userFilters}
                setUserFilters={setUserFilters}
                useMetric={useMetric}
                setUseMetric={setUseMetric}
                useMetricDimensions={useMetricDimensions}
                setUseMetricDimensions={setUseMetricDimensions}
                useCelsius={useCelsius}
                setUseCelsius={setUseCelsius}
              />
            </Container>
          </Collapse>
        </Stack>
        <Container
          sx={{
            marginTop: filtersAreOpen ? "0px" : "20px",
          }}
        >
          <Stack>
            <Stack
              sx={{ height: isMobile ? "70px" : "50px" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant={isMobile ? "h6" : "body1"}
                sx={{
                  paddingLeft: "5px",
                  fontFamily: "Helvetica",
                }}
              >
                {`${filteredSleepingBags.length} Sleeping Bags & Quilts`}
              </Typography>
              <TextField
                size={isMobile ? "large" : "small"}
                sx={{
                  width: "300px",
                }}
                placeholder="Search"
                onChange={debouncedChangeHandler}
              ></TextField>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                height: "40px",
                maxWidth: "100%",
                whiteSpace: "nowrap",
              }}
            >
              <Button
                sx={{ width: "80px" }}
                disabled={selectedItems.length <= 1 || selectedItems.length > 3}
                onClick={() => {
                  window.location.assign(
                    `/gear/sleeping-bags/compare?products=${selectedItems.join(
                      ","
                    )}`
                  );
                }}
              >
                Compare
              </Button>
              {selectedItems.length ? (
                <Divider
                  sx={{
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    height: "20px",
                  }}
                  orientation="vertical"
                />
              ) : (
                <></>
              )}
              <Box
                sx={{
                  maxWidth: "100%",
                  textOverflow: "nowrap",
                  overflow: "hidden",
                  overflowX: "scroll",
                }}
              >
                {selectedItems.map((itemId) => {
                  const itemToRender = sleepingBags.find(
                    ({ id }) => itemId === id
                  );
                  return (
                    <Chip
                      sx={{ marginLeft: "5px" }}
                      variant="outlined"
                      label={`${itemToRender.formattedBrand} ${
                        itemToRender.name
                      } ${
                        itemToRender.variant ? `(${itemToRender.variant})` : ""
                      }`}
                      onDelete={() => {
                        handleSetSelectedItems(
                          selectedItems.filter(
                            (itemId) => itemId !== itemToRender.id
                          )
                        );
                      }}
                    />
                  );
                })}
              </Box>
            </Stack>
            <SleepingBagTable
              sleepingBags={filteredSleepingBags}
              handleSetSelectedItems={handleSetSelectedItems}
              selectedItems={selectedItems}
              useMetric={useMetric}
              useMetricDimensions={useMetricDimensions}
              useCelsius={useCelsius}
              setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
              setReportSnackBarIsOpen={setReportSnackBarIsOpen}
              handleAddToPackMenu={handleAddToPackMenu}
              gearText={gearText}
              darkMode={darkMode}
            />
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

export default SleepingBagContainer;
