import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Box,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Tooltip,
} from "@mui/material";
import BrandBuyButton from "./BrandBuyButton";
import { externalLinkWrapper, getAffiliateKey } from "../../utils/links";
import momenttz from "moment-timezone";

const getUrl = (url) => {
  const rawUrl = new URL(url);
  return rawUrl.hostname.split(/\./).slice(-2).join(".");
};

const getMostRecentPriceUpdate = (retailers) => {
  if (!retailers || !retailers.length) return null;
  let mostRecentDate = 0;
  retailers.forEach(({ lastUpdated }) => {
    if (new Date(lastUpdated) > new Date(mostRecentDate)) {
      mostRecentDate = lastUpdated;
    }
  });
  return mostRecentDate;
};

const ItemRetailerModule = ({
  item,
  darkMode,
  gearText,
  dynamicWidth,
  packwizardUrl,
}) => {
  const mostRecentDate = getMostRecentPriceUpdate(item.retailers);
  if (!item) return <></>;
  return (
    <Paper sx={{ width: dynamicWidth }}>
      {!item.retailers || item.retailers.length === 0 ? (
        <Container
          sx={{
            width: dynamicWidth,
            padding: "0px 0px 0px 0px",
          }}
        >
          {getAffiliateKey(item.url) ? (
            <>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          fontSize: "16px",
                        }}
                      >
                        Retailer
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          fontSize: "16px",
                        }}
                      >
                        Price
                      </TableCell>
                      <TableCell
                        sx={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          fontSize: "16px",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        <BrandBuyButton
                          retailer={getAffiliateKey(item.url)}
                          url={item.url}
                          darkMode={darkMode}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "16px",
                        }}
                      >
                        <Tooltip title="This price may be out of date as we do not capture live pricing data for this item for this retailer">
                          <Typography>
                            {Number(item.price) ? (
                              <Link
                                onClick={() => {
                                  externalLinkWrapper(item.url);
                                }}
                                sx={{
                                  textDecoration: "none",
                                  color: "orange",
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {Number(item.price).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="large"
                          color="success"
                          onClick={() => {
                            externalLinkWrapper(item.url);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Paper
              sx={{
                textAlign: "center",
                height: "300px",
              }}
              component={Stack}
              direction="column"
              justifyContent="center"
            >
              <Stack
                alignItems="center"
                sx={{ marginTop: "-20px" }}
                spacing={2}
              >
                <Typography
                  sx={{ fontSize: "20px" }}
                >{`We don't yet offer price tracking for this ${
                  gearText ? gearText : "item"
                }`}</Typography>
                <Button
                  variant="contained"
                  sx={{ width: dynamicWidth }}
                  onClick={() => {
                    externalLinkWrapper(item.url);
                  }}
                >
                  View at {getUrl(item.url)}
                </Button>
              </Stack>
            </Paper>
          )}
        </Container>
      ) : (
        <Container
          sx={{
            width: dynamicWidth,
            padding: "0px 0px 0px 0px",
          }}
        >
          <Stack spacing={0}>
            <Stack spacing={0} alignItems="flex-end">
              {item.retailers.length > 0 ? (
                <TableContainer
                  sx={{
                    marginTop: "8px",
                    fontSize: "16px",
                  }}
                  component={Paper}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            fontSize: "16px",
                          }}
                        >
                          Retailer
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            fontSize: "16px",
                          }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            fontSize: "16px",
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!getAffiliateKey(item.url) ||
                      item.retailers.find(
                        ({ retailer }) =>
                          retailer.toLowerCase() === getAffiliateKey(item.url)
                      ) ? (
                        <></>
                      ) : (
                        <TableRow>
                          <TableCell align="center">
                            <BrandBuyButton
                              retailer={getAffiliateKey(item.url)}
                              url={item.url}
                              darkMode={darkMode}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: "16px",
                              color: "orange",
                            }}
                          >
                            <Tooltip title="This price may be out of date as we do not capture live pricing data for this item for this retailer">
                              <Typography>
                                {Number(item.price) ? (
                                  <Link
                                    onClick={() => {
                                      externalLinkWrapper(item.url);
                                    }}
                                    sx={{
                                      textDecoration: "none",
                                      color: "orange",
                                      "&:hover": {
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    {Number(item.price).toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              size="large"
                              color="success"
                              onClick={() => {
                                externalLinkWrapper(item.url);
                              }}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                      {item.retailers.map(
                        ({ retailer, buyLink, salePrice }) => (
                          <TableRow key={`${retailer}-${buyLink}`}>
                            <TableCell align="center">
                              <BrandBuyButton
                                retailer={retailer}
                                url={buyLink}
                                darkMode={darkMode}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                fontSize: "16px",
                              }}
                            >
                              {Number(salePrice) ? (
                                <Link
                                  onClick={() => {
                                    externalLinkWrapper(buyLink);
                                  }}
                                  sx={{
                                    textDecoration: "none",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                >
                                  {Number(salePrice).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </Link>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                size="large"
                                color="success"
                                onClick={() => {
                                  externalLinkWrapper(buyLink);
                                }}
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography textAlign="center" sx={{ marginTop: "10px" }}>
                  No Retailers Found
                </Typography>
              )}
              {mostRecentDate ? (
                <Box paddingRight="5px">
                  <Typography variant="subtitle2" color="grey">
                    {`Last updated: ${momenttz(mostRecentDate)
                      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                      .format("ddd MMM DD YYYY @ hh:mm a zz")}`}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
              <Link padding="5px" underline="none" href={packwizardUrl}>
                View Price History
              </Link>
            </Stack>
          </Stack>
        </Container>
      )}
    </Paper>
  );
};

export default ItemRetailerModule;
