import { getLocalUser } from "../utils";

export const createReview = ({ reviewData }) => {
  const user = getLocalUser();
  const formData = new FormData();
  if (reviewData.images.length) {
    [...reviewData.images].forEach((file, i) => {
      formData.append(`file-${i}`, file, file.name);
    });
  }
  const newReviewData = { ...reviewData };
  newReviewData.images = [];
  formData.append("data", JSON.stringify(newReviewData));

  return fetch(`/api/review`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const editReview = ({ reviewData }) => {
  const user = getLocalUser();
  const formData = new FormData();
  let existingImages = [];
  if (reviewData.images.length) {
    [...reviewData.images].forEach((file, i) => {
      if (typeof file !== "string") {
        formData.append(`file-${i}`, file, file.name);
      } else {
        existingImages.push(file);
      }
    });
  }
  const newReviewData = { ...reviewData };
  newReviewData.images = existingImages;
  formData.append("data", JSON.stringify(newReviewData));

  return fetch(`/api/review`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getReviews = ({ gearType, itemId }) => {
  const user = getLocalUser();

  return fetch(`/api/review?gearType=${gearType}&itemId=${itemId}`, {
    headers: {
      Authorization: user ? `Bearer ${user.token}` : null,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const markReviewAsHelpful = ({ reviewId }) => {
  const user = getLocalUser();

  return fetch(`/api/review/markAsHelpful?reviewId=${reviewId}`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const reportReview = ({ reviewId }) => {
  const user = getLocalUser();

  return fetch(`/api/review/report?reviewId=${reviewId}`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
