import React from "react";

import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import { externalLinkWrapper } from "../utils/links";
import { useMediaQuery } from "react-responsive";

const REIBuyNowButton = ({ link, width, isDesktopOrLaptop }) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const REIIcon = (
    <Icon
      sx={{
        textAlign: "center",
        width: "54px",
        height: "36px",
        marginTop: "-5px",
        marginRight: "-5px",
        color: "white",
      }}
    >
      <img style={{ height: "100%" }} alt="REI" src="/REI-white.svg" />
    </Icon>
  );

  return (
    <Button
      variant="filled"
      sx={{
        backgroundColor: "#1f513f",
        width,
        color: "white",
        textTransform: "none",
        padding: "0px",
        height: isMobile ? "55px" : isDesktopOrLaptop ? "40px" : "35px",
        marginTop: "10px",
        paddingTop: "5px",
        fontFamily: "Roboto, sans-serif",
        fontSize: isMobile ? "20px" : "",
        "&:hover": {
          opacity: 0.8,
          backgroundColor: "#1f513f",
        },
      }}
      endIcon={REIIcon}
      onClick={() => {
        externalLinkWrapper(link);
      }}
    >
      View on
    </Button>
  );
};
export default REIBuyNowButton;
