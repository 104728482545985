import { getLocalUser } from "../utils";

export const submitContactUsMessage = async ({ contactForm }) => {
  const user = getLocalUser();
  return fetch(`/api/contact`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ contactForm }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const submitContactUsMessageLoggedOut = async ({ contactForm }) => {
  return fetch(`/api/contact`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: null,
    },
    method: "POST",
    body: JSON.stringify({ contactForm }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
