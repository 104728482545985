import React from "react";
import { Stack, Typography, Container, Divider } from "@mui/material";

import LeaveAReviewContainer from "./LeaveAReviewContainer";
import { useMediaQuery } from "react-responsive";
import { fetchReviews, getUserIsLoggedIn } from "../../utils";
import ProductReviewCard from "./ProductReviewCard";

const ReviewSystemContainer = ({ gearType, productId }) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });

  const [reviews, setReviews] = React.useState([]);
  const [userReview, setUserReview] = React.useState({});
  const [userHasAlreadyReviewed, setUserHasAlreadyReviewed] =
    React.useState(false);

  React.useEffect(() => {
    (async () => {
      const { reviews, usersReview, userHasReviewed } = await fetchReviews(
        gearType,
        productId
      );
      setReviews(reviews);
      setUserReview(usersReview);
      setUserHasAlreadyReviewed(userHasReviewed);
    })();
  }, []);

  const refetchReviews = async () => {
    const { reviews, usersReview, userHasReviewed } = await fetchReviews(
      gearType,
      productId
    );
    setReviews(reviews);
    setUserReview(usersReview);
    setUserHasAlreadyReviewed(userHasReviewed);
  };

  return (
    <Stack spacing={2} id="review-system-container">
      <Container sx={{ paddingTop: "10px" }}>
        <Typography textAlign="left" variant={isDesktopOrLaptop ? "h6" : "h5"}>
          Reviews
        </Typography>
        <Divider />
      </Container>
      <Container>
        {getUserIsLoggedIn() ? (
          <LeaveAReviewContainer
            gearType={gearType}
            itemId={productId}
            userHasReviewed={userHasAlreadyReviewed}
            userReview={userReview}
            refetchReviews={refetchReviews}
          />
        ) : (
          <></>
        )}
      </Container>
      <Container>
        {reviews.length ? (
          <Stack spacing={2}>
            {reviews.map((review) => (
              <ProductReviewCard
                reviewValues={review}
                gearType={gearType}
                authorDetails={{}}
                isUsersReview={review._id === userReview._id}
              />
            ))}
          </Stack>
        ) : (
          <Stack justifyContent="center" sx={{ height: "200px" }}>
            <Typography variant="body1" textAlign="center">
              No Reviews Yet
            </Typography>
          </Stack>
        )}
      </Container>
    </Stack>
  );
};

export default ReviewSystemContainer;
