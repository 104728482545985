import {
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { deleteDuplicatesFromGearCloset } from "../utils";

const GearClosetDuplicateDeleteDialog = ({
  isOpen,
  setIsOpen,
  gearRows,
  handleRefetchGearCloset,
}) => {
  const [duplicateIds, setDuplicateIds] = React.useState([]);

  React.useEffect(() => {
    const seenItems = new Set();
    const newDuplicateIds = [];
    gearRows.forEach((row) => {
      const {
        id,
        brand,
        category,
        description,
        url,
        imageUrl,
        price,
        item,
        weight,
        unit,
      } = row;
      const uniqueItem = [
        brand,
        category,
        description,
        url,
        imageUrl,
        price,
        item,
        weight,
        unit,
      ].join("_");
      if (seenItems.has(uniqueItem)) {
        newDuplicateIds.push(id);
      } else {
        seenItems.add(uniqueItem);
      }
    });
    setDuplicateIds(newDuplicateIds);
  }, [gearRows]);

  const columns = [
    {
      field: "item",
      headerName: "Item",
      width: 230,
      headerAlign: "left",
      headerClassName: "firstcolumnSeparator",
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 118,
      headerAlign: "left",
      headerClassName: "firstcolumnSeparator",
    },
    {
      field: "category",
      headerName: "Item Type",
      width: 95,
      headerAlign: "left",
    },
    {
      field: "weight",
      headerName: "Weight",
      type: "number",
      width: 80,
      headerAlign: "right",
      headerClassName: "lastcolumnSeparator",
      sortable: false,
    },
    {
      field: "unit",
      headerName: "",
      type: "singleSelect",
      width: 69,
      height: "36px",
      valueOptions: ["g", "oz", "lb", "kg"],
      headerAlign: "left",
      sortable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 92,
      type: "number",
      headerAlign: "right",
      headerClassName: "lastcolumnSeparator",
      renderCell: (params) => {
        return Number(params.value).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      },
    },
  ];

  const handleClose = () => setIsOpen(false);

  const duplicateRows = gearRows.filter(({ id }) => duplicateIds.includes(id));

  const handleDeleteDuplicates = async () => {
    await deleteDuplicatesFromGearCloset(duplicateIds);
    handleRefetchGearCloset();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Paper sx={{ padding: "20px 20px 20px 20px" }}>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              The following duplicates were found
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box sx={{ maxHeight: "0px", overflow: "auto" }}>
            <DataGridPro
              sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                  {
                    display: "none",
                  },
                borderRadius: 0,
                marginTop: "5px",
                width: "700px",
              }}
              rows={duplicateRows}
              autoHeight
              height={"300px"}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              experimentalFeatures={{ newEditingApi: true, rowPinning: true }}
              getRowHeight={({ id, densityFactor }) => {
                if (id === "ADDING_ROW") {
                  return 610 * densityFactor;
                }
                if (id === "SUMMARY") {
                  return 40 * densityFactor;
                }
                return 46 * densityFactor;
              }}
              components={{
                NoRowsOverlay: () => (
                  <Box
                    sx={{
                      textAlign: "center",
                      verticalAlign: "center",
                      lineHeight: "72px",
                      color: "#999",
                    }}
                  >
                    No duplicates found
                  </Box>
                ),
              }}
              keepNonExistentRowsSelected
              density="compact"
              disableColumnReorder
              disableColumnPinning
              disableColumnMenu
            />
          </Box>
          {duplicateRows.length > 0 ? (
            <>
              <Typography variant="body1">
                Would you like to delete all of the above duplicate items?
              </Typography>
              <Stack
                justifyContent="flex-end"
                sx={{ padding: "10px 10px 10px 10px" }}
                direction="row"
                spacing={1}
              >
                <Button
                  variant="outlined"
                  startIcon={<CloseIcon />}
                  sx={{ width: "200px" }}
                  onClick={handleClose}
                >
                  Keep Duplicates
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  startIcon={<DeleteForeverIcon />}
                  sx={{ width: "200px" }}
                  onClick={handleDeleteDuplicates}
                >
                  Delete Duplicates
                </Button>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Paper>
    </Dialog>
  );
};

export default GearClosetDuplicateDeleteDialog;
