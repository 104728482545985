import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-pro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GearClosetDetailPanelContent from "./GearClosetDetailPanelContent";
import CustomDetailPanelToggle from "./CustomDetailPanelToggle";
import GearClosetAddRowForm from "./GearClosetAddRowForm";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  addItemToGearCloset,
  getGearCloset,
  updateGearClosetItem,
  deleteItemFromGearCloset,
  getUserIsLoggedIn,
  getWeightInGrams,
} from "../utils";
import { Tooltip } from "@mui/material";
import GearClosetDuplicateDeleteDialog from "./GearClosetDuplicateDeleteDialog";
import { useMediaQuery } from "react-responsive";

const baseAddRow = {
  id: "ADD_ROW",
  type: "ADD_ROW",
  item: "",
  weight: 0,
  unit: 0,
  price: 0,
};

const tempAddRow = {
  id: "ADDING_ROW",
  type: "ADDING_ROW",
  item: "",
  weight: 0,
  unit: 0,
  price: 0,
};

const UNEDITABLE_ROW_TYPES = ["ADD_ROW", "ADDING_ROW", "DIVIDER"];

const customWeightComparator = (a, b, ap, bp) => {
  const aUnit = ap.api.getRow(ap.id).unit;
  const bUnit = bp.api.getRow(bp.id).unit;
  const aValueInGrams = getWeightInGrams(a, aUnit);
  const bValueInGrams = getWeightInGrams(b, bUnit);
  const finValue = aValueInGrams - bValueInGrams;
  return finValue;
};

const GearClosetDataTable = ({
  setSelectedItems,
  darkMode,
  searchText,
  setDeleteDuplicateIsOpen,
  deleteDuplicateIsOpen,
  gearRows,
  setGearRows,
  filteredGearRows,
  setFilteredGearRows,
}) => {
  const [addRow, setAddRow] = React.useState(baseAddRow);
  const [isAddItemOpen, setIsAddItemOpen] = React.useState(false);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });

  React.useEffect(() => {
    const filteredRows = gearRows.filter((item) =>
      `${item.brand}${item.item}${item.category}`
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredGearRows(filteredRows);
  }, [gearRows, searchText]);

  const handleAddNewItemToTable = async (item) => {
    const newGearRows = [...gearRows];
    const newGearClosetItem = await addItemToGearCloset(item);
    newGearRows.push(newGearClosetItem);
    setGearRows(newGearRows);
  };

  const handleDeleteRow = async (id) => {
    await deleteItemFromGearCloset(id);
    const newRows = gearRows.filter((row) => row.id !== id);
    setGearRows(newRows);
  };

  const handleRowUpdate = async (cellData) => {
    const newGearRows = gearRows.map((row) => {
      if (row.id === cellData.id) return cellData;
      return row;
    });
    await updateGearClosetItem(cellData);
    setGearRows(newGearRows);
    return cellData;
  };

  const handleSetSelectedItems = (items) => {
    const itemSet = new Set(items);
    const gearToAdd = gearRows.filter(({ id }) => itemSet.has(id));
    setSelectedItems(gearToAdd);
  };

  const handleRowEditError = (err) => {
    console.log(err);
  };

  const getDetailPanelHeight = React.useCallback(() => 400, []);

  React.useEffect(() => {
    (async () => {
      const items = await getGearCloset();
      if (items) {
        setGearRows(items);
      }
    })();
  }, []);

  const handleRefetchGearCloset = async () => {
    const items = await getGearCloset();
    if (items) {
      setGearRows(items);
    }
  };

  React.useEffect(() => {
    if (isAddItemOpen) {
      setAddRow(tempAddRow);
    } else {
      setAddRow(baseAddRow);
    }
  }, [isAddItemOpen]);

  const pinnedRows = {
    top: [addRow],
  };

  const columns = [
    {
      field: "item",
      headerName: "Item",
      headerAlign: "left",
      headerClassName: "firstcolumnSeparator",
      editable: true,
      flex: 1,
      colSpan: ({ row }) => {
        if (row.type === "ADD_ROW" || row.type === "ADDING_ROW") {
          return 8;
        }
      },
      renderCell: (params) => {
        if (params.id === "ADD_ROW") {
          return (
            <Button
              disabled={!getUserIsLoggedIn()}
              className="add-row-button"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
                width: "100%",
                marginLeft: "-30px",
              }}
              onClick={() => setIsAddItemOpen(true)}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add Item
            </Button>
          );
        }
        if (params.id === "ADDING_ROW") {
          return (
            <GearClosetAddRowForm
              setIsAddItemOpen={setIsAddItemOpen}
              handleAddNewItemToTable={handleAddNewItemToTable}
              darkMode={darkMode}
            />
          );
        }
      },
    },
    {
      field: "category",
      headerName: "Item Type",
      minWidth: 95,
      flex: 0.65,
      headerAlign: "left",
      editable: true,
    },
    {
      field: "weight",
      headerName: "Weight",
      type: "number",
      width: 80,
      headerAlign: "right",
      headerClassName: "lastcolumnSeparator",
      editable: true,
      sortable: true,
      sortComparator: customWeightComparator,
    },
    {
      field: "unit",
      headerName: "",
      type: "singleSelect",
      width: 69,
      height: "36px",
      valueOptions: ["g", "oz", "lb", "kg"],
      headerAlign: "left",
      sortable: false,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 92,
      type: "number",
      headerAlign: "right",
      editable: true,
      renderCell: (params) => {
        return Number(params.value).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      },
    },
    {
      field: "action",
      headerName: "",
      width: 40,
      headerClassName: "lastcolumnSeparator",
      headerAlign: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              sx={{
                marginLeft: "5px",
                height: "30px",
                width: "30px",
              }}
              onClick={() => {
                handleDeleteRow(params.row.id);
              }}
            >
              <Tooltip title="Delete this piece of gear forever">
                <DeleteForeverIcon sx={{ color: "#f44030" }} />
              </Tooltip>
            </IconButton>
          </>
        );
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      headerClassName: "lastcolumnSeparator",
      width: 80,
      headerAlign: "right",
      renderCell: (params) => {
        if (UNEDITABLE_ROW_TYPES.includes(params.row.type)) {
          return <></>;
        }
        return <CustomDetailPanelToggle id={params.id} value={params.value} />;
      },
    },
  ];

  return (
    <Box>
      <GearClosetDuplicateDeleteDialog
        isOpen={deleteDuplicateIsOpen}
        setIsOpen={setDeleteDuplicateIsOpen}
        gearRows={gearRows}
        handleRefetchGearCloset={handleRefetchGearCloset}
      />
      <DataGridPro
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
          borderRadius: 0,
          marginTop: "5px",
          height: "500px",
        }}
        rows={Object.values(filteredGearRows)}
        columns={columns}
        disableSelectionOnClick
        hideFooter
        experimentalFeatures={{ newEditingApi: true, rowPinning: true }}
        getRowHeight={({ id, densityFactor }) => {
          if (id === "ADDING_ROW") {
            return 420;
          }
          if (id === "SUMMARY") {
            return 40 * densityFactor;
          }
          return 46 * densityFactor;
        }}
        components={{
          NoRowsOverlay: () => (
            <Box
              sx={{
                paddingTop: "30px",
                textAlign: "center",
                verticalAlign: "center",
                lineHeight: "72px",
                color: "#999",
              }}
            >
              {getUserIsLoggedIn()
                ? "No items"
                : "Sign in to begin adding items to your Gear Closet"}
            </Box>
          ),
        }}
        keepNonExistentRowsSelected
        pinnedRows={pinnedRows}
        density={isDesktopOrLaptop ? "compact" : "comfortable"}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={handleRowEditError}
        disableColumnReorder
        disableColumnPinning
        checkboxSelection
        onSelectionModelChange={(item) => {
          handleSetSelectedItems(item);
        }}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={(props) => (
          <GearClosetDetailPanelContent
            {...props}
            gearRows={gearRows}
            setGearRows={setGearRows}
            handleRowUpdate={handleRowUpdate}
            darkMode={darkMode}
          />
        )}
        disableColumnMenu
      />
    </Box>
  );
};

export default GearClosetDataTable;
