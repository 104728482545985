import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  updateGearDatabaseItem,
  updateItemDeleteState,
  addRetailerToGear,
} from "../utils";

const ignoredKeysForResults = [
  "googleId",
  "name",
  "item",
  "brand",
  "imageUrl",
  "url",
  "variant",
  "description",
];

const getEditor = (
  key,
  type,
  value,
  handleUpdateValue,
  modifiedKeys,
  selectValues
) => {
  if (type === "String") {
    return (
      <TextField
        size="small"
        sx={{ border: modifiedKeys.includes(key) ? "1px solid yellow" : "" }}
        defaultValue={value}
        onChange={(e) => {
          handleUpdateValue(key, e.target.value);
        }}
      ></TextField>
    );
  }
  if (type === "Number") {
    return (
      <TextField
        type="number"
        size="small"
        sx={{ border: modifiedKeys.includes(key) ? "1px solid yellow" : "" }}
        defaultValue={value}
        onChange={(e) => {
          handleUpdateValue(key, Number(e.target.value));
        }}
      ></TextField>
    );
  }
  if (type === "Multi") {
    return (
      <Select
        size="small"
        defaultValue={value}
        sx={{ border: modifiedKeys.includes(key) ? "1px solid yellow" : "" }}
        onChange={(e) => {
          handleUpdateValue(key, e.target.value);
        }}
      >
        {selectValues.map((value) => (
          <MenuItem value={value}>{value}</MenuItem>
        ))}
      </Select>
    );
  }
};

const GearManagerItem = ({ item, category, specMap }) => {
  const [temporaryData, setTemporaryData] = React.useState(item);
  const [itemData, setItemData] = React.useState(item);
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  const [modifiedKeys, setModifiedKeys] = React.useState([]);
  const [isDeleted, setIsDeleted] = React.useState(item.isDeleted);
  const [isAddingRetailer, setIsAddingRetailer] = React.useState(false);

  const [retailerToAdd, setRetailerToAdd] = React.useState({
    retailer: "rei",
    sku: "",
    buyLink: "",
  });

  const handleSetKeyBeingEdited = () => {
    setIsInEditMode(true);
  };

  const handleUpdateRetailerToAdd = (key, value) => {
    setRetailerToAdd({
      ...retailerToAdd,
      [key]: value,
    });
  };

  const handleCancelEdit = () => {
    setTemporaryData(itemData);
    setIsInEditMode(false);
    setModifiedKeys([]);
  };

  const handleDelete = async (shouldDelete) => {
    await updateItemDeleteState(item._id, shouldDelete);
    setIsDeleted(shouldDelete);
  };

  const handleSaveEdit = () => {
    setItemData(temporaryData);
    const updateObject = modifiedKeys.reduce((acc, curr) => {
      acc[curr] = temporaryData[curr];
      return acc;
    }, {});
    updateGearDatabaseItem(category, temporaryData._id, updateObject);
    setIsInEditMode(false);
    setModifiedKeys([]);
  };

  const handleAddRetailer = async () => {
    try {
      await addRetailerToGear(temporaryData._id, category, retailerToAdd);
      setIsAddingRetailer(false);
      alert(
        "Retailer was added, refresh to see update. (Sorry, too lazy to make it update itself)."
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateValue = (key, value) => {
    setTemporaryData({ ...temporaryData, [key]: value });
    let newModifiedKeys = [...modifiedKeys];

    if (itemData[key] == value) {
      newModifiedKeys = modifiedKeys.filter((mk) => mk !== key);
    } else {
      newModifiedKeys = [...modifiedKeys, key];
    }
    setModifiedKeys([...new Set(newModifiedKeys)]);
  };

  return (
    <Paper
      key={`${category}-${itemData._id}`}
      sx={{
        width: "1400px",
        minHeight: "100px",
        padding: "10px 10px 10px 10px",
        margin: "5px 5px 5px 5px",
      }}
    >
      <Stack direction="row" spacing={1}>
        <Stack sx={{ maxWidth: "350px", borderRight: "1px solid grey" }}>
          <Stack direction="row">
            <Box
              height="80px"
              width="80px"
              sx={{
                backgroundImage: `url("${itemData.imageUrl}")`,
                backgroundSize: "auto 80px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
            <Stack
              sx={{
                padding: "5px 5px 5px 5px",
                width: "280px",
              }}
              textAlign="left"
            >
              <Typography variant="body">
                {isInEditMode ? (
                  <TextField
                    sx={{
                      border: modifiedKeys.includes(
                        category === "autocomplete" ? "item" : "name"
                      )
                        ? "1px solid yellow"
                        : "",
                    }}
                    onChange={(e) => {
                      const key = category === "autocomplete" ? "item" : "name";
                      handleUpdateValue(key, e.target.value);
                    }}
                    defaultValue={
                      category === "autocomplete"
                        ? itemData.item
                        : itemData.name
                    }
                    size="small"
                    fullWidth
                  />
                ) : category === "autocomplete" ? (
                  itemData.item
                ) : (
                  itemData.name
                )}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ height: "50px" }}
              >
                <Typography>Variant:</Typography>
                {isInEditMode ? (
                  <TextField
                    defaultValue={itemData.variant}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      handleUpdateValue("variant", e.target.value);
                    }}
                  />
                ) : (
                  <Typography>{itemData.variant}</Typography>
                )}
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ height: "50px" }}
              >
                <Typography>Brand:</Typography>
                {isInEditMode ? (
                  <TextField
                    defaultValue={itemData.formattedBrand || itemData.brand}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      handleUpdateValue("formattedBrand", e.target.value);
                      handleUpdateValue("brand", e.target.value);
                    }}
                  />
                ) : (
                  <Typography>
                    {itemData.formattedBrand || itemData.brand}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Typography variant="subtitle2">Description </Typography>
          <Box sx={{ maxHeight: "200px", overflowY: "scroll" }}>
            <Typography
              variant="subtitle2"
              textAlign={itemData.description ? "left" : "center"}
            >
              {isInEditMode ? (
                <TextField
                  multiline
                  sx={{
                    border: modifiedKeys.includes("description")
                      ? "1px solid yellow"
                      : "",
                  }}
                  defaultValue={itemData.description}
                  size="small"
                  rows={10}
                  fullWidth
                  onChange={(e) => {
                    handleUpdateValue("description", e.target.value);
                  }}
                />
              ) : (
                itemData.description || "No description"
              )}
            </Typography>
          </Box>
        </Stack>
        <Box width="500px">
          <Stack spacing={1}>
            <TableContainer sx={{ maxHeight: "300px" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ width: "250px", padding: "5px 5px 5px 5px" }}
                    >
                      Spec
                    </TableCell>
                    <TableCell sx={{ padding: "5px 5px 5px 5px" }}>
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ maxHeight: "300px", overflowY: "scroll" }}>
                  {specMap.map(({ key, label, type, values }) =>
                    ignoredKeysForResults.includes(key) ? (
                      <></>
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{ width: "250px", padding: "5px 5px 5px 5px" }}
                        >
                          {label}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "5px 5px 5px 5px", height: "51px" }}
                        >
                          {isInEditMode
                            ? getEditor(
                                key,
                                type,
                                itemData[key],
                                handleUpdateValue,
                                modifiedKeys,
                                values
                              )
                            : `${itemData[key] === null ? "-" : itemData[key]}`}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              {isInEditMode ? (
                <>
                  <Button
                    variant="outlined"
                    color="success"
                    startIcon={<SaveIcon />}
                    onClick={handleSaveEdit}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<CancelIcon />}
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={handleSetKeyBeingEdited}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ width: "500px" }}>
          <Stack>
            {category === "autocomplete" ? (
              <Stack direction="row" justifyContent="flex-end">
                {isDeleted ? (
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => handleDelete(false)}
                  >
                    Deleted
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDelete(true)}
                  >
                    Delete
                  </Button>
                )}
              </Stack>
            ) : (
              <></>
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ height: "50px" }}
            >
              <Typography
                variant="subtitle2"
                sx={{ minWidth: "50px", maxWidth: "50px", textAlign: "left" }}
              >{`Url: `}</Typography>
              {isInEditMode ? (
                <TextField
                  size="small"
                  fullWidth
                  defaultValue={itemData.url}
                  sx={{
                    border: modifiedKeys.includes("url")
                      ? "1px solid yellow"
                      : "",
                  }}
                  onChange={(e) => {
                    handleUpdateValue("url", e.target.value);
                  }}
                ></TextField>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="subtitle2"
                >
                  {itemData.url}
                </Typography>
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ height: "50px" }}
            >
              <Typography
                sx={{
                  minWidth: "50px",
                  maxWidth: "50px",
                  textAlign: "left",
                }}
                variant="subtitle2"
              >{`Image: `}</Typography>
              {isInEditMode ? (
                <TextField
                  size="small"
                  fullWidth
                  defaultValue={itemData.imageUrl}
                  sx={{
                    border: modifiedKeys.includes("imageUrl")
                      ? "1px solid yellow"
                      : "",
                  }}
                  onChange={(e) => {
                    handleUpdateValue("imageUrl", e.target.value);
                  }}
                ></TextField>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="subtitle2"
                >
                  {itemData.imageUrl}
                </Typography>
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ height: "50px" }}
            >
              <Typography
                sx={{
                  minWidth: "50px",
                  maxWidth: "50px",
                  textAlign: "left",
                }}
                variant="subtitle2"
              >{`GTIN: `}</Typography>
              {isInEditMode ? (
                <TextField
                  size="small"
                  fullWidth
                  defaultValue={itemData.googleId}
                  sx={{
                    border: modifiedKeys.includes("googleId")
                      ? "1px solid yellow"
                      : "",
                  }}
                  onChange={(e) => {
                    handleUpdateValue("googleId", e.target.value);
                  }}
                ></TextField>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                  }}
                  variant="subtitle2"
                >
                  {itemData.googleId}
                </Typography>
              )}
            </Stack>
            {category !== "autocomplete" ? (
              <>
                <Typography textAlign="center">Retailers</Typography>
                {itemData.retailers.map((item) => (
                  <Box>
                    <Stack direction="row">
                      <Typography
                        sx={{
                          minWidth: "120px",
                          maxWidth: "120px",
                          textAlign: "left",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.retailer}
                      </Typography>
                      <Typography
                        sx={{
                          minWidth: "100px",
                          maxWidth: "100px",
                          textAlign: "left",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.retailPrice}
                      </Typography>
                      <Typography
                        sx={{
                          minWidth: "300px",
                          maxWidth: "300px",
                          textAlign: "left",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.buyLink}
                      </Typography>
                    </Stack>
                  </Box>
                ))}
              </>
            ) : (
              <></>
            )}
            {isAddingRetailer ? (
              <Stack gap={1} paddingTop="20px">
                <FormControl sx={{ width: "125px" }} size="small">
                  <InputLabel>Retailer</InputLabel>
                  <Select
                    value={retailerToAdd.retailer}
                    label="Retailer"
                    onChange={(event) => {
                      handleUpdateRetailerToAdd("retailer", event.target.value);
                    }}
                  >
                    <MenuItem value="rei">REI</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  size="small"
                  fullWidth
                  label="sku"
                  defaultValue={retailerToAdd.sku}
                  onChange={(e) => {
                    handleUpdateRetailerToAdd("sku", e.target.value);
                  }}
                />
                <TextField
                  size="small"
                  fullWidth
                  label="url"
                  defaultValue={retailerToAdd.buyLink}
                  onChange={(e) => {
                    handleUpdateRetailerToAdd("buyLink", e.target.value);
                  }}
                />
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    color="success"
                    variant="outlined"
                    onClick={handleAddRetailer}
                  >
                    Save
                  </Button>
                  <Button
                    color="error"
                    onClick={() => {
                      setIsAddingRetailer(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <Button
                variant="contained"
                onClick={() => setIsAddingRetailer(true)}
              >
                Add Avantlink Retailer
              </Button>
            )}
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default GearManagerItem;
