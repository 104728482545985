export const fetchArticlePaths = () => {
  return fetch(`/api/articles/paths`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: null,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchArticle = ({ articleId }) => {
  return fetch(`/api/articles?articleId=${articleId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: null,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
