import React from "react";

import Dialog from "@mui/material/Dialog";
import Container from "@mui/system/Container";
import Slide from "@mui/material/Slide";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Stack } from "@mui/system";
import { getUserIsLoggedIn } from "../utils";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ShareableLinkDialog = ({
  shareDialogIsOpen,
  setShareDialogIsOpen,
  shareableLink,
  handleSetAuthenticationIsOpen,
}) => {
  const [snackbarIsOpen, setSnackbarIsOpen] = React.useState(false);
  const handleClose = () => {
    setShareDialogIsOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarIsOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={shareDialogIsOpen}
      TransitionComponent={Transition}
      key={shareableLink}
    >
      <Container
        sx={{ width: "600px", height: "350px", padding: "25px 0px 100px 50px" }}
      >
        <DialogTitle sx={{ marginLeft: "-25px" }}>
          Share Your Pack
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px", marginLeft: "0px" }}>
          {getUserIsLoggedIn() ? (
            <>
              <Typography
                sx={{
                  color: "#D0D0D0",
                  fontStyle: "italic",
                  marginTop: "10px",
                  width: "480px",
                }}
              >
                Below is the link to share your pack. Anyone with this link will
                be able to see any future updates to this pack.
              </Typography>
              <Stack direction="row" sx={{ marginTop: "20px" }}>
                <IconButton
                  sx={{ height: "40px", width: "40px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://www.packwizard.com/s/${shareableLink}`
                    );
                    setSnackbarIsOpen(true);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
                <TextField
                  sx={{ width: "420px", marginLeft: "5px" }}
                  size="small"
                  value={`https://www.packwizard.com/s/${shareableLink}`}
                  inputProps={{ readOnly: true }}
                />
              </Stack>
              <Typography
                sx={{
                  color: "#D0D0D0",
                  marginTop: "10px",
                  textDecoration: "underline",
                  width: "480px",
                }}
              >
                Tip
              </Typography>
              <Typography
                sx={{
                  color: "#D0D0D0",
                  fontStyle: "italic",
                  width: "480px",
                }}
                variant="subtitle2"
              >
                Sharing your pack allows people with the above link to view your
                pack. In order for your pack to show up on the Pack Explore
                Page, you must set your pack to "Public" by editing your pack
                tags.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  color: "#D0D0D0",
                  marginTop: "60px",
                  width: "430px",
                  textAlign: "center",
                }}
              >
                To share your pack, please{" "}
                <Link
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      fontWeight: "550",
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSetAuthenticationIsOpen("LOGIN");
                  }}
                >
                  sign in
                </Link>
              </Typography>
            </>
          )}
        </DialogContent>
      </Container>
      <Snackbar
        open={snackbarIsOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "300px", color: "white" }}
        >
          Copied to clipboard
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ShareableLinkDialog;
