import React from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DEFAULT_CATEGORIES from "../../constants/defaultCategories";
import { addItemToAutocomplete, addItemToGearExplorePage } from "../../utils";
import { gearSpecMap } from "./gearSpecMap";

const getEditor = (
  key,
  label,
  type,
  value,
  handleUpdateValue,
  selectValues
) => {
  if (type === "String" || type === "Url") {
    return (
      <TextField
        label={label}
        size="small"
        defaultValue={value}
        onChange={(e) => {
          handleUpdateValue(key, e.target.value);
        }}
      ></TextField>
    );
  }
  if (type === "Number") {
    return (
      <TextField
        label={label}
        type="number"
        size="small"
        defaultValue={value}
        onChange={(e) => {
          handleUpdateValue(key, Number(e.target.value));
        }}
      ></TextField>
    );
  }
  if (type === "Multi") {
    return (
      <FormControl size="small">
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          defaultValue={value}
          onChange={(e) => {
            handleUpdateValue(key, e.target.value);
          }}
        >
          {selectValues.map((value) => (
            <MenuItem value={value}>{value}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
};

const AdminAddItemModule = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [itemToCreate, setItemToCreate] = React.useState({
    brand: "",
    item: "",
    reviewValue: 0,
    price: 0,
    imageUrl: "",
    url: "",
    variant: "",
    displayWeight: 0,
    category: "",
    description: "",
    purchaseWebsite: "",
  });
  const [chosenCategory, setChosenCategory] = React.useState("tents");
  const [shouldAddItemToAutocomplete, setShouldAddItemToAutocomplete] =
    React.useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChangeValue = (key, value) => {
    const newDetails = { ...itemToCreate };
    newDetails[key] = value;
    setItemToCreate(newDetails);
  };

  const handleSave = async () => {
    setIsSaving(true);
    if (chosenCategory === "autocomplete") {
      await addItemToAutocomplete(itemToCreate);
    } else {
      await addItemToGearExplorePage(
        itemToCreate,
        chosenCategory,
        shouldAddItemToAutocomplete
      );
    }
    setIsSaving(false);
    setIsSaved(true);
  };

  const handleReset = () => {
    setIsSaved(false);
    setItemToCreate({
      brand: "",
      item: "",
      reviewValue: 0,
      price: 0,
      imageUrl: "",
      url: "",
      variant: "",
      displayWeight: 0,
      category: "",
      description: "",
      purchaseWebsite: "",
    });
  };

  return (
    <>
      <Dialog onClose={handleClose} open={isOpen}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ minWidth: "500px", padding: "10px 10px 10px 10px" }}
        >
          <DialogTitle>Add Item</DialogTitle>
          <IconButton
            onClick={handleClose}
            sx={{ height: "40px", width: "40px" }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          {isSaved ? (
            <Stack spacing={2}>
              <Typography textAlign="center">Saved</Typography>
              <Button variant="outlined" onClick={handleReset}>
                Create Another Item
              </Button>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <ToggleButtonGroup
                color="primary"
                value={chosenCategory}
                exclusive
                onChange={(e) => {
                  setChosenCategory(e.target.value);
                }}
              >
                <ToggleButton value="tents">Tents</ToggleButton>
                <ToggleButton value="sleepingBags">Sleeping Bags</ToggleButton>
                <ToggleButton value="packs">Packs</ToggleButton>
                <ToggleButton value="sleepingPads">Sleeping Pads</ToggleButton>
                <ToggleButton value="insulatedJackets">
                  Insulated Jackets
                </ToggleButton>
                <ToggleButton value="autocomplete">Autocomplete</ToggleButton>
              </ToggleButtonGroup>
              {chosenCategory === "autocomplete" ? (
                <Stack spacing={2} sx={{ width: "500px" }}>
                  <TextField
                    label="Brand"
                    size="small"
                    fullWidth
                    value={itemToCreate["brand"]}
                    onChange={(e) => {
                      handleChangeValue("brand", e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    label="Name"
                    size="small"
                    fullWidth
                    value={itemToCreate["item"]}
                    onChange={(e) => {
                      handleChangeValue("item", e.target.value);
                    }}
                  ></TextField>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    size="small"
                    sx={{ width: "100%", minWidth: "110px" }}
                    options={DEFAULT_CATEGORIES.map((option) => ({
                      id: option,
                      label: option,
                    }))}
                    PopperComponent={(props) => (
                      <Popper {...props} sx={{ minWidth: "170px" }} />
                    )}
                    value={itemToCreate["category"]}
                    inputValue={itemToCreate["category"]}
                    onInputChange={(event, newInputValue) => {
                      handleChangeValue("category", newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                  <TextField
                    label="Variant"
                    fullWidth
                    size="small"
                    value={itemToCreate["variant"]}
                    onChange={(e) => {
                      handleChangeValue("variant", e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    label="Weight (grams)"
                    fullWidth
                    size="small"
                    type="number"
                    value={itemToCreate["displayWeight"]}
                    onChange={(e) => {
                      handleChangeValue("displayWeight", e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    label="Price"
                    fullWidth
                    size="small"
                    type="number"
                    value={itemToCreate["price"]}
                    onChange={(e) => {
                      handleChangeValue("price", e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    label="Url"
                    fullWidth
                    size="small"
                    value={itemToCreate["url"]}
                    onChange={(e) => {
                      handleChangeValue("url", e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    label="Image Url"
                    fullWidth
                    size="small"
                    value={itemToCreate["imageUrl"]}
                    onChange={(e) => {
                      handleChangeValue("imageUrl", e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    label="Description"
                    fullWidth
                    size="small"
                    value={itemToCreate["description"]}
                    onChange={(e) => {
                      handleChangeValue("description", e.target.value);
                    }}
                  ></TextField>
                </Stack>
              ) : (
                <Stack spacing={2} sx={{ width: "500px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={shouldAddItemToAutocomplete}
                        sx={{ width: "40px" }}
                        onChange={(e, value) => {
                          setShouldAddItemToAutocomplete(value);
                        }}
                      />
                    }
                    label="Add to Autocomplete"
                  />
                  {gearSpecMap[chosenCategory].map(
                    ({ key, label, type, values }) =>
                      getEditor(
                        key,
                        label,
                        type,
                        itemToCreate[key],
                        handleChangeValue,
                        values
                      )
                  )}
                </Stack>
              )}
              <Button onClick={handleSave}>Save</Button>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
      {isSaving ? (
        <Button
          variant="outlined"
          color="success"
          sx={{ height: "30px" }}
          startIcon={<AddCircleIcon />}
          disabled
        >
          Adding...
        </Button>
      ) : (
        <Button
          variant="outlined"
          sx={{ height: "30px" }}
          startIcon={<AddCircleIcon />}
          color="success"
          onClick={() => setIsOpen(!isOpen)}
        >
          Add Item
        </Button>
      )}
    </>
  );
};

export default AdminAddItemModule;
