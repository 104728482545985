import React from "react";
import { Container } from "@mui/system";
import { getColorWithMode, STYLISH_DARK } from "../constants/colors";
import { Grid, Link, List, ListItem, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { releaseNotes, issuesAndFeatures } from "../constants/releaseNotes";

const getPriority = (priority) => {
  switch (priority) {
    case 0:
      return "low";
    case 1:
      return "medium";
    case 2:
      return "high";
    default:
      return "";
  }
};

const getPriorityColor = (priority) => {
  switch (priority) {
    case 0:
      return "green";
    case 1:
      return "gold";
    case 2:
      return "red";
    default:
      return "";
  }
};

const ReleaseNotesContainer = ({ isSideNavOpen, isDarkMode }) => {
  return (
    <Container
      sx={{
        minWidth: "360px",
        height: "100%",
        width: `calc(100vw - ${isSideNavOpen ? "240px" : "0px"})`,
        maxWidth: isSideNavOpen ? "1153px" : "1440px",
        backgroundColor: getColorWithMode(isDarkMode, "base"),
      }}
    >
      <Container
        sx={{
          marginTop: "10px",
          marginLeft: "0",
          minWidth: "360px",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "scroll",
        }}
      >
        <Typography variant="h4">Planned Updates</Typography>
        <Typography variant="subtitle">
          Below is our list of planned updates as well as known issues. Feature
          requests are greatly appreciated and all are considered. If you have a
          feature to request, or an issue to report, please don't hesitate to
          reach out on our{" "}
          <Link sx={{ textDecoration: "none" }} href="/contact">
            {" "}
            Contact Page
          </Link>
        </Typography>
        {issuesAndFeatures.map((data) => {
          return (
            <Container sx={{ marginTop: "20px" }} key={data.name}>
              <Typography variant="h5">{data.name}</Typography>
              {!data.items.length ? (
                <Typography>No known issues at this time 🤞</Typography>
              ) : (
                <TableContainer
                  variant="standard"
                  size="small"
                  sx={{
                    borderBottom: "none",
                    fontSize: "10px",
                    minWidth: "360px",
                    marginLeft: "15px",
                    marginTop: "10px",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ padding: "0px 0px 0px 0px", minWidth: "100px" }}
                        >
                          Feature
                        </TableCell>
                        <TableCell sx={{ padding: "0px 0px 0px 0px" }}>
                          Description
                        </TableCell>
                        <TableCell
                          sx={{ padding: "0px 0px 0px 0px", width: "40px" }}
                          align="center"
                        >
                          Priority
                        </TableCell>
                        <TableCell
                          sx={{ padding: "0px 0px 0px 0px", width: "90px" }}
                          align="center"
                        >
                          In Progress
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.items.map((item, index) => (
                        <TableRow key={`${data.name}-${index}`}>
                          <TableCell sx={{ padding: "10px 5px 10px 0px" }}>
                            {item.label}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "10px 5px 10px 0px" }}
                            align="left"
                          >
                            {item.description}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "10px 0px 10px 0px",
                              color: getPriorityColor(item.priority),
                            }}
                            align="center"
                          >
                            {getPriority(item.priority)}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "10px 0px 10px 0px",
                              color: item.started ? "green" : "red",
                            }}
                            align="center"
                          >
                            {item.started ? "yes" : "no"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Container>
          );
        })}
        <Typography
          variant="h4"
          sx={{ marginTop: "20px", paddingBottom: "20px" }}
        >
          Release Notes
        </Typography>
        {releaseNotes.map((updates, index) => {
          return (
            <React.Fragment key={`releaseUpdateGroup-${index}`}>
              <Typography>{updates.date}</Typography>
              <List>
                {updates.items.map((item, index) => (
                  <ListItem key={`release_note_${index}`}>-{item}</ListItem>
                ))}
              </List>
            </React.Fragment>
          );
        })}
      </Container>
    </Container>
  );
};

export default ReleaseNotesContainer;
