import WEIGHT_TYPES from "../constants/weightTypes.json";

const initialTableStructure = [
  {
    title: "Big 3",
    subtitle: "Pack, Tent, Sleep System",
    defaultCategory: "Big 3",
    rows: {},
    markedAsWeightType: WEIGHT_TYPES.BASE,
  },
  {
    title: "Cook System",
    subtitle: "Stove, cookware, utensils, etc...",
    defaultCategory: "Cook System",
    rows: {},
    markedAsWeightType: WEIGHT_TYPES.BASE,
  },
  {
    title: "Electronics",
    subtitle: "Powerbank, phone, gps, etc...",
    defaultCategory: "Utility",
    rows: {},
    markedAsWeightType: WEIGHT_TYPES.BASE,
  },
  {
    title: "Ditty Bag",
    subtitle: "Hygiene, first aid, toiletries, etc...",
    defaultCategory: "Ditty",
    rows: {},
    markedAsWeightType: WEIGHT_TYPES.BASE,
  },
  {
    title: "Miscellaneous",
    subtitle: "Tools, maps, books, games, etc...",
    defaultCategory: "Ditty",
    rows: {},
    markedAsWeightType: WEIGHT_TYPES.BASE,
  },
  {
    title: "Clothing",
    subtitle: "Jacket, shoes, layers, etc...",
    defaultCategory: "Clothing",
    rows: {},
    markedAsWeightType: WEIGHT_TYPES.BASE,
  },
];

export default initialTableStructure;
