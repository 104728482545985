export const handleSignIn = async (formValues) => {
  try {
    const response = await fetch(`/auth/signin`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(formValues),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (err) {
    console.log(err);
  }
};

export const handleSignUp = async (formValues) => {
  try {
    const response = await fetch(`/auth/signup`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(formValues),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (err) {
    console.log(err);
    return;
  }
};

export const handleUpdatePassword = async (formValues, resetCode) => {
  try {
    const response = await fetch(`/auth/resetPassword`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({ ...formValues, resetCode }),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (err) {
    console.log(err);
  }
};

export const handleRequestPasswordChange = async (formValues) => {
  try {
    const response = await fetch(`/auth/requestPasswordReset`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(formValues),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const refreshUserToken = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("profile"));
    const response = await fetch(`/auth/refresh`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(user),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getRefreshedUser = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("profile"));
    if (user) {
      const refreshedUser = await refreshUserToken(user);
      return refreshedUser;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
