import { getLocalUser } from "../utils";

export const searchItemsWithSubstring = ({ inputValue }) => {
  const user = getLocalUser();
  return fetch(`/api/items/search?substring=${inputValue}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user ? `Bearer ${user.token}` : null,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const addGearClosetItem = ({ item }) => {
  const user = getLocalUser();
  return fetch("/api/items/addItemToGearCloset", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ item }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const addGearClosetItemWithId = ({ itemId, gearType }) => {
  const user = getLocalUser();
  return fetch("/api/items/addItemToGearClosetWithId", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ itemId, gearType }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getGearCloset = () => {
  const user = getLocalUser();
  return fetch("/api/items/gearCloset", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateGearClosetItem = ({ item }) => {
  const user = getLocalUser();
  return fetch("/api/items/updateItemInGearCloset", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PUT",
    body: JSON.stringify({ item }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteGearClosetItem = ({ id }) => {
  const user = getLocalUser();
  return fetch(`/api/items/deleteItemInGearCloset?itemId=${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "DELETE",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getItemSuggestions = ({ items }) => {
  let userSettings = localStorage.getItem("suggestionSettings");
  if (!userSettings) {
    userSettings = { maxPrice: 1000, packSizes: 5 };
  } else {
    userSettings = JSON.parse(userSettings);
  }
  return fetch(`/api/items/itemSuggestions`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ items, userSettings }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteDuplicatesFromGearCloset = ({ duplicateIds }) => {
  const user = getLocalUser();
  return fetch(`/api/items/deleteDuplicatesInGearCloset`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    body: JSON.stringify({ duplicateIds }),
    method: "DELETE",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const createNewBin = async ({ selectedItems }) => {
  const user = getLocalUser();
  return fetch(`/api/items/createNewBin`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ selectedItems }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserBins = async () => {
  const user = getLocalUser();
  return fetch(`/api/items/getUserBins`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserBin = async ({ binId, binUpdates }) => {
  const user = getLocalUser();
  return fetch(`/api/items/updateUserBin`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ binId, binUpdates }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteUserBin = async ({ binId }) => {
  const user = getLocalUser();
  return fetch(`/api/items/deleteUserBin?binId=${binId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "DELETE",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
