import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import ItemRetailerModule from "./ItemRetailerModule";

const RetailerPreview = ({ item, darkMode, gearText, packwizardUrl }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        size="small"
        sx={{
          fontSize: "10px",
          width: "80px",
          paddingLeft: "0px",
          paddingRight: "0px",
          backgroundColor: "#00563B",
          "&:hover": { backgroundColor: "#2E8B57" },
          color: "white",
        }}
        onClick={handleClick}
      >
        {`Retailers (${
          item.retailers && item.retailers.length !== 0
            ? item.retailers.length
            : 1
        })`}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -500,
        }}
      >
        <ItemRetailerModule
          item={item}
          darkMode={darkMode}
          gearText={gearText}
          packwizardUrl={packwizardUrl}
          dynamicWidth="500px"
        />
      </Popover>
    </>
  );
};

export default RetailerPreview;
