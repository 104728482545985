import MODULE_KEYS from "./moduleKeys.json";
import CHILDREN_KEYS from "./childrenKeys.json";
import childrenTypes from "./childrenTypes";

export const SEO_MODULE = {
  type: MODULE_KEYS.SEO,
  title: "SEO",
  children: [
    childrenTypes[CHILDREN_KEYS.SEO_TITLE],
    childrenTypes[CHILDREN_KEYS.SEO_PATH],
    childrenTypes[CHILDREN_KEYS.SEO_DESCRIPTION],
    childrenTypes[CHILDREN_KEYS.SEO_IMAGE],
    childrenTypes[CHILDREN_KEYS.SEO_KEYWORDS],
  ],
};

export const INTRO_MODULE = {
  type: MODULE_KEYS.INTRO,
  title: "Intro",
  children: [
    childrenTypes[CHILDREN_KEYS.INTRO_TITLE],
    childrenTypes[CHILDREN_KEYS.INTRO_SUBTITLE],
    childrenTypes[CHILDREN_KEYS.INTRO_IMAGE],
  ],
};

export const HTML_MODULE = {
  type: MODULE_KEYS.RAW_HTML,
  title: "HTML",
  children: [childrenTypes[CHILDREN_KEYS.HTML]],
};

export const GEAR_PIECE_MODULE = {
  type: MODULE_KEYS.GEAR_CARD,
  title: "Gear Card",
  children: [
    childrenTypes[CHILDREN_KEYS.GEAR_TYPE],
    childrenTypes[CHILDREN_KEYS.GEAR_ID],
    childrenTypes[CHILDREN_KEYS.HEADER],
    childrenTypes[CHILDREN_KEYS.PRODUCT_NAME],
    childrenTypes[CHILDREN_KEYS.DESCRIPTION],
  ],
};

export const GEAR_FILTER_MODULE = {
  type: MODULE_KEYS.FILTERED_GEAR,
  title: "Filtered Gear",
  children: [
    childrenTypes[CHILDREN_KEYS.HEADER],
    childrenTypes[CHILDREN_KEYS.LIMIT],
    childrenTypes[CHILDREN_KEYS.GEAR_TYPE],
    childrenTypes[CHILDREN_KEYS.GEAR_FILTER],
  ],
};
