import React from "react";

import ShareDataGridContainer from "./ShareDataGridContainer";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const ShareTableContainer = ({
  overridingUnit,
  tableData,
  tableIndex,
  tableId,
  handleSetTableData,
  affiliateLinksAreOff,
  isDarkMode,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  return (
    <>
      <Stack spacing={0}>
        <TextField
          defaultValue={tableData.title}
          key={tableData.title}
          size="small"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          sx={{
            "& .MuiInputBase-input": {
              fontSize: isMobile ? "30px" : "20px",
              height: isMobile ? "30px" : "20px",
            },
            "& fieldset": {
              borderColor: "#2F2F2F",
            },
            "&:hover": {
              borderColor: "#2F2F2F",
            },
          }}
        />
        {tableData.subtitle && tableData.subtitle.length ? (
          <TextField
            defaultValue={tableData.subtitle}
            key={tableData.subtitle}
            size="small"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              readOnly: true,
            }}
            sx={{
              width: "705px",
              "& .MuiInputBase-input": {
                fontSize: isMobile ? "24px" : "16px",
                paddingLeft: "0px",
                fontStyle: "italic",
                color: "#909090",
              },
              "& fieldset": {
                borderColor: "#2F2F2F",
              },
              "&:hover": {
                borderColor: "#2F2F2F",
              },
            }}
          />
        ) : (
          <></>
        )}
      </Stack>
      <ShareDataGridContainer
        tableRows={tableData.rows}
        tableIndex={tableIndex}
        tableWeightType={tableData.markedAsWeightType}
        packTitle={tableData.title}
        packSubtitle={tableData.subtitle}
        tableId={tableId}
        key={`${tableId}-${tableIndex}`}
        overridingUnit={overridingUnit}
        handleSetTableData={handleSetTableData}
        summaryRowUnit={tableData.summaryRowUnit || "oz"}
        affiliateLinksAreOff={affiliateLinksAreOff}
        isDarkMode={isDarkMode}
      />
    </>
  );
};

export default ShareTableContainer;
