import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  addItemToPackFromGearExplorer,
  getUserPackDataForAddToPackDialog,
} from "../utils";

const AddToPackDialog = ({
  item,
  addToPackMenuIsOpen,
  setAddToPackMenuIsOpen,
  setAddToPackSnackbarIsOpen,
}) => {
  const [userPackData, setUserPackData] = React.useState({});
  const [selectedPackId, setSelectedPackId] = React.useState("");
  const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState("");

  const handleSetSelectedPackIndex = (e) => {
    setSelectedPackId(e.target.value);
    setSelectedCategoryIndex("");
  };

  const handleAddItemToPack = () => {
    addItemToPackFromGearExplorer(
      selectedPackId,
      selectedCategoryIndex,
      item.id,
      item.type
    );
    setAddToPackSnackbarIsOpen(true);
  };

  const handleClose = () => {
    setSelectedPackId("");
    setSelectedCategoryIndex("");
    setAddToPackMenuIsOpen(false);
  };

  React.useEffect(() => {
    (async () => {
      if (addToPackMenuIsOpen) {
        const fetchedUserPackData = await getUserPackDataForAddToPackDialog();
        setUserPackData(fetchedUserPackData);
      }
    })();
  }, [addToPackMenuIsOpen]);

  return (
    <Dialog open={addToPackMenuIsOpen} onClose={handleClose}>
      <DialogTitle>Add item to Pack</DialogTitle>
      <DialogContent sx={{ height: "150px", width: "150px" }}>
        <Stack>
          <Box
            component="img"
            sx={{
              left: "50%",
              transform: "translate(-50%)",
              position: "absolute",
              maxHeight: 150,
              maxWidth: 150,
              width: "150px",
              height: "150px",
              objectFit: "cover",
            }}
            alt={`${item.brand} ${item.name}`}
            src={item.imageUrl}
          />
        </Stack>
      </DialogContent>
      <DialogContent sx={{ width: "400px", textAlign: "center" }}>
        <Typography>{`${item.brand} ${item.name}`}</Typography>
      </DialogContent>
      <DialogContent
        sx={{ width: "400px", marginTop: "-20px", textAlign: "center" }}
      >
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel>Select Pack</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={selectedPackId}
            onChange={handleSetSelectedPackIndex}
            input={<OutlinedInput label="Select Pack" />}
          >
            {Object.values(userPackData).map(({ packName, packId }, index) => (
              <MenuItem key={`${packName} - ${index}`} value={packId}>
                {packName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }} disabled={!selectedPackId}>
          <InputLabel>Select Category</InputLabel>
          <Select
            value={selectedCategoryIndex}
            onChange={(e) => {
              setSelectedCategoryIndex(e.target.value);
            }}
            input={<OutlinedInput label="Select Pack" />}
          >
            {selectedPackId
              ? userPackData[selectedPackId].categories.map(
                  (category, index) => (
                    <MenuItem key={`${category} - ${index}`} value={index}>
                      {category}
                    </MenuItem>
                  )
                )
              : null}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            handleAddItemToPack();
            handleClose();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToPackDialog;
