import { Paper, Link, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";

const BottomNav = ({ isSideNavOpen, hideAffiliateWarning }) => {
  return (
    <Paper
      sx={{
        marginLeft: isSideNavOpen ? "-240px" : "0px",
        width: `calc(100% + ${isSideNavOpen ? "240px" : "0px"})`,
        maxWidth: "1440px",
        minWidth: "500px",
      }}
    >
      {hideAffiliateWarning ? (
        <></>
      ) : (
        <Container
          sx={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          <Typography sx={{ paddingTop: "15px", color: "grey" }}>
            Pages may contain links to third party sites where a small portion
            of any sale will help support the continuing operation of this site,
            at no extra cost to you. We greatly appreciate your support!
          </Typography>
        </Container>
      )}
      <Container
        sx={{
          height: "50px",
          lineHeight: "50px",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{ width: "100%" }}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <Link sx={{ textDecoration: "none" }} href="/about">
            About Us
          </Link>
          <Link sx={{ textDecoration: "none" }} href="/contact">
            Contact Us
          </Link>
          <Link sx={{ textDecoration: "none" }} href="/privacy">
            Privacy Policy
          </Link>
          <Link sx={{ textDecoration: "none" }} href="/terms">
            Terms of Service
          </Link>
          <Link sx={{ textDecoration: "none" }} href="/article"></Link>
        </Stack>
      </Container>
    </Paper>
  );
};

export default BottomNav;
