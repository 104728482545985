import React from "react";
import Dialog from "@mui/material/Dialog";
import Container from "@mui/system/Container";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { getUserIsLoggedIn } from "../utils";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ImportDialog = ({
  importDialogIsOpen,
  setImportDialogIsOpen,
  handleImportFromLighterPack,
  darkMode,
}) => {
  const [lpItemColumn, setLpItemColumn] = React.useState("name");
  const [lpDescriptionColumn, setLpDescriptionColumn] =
    React.useState("description");
  const [importToGC, setImportToGc] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isValidLink, setIsValidLink] = React.useState(true);
  const [lighterpackLink, setLighterpackLink] = React.useState("");
  const [confirmImportDialogIsOpen, setConfirmImportDialogIsOpen] =
    React.useState(false);

  const handleConfirmDialogClose = () => {
    setConfirmImportDialogIsOpen(false);
  };

  const handleConfirmDialogAccept = async () => {
    setIsLoading(true);
    setConfirmImportDialogIsOpen(false);
    await handleImportFromLighterPack(
      lighterpackLink,
      lpItemColumn,
      lpDescriptionColumn,
      importToGC
    );
    setIsLoading(false);
  };

  const handleClose = () => {
    setImportDialogIsOpen(false);
  };

  const checkIsLinkValid = () => {
    return lighterpackLink.toLowerCase().includes("lighterpack.com/r/");
  };

  const handleOnImportClick = async () => {
    const isLinkValid = checkIsLinkValid();
    if (isLinkValid) {
      if (getUserIsLoggedIn()) {
        setIsLoading(true);
        await handleImportFromLighterPack(
          lighterpackLink,
          lpItemColumn,
          lpDescriptionColumn,
          importToGC
        );
        setIsLoading(false);
      } else {
        setConfirmImportDialogIsOpen(true);
      }
    } else setIsValidLink(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={importDialogIsOpen}
      TransitionComponent={Transition}
    >
      <Container sx={{ width: "500px", height: "520px" }}>
        <DialogTitle sx={{ marginLeft: "-25px" }}>
          Import from LighterPack
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px", marginLeft: "0px" }}>
          <Typography
            sx={{
              color: darkMode ? "#D0D0D0" : "#666",
              fontStyle: "italic",
              marginTop: "20px",
            }}
          >
            Enter the shareable LighterPack url for the pack you'd like to
            import
          </Typography>
          <TextField
            sx={{ width: "450px", marginTop: "5px" }}
            size="small"
            placeholder="example: lighterpack.com/r/0y9x6l"
            value={lighterpackLink}
            onChange={(e) => {
              setIsValidLink(true);
              setLighterpackLink(e.target.value);
            }}
            error={!isValidLink}
            helperText={isValidLink ? "" : "Not a valid lighterpack link"}
          />
          <FormControl sx={{ paddingTop: "10px" }}>
            <FormLabel>
              {
                "Which column does your lighterpack use for item names? (example: Hyperlite 2400)"
              }
            </FormLabel>
            <RadioGroup
              aria-labelledby="Which column does your lighterpack use for item names? (e.g Hyperlite 2400)"
              name="item names"
              sx={{ paddingLeft: "10px" }}
              value={lpItemColumn}
              onChange={(e) => {
                setLpItemColumn(e.target.value);
              }}
            >
              <FormControlLabel
                value="name"
                control={<Radio size="small" />}
                label="Name"
              />
              <FormControlLabel
                value="description"
                control={<Radio size="small" />}
                label="Description"
                sx={{ marginTop: "-5px" }}
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ paddingTop: "10px" }}>
            <FormLabel>
              {`What do you use your lighterpack's ${
                lpItemColumn === "name" ? '"description"' : '"name"'
              } column for?`}
            </FormLabel>
            <RadioGroup
              aria-labelledby="Which column does your lighterpack use for item names? (e.g Hyperlite 2400)"
              name="item names"
              value={lpDescriptionColumn}
              sx={{ paddingLeft: "10px" }}
              onChange={(e) => {
                setLpDescriptionColumn(e.target.value);
              }}
            >
              <FormControlLabel
                value="description"
                control={<Radio size="small" />}
                label={`A description of the item`}
                sx={{ marginTop: "-5px" }}
              />
              <FormControlLabel
                value="type"
                control={<Radio size="small" />}
                label="Item type (pack, quilt, water filter, etc.)"
              />
            </RadioGroup>
          </FormControl>
          {getUserIsLoggedIn() ? (
            <FormControlLabel
              sx={{ marginTop: "5px", paddingLeft: "10px" }}
              control={
                <Checkbox
                  size="small"
                  checked={importToGC}
                  onChange={() => setImportToGc(!importToGC)}
                />
              }
              label="Import items to your gear closet"
            />
          ) : (
            <></>
          )}
          {isLoading ? (
            <Box
              sx={{
                width: "50px",
                marginLeft: "calc(50% - 25px)",
                marginTop: "10px",
              }}
            >
              <CircularProgress sx={{ maxWidth: "30px", maxHeight: "30px" }} />
            </Box>
          ) : (
            <Button
              variant={darkMode ? "outlined" : "contained"}
              sx={{
                width: "150px",
                marginLeft: "calc(50% - 75px)",
                marginTop: "10px",
              }}
              onClick={handleOnImportClick}
            >
              Import
            </Button>
          )}
        </DialogContent>
      </Container>
      <Dialog
        open={confirmImportDialogIsOpen}
        onClose={handleConfirmDialogClose}
      >
        <DialogTitle id="alert-dialog-title">
          {"Overwrite local pack?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Because you are not logged in, importing this pack will overwrite
            your current pack.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Would you like to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>Cancel</Button>
          <Button onClick={handleConfirmDialogAccept} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ImportDialog;
