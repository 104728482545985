import React from "react";

import Button from "@mui/material/Button";

const SignUpButton = ({ handleSetAuthenticationIsOpen }) => (
  <Button
    sx={{ width: "100px" }}
    variant="contained"
    onClick={() => {
      handleSetAuthenticationIsOpen(true, "SIGNUP");
    }}
  >
    Sign Up
  </Button>
);

export default SignUpButton;
