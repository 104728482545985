import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import WEIGHT_TYPES from "../constants/weightTypes.json";
import DEFAULT_CATEGORIES from "../constants/defaultCategories";
import ItemLookupAutocomplete from "./ItemLookupAutocomplete";

const EMPTY_ITEM = {
  weight: 0,
  unit: "g",
  item: "",
  category: "",
  price: 0,
  weightType: WEIGHT_TYPES.BASE,
  imageUrl: "",
  url: "",
  description: "",
};

const createEmptyItem = (id, tableWeightType) => ({
  ...EMPTY_ITEM,
  weightType: tableWeightType,
  id,
});

const AddRowForm = ({
  setIsAddItemOpen,
  handleAddNewItemToTable,
  tableWeightType,
  darkMode,
}) => {
  const [itemToBeAdded, setItemToBeAdded] = React.useState(
    createEmptyItem(Date.now(), tableWeightType)
  );

  const handleUpdateItemToBeAdded = (key, value) => {
    setItemToBeAdded({
      ...itemToBeAdded,
      [key]: value,
    });
  };

  const handleSetItemWithAutocomplete = (item) => {
    if (!item) return;
    if (typeof item === "string") {
      setItemToBeAdded({
        ...itemToBeAdded,
        item,
      });
    } else {
      setItemToBeAdded({
        ...itemToBeAdded,
        brand: item.brand,
        item: item.item,
        weight: item.displayWeight,
        description: item.description,
        unit: item.unit,
        category: item.category,
        price: item.price,
        url: item.url,
        imageUrl: item.imageUrl ? `${item.imageUrl}` : "",
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "-20px",
        marginLeft: "-25px",
      }}
    >
      <Stack direction="row" justifyContent="flex-end">
        <IconButton color="primary" onClick={() => setIsAddItemOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack spacing={2} alignItems="center">
        <Stack direction="row" spacing={2} sx={{ marginTop: "10px" }}>
          <ItemLookupAutocomplete
            handleSetItemWithAutocomplete={handleSetItemWithAutocomplete}
            handleUpdateItemToBeAdded={handleUpdateItemToBeAdded}
            isGearCloset
          />
          <TextField
            label="Brand"
            size="small"
            sx={{ width: "175px" }}
            value={itemToBeAdded.brand}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              handleUpdateItemToBeAdded("brand", event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Autocomplete
            freeSolo
            sx={{ width: "160px" }}
            disableClearable
            options={DEFAULT_CATEGORIES.map((option) => ({
              id: option,
              label: option,
            }))}
            value={itemToBeAdded.category}
            size="small"
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onChange={(event, value) => {
              handleUpdateItemToBeAdded("category", value.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Item Type"
                onChange={(event) => {
                  handleUpdateItemToBeAdded("category", event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ marginTop: "10px" }}>
          <TextField
            label="Link"
            size="small"
            value={itemToBeAdded.url}
            sx={{ width: "292px" }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              handleUpdateItemToBeAdded("url", e.target.value);
            }}
          />
          <TextField
            label="Weight"
            size="small"
            type="number"
            sx={{ width: "80px" }}
            value={itemToBeAdded.weight}
            onChange={(event) => {
              const newVal =
                event.target.value < 0
                  ? (event.target.value = 0)
                  : event.target.value;
              handleUpdateItemToBeAdded("weight", newVal);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl sx={{ width: "65px" }} size="small">
            <InputLabel>Unit</InputLabel>
            <Select
              value={itemToBeAdded.unit}
              label="Unit"
              onChange={(event) => {
                handleUpdateItemToBeAdded("unit", event.target.value);
              }}
            >
              <MenuItem value="g">g</MenuItem>
              <MenuItem value="oz">oz</MenuItem>
              <MenuItem value="lb">lb</MenuItem>
              <MenuItem value="kg">kg</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Price"
            size="small"
            type="number"
            sx={{ width: "125px" }}
            value={itemToBeAdded.price}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(event) => {
              const newVal =
                event.target.value < 0
                  ? (event.target.value = 0)
                  : event.target.value;
              handleUpdateItemToBeAdded("price", newVal);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>
        <Stack spacing={2}>
          <TextField
            label="Description"
            multiline
            size="small"
            rows={3}
            sx={{ width: "608px" }}
            value={itemToBeAdded.description}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onChange={(e) => {
              handleUpdateItemToBeAdded("description", e.target.value);
            }}
          />
          <TextField
            label="Image Link"
            size="small"
            sx={{ width: "608px" }}
            value={itemToBeAdded.imageUrl}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              handleUpdateItemToBeAdded("imageUrl", e.target.value);
            }}
          />
          <Button
            sx={{
              marginLeft: "160px !important",
              width: "300px",
            }}
            variant={darkMode ? "outlined" : "contained"}
            onClick={() => {
              setIsAddItemOpen(false);
              handleAddNewItemToTable(itemToBeAdded);
              setItemToBeAdded(createEmptyItem(itemToBeAdded.id + 1));
            }}
          >
            Add Item to Gear Closet
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddRowForm;
