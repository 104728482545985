import * as React from "react";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { handleRequestPasswordChange } from "../auth";
import { isValidEmail } from "../utils/validationUtils";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const RequestForgotPasswordForm = ({ setForgotPasswordFormIsOpen }) => {
  const [requestSent, setRequestSent] = React.useState(false);
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [values, setValues] = React.useState({
    email: "",
  });

  const handleChange = (prop) => (event) => {
    setInvalidEmail(false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickSignIn = () => {
    setForgotPasswordFormIsOpen(false);
  };

  const handleBack = () => {
    setRequestSent(false);
  };

  const handleSubmit = async () => {
    const { email } = values;
    if (isValidEmail(email)) {
      await handleRequestPasswordChange(values);
      setRequestSent(true);
    } else {
      setInvalidEmail(true);
    }
  };

  return (
    <>
      <DialogTitle
        style={{
          width: "100%",
          textAlign: "center",
          paddingTop: "30px",
          fontWeight: "bold",
        }}
      >
        Forgot your password?
      </DialogTitle>
      {requestSent ? (
        <DialogContent
          style={{ width: "420px", height: "250px", paddingTop: "30px" }}
        >
          <DialogContentText
            style={{ width: "360px", fontSize: "14px", marginLeft: "10px" }}
          >
            An email has been sent to <b>{values.email}</b> with further
            instructions to reset your password.
          </DialogContentText>
          <Button
            variant="text"
            style={{ width: "360px", marginLeft: "5px", marginTop: "20px" }}
            onClick={() => handleSubmit()}
          >
            Didnt Receive it? Send another Email
          </Button>
          <Button
            variant="filled"
            style={{ float: "right", marginTop: "20px" }}
            endIcon={<KeyboardReturnIcon />}
            onClick={handleBack}
          >
            Back
          </Button>
        </DialogContent>
      ) : (
        <DialogContent
          style={{ width: "360px", height: "360px", paddingTop: "30px" }}
        >
          <DialogContentText
            style={{
              fontSize: "14px",
              marginTop: "-10px",
              marginLeft: "10px",
            }}
          >
            Please enter the email you use to sign in to PackWizard
          </DialogContentText>
          <FormControl
            sx={{ m: 1, width: "25ch", marginTop: "30px" }}
            variant="standard"
          >
            <TextField
              required
              error={invalidEmail}
              label="Email"
              defaultValue=""
              style={{ width: "296px" }}
              variant="standard"
              onChange={handleChange("email")}
            />
            <Button
              variant="contained"
              style={{ width: "296px", marginLeft: "5px", marginTop: "20px" }}
              onClick={() => handleSubmit()}
            >
              Send Reset Link
            </Button>
            <Button
              type="submit"
              variant="text"
              style={{
                width: "296px",
                marginLeft: "5px",
                textAlign: "center",
                marginTop: "60px",
                color: "white",
                textTransform: "none",
              }}
              onClick={handleClickSignIn}
            >
              Remember your password? Login
            </Button>
          </FormControl>
        </DialogContent>
      )}
    </>
  );
};

export default RequestForgotPasswordForm;
