import { Container } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../constants/colors";
import { fetchLinkManagerData } from "../utils";
import GearManagerItem from "./GearManagerItem";
import AdminReportViewer from "./AdminTools/AdminReportViewer";
import {
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { gearSpecMap } from "./AdminTools/gearSpecMap";
import AdminAddItemModule from "./AdminTools/AdminAddItemModule";
import AdminBulkAddModule from "./AdminTools/AdminBulkAddModule";
import TablePagination from "@mui/material/TablePagination";
import { debounce } from "lodash";

const GearManagerContainer = ({ darkMode }) => {
  const [data, setData] = React.useState(null);
  const [filteredData, setFilteredData] = React.useState([]);
  const [chosenCategory, setChosenCategory] = React.useState("tents");
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchText, setSearchText] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    if (data && data.items) {
      const itemNameKey = chosenCategory === "autocomplete" ? "item" : "name";
      const filteredItems = data.items.filter((item) =>
        `${item.brand} ${item[itemNameKey]}`
          .toLowerCase()
          .replaceAll(" ", "")
          .includes(searchText.toLowerCase().replaceAll(" ", ""))
      );
      setFilteredData(filteredItems);
    }
  };

  const handleDebouncedSearch = debounce(handleSearch, 300);

  React.useEffect(() => {
    (async () => {
      const adminData = await fetchLinkManagerData(chosenCategory);
      setData(adminData);
      setFilteredData(adminData.items);
      setIsLoading(false);
      setSearchText("");
    })();
  }, []);

  const handleChangeCategory = async (category) => {
    setIsLoading(true);
    setChosenCategory(category);
    const adminData = await fetchLinkManagerData(category);
    setData(adminData);
    setFilteredData(adminData.items);
    setIsLoading(false);
    setSearchText("");
  };
  return (
    <Container
      sx={{
        minWidth: "360px",
        width: "100%",
        maxWidth: "1440px",
        minHeight: "100vh",
        backgroundColor: getColorWithMode(darkMode, "base"),
        padding: "0px 0px 0px 0px",
        paddingBottom: "20px",
        paddingTop: "20px",
      }}
    >
      <Container>
        <Stack direction="row" justifyContent="space-between">
          <ToggleButtonGroup
            color="primary"
            value={chosenCategory}
            exclusive
            onChange={(e) => {
              handleChangeCategory(e.target.value);
            }}
          >
            <ToggleButton value="tents">Tents</ToggleButton>
            <ToggleButton value="sleepingBags">Sleeping Bags</ToggleButton>
            <ToggleButton value="packs">Packs</ToggleButton>
            <ToggleButton value="sleepingPads">Sleeping Pads</ToggleButton>
            <ToggleButton value="insulatedJackets">
              Insulated Jackets
            </ToggleButton>
            <ToggleButton value="autocomplete">Autocomplete</ToggleButton>
          </ToggleButtonGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <AdminAddItemModule />
            <AdminBulkAddModule />
            <AdminReportViewer />
          </Stack>
        </Stack>
      </Container>
      <Container>
        <Stack
          direction="row"
          sx={{ height: "60px" }}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              defaultValue={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                handleDebouncedSearch();
              }}
              value={searchText}
              sx={{ width: "350px" }}
              size="small"
              placeholder="search"
            />
          </Stack>
          <Stack sx={{ height: "80px" }} justifyContent="end">
            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </Stack>
      </Container>
      {data ? (
        <Container
          sx={{
            textAlign: "center",
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <>Loading...</>
          ) : (
            filteredData
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((item) => {
                return (
                  <GearManagerItem
                    category={chosenCategory}
                    key={`${chosenCategory}-${item._id}`}
                    item={item}
                    specMap={gearSpecMap[chosenCategory]}
                  />
                );
              })
          )}
        </Container>
      ) : (
        <Container sx={{ textAlign: "center" }}>
          You do not have permission to see this
        </Container>
      )}
    </Container>
  );
};

export default GearManagerContainer;
