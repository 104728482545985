import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { interpolateColors } from "../../utils/colorGenerator";
import { interpolateTurbo } from "d3-scale-chromatic";
import { useMediaQuery } from "react-responsive";

const colorScale = interpolateTurbo;
const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: true,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getDateArray = () => {
  const endDate = new Date();
  const startDateTime = new Date();
  startDateTime.setDate(startDateTime.getDate() - 365);
  const startDate = new Date(startDateTime);
  const dateArray = [];
  while (startDate <= endDate) {
    dateArray.push(new Date(startDate).toISOString().split("T")[0]);
    startDate.setDate(startDate.getDate() + 1);
  }
  return dateArray;
};

const getDataFromPricesAndLabels = (priceHistory, labels) => {
  const firstLabelAsNumber = Number(labels[0].replaceAll("-", ""));
  let priceHistoryPointer = priceHistory.findIndex(({ date }) => {
    const cleanDateAsNumber = Number(date.split("T")[0].replaceAll("-", ""));
    return cleanDateAsNumber >= firstLabelAsNumber;
  });

  if (priceHistoryPointer === -1) {
    return [];
  }
  const dataArray = [];

  for (let i = 0; i < labels.length; i++) {
    // We are at the last value so just start using this value
    if (priceHistoryPointer === priceHistory.length - 1) {
      dataArray.push(priceHistory[priceHistoryPointer].salePrice);
    } else {
      const nextPointerDate = Number(
        priceHistory[priceHistoryPointer + 1].date
          .split("T")[0]
          .replaceAll("-", "")
      );
      if (nextPointerDate <= Number(labels[i].replaceAll("-", ""))) {
        priceHistoryPointer++;
      }
      dataArray.push(priceHistory[priceHistoryPointer].salePrice);
    }
  }
  return dataArray;
};

const getOptions = (darkMode, isDesktopOrLaptop) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: darkMode ? "white" : "black",
          font: {
            size: isDesktopOrLaptop ? 14 : 18,
          },
        },
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    scales: {
      y: {
        ticks: {
          color: darkMode ? "white" : "black",
          font: {
            size: isDesktopOrLaptop ? 14 : 18,
          },
          stepSize: 1,
          beginAtZero: true,
          callback: (label) => `$${label}`,
        },
      },
      x: {
        ticks: {
          color: darkMode ? "white" : "black",
          font: {
            size: isDesktopOrLaptop ? 12 : 16,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
  };
};

const PriceHistory = ({ retailers, darkMode }) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const labels = getDateArray();
  const datasets = retailers.map((retailer, index) => {
    const label = retailer.retailer;
    const data = getDataFromPricesAndLabels(retailer.priceHistory, labels);
    const colors = interpolateColors(
      retailers.length,
      colorScale,
      colorRangeInfo
    );
    const borderColor = colors[index];
    const backgroundColor = colors[index];
    return {
      label,
      borderColor,
      backgroundColor,
      data,
      pointRadius: 0.5,
    };
  });

  const data = { labels, datasets };

  return <Line options={getOptions(darkMode, isDesktopOrLaptop)} data={data} />;
};

export default PriceHistory;
