import { getLocalUser } from "../utils";

export const getDefaultPack = async () => {
  const user = getLocalUser();
  return fetch("/api/packs/defaultPack", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPackList = async () => {
  const user = getLocalUser();
  return fetch("/api/packs/packList", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserPackDataForAddToPackDialog = async () => {
  const user = getLocalUser();
  return fetch("/api/packs/userPackDataForAddToPackDialog", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePack = async ({ packId, packData }) => {
  const user = getLocalUser();
  return fetch("/api/packs/updatePack", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PUT",
    body: JSON.stringify({ packId, packData }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const createNewPack = async () => {
  const user = getLocalUser();
  return fetch("/api/packs/createPack", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const importLocalPack = async ({ packName, data }) => {
  const user = getLocalUser();
  return fetch("/api/packs/importLocalPack", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ packName, data }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPackWithId = async ({ packId }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/getPackWithId?packId=${packId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const deletePackWithId = async ({ packId }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/delete?packId=${packId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "DELETE",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const setUserDefaultPack = async ({ packId }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/setDefault?packId=${packId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const importFromLighterPack = async ({ url, settings }) => {
  const user = getLocalUser();
  return fetch("/api/packs/importFromLighterPack", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ url, settings }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const importFromLighterPackWhileSignedOut = async ({
  url,
  settings,
}) => {
  return fetch(
    `/api/packs/importFromLighterPack?url=${url}&itemColumn=${settings.itemColumn}&descriptionColumn=${settings.descriptionColumn}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSharedPackWithId = async ({ id }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/getSharedPackWithId?shareableId=${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user ? `Bearer ${user.token}` : null,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPackDisplayData = async ({ shareId }) => {
  return fetch(`/api/packs/packDataDisplay?shareId=${shareId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const generateShareableLink = async ({ packId }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/generateShareableLink?packId=${packId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePackUnit = async ({ packId, unit }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/updatePackUnit?packId=${packId}&packUnit=${unit}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const duplicatePackWithId = async ({ id }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/duplicatePackWithId?packId=${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePackToMetric = async ({ packId }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/updatePackToMetric?packId=${packId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePackToImperial = async ({ packId }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/updatePackToImperial?packId=${packId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const handleUserPackVote = async ({ packId, voteType }) => {
  const user = getLocalUser();
  return fetch(`/api/packs/vote`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ packId, voteType }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const addItemToPackFromGearExplorer = async ({
  packId,
  categoryIndex,
  itemId,
  gearType,
}) => {
  const user = getLocalUser();
  return fetch(`/api/packs/addItemToPackFromGearExplorer`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ packId, categoryIndex, itemId, gearType }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateUsersPackSettings = async ({
  packId,
  packVisibility,
  packSettings,
}) => {
  const user = getLocalUser();
  return fetch(`/api/packs/updatePackSettings`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ packId, packVisibility, packSettings }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPacksForPackExplorer = async () => {
  const user = getLocalUser();
  return fetch(`/api/packs/explore`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user ? `Bearer ${user.token}` : null,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
