import React from "react";

import { getWeightPercentage, getWeightInUnitFromGrams } from "../utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import GridVisualization from "./GridVisualization";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import { useMediaQuery } from "react-responsive";
import { BottomNavigation, BottomNavigationAction, Stack } from "@mui/material";

const PackVisualizationTableCarousel = ({
  weightsInGrams,
  unit,
  colorArray,
  isDesktopOrLaptop,
  packDataArray,
  categoryDataArray,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [activeStep, setActiveStep] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  return packDataArray.length || categoryDataArray.length ? (
    <Stack>
      <Box sx={{ height: isMobile ? "275px" : "180px" }}>
        {activeStep === 0 ? (
          <Container
            sx={{
              height: isMobile ? "275px" : "220px",
              width: isMobile ? "340px" : "240px",
              marginTop: "10px",
            }}
          >
            <Container
              sx={{
                marginLeft: "-25px",
                height: isMobile ? "235px" : "190px",
                width: isMobile ? "400px" : "255px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <TableContainer
                variant="standard"
                size="small"
                sx={{
                  borderBottom: "none",
                  fontSize: isMobile ? "20px" : "10px",
                  width: isMobile ? "360px" : "225px",
                  marginTop: "10px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ padding: "0px 0px 0px 0px" }}>
                      <TableCell
                        sx={{ padding: "0px 0px 0px 0px", width: "15px" }}
                      />
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "18px" : "13px",
                        }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "18px" : "13px",
                        }}
                        align="right"
                      >
                        Weight
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "18px" : "13px",
                        }}
                        align="right"
                      >
                        %
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{ padding: "0px 0px 0px 0px", width: "15px" }}
                      />
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        Base
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {`${Number(
                          getWeightInUnitFromGrams(
                            weightsInGrams.baseInGrams,
                            unit
                          )
                        ).toFixed(2)} ${unit}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {getWeightPercentage(
                          weightsInGrams.baseInGrams,
                          weightsInGrams.weightInGrams
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ padding: "0px 0px 0px 0px", width: "15px" }}
                      />
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        Worn
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {`${Number(
                          getWeightInUnitFromGrams(
                            weightsInGrams.wornInGrams,
                            unit
                          )
                        ).toFixed(2)} ${unit}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {getWeightPercentage(
                          weightsInGrams.wornInGrams,
                          weightsInGrams.weightInGrams
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ padding: "0px 0px 0px 0px", width: "15px" }}
                      />
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        Consumables
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {`${Number(
                          getWeightInUnitFromGrams(
                            weightsInGrams.consumableInGrams,
                            unit
                          )
                        ).toFixed(2)} ${unit}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {getWeightPercentage(
                          weightsInGrams.consumableInGrams,
                          weightsInGrams.weightInGrams
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          paddingTop: "1px",
                        },
                      }}
                    >
                      <TableCell
                        sx={{ padding: "0px 0px 0px 0px", width: "15px" }}
                      />
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {`${Number(
                          getWeightInUnitFromGrams(
                            weightsInGrams.weightInGrams,
                            unit
                          )
                        ).toFixed(2)} ${unit}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        100.00%
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Container>
        ) : (
          <></>
        )}
        {activeStep === 1 ? (
          <Container>
            {categoryDataArray.length ? (
              <GridVisualization
                packDataArray={categoryDataArray}
                colorArray={colorArray}
                totalWeightInGrams={weightsInGrams.weightInGrams}
                isDesktopOrLaptop={isDesktopOrLaptop}
                unit={unit}
              />
            ) : (
              <></>
            )}
          </Container>
        ) : (
          <></>
        )}
        {activeStep === 2 ? (
          <Container>
            {packDataArray.length ? (
              <GridVisualization
                packDataArray={packDataArray}
                colorArray={colorArray}
                totalWeightInGrams={weightsInGrams.weightInGrams}
                isItem
                isDesktopOrLaptop={isDesktopOrLaptop}
                unit={unit}
              />
            ) : (
              <></>
            )}
          </Container>
        ) : (
          <></>
        )}
      </Box>
      <BottomNavigation
        showLabels
        sx={{
          height: isMobile ? "50px" : "35px",
          width: isMobile ? "380px" : "240px",
          backgroundColor: "inherit",
        }}
        value={activeStep}
        onChange={(event, newValue) => {
          setActiveStep(newValue);
        }}
      >
        <BottomNavigationAction label="Base" />
        <BottomNavigationAction label="Categories" />
        <BottomNavigationAction label="Items" />
      </BottomNavigation>
    </Stack>
  ) : (
    <></>
  );
};

export default PackVisualizationTableCarousel;
