import React from "react";

import { Button, Container, Divider, Slider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { getColorWithMode } from "../../constants/colors";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery } from "react-responsive";

const formatBrand = (brand) => {
  if (brand === "Nemo" || brand === "nemo") return "NEMO";
  if (brand === "thermarest") return "Therm-a-Rest";
  if (brand === "rei") return "REI Co-op";
  if (brand === "north face") return "The North Face";
  if (brand === "hyperlite") return "Hyperlite Mountain Gear";
  if (brand === "msr") return "MSR";
  if (brand === "3ful") return "3FUL";
  if (brand === "ula") return "ULA";
  if (brand === "swd") return "SWD";
  if (brand === "liteaf") return "LiteAF";
  return brand
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

const BackpackFilterBar = ({
  darkMode,
  baseFilters,
  userFilters,
  setUserFilters,
  useMetric,
  setUseMetric,
  setUseMetricVolume,
  useMetricVolume,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  if (!baseFilters) return <></>;

  const handleChangeFilter = (type, value, toggled) => {
    const newFilters = { ...userFilters };
    if (type === "price") {
      newFilters.price = value;
    } else if (type === "weight") {
      newFilters.weight = value;
    } else if (type === "maxLoad") {
      newFilters.maxLoad = value;
    } else if (type === "brand") {
      if (toggled) {
        const brandSet = new Set(newFilters.brand);
        brandSet.add(value);
        newFilters.brand = [...brandSet];
      } else {
        const brandSet = new Set(newFilters.brand);
        brandSet.delete(value);
        newFilters.brand = [...brandSet];
      }
    } else if (type === "volume") {
      newFilters.volume = value;
    } else if (type === "framed") {
      if (toggled) {
        const framedSet = new Set(newFilters.framed);
        framedSet.delete(value);
        newFilters.framed = [...framedSet];
      } else {
        if (!newFilters.framed || newFilters.framed.length === 0) {
          newFilters.framed = [value];
        } else {
          newFilters.framed.push(value);
        }
      }
    } else if (type === "adjustableTorso") {
      if (toggled) {
        const adjustableTorsoSet = new Set(newFilters.adjustableTorso);
        adjustableTorsoSet.delete(value);
        newFilters.adjustableTorso = [...adjustableTorsoSet];
      } else {
        if (
          !newFilters.adjustableTorso ||
          newFilters.adjustableTorso.length === 0
        ) {
          newFilters.adjustableTorso = [value];
        } else {
          newFilters.adjustableTorso.push(value);
        }
      }
    }
    setUserFilters(newFilters);
  };

  const weightMarks = [
    {
      value: baseFilters.weights.min,
      label: useMetric
        ? `${baseFilters.weights.min}g`
        : `${Math.floor(baseFilters.weights.min / 28)}oz`,
    },
    {
      value: baseFilters.weights.max,
      label: useMetric
        ? `${(baseFilters.weights.max / 1000).toFixed(2)}kg`
        : `${Math.floor(baseFilters.weights.max / 28)}oz`,
    },
  ];

  const priceMarks = [
    {
      value: baseFilters.prices.min,
      label: `$${baseFilters.prices.min}`,
    },
    {
      value: baseFilters.prices.max,
      label: `$${baseFilters.prices.max}`,
    },
  ];

  const volumeMarks = [
    {
      value: baseFilters.volume.min,
      label: useMetricVolume
        ? `${baseFilters.volume.min}L`
        : `${Math.floor(Number(baseFilters.volume.min) * 61.024)}cu in`,
    },
    {
      value: baseFilters.volume.max,
      label: useMetricVolume
        ? `${baseFilters.volume.max}L`
        : `${Math.floor(Number(baseFilters.volume.max) * 61.024)}cu in`,
    },
  ];

  const maxLoadMarks = [
    {
      value: baseFilters.maxLoad.min,
      label: useMetric
        ? `${((baseFilters.maxLoad.min * 454) / 1000).toFixed(1)}kg`
        : `${baseFilters.maxLoad.min}lb`,
    },
    {
      value: baseFilters.maxLoad.max,
      label: useMetric
        ? `${((baseFilters.maxLoad.max * 454) / 1000).toFixed(1)}kg`
        : `${baseFilters.maxLoad.max}lb`,
    },
  ];

  return (
    <Container
      sx={{
        backgroundColor: `${getColorWithMode("base", darkMode)} !important`,
        width: "100%",
      }}
    >
      <Stack
        direction={isMobile ? "row" : "column"}
        sx={{ width: "100%" }}
        justifyContent={"space-between"}
      >
        <Box sx={{ minWidth: "200px" }}>
          <FormControl
            sx={{ width: "100%", paddingBottom: "15px" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Settings</FormLabel>
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetric}
                  onChange={() => setUseMetric(!useMetric)}
                />
              }
              label="Metric Weights"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetricVolume}
                  onChange={() => setUseMetricVolume(!useMetricVolume)}
                />
              }
              label="Metric Volume"
            />
          </FormControl>
        </Box>
        <Stack>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Price</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.prices}
                valueLabelFormat={(value) => `$${value}`}
                defaultValue={[baseFilters.prices.min, baseFilters.prices.max]}
                marks={priceMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("price", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            {" "}
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Volume</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) =>
                  useMetricVolume
                    ? `${value}L`
                    : `${Math.floor(Number(value) * 61.024)}cu in`
                }
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.volume}
                step={1}
                defaultValue={[baseFilters.volume.min, baseFilters.volume.max]}
                marks={volumeMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("volume", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            {" "}
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Weight</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) =>
                  useMetric ? `${value}g` : `${Math.floor(value / 28)} oz`
                }
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.weights}
                defaultValue={[
                  baseFilters.weights.min,
                  baseFilters.weights.max,
                ]}
                marks={weightMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("weight", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            {" "}
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Max Load</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) =>
                  useMetric ? `${(value * 454) / 1000}kg` : `${value}lbs`
                }
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.maxLoad}
                defaultValue={[
                  baseFilters.maxLoad.min,
                  baseFilters.maxLoad.max,
                ]}
                marks={maxLoadMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("maxLoad", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            {" "}
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Adjustable Torso</FormLabel>
              <Divider />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked name="yes" />}
                  label="Yes"
                  onChange={(e, value) => {
                    handleChangeFilter("adjustableTorso", "yes", value);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked name="no" />}
                  label="No"
                  onChange={(e, value) => {
                    handleChangeFilter("adjustableTorso", "no", value);
                  }}
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            {" "}
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Frame</FormLabel>
              <Divider />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked name="framed" />}
                  label="Framed"
                  onChange={(e, value) => {
                    handleChangeFilter("framed", "framed", value);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked name="frameless" />}
                  label="Frameless"
                  onChange={(e, value) => {
                    handleChangeFilter("framed", "frameless", value);
                  }}
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Stack>
        <Box sx={{ minWidth: "200px" }}>
          <FormControl
            sx={{ width: "100%" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Brand</FormLabel>
            <Divider />
            <Button
              sx={{
                justifyContent: "left",
                paddingLeft: "0px",
                width: "150px",
                "&:hover": {
                  opacity: 0.8,
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => {
                const newFilters = { ...userFilters };
                if (userFilters.brand.length !== baseFilters.brands.length) {
                  newFilters.brand = baseFilters.brands;
                } else {
                  newFilters.brand = [];
                }
                setUserFilters(newFilters);
              }}
            >
              {userFilters.brand.length !== baseFilters.brands.length
                ? "Select All"
                : "Deselect All"}
            </Button>
            <FormGroup>
              {baseFilters.brands.map((brand) => (
                <FormControlLabel
                  key={`${brand}-brand-filter`}
                  control={
                    <Checkbox
                      checked={userFilters.brand.includes(brand)}
                      name={brand}
                    />
                  }
                  label={formatBrand(brand)}
                  onChange={(e, value) => {
                    handleChangeFilter("brand", brand, value);
                  }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Stack>
    </Container>
  );
};

export default BackpackFilterBar;
