import {
  Autocomplete,
  Button,
  Container,
  Divider,
  Slider,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../constants/colors";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  DURATION_SETTINGS,
  SEASON_SETTINGS,
  TAG_LIST,
  TRAIL_LIST,
} from "../constants/packSettings";

const PackExploreFilterBar = ({
  darkMode,
  useMetric,
  setUseMetric,
  baseFilters,
  userFilters,
  setUserFilters,
  handleChangeSearchText,
}) => {
  if (!baseFilters) return <></>;

  const handleChangeFilter = (type, value, toggled) => {
    const newFilters = { ...userFilters };
    if (type === "price") {
      newFilters.price = value;
    } else if (type === "weight") {
      newFilters.weight = value;
    } else if (type === "durations" || type === "seasons" || type === "tags") {
      if (toggled) {
        const filterSet = new Set(newFilters[type]);
        filterSet.add(value);
        newFilters[type] = [...filterSet];
      } else {
        const filterSet = new Set(newFilters[type]);
        filterSet.delete(value);
        newFilters[type] = [...filterSet];
      }
    }
    setUserFilters(newFilters);
  };

  const weightMarks = [
    {
      value: baseFilters.weights.min,
      label: useMetric
        ? `${baseFilters.weights.min}g`
        : `${Math.floor(baseFilters.weights.min / 454)}lbs`,
    },
    {
      value: baseFilters.weights.max,
      label: useMetric
        ? "5kg+"
        : `${Math.floor(baseFilters.weights.max / 454)}lbs+`,
    },
  ];

  const priceMarks = [
    {
      value: baseFilters.prices.min,
      label: `$${baseFilters.prices.min}`,
    },
    {
      value: baseFilters.prices.max,
      label: `$${baseFilters.prices.max}+`,
    },
  ];

  return (
    <Container
      sx={{
        backgroundColor: `${getColorWithMode("base", darkMode)} !important`,
        height: "100%",
      }}
    >
      <Box>
        <FormControl
          sx={{ width: "100%", paddingBottom: "15px" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">Settings</FormLabel>
          <Divider />
          <FormControlLabel
            control={
              <Checkbox
                checked={useMetric}
                onChange={() => setUseMetric(!useMetric)}
              />
            }
            label="Metric Weights"
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl
          sx={{ width: "100%", paddingBottom: "15px" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">Price</FormLabel>
          <Divider />
          <Slider
            valueLabelDisplay="auto"
            sx={{
              marginLeft: "10px",
              marginTop: "10px",
              width: "calc(100% - 20px)",
            }}
            size="small"
            {...baseFilters.prices}
            valueLabelFormat={(value) => {
              if (value === 5000) return "$5,000+";
              return `$${value}`;
            }}
            defaultValue={[baseFilters.prices.min, baseFilters.prices.max]}
            marks={priceMarks}
            onChangeCommitted={(e, value) => {
              handleChangeFilter("price", value);
            }}
          />
        </FormControl>
      </Box>
      <Box sx={{ paddingTop: "7px" }}>
        <FormControl
          sx={{ width: "100%", paddingBottom: "15px" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">Weight</FormLabel>
          <Divider />
          <Slider
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => {
              if (value === 5000) {
                return useMetric ? `5kg+` : `${(value / 454).toFixed(1)}lbs+`;
              }
              return useMetric ? `${value}g` : `${(value / 454).toFixed(1)}lbs`;
            }}
            sx={{
              marginLeft: "10px",
              marginTop: "10px",
              width: "calc(100% - 20px)",
            }}
            size="small"
            {...baseFilters.weights}
            defaultValue={[baseFilters.weights.min, baseFilters.weights.max]}
            marks={weightMarks}
            onChangeCommitted={(e, value) => {
              handleChangeFilter("weight", value);
            }}
          />
        </FormControl>
      </Box>
      <Box sx={{ paddingTop: "7px" }}>
        <FormControl
          sx={{ width: "100%" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">Search</FormLabel>
          <Divider />
          <Stack
            spacing={1}
            sx={{
              marginTop: "10px",
              padding: "0px 0px 0px 0px",
              width: "100%",
            }}
          >
            <TextField
              size="small"
              sx={{
                paddingBottom: "5px",
                width: "100%",
              }}
              label="Name/Username"
              onChange={(e) => {
                handleChangeSearchText("packNameSearchText", e.target.value);
              }}
            />
            <Autocomplete
              size="small"
              freeSolo
              options={TRAIL_LIST.map((option) => option)}
              onChange={(e, value) => {
                handleChangeSearchText("packLocationSearchText", value);
              }}
              onInputChange={(e, value) => {
                handleChangeSearchText("packLocationSearchText", value);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Trail or Location" />
              )}
            />
          </Stack>
        </FormControl>
      </Box>
      <Box sx={{ paddingTop: "7px" }}>
        <FormControl
          sx={{ width: "100%" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">Season</FormLabel>
          <Divider />
          <Button
            sx={{
              justifyContent: "left",
              paddingLeft: "0px",
              width: "150px",
              "&:hover": {
                opacity: 0.8,
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              const newFilters = { ...userFilters };
              if (userFilters.seasons.length !== baseFilters.seasons.length) {
                newFilters.seasons = baseFilters.seasons;
              } else {
                newFilters.seasons = [];
              }
              setUserFilters(newFilters);
            }}
          >
            {userFilters.seasons.length !== baseFilters.seasons.length
              ? "Select All"
              : "Deselect All"}
          </Button>
          <FormGroup>
            {Object.keys(SEASON_SETTINGS).map((season) =>
              season === "NONE" ? (
                <></>
              ) : (
                <FormControlLabel
                  key={`${season}-season-filter`}
                  control={
                    <Checkbox
                      checked={userFilters.seasons.includes(season)}
                      name={season}
                    />
                  }
                  label={SEASON_SETTINGS[season]}
                  onChange={(e, value) => {
                    handleChangeFilter("seasons", season, value);
                  }}
                />
              )
            )}
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={{ paddingTop: "7px" }}>
        <FormControl
          sx={{ width: "100%" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">Tags</FormLabel>
          <Divider />
          <Button
            sx={{
              justifyContent: "left",
              paddingLeft: "0px",
              width: "150px",
              "&:hover": {
                opacity: 0.8,
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              const newFilters = { ...userFilters };
              if (userFilters.tags.length !== baseFilters.tags.length) {
                newFilters.tags = baseFilters.tags;
              } else {
                newFilters.tags = [];
              }
              setUserFilters(newFilters);
            }}
          >
            {userFilters.tags.length !== baseFilters.tags.length
              ? "Select All"
              : "Deselect All"}
          </Button>
          <FormGroup>
            {TAG_LIST.map((tag) => (
              <FormControlLabel
                key={`${tag}-tag-filter`}
                control={
                  <Checkbox
                    checked={userFilters.tags.includes(tag)}
                    name={tag}
                  />
                }
                label={tag}
                onChange={(e, value) => {
                  handleChangeFilter("tags", tag, value);
                }}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={{ paddingTop: "7px" }}>
        <FormControl
          sx={{ width: "100%" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">Duration</FormLabel>
          <Divider />
          <Button
            sx={{
              justifyContent: "left",
              paddingLeft: "0px",
              width: "150px",
              "&:hover": {
                opacity: 0.8,
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              const newFilters = { ...userFilters };
              if (
                userFilters.durations.length !== baseFilters.durations.length
              ) {
                newFilters.durations = baseFilters.durations;
              } else {
                newFilters.durations = [];
              }
              setUserFilters(newFilters);
            }}
          >
            {userFilters.durations.length !== baseFilters.durations.length
              ? "Select All"
              : "Deselect All"}
          </Button>
          <FormGroup>
            {Object.keys(DURATION_SETTINGS).map((duration) =>
              duration === "NONE" ? (
                <></>
              ) : (
                <FormControlLabel
                  key={`${duration}-season-filter`}
                  control={
                    <Checkbox
                      checked={userFilters.durations.includes(duration)}
                      name={duration}
                    />
                  }
                  label={DURATION_SETTINGS[duration]}
                  onChange={(e, value) => {
                    handleChangeFilter("durations", duration, value);
                  }}
                />
              )
            )}
          </FormGroup>
        </FormControl>
      </Box>
    </Container>
  );
};

export default PackExploreFilterBar;
