import React from "react";

import {
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import ProductReviewCard from "./ProductReviewCard";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  getLoggedInUserDetails,
  getUserIsLoggedIn,
  addReview,
  editReview,
} from "../../utils";
import { ASPECT_REVIEW_MAP, GEAR_TYPE_USAGE_MAP } from "./constants";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
const MAX_IMAGE_COUNT = 3;

const LeaveAReviewContainer = ({
  gearType,
  itemId,
  userHasReviewed,
  userReview,
  refetchReviews,
}) => {
  const [isReviewing, setIsReviewing] = React.useState(false);
  const [reviewValues, setReviewValues] = React.useState({
    starRating: 0,
    positives: [""],
    negatives: [""],
    review: "",
    usage: "",
    aspects: {},
    images: [],
    gearType,
    itemId,
    authorDetails: {},
    reviewTitle: "",
    wouldRecommend: "true",
    unit: "g",
    weight: 0,
  });
  const [fileLimit, setFileLimit] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (getUserIsLoggedIn()) {
        const userDetails = await getLoggedInUserDetails();
        handleSetValue("authorDetails", userDetails);
      }
    })();
  }, []);

  React.useEffect(() => {
    const newRevieWvalues = { ...reviewValues, ...userReview };
    setReviewValues(newRevieWvalues);
  }, [userReview]);

  const handleClick = () => {
    setIsReviewing(true);
  };

  const handleCancel = () => {
    setIsReviewing(false);
  };

  const handleSetValue = (key, value) => {
    const newValues = { ...reviewValues, [key]: value };
    setReviewValues(newValues);
  };

  const handleSetPosNegValues = (key, index, value) => {
    const values = reviewValues[key];
    values[index] = value;
    const newValues = { ...reviewValues, [key]: values };
    setReviewValues(newValues);
  };

  const handleSetAspectValue = (key, value) => {
    const newValues = {
      ...reviewValues,
      aspects: {
        ...reviewValues.aspects,
        [key]: value,
      },
    };
    setReviewValues(newValues);
  };

  const handleFileEvents = (e) => {
    const chosenFiles = Object.values(e.target.files);
    handleImageUpload(chosenFiles);
  };

  const handleDeleteFile = (index) => {
    const newImages = [...reviewValues.images];
    newImages.splice(index, 1);
    handleSetValue("images", newImages);
  };

  const handleSubmitReview = async () => {
    await addReview(reviewValues);
  };

  const handleEditReview = async () => {
    await editReview(reviewValues);
  };

  const addRowToPosNeg = async (key) => {
    const values = [...reviewValues[key], ""];
    const newValues = { ...reviewValues, [key]: values };
    setReviewValues(newValues);
  };

  const handleSubmit = async () => {
    if (userHasReviewed) {
      await handleEditReview();
    } else {
      await handleSubmitReview();
    }
    handleCancel();
    await refetchReviews();
  };

  const handleImageUpload = (files) => {
    const uploaded = [...reviewValues.images];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_IMAGE_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_IMAGE_COUNT) {
          alert("You can only add 3 images");
          setFileLimit(true);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) handleSetValue("images", uploaded);
  };

  return (
    <Stack>
      <Collapse in={isReviewing} collapsedSize={0}>
        <Paper sx={{ width: "100%", padding: "10px 10px 10px 20px" }}>
          <Stack spacing={2}>
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">
                  {userHasReviewed ? "Edit Review" : "Review"}
                </Typography>
                <IconButton onClick={handleCancel}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider />
            </Stack>
            {getUserIsLoggedIn() ? (
              <></>
            ) : (
              <Stack>
                <Typography
                  variant="subtitle2"
                  fontStyle="italic"
                  color="error"
                >
                  You are currently writing a review anonymously and will not be
                  able to edit it after posting. Login or Sign Up to write a
                  review that you can later edit.
                </Typography>
              </Stack>
            )}
            {/* {getUserIsLoggedIn() && reviewValues.authorDetails ? (
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Leave Review As
                </FormLabel>
                <RadioGroup
                  value={reviewValues.isAnonymous}
                  onChange={(e) => {
                    handleSetValue("isAnonymous", e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={`@${reviewValues.authorDetails.username}`}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Anonymous"
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              <></>
            )} */}
            <Stack spacing={1}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="body1">{`${GEAR_TYPE_USAGE_MAP[gearType].label}`}</Typography>
                <Typography
                  variant="body2"
                  sx={{ opacity: "0.8" }}
                >{`(Required)`}</Typography>
              </Stack>
              <Select
                size="small"
                fullWidth
                sx={{ maxWidth: "300px" }}
                value={reviewValues.usage}
                required
                onChange={(e) => {
                  handleSetValue("usage", e.target.value);
                }}
              >
                {GEAR_TYPE_USAGE_MAP[gearType].options.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="body1">Overall Rating</Typography>
                <Typography
                  variant="body2"
                  sx={{ opacity: "0.8" }}
                >{`(Required)`}</Typography>
              </Stack>
              <Rating
                value={reviewValues.starRating}
                precision={1}
                required
                onChange={(e) => {
                  handleSetValue("starRating", e.target.value);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            </Stack>
            <InputLabel>
              How would you rate the following aspects of this item?
            </InputLabel>
            <Stack spacing={1} sx={{ paddingLeft: "10px" }}>
              {ASPECT_REVIEW_MAP[gearType].map((option) => (
                <>
                  <InputLabel>{option.label}</InputLabel>
                  <Rating
                    value={reviewValues.aspects[option.key]}
                    precision={1}
                    key={reviewValues.aspects[option.key]}
                    onChange={(e) => {
                      handleSetAspectValue(option.key, e.target.value);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                </>
              ))}
            </Stack>
            <FormControl>
              <FormLabel>Would you recommend this to a friend?</FormLabel>
              <RadioGroup
                value={reviewValues.wouldRecommend}
                onChange={(e) => {
                  handleSetValue("wouldRecommend", e.target.value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            <FormLabel>Positives (Max 5)</FormLabel>
            {reviewValues.positives.map((value, index) => (
              <Stack sx={{ maxWidth: "1000px" }} alignItems="flex-end">
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  minRows={1}
                  placeholder={"What do you like about this item?"}
                  defaultValue={
                    reviewValues.positives[index]
                      ? reviewValues.positives[index]
                      : ""
                  }
                  onChange={(e) => {
                    handleSetPosNegValues("positives", index, e.target.value);
                  }}
                  inputProps={{ maxLength: 140 }}
                  sx={{ maxWidth: "1000px" }}
                />
                {value.length > 120 ? (
                  <Typography
                    fontSize="14px"
                    color={value.length < 140 ? "white" : "red"}
                  >{`${value.length} / 140`}</Typography>
                ) : (
                  <></>
                )}
              </Stack>
            ))}
            {reviewValues.positives.length < 5 ? (
              <Button
                size="small"
                sx={{ width: "200px" }}
                startIcon={<AddIcon sx={{ marginTop: "-2px" }} />}
                onClick={() => {
                  addRowToPosNeg("positives");
                }}
              >
                Add Another Positive
              </Button>
            ) : (
              <></>
            )}
            <FormLabel>Negatives (Max 5)</FormLabel>
            {reviewValues.negatives.map((value, index) => (
              <Stack sx={{ maxWidth: "1000px" }} alignItems="flex-end">
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  minRows={1}
                  placeholder={"What do you not like about this item?"}
                  onChange={(e) => {
                    handleSetPosNegValues("negatives", index, e.target.value);
                  }}
                  defaultValue={
                    reviewValues.negatives[index]
                      ? reviewValues.negatives[index]
                      : ""
                  }
                  inputProps={{ maxLength: 140 }}
                  sx={{ maxWidth: "1000px" }}
                />
                {value.length > 120 ? (
                  <Typography
                    fontSize="14px"
                    color={value.length < 140 ? "white" : "red"}
                  >{`${value.length} / 140`}</Typography>
                ) : (
                  <></>
                )}
              </Stack>
            ))}
            {reviewValues.negatives.length < 5 ? (
              <Button
                size="small"
                sx={{ width: "200px" }}
                startIcon={<AddIcon sx={{ marginTop: "-2px" }} />}
                onClick={() => {
                  addRowToPosNeg("negatives");
                }}
              >
                Add Another Negative
              </Button>
            ) : (
              <></>
            )}
            <FormLabel>Overall Review</FormLabel>
            <TextField
              placeholder="Review Title"
              size="small"
              fullWidth
              multiline
              minRows={1}
              inputProps={{ maxLength: 120 }}
              defaultValue={reviewValues.reviewTitle}
              onChange={(e) => {
                handleSetValue("reviewTitle", e.target.value);
              }}
              sx={{ maxWidth: "1000px" }}
            />
            <TextField
              placeholder="Review Body"
              size="small"
              fullWidth
              multiline
              minRows={4}
              defaultValue={reviewValues.review}
              inputProps={{ maxLength: 5000 }}
              onChange={(e) => {
                handleSetValue("review", e.target.value);
              }}
              sx={{ maxWidth: "1000px" }}
            />
            <FormLabel>
              <Stack direction="row" align="center" spacing={1}>
                <span>Measured Trail Weight</span>
                <Tooltip title="Trail Weight is the weight of the item with just the materials required to use it on the trail">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Stack>
            </FormLabel>
            <Stack direction="row">
              <TextField
                size="small"
                type="number"
                value={reviewValues.weight}
                onChange={(e) => {
                  handleSetValue("weight", e.target.value);
                }}
                InputProps={{
                  style: {
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderRight: "0px",
                  },
                }}
              />
              <Select
                size="small"
                fullWidth
                sx={{
                  width: "65px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderLeft: "0px",
                }}
                value={reviewValues.unit}
                onChange={(e) => {
                  handleSetValue("unit", e.target.value);
                }}
              >
                <MenuItem value={"g"}>g</MenuItem>
                <MenuItem value={"oz"}>oz</MenuItem>
                <MenuItem value={"kg"}>kg</MenuItem>
                <MenuItem value={"lb"}>lb</MenuItem>
              </Select>
            </Stack>
            <InputLabel>Images (Max 3)</InputLabel>
            <input
              type="file"
              name="file"
              multiple
              accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
              onChange={handleFileEvents}
            />
            <ImageList cols={3} sx={{ maxWidth: "500px" }}>
              {reviewValues.images.map((image, index) => (
                <ImageListItem
                  key={image.name}
                  sx={{
                    objectFit: "contain",
                    maxWidth: "150px",
                    maxHeight: "150px",
                  }}
                >
                  <img
                    srcSet={
                      typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    src={
                      typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    alt={image.title}
                    loading="lazy"
                    style={{
                      objectFit: "contain",
                      maxWidth: "150px",
                      maxHeight: "100px",
                      marginTop: "15px",
                      border: "1px solid grey",
                      borderRadius: "5px",
                    }}
                  />
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 0%)",
                    }}
                    position="top"
                    actionIcon={
                      <Tooltip title="Remove Image">
                        <IconButton
                          sx={{
                            color: "red",
                            left: "10px",
                            background:
                              "linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, " +
                              "rgba(0,0,0,0.3) 100%, rgba(0,0,0,0) 100%)",
                          }}
                          onClick={() => {
                            handleDeleteFile(index);
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    actionPosition="right"
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <Divider />
            <Typography variant="h6">Preview New Review</Typography>
            <ProductReviewCard
              reviewValues={reviewValues}
              gearType={gearType}
              isPreview
            />
            <Button
              variant="contained"
              color="success"
              sx={{ color: "white", width: "200px" }}
              startIcon={<RateReviewIcon />}
              onClick={handleSubmit}
              disabled={!reviewValues.starRating || !reviewValues.usage}
            >
              {userHasReviewed ? "Submit Edits" : "Submit Review"}
            </Button>
          </Stack>
        </Paper>
      </Collapse>
      {isReviewing ? (
        <></>
      ) : (
        <Button
          variant="contained"
          color={userHasReviewed ? "info" : "success"}
          sx={{ color: "white", width: "200px" }}
          startIcon={<RateReviewIcon />}
          onClick={handleClick}
        >
          {userHasReviewed ? "Edit Your Review" : "Write a Review"}
        </Button>
      )}
    </Stack>
  );
};

export default LeaveAReviewContainer;
