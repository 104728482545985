export const getUrlForCheapestItem = (retailers, defaultUrl) => {
  if (!retailers) return defaultUrl;
  if (retailers.length === 0) return defaultUrl;
  if (retailers.length === 1) return retailers[0].buyLink || defaultUrl;
  const sortedRetailers = retailers.sort((a, b) => a.salePrice - b.salePrice);
  const sortedRetailersWithPrices = sortedRetailers.find(
    ({ salePrice }) => salePrice && salePrice > 0
  );
  if (sortedRetailersWithPrices)
    return sortedRetailersWithPrices.buyLink || defaultUrl;
  return defaultUrl;
};

const gearTypeToPathMap = {
  tent: "tent",
  sleepingPad: "sleeping-pad",
  sleepingBag: "sleeping-bag",
  backpack: "backpacks",
  insulatedJacket: "insulated-jacket",
  stove: "stove",
};

export const buildUrl = (gearType, id) => {
  if (!id || !gearTypeToPathMap[gearType]) return "";
  return `https://www.packwizard.com/gear/${gearTypeToPathMap[gearType]}/${id}`;
};
