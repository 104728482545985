import React from "react";

import { getColorWithMode } from "../constants/colors";
import { Container } from "@mui/system";
import {
  Button,
  Collapse,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import SubNavBar from "./SubNavBar";
import { logUserInteraction } from "../api/logging";
import { Helmet } from "react-helmet";
import { fetchPublicPacksForPackExplorer } from "../utils";
import PackExploreTable from "./PackExploreTable";
import PackExploreFilterBar from "./PackExploreFilterBar";
import {
  DURATION_SETTINGS,
  SEASON_SETTINGS,
  TAG_LIST,
} from "../constants/packSettings";
import { useMediaQuery } from "react-responsive";

const fetchFilterValues = (rows) => {
  let lowestWeight = Number.MAX_SAFE_INTEGER;
  let lowestPrice = Number.MAX_SAFE_INTEGER;

  rows.forEach((row) => {
    if (row.weight < lowestWeight) lowestWeight = row.weight;
    if (row.price < lowestPrice) lowestPrice = row.price;
  });
  return {
    tags: TAG_LIST,
    seasons: Object.keys(SEASON_SETTINGS),
    durations: Object.keys(DURATION_SETTINGS),
    weights: {
      min: Math.floor(lowestWeight),
      max: 10000,
    },
    prices: {
      min: Math.floor(lowestPrice),
      max: 5000,
    },
    searchIsByLocation: false,
    searchText: "",
  };
};

const PackExplorerContainer = ({ darkMode }) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });

  const [filtersAreOpen, setFiltersAreOpen] = React.useState(isDesktopOrLaptop);
  const [userPacks, setUserPacks] = React.useState([]);
  const [filteredUserPacks, setFilteredUserPacks] = React.useState([]);
  const [useMetric, setUseMetric] = React.useState(false);
  const [baseFilters, setBaseFilters] = React.useState(null);
  const [userFilters, setUserFilters] = React.useState({});
  const [upvotedIds, setUpvotedIds] = React.useState([]);
  const [downvotedIds, setDownvotedIds] = React.useState([]);
  const [sortValue, setSortValue] = React.useState("lastUpdated");
  const [sortModel, setSortModel] = React.useState([
    { field: "lastUpdated", sort: "desc" },
  ]);

  React.useEffect(() => {
    (async () => {
      logUserInteraction("session", "explorer");
      const { packs, upvotes, downvotes } =
        await fetchPublicPacksForPackExplorer();
      const newFilters = fetchFilterValues(packs);
      setUpvotedIds(upvotes);
      setDownvotedIds(downvotes);
      setBaseFilters(newFilters);
      setUserFilters({
        tags: newFilters.tags,
        seasons: newFilters.seasons,
        durations: newFilters.durations,
        searchIsByLocation: false,
        searchText: "",
      });
      setUserPacks(packs);
      setFilteredUserPacks(packs);
    })();
  }, []);

  React.useEffect(() => {
    if (sortValue === "lastUpdated") {
      setSortModel([{ field: "lastUpdated", sort: "asc" }]);
    } else {
      setSortModel([{ field: "voteCount", sort: "desc" }]);
    }
  }, [sortValue]);

  React.useEffect(() => {
    const userFiltersTagSet = new Set(userFilters.tags);
    const filteredDownPacks = userPacks.filter(
      (pack) =>
        (!userFilters.price ||
          !pack.price ||
          (pack.price >= userFilters.price[0] &&
            (pack.price <= userFilters.price[1] ||
              userFilters.price[1] === 5000))) &&
        (!userFilters.weight ||
          !pack.weight ||
          (pack.weight >= userFilters.weight[0] &&
            (pack.weight <= userFilters.weight[1] ||
              userFilters.weight[1] === 5000))) &&
        (userFilters.durations.length ===
          Object.keys(DURATION_SETTINGS).length ||
          userFilters.durations.includes(pack.duration)) &&
        (userFilters.seasons.length === Object.keys(SEASON_SETTINGS).length ||
          userFilters.seasons.includes(pack.season)) &&
        (userFilters.tags.length === TAG_LIST.length ||
          pack.tags.some((tag) => userFiltersTagSet.has(tag))) &&
        (!userFilters.packNameSearchText ||
          pack.packName
            .toLowerCase()
            .includes(userFilters.packNameSearchText) ||
          (pack.creatorUsername || "")
            .toLowerCase()
            .includes(userFilters.packNameSearchText)) &&
        (!userFilters.packLocationSearchText ||
          pack.trail
            .toLowerCase()
            .includes(userFilters.packLocationSearchText.toLowerCase()))
    );

    setFilteredUserPacks(filteredDownPacks);
  }, [userFilters, userPacks, sortValue]);

  const handleChangeSearchText = (searchType, text) => {
    const newFilters = { ...userFilters };
    newFilters[searchType] = text;
    setUserFilters(newFilters);
  };

  return (
    <Container
      sx={{
        width: "100vw",
        maxWidth: "1440px",
        minHeight: "100vh",
        backgroundColor: getColorWithMode(darkMode, "base"),
        padding: "0px 0px 0px 0px",
        paddingBottom: "20px",
        minWidth: filtersAreOpen ? "1024px" : "780px",
      }}
    >
      <Stack>
        <SubNavBar darkMode={darkMode} />
        <Stack
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Helmet>
            <title>Browse Packs</title>
            <link rel="canonical" href="https://www.packwizard.com/browse" />

            <meta
              name="keywords"
              content="backpacking gear list finder, find gear lists for your hike"
            />
            <meta
              name="description"
              content="Find backpacking gear lists for your area and trails, season and activities such as bikepacking, fishing, hunting or thru-hiking."
            />
          </Helmet>
          <Container
            sx={{
              width: "100%",
              backgroundColor: getColorWithMode(darkMode, "title"),
            }}
          >
            <Stack
              textAlign="center"
              alignItems="center"
              sx={{ padding: "20px 10px 20px 10px" }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "700",
                  fontFamily: "Helvetica",
                  color: "white",
                }}
              >
                Browse Packs
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Helvetica",
                  color: "white",
                  maxWidth: "650px",
                }}
              >
                Browse gear lists by trail/region, season and duration, as well
                as find gear lists for activities you love, like fishing,
                hunting, rock climbing and more.
              </Typography>
            </Stack>
          </Container>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Stack sx={{ width: filtersAreOpen ? "240px" : "10px" }}>
              <Button
                sx={{ width: "120px" }}
                onClick={() => {
                  setFiltersAreOpen(!filtersAreOpen);
                }}
              >{`${filtersAreOpen ? "Hide Filters" : "Show Filters"}`}</Button>
              <Collapse in={filtersAreOpen} orientation="horizontal">
                <Container
                  sx={{
                    width: "240px",
                    height: "100%",
                    padding: "5px 5px 5px 5px",
                    marginTop: "20px",
                  }}
                >
                  <PackExploreFilterBar
                    darkMode={darkMode}
                    useMetric={useMetric}
                    setUseMetric={setUseMetric}
                    userFilters={userFilters}
                    setUserFilters={setUserFilters}
                    baseFilters={baseFilters}
                    handleChangeSearchText={handleChangeSearchText}
                  />
                </Container>
              </Collapse>
            </Stack>
            <Container
              sx={{
                marginTop: filtersAreOpen ? "0px" : "27px",
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Container
                  sx={{
                    width: "300px",
                    height: "40px",
                    padding: "0px 0px 0px 0px",
                    marginLeft: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      height: "20px",
                      paddingLeft: "5px",
                      fontFamily: "Helvetica",
                      lineHeight: "40px",
                    }}
                  >
                    {`${filteredUserPacks.length} Pack${
                      filteredUserPacks.length === 1 ? "" : "s"
                    }`}
                  </Typography>
                </Container>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle2">Sort By: </Typography>
                  <Select
                    size="small"
                    sx={{ height: "30px", width: "180px", fontSize: "15px" }}
                    value={sortValue}
                    onChange={(e) => setSortValue(e.target.value)}
                  >
                    <MenuItem value={"voteCount"}>Upvotes</MenuItem>
                    <MenuItem value={"lastUpdated"}>Last Updated</MenuItem>
                  </Select>
                </Stack>
              </Stack>
              <PackExploreTable
                sortModel={sortModel}
                setSortModel={setSortModel}
                userPacks={filteredUserPacks}
                darkMode={darkMode}
                useMetric={useMetric}
                upvotedIds={upvotedIds}
                downvotedIds={downvotedIds}
              />
            </Container>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default PackExplorerContainer;
