import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TimerIcon from "@mui/icons-material/Timer";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  DURATION_SETTINGS,
  SEASON_SETTINGS,
  TAG_LIST,
  TRAIL_LIST,
} from "../constants/packSettings";
import CancelIcon from "@mui/icons-material/Cancel";
import { GridCheckIcon } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "react-responsive";

const getDisplayChips = (tags, isMobile) => {
  const { duration, season, trail } = tags;
  return [
    trail && trail.length ? (
      <Chip
        key={trail}
        icon={<LocationOnIcon />}
        color="info"
        variant="outlined"
        size={isMobile ? "large" : "small"}
        label={trail}
        sx={{ maxWidth: "180px", overflowX: "ellipsis", whitespace: "nowrap" }}
      />
    ) : null,
    season && season.length ? (
      <Chip
        key={season}
        icon={<CalendarMonthIcon />}
        color="warning"
        variant="outlined"
        size={isMobile ? "large" : "small"}
        label={SEASON_SETTINGS[season]}
      />
    ) : null,
    duration && duration.length ? (
      <Chip
        key={duration}
        icon={<TimerIcon />}
        color="secondary"
        variant="outlined"
        size={isMobile ? "large" : "small"}
        label={DURATION_SETTINGS[duration]}
      />
    ) : null,
  ].filter((chip) => chip);
};

const getGenericChips = (tags, isMobile) => {
  const { tag0, tag1, tag2 } = tags;
  return [tag0, tag1, tag2]
    .map((tag) => {
      if (tag) {
        return (
          <Chip
            key={tag}
            size={isMobile ? "large" : "small"}
            color="success"
            variant="outlined"
            label={tag}
          />
        );
      } else {
        return null;
      }
    })
    .filter((chip) => chip);
};

const getDefaultTags = (userTags) => {
  const tagMap = userTags.reduce(
    (acc, { type, value }) => {
      acc[type] = value;
      return acc;
    },
    {
      duration: "",
      season: "",
      trail: "",
      tag0: "",
      tag1: "",
      tag2: "",
    }
  );
  return tagMap;
};

const getInitialFormat = (userTags) => {
  return Object.keys(userTags).map((key) => ({
    type: key,
    value: userTags[key],
  }));
};

const PackSettings = ({
  userPackTags,
  visibility,
  handleUpdatePackSettings,
  packAnalytics,
}) => {
  const userPackTagsStringified = JSON.stringify(userPackTags);
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [expanded, setExpanded] = React.useState(false);
  const [packIsPublic, setPackIsPublic] = React.useState(visibility);
  const [tags, setTags] = React.useState(getDefaultTags(userPackTags));
  const [genericTags, setGenericTags] = React.useState(
    [tags.tag0, tags.tag1, tags.tag2].filter((tag) => tag && tag.length > 0)
  );
  const [isSaved, setIsSaved] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);

  React.useEffect(() => {
    setPackIsPublic(Boolean(visibility));
    setTags(getDefaultTags(userPackTags));
    const defaultTags = getDefaultTags(userPackTags);
    setGenericTags(
      [defaultTags.tag0, defaultTags.tag1, defaultTags.tag2].filter(
        (tag) => tag && tag.length > 0
      )
    );
  }, [userPackTagsStringified, userPackTags, visibility]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMakePublic = () => {
    checkIfChangesExist(tags, !packIsPublic);
    setPackIsPublic(!packIsPublic);
    setIsSaved(false);
  };

  const handleTagChange = (key, value) => {
    const valueOfTag = value === "NONE" || !value ? "" : value;
    const newTags = { ...tags };
    newTags[key] = valueOfTag;
    checkIfChangesExist(newTags, packIsPublic);
    setTags(newTags);
    setIsSaved(false);
  };

  const handleSave = () => {
    const tagsInInitialFormat = getInitialFormat(tags);
    handleUpdatePackSettings(packIsPublic, tagsInInitialFormat);
    setIsSaved(true);
    setHasChanges(false);
  };

  const checkIfChangesExist = (newTags, privacySetting) => {
    const hasVisibilityChanged = privacySetting !== visibility;
    const changedTags = userPackTags.filter(({ type, value }) => {
      return value !== newTags[type];
    });
    const hasTagsChanged = changedTags.length > 0;
    const hasChanged = hasVisibilityChanged || hasTagsChanged;
    setHasChanges(hasChanged);
  };

  const handleResetTags = () => {
    const resetTags = getDefaultTags(userPackTags);
    const resetGenericTags = [
      resetTags.tag0,
      resetTags.tag1,
      resetTags.tag2,
    ].filter((tag) => tag && tag.length > 0);
    setGenericTags(resetGenericTags);
    setTags(resetTags);
    setPackIsPublic(visibility);
    setIsSaved(false);
    setHasChanges(false);
  };

  const handleSetGenericTag = (selectedTags) => {
    const newUserTags = tags;
    for (let i = 0; i < 3; i++) {
      if (i >= selectedTags.length) {
        newUserTags[`tag${i}`] = "";
      } else {
        newUserTags[`tag${i}`] = selectedTags[i];
      }
    }
    setTags(newUserTags);
    checkIfChangesExist(newUserTags, packIsPublic);
    setGenericTags(selectedTags);
    setIsSaved(false);
  };

  const handleDeleteChip = (value) => {
    const newTagList = genericTags.filter((v) => v !== value);
    handleSetGenericTag(newTagList);
  };

  const formattedViewHistory = {
    labels: packAnalytics.viewHistory
      ? packAnalytics.viewHistory.map(({ date }) => date)
      : [],
    datasets: [
      {
        label: "Views",
        data: packAnalytics.viewHistory
          ? packAnalytics.viewHistory.map(({ views }) => views)
          : [],
        borderColor: "rgb(53, 162, 235)",
      },
    ],
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          marginTop: "10px !important",
          height: isMobile ? "50px" : "40px",
          width: "100%",
        }}
        spacing={1}
      >
        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              maxWidth: isMobile ? "650px" : "100%",
              overflowX: "scroll",
            }}
          >
            <Chip
              label={packIsPublic ? "Public" : "Private"}
              icon={packIsPublic ? <PublicIcon /> : <LockIcon />}
              color={packIsPublic ? "primary" : "error"}
              variant="outlined"
              size={isMobile ? "large" : "small"}
              sx={{ width: isMobile ? "105px" : "85px" }}
            />
            {getDisplayChips(tags, isMobile)}
            {getGenericChips(tags, isMobile)}
          </Stack>
          <Button
            size="small"
            endIcon={<ArrowDropDownIcon />}
            onClick={handleExpandClick}
            sx={{ height: isMobile ? "30px" : "22px" }}
          >
            Edit Tags & View Analytics
          </Button>
        </Stack>
      </Stack>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ width: "100%" }}
      >
        <Paper elevation={3} sx={{ marginTop: "-5px" }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            paddingRight="10px"
          >
            <Stack>
              <Stack spacing={0}>
                <List sx={{ paddingBottom: "0px", paddingTop: "0px" }}>
                  <ListItem key="private-checkbox" sx={{ width: "220px" }}>
                    <ListItemButton
                      role={undefined}
                      onClick={handleMakePublic}
                      dense
                    >
                      <ListItemIcon sx={{ marginLeft: "-15px" }}>
                        <Checkbox
                          icon={<LockIcon />}
                          checkedIcon={<PublicIcon />}
                          checked={packIsPublic}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ marginLeft: "-10px" }}
                        primary={`Visibility: ${
                          packIsPublic ? "Public" : "Private"
                        }`}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ paddingLeft: "17px", paddingTop: "5px" }}
                >
                  <FormControl sx={{ width: "200px" }} size="small">
                    <InputLabel>Duration</InputLabel>
                    <Select
                      value={tags.duration}
                      label="Duration"
                      onChange={(e) => {
                        handleTagChange("duration", e.target.value);
                      }}
                    >
                      {Object.keys(DURATION_SETTINGS).map((key) => (
                        <MenuItem value={key} key={key}>
                          {DURATION_SETTINGS[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: "200px" }} size="small">
                    <InputLabel>Season</InputLabel>
                    <Select
                      value={tags.season}
                      label="Season"
                      onChange={(e) => {
                        handleTagChange("season", e.target.value);
                      }}
                    >
                      {Object.keys(SEASON_SETTINGS).map((key) => (
                        <MenuItem value={key} key={key}>
                          {SEASON_SETTINGS[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
              <Autocomplete
                size="small"
                sx={{ width: "410px", marginLeft: "17px", marginTop: "15px" }}
                freeSolo
                options={TRAIL_LIST.map((option) => option)}
                value={tags.trail}
                onChange={(e, value) => {
                  handleTagChange("trail", value);
                }}
                onInputChange={(e, value) => {
                  handleTagChange("trail", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 36 }}
                    label="Trail or Location"
                  />
                )}
              />
              <FormControl sx={{ marginLeft: "17px", marginTop: "15px" }}>
                <InputLabel size="small">{"Tags (Max 3)"}</InputLabel>
                <Select
                  multiple
                  value={genericTags}
                  onChange={(e) => {
                    handleSetGenericTag(e.target.value);
                  }}
                  on
                  input={<OutlinedInput label="Tags (Max 3)" />}
                  size="small"
                  sx={{ width: "410px" }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          clickable
                          onDelete={() => {
                            handleDeleteChip(value);
                          }}
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                        />
                      ))}
                    </Box>
                  )}
                >
                  {TAG_LIST.map((tag) => (
                    <MenuItem
                      key={tag}
                      value={tag}
                      disabled={
                        !genericTags.includes(tag) && genericTags.length >= 3
                      }
                      sx={{ width: "400px" }}
                    >
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack direction="row" spacing={0} sx={{ marginBottom: "10px" }}>
                {isSaved ? (
                  <Button
                    sx={{
                      width: "100px",
                      marginLeft: "17px",
                      marginTop: "15px",
                    }}
                    startIcon={<GridCheckIcon />}
                    color="success"
                    variant="outlined"
                    disabled
                  >
                    Saved
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "100px",
                      marginLeft: "17px",
                      marginTop: "15px",
                    }}
                    startIcon={<SaveIcon />}
                    color="success"
                    variant="outlined"
                    onClick={handleSave}
                    disabled={!hasChanges}
                  >
                    Save
                  </Button>
                )}
                <Button
                  sx={{
                    width: "180px",
                    marginLeft: "17px",
                    marginTop: "15px",
                  }}
                  startIcon={<DeleteIcon />}
                  onClick={handleResetTags}
                  disabled={!hasChanges}
                >
                  Discard Changes
                </Button>
              </Stack>
              {hasChanges ? (
                <Typography
                  color="error"
                  variant="subtitle2"
                  sx={{ marginLeft: "17px" }}
                >
                  You have unsaved changes
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
            <Stack
              sx={{
                paddingTop: "5px",
                paddingBottom: "10px",
                width: "100%",
              }}
              alignItems="flex-end"
            >
              <IconButton onClick={handleExpandClick}>
                <CloseIcon />
              </IconButton>
              <Paper
                sx={{
                  padding: "10px 10px 10px 10px",
                  height: "100%",
                  width: "100%",
                }}
                elevation={2}
              >
                <Stack direction="row" spacing={3}>
                  <Paper sx={{ padding: "5px 5px 5px 5px" }}>
                    <Stack alignItems="center">
                      <Typography>{packAnalytics.views}</Typography>
                      <Divider />
                      <Typography>Views</Typography>
                    </Stack>
                  </Paper>
                  <Paper sx={{ padding: "5px 5px 5px 5px" }}>
                    <Stack alignItems="center">
                      <Typography>
                        {packAnalytics.upvoteCount -
                          packAnalytics.downvoteCount}
                      </Typography>
                      <Divider />
                      <Typography>Votes</Typography>
                    </Stack>
                  </Paper>
                  <Paper sx={{ padding: "5px 5px 5px 5px" }}>
                    <Stack alignItems="center">
                      <Typography>{packAnalytics.upvoteCount}</Typography>
                      <Divider />
                      <Typography>Upvotes</Typography>
                    </Stack>
                  </Paper>
                </Stack>
                <Stack>
                  <Typography
                    variant="h8"
                    sx={{ paddingTop: "10px", textAlign: "center" }}
                  >
                    Last 30 days
                  </Typography>
                  <Line
                    style={{ maxHeight: "220px" }}
                    data={formattedViewHistory}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Stack>
        </Paper>
      </Collapse>
    </>
  );
};

export default PackSettings;
