import React from "react";

import { Container, Stack, Box } from "@mui/system";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { externalLinkWrapper } from "../utils/links";

const DetailPanelContent = ({
  row,
  tableIndex,
  tableId,
  isDarkMode,
  affiliateLinksAreOff,
}) => {
  return (
    <Container
      sx={{
        height: "380px",
        backgroundColor: isDarkMode ? "#202020" : "#F0F0F0",
      }}
      key={`${tableId}-${tableIndex}-${row.id}`}
    >
      <Stack alignItems="center">
        <Stack direction="row">
          <Paper
            elevation={3}
            sx={{
              height: "285px",
              width: "285px",
              marginTop: "22px",
              backgroundColor: "white",
            }}
          >
            {row.imageUrl ? (
              <img
                alt="product"
                className={row.url ? "share-image" : ""}
                style={{
                  height: "285px",
                  width: "285px",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
                src={row.imageUrl}
                onClick={() => {
                  if (row.url) {
                    externalLinkWrapper(row.url, affiliateLinksAreOff);
                  }
                }}
              />
            ) : (
              <Box
                style={{
                  height: "285px",
                  width: "285px",
                  backgroundColor: "#333",
                  textAlign: "center",
                  lineHeight: "300px",
                }}
              >
                No Image Available
              </Box>
            )}
          </Paper>
          <Stack sx={{ height: "300px", width: "400px" }}>
            <TextField
              inputProps={{ readOnly: true }}
              label="Item"
              variant="standard"
              size="small"
              sx={{
                width: "380px",
                marginLeft: "20px",
                marginTop: "20px",
              }}
              defaultValue={row.item}
              InputLabelProps={{ shrink: true }}
            />
            <Stack direction="row" sx={{ width: "400px", marginTop: "20px" }}>
              <TextField
                label="Brand"
                variant="standard"
                size="small"
                sx={{ width: "195px", marginLeft: "20px" }}
                value={row.brand}
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true }}
              />
              <TextField
                sx={{ width: "225px", marginLeft: "10px" }}
                variant="standard"
                size="small"
                label="Item Type"
                value={row.category}
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true }}
              />
            </Stack>
            <Stack direction="row" sx={{ marginTop: "20px", width: "400px" }}>
              <TextField
                variant="standard"
                inputProps={{ readOnly: true }}
                label="Weight"
                size="small"
                type="number"
                sx={{ width: "100px", marginLeft: "20px" }}
                value={row.weight}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                sx={{ width: "70px", marginLeft: "10px" }}
                size="small"
                inputProps={{ readOnly: true }}
                label="Unit"
                variant="standard"
                value={row.unit}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                variant="standard"
                label="Price"
                size="small"
                type="number"
                sx={{ width: "130px", marginLeft: "10px" }}
                value={Number(row.price).toFixed(2)}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                variant="standard"
                inputProps={{ readOnly: true }}
                label="Quantity"
                size="small"
                type="number"
                sx={{ width: "80px", marginLeft: "10px" }}
                value={row.quantity}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label="Description"
              multiline
              value={row.description}
              size="small"
              rows={row.url ? 5 : 3}
              sx={{ width: "380px", marginLeft: "20px", marginTop: "23px" }}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default DetailPanelContent;
