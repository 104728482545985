// This list is generated by our db via items.distinct(); and does not include any item that contains "tent" "pack" or "sleeping bag"

const categories = [
  "500ml pot",
  "750ml pot",
  "20l pack",
  "25l pack",
  "30l pack",
  "35l pack",
  "40l pack",
  "45l pack",
  "50l pack",
  "55l pack",
  "60l pack",
  "65l pack",
  "70l pack",
  "75l pack",
  "80l pack",
  "85l pack",
  "0f sleeping bag",
  "5f sleeping bag",
  "10f sleeping bag",
  "15f sleeping bag",
  "20f sleeping bag",
  "25f sleeping bag",
  "30f sleeping bag",
  "35f sleeping bag",
  "40f sleeping bag",
  "45f sleeping bag",
  "50f sleeping bag",
  "55f sleeping bag",
  "assorted stuff sacks",
  "bear spray",
  "bidet",
  "buff",
  "camera",
  "camera accessory",
  "camera equipment",
  "camera lens",
  "chapstick",
  "cleats",
  "cook system",
  "first aid kit",
  "fuel",
  "headlamp",
  "ice axe",
  "iphone",
  "lighter",
  "medical",
  "microphone",
  "mosquito headnet",
  "multitool",
  "phone",
  "pillow",
  "pocket knife",
  "powerbank",
  "shorts",
  "sleeping pad",
  "smartwatch",
  "socks",
  "spork",
  "stove",
  "sun gloves",
  "sunblock",
  "sunscreen stick",
  "sunscreen",
  "swiss army knife",
  "tarp",
  "toothbrush",
  "towel",
  "trailrunners",
  "trekking poles",
  "tripod",
  "trowel",
  "water filter",
];

export default categories;
