import { Container } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../../../constants/colors";
import { getItemsForGTINAudit, updatePricingData } from "../../../utils";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import DataRefreshDialog from "./DataRefreshDialog";

const LivePricingAuditContainer = ({ darkMode }) => {
  const [data, setData] = React.useState([]);
  const [filters, setFilters] = React.useState({
    hasZeroRetailers: false,
    updatedSevenDaysAgo: false,
    hasGTIN: false,
  });
  const [rowToRefresh, setRowToRefresh] = React.useState(null);
  const [dataRefreshDialogIsOpen, setDataRefreshDialogIsOpen] =
    React.useState(false);

  React.useEffect(() => {
    (async () => {
      const items = await getItemsForGTINAudit();
      setData(items);
    })();
  }, []);

  const filterRows = (rows) => {
    const todaysDate = new Date();
    const weekInMillis = 1000 * 60 * 60 * 24 * 7;
    const sevenDaysAgo = todaysDate - weekInMillis;
    return rows.filter((row) => {
      return (
        (!filters.hasZeroRetailers || row.retailers.length === 0) &&
        (!filters.updatedSevenDaysAgo ||
          new Date(row.lastUpdated) < sevenDaysAgo) &&
        (!filters.hasGTIN || row.googleId) &&
        (!filters.hasASIN || row.asin)
      );
    });
  };

  const setNewFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleRowUpdate = async (value) => {
    await updatePricingData({
      id: value.id,
      gearType: value.gearType,
      googleId: value.googleId,
      asin: value.asin,
    });
    return value;
  };

  const handleRowUpdateError = (error) => {
    console.log(error);
  };

  const handleDataRefresh = (row) => {
    setRowToRefresh(row);
    setDataRefreshDialogIsOpen(true);
  };

  const handleCloseDataRefreshDialog = () => {
    setRowToRefresh(null);
    setDataRefreshDialogIsOpen(false);
  };

  const handleUpdateRowAfterRefresh = (newRow) => {
    if (!newRow) return;

    const indexOfOldRow = data.findIndex((value) => value._id === newRow._id);
    const newData = [...data];

    newData[indexOfOldRow] = {
      ...data[indexOfOldRow],
      ...newRow,
      id: newRow._id,
    };
    setData(newData);
  };

  const columns = [
    {
      field: "name",
      headerName: "Item",
      width: 400,
      headerAlign: "left",
      flex: 1,
      headerClassName: "firstcolumnSeparator",
      renderCell: (params) => {
        return `${params.row.formattedBrand} ${params.row.name}${
          params.row.variant ? ` (${params.row.variant})` : ""
        }`;
      },
    },
    {
      field: "googleId",
      width: 200,
      headerName: "GTIN",
      headerAlign: "center",
      align: "left",
      editable: true,
      sortable: false,
    },
    {
      field: "asin",
      headerName: "ASIN",
      width: 120,
      headerAlign: "center",
      align: "left",
      editable: true,
      sortable: false,
    },
    {
      field: "gearType",
      headerName: "Gear Type",
      width: 110,
      headerAlign: "center",
    },
    {
      field: "price",
      headerName: "Price",
      width: 92,
      type: "number",
      headerAlign: "center",
      renderCell: (params) => {
        return Number(params.value).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      },
    },
    {
      field: "retailerCount",
      headerName: "Retailer #",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      headerAlign: "center",
      align: "center",
      width: 110,
      renderCell: (params) => {
        return params.value.toLocaleString("en-us").split("T")[0];
      },
    },
    {
      field: "Re-Run",
      headerName: "Re-Run",
      headerAlign: "center",
      headerClassName: "lastcolumnSeparator",
      align: "center",
      width: 70,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              handleDataRefresh(params.row);
            }}
          >
            <UpdateIcon color="error" />
          </IconButton>
        );
      },
    },
  ];

  const filteredData = filterRows(data);

  return (
    <Container
      sx={{
        minWidth: "360px",
        width: "100%",
        maxWidth: "1440px",
        minHeight: "100vh",
        backgroundColor: getColorWithMode(darkMode, "base"),
        padding: "0px 0px 0px 0px",
        paddingBottom: "20px",
        paddingTop: "20px",
      }}
    >
      <DataRefreshDialog
        rowToRefresh={rowToRefresh}
        isOpen={dataRefreshDialogIsOpen}
        handleCloseDataRefreshDialog={handleCloseDataRefreshDialog}
        handleUpdateRowAfterRefresh={handleUpdateRowAfterRefresh}
      />
      <Stack direction="row" sx={{ height: "calc(100vh - 100px)" }}>
        <Container sx={{ width: "240px" }}>
          <Typography textAlign="center">Quick Filters</Typography>
          <FormControl>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  checked={filters.hasGTIN}
                  sx={{ width: "40px" }}
                  onChange={() => {
                    setNewFilters("hasGTIN", !filters.hasGTIN);
                  }}
                />
              }
              label="Has GTIN"
              labelPlacement="end"
            />
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  checked={filters.hasASIN}
                  sx={{ width: "40px" }}
                  onChange={() => {
                    setNewFilters("hasASIN", !filters.hasASIN);
                  }}
                />
              }
              label="Has ASIN"
              labelPlacement="end"
            />
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  checked={filters.hasZeroRetailers}
                  sx={{ width: "40px" }}
                  onChange={() => {
                    setNewFilters(
                      "hasZeroRetailers",
                      !filters.hasZeroRetailers
                    );
                  }}
                />
              }
              label="0 Retailers"
              labelPlacement="end"
            />
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  checked={filters.updatedSevenDaysAgo}
                  sx={{ width: "40px" }}
                  onChange={() => {
                    setNewFilters(
                      "updatedSevenDaysAgo",
                      !filters.updatedSevenDaysAgo
                    );
                  }}
                />
              }
              label="Updated >7 days Ago"
              labelPlacement="end"
            />
          </FormControl>
        </Container>
        <DataGridPro
          rows={filteredData}
          columns={columns}
          processRowUpdate={handleRowUpdate}
          onProcessRowUpdateError={handleRowUpdateError}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Stack>
    </Container>
  );
};

export default LivePricingAuditContainer;
