import { flatMap } from "lodash";
import { getPackSuggestions } from "../utils";
import CategoriesFromDb from "../constants/itemCategoriesFromDb";

const filterValues = [
  "pack",
  "tent",
  "sleeping bag",
  "sleeping pad",
  "headlamp",
  ...CategoriesFromDb,
];

const filterRowsForSmallerSuggestionSearch = (rows) => {
  return rows.filter(({ category }) => {
    if (category) {
      const lowercaseCategory = category.toLowerCase();
      for (let i = 0; i < filterValues.length; i++) {
        if (lowercaseCategory.includes(filterValues[i].toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  });
};

export const generatePackSuggestionsFromTableData = async (tableData) => {
  const tableRows = tableData.map((table) => Object.values(table.rows));
  const flatRows = flatMap(tableRows);
  const filteredRows = filterRowsForSmallerSuggestionSearch(flatRows);
  const relevantDetails = filteredRows.map(
    ({ category, item, weight, unit, price }) => {
      return { category, item, weight, unit, price };
    }
  );
  const suggestions = await getPackSuggestions(relevantDetails);
  return suggestions;
};
