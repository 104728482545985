import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import { Container, Stack, Box } from "@mui/system";
import { IconButton, TextField, Typography } from "@mui/material";
import DEFAULT_CATEGORIES from "../constants/defaultCategories";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import DetailPanelContentActions from "./DetailPanelContentActions";
import LinkIcon from "@mui/icons-material/Link";
import { externalLinkWrapper } from "../utils/links";
import { isValidUrl } from "../utils/validationUtils";

const DetailPanelContent = ({
  row,
  handleSetTableData,
  tableIndex,
  tableRows,
  tableId,
  darkMode,
  affiliateLinksAreOff,
}) => {
  const [updatedRow, setUpdatedRow] = React.useState({ ...row });
  const [imageUrl, setImageUrl] = React.useState(row.imageUrl);

  const updateParentRowState = () => {
    const newRows = { ...tableRows };
    newRows[updatedRow.id] = updatedRow;
    handleSetTableData(tableIndex, "rows", newRows);
  };

  const handleUpdateRow = (key, value) => {
    const newUpdatedRow = { ...updatedRow, [key]: value };
    setUpdatedRow(newUpdatedRow);
    if (key === "imageUrl") setImageUrl(value);
  };

  const handleBlur = (key, value) => {
    const newRows = { ...tableRows };
    newRows[updatedRow.id] = { ...updatedRow, [key]: value };
    handleSetTableData(tableIndex, "rows", newRows);
  };

  return (
    <Container
      sx={{
        height: "500px",
        backgroundColor: darkMode ? "#202020" : "#EEE",
      }}
      key={`${tableId}-${tableIndex}-${row.id}`}
    >
      <Stack alignItems="center">
        <Stack sx={{ marginLeft: "50px", paddingTop: "20px" }}>
          <DetailPanelContentActions
            updatedRow={updatedRow}
            setUpdatedRow={setUpdatedRow}
          />
          <Stack direction="row">
            {imageUrl ? (
              <Paper
                sx={{
                  height: "285px",
                  width: "285px",
                  marginTop: "22px",
                  backgroundColor: "white",
                }}
              >
                <img
                  alt="item"
                  className={
                    isValidUrl(updatedRow.url)
                      ? "detail-panel-content-hover"
                      : ""
                  }
                  style={{
                    height: "285px",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                  src={imageUrl}
                  onClick={() => {
                    if (isValidUrl(updatedRow.url)) {
                      externalLinkWrapper(updatedRow.url, affiliateLinksAreOff);
                    }
                  }}
                />
              </Paper>
            ) : (
              <Box
                style={{
                  height: "285px",
                  width: "285px",
                  backgroundColor: darkMode ? "#333" : "white",
                  textAlign: "center",
                  lineHeight: "300px",
                  marginTop: "22px",
                }}
              >
                No Image Available
              </Box>
            )}
            <Stack sx={{ height: "300px", width: "400px" }}>
              <TextField
                label="Item"
                variant="outlined"
                size="small"
                sx={{ width: "380px", marginLeft: "20px", marginTop: "20px" }}
                defaultValue={updatedRow.item}
                onChange={(event) => {
                  handleUpdateRow("item", event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.target.blur();
                  }
                  event.stopPropagation();
                }}
                onBlur={() => {
                  updateParentRowState();
                }}
                InputLabelProps={{ shrink: true }}
              />
              <Stack direction="row" sx={{ width: "400px", marginTop: "20px" }}>
                <TextField
                  label="Brand"
                  variant="outlined"
                  size="small"
                  sx={{ width: "195px", marginLeft: "20px" }}
                  value={updatedRow.brand}
                  onChange={(event) => {
                    handleUpdateRow("brand", event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.target.blur();
                    }
                    event.stopPropagation();
                  }}
                  onBlur={() => {
                    updateParentRowState();
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <Autocomplete
                  freeSolo
                  sx={{ width: "225px", marginLeft: "10px" }}
                  autoSelect
                  disableClearable
                  options={DEFAULT_CATEGORIES.map((option) => ({
                    id: option,
                    label: option,
                  }))}
                  value={updatedRow.category}
                  size="small"
                  onChange={(event, value) => {
                    const valueToUpdate = value.label ? value.label : value;
                    handleUpdateRow("category", valueToUpdate);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.target.blur();
                    }
                    event.stopPropagation();
                  }}
                  onBlur={(e) => {
                    const valueToUpdate = e.target.value;
                    handleBlur("category", valueToUpdate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Item Type"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: "20px", width: "400px" }}>
                <TextField
                  label="Weight"
                  size="small"
                  type="number"
                  sx={{ width: "100px", marginLeft: "20px" }}
                  value={updatedRow.weight}
                  onChange={(event) => {
                    const newVal =
                      event.target.value < 0
                        ? (event.target.value = 0)
                        : event.target.value;
                    handleUpdateRow("weight", newVal);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.target.blur();
                    }
                    event.stopPropagation();
                  }}
                  onBlur={() => {
                    updateParentRowState();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl
                  sx={{ width: "70px", marginLeft: "10px" }}
                  size="small"
                >
                  <InputLabel>Unit</InputLabel>
                  <Select
                    value={updatedRow.unit}
                    label="Unit"
                    onChange={(event) => {
                      handleUpdateRow("unit", event.target.value);
                    }}
                    onBlur={() => {
                      updateParentRowState();
                    }}
                  >
                    <MenuItem value="g">g</MenuItem>
                    <MenuItem value="oz">oz</MenuItem>
                    <MenuItem value="lb">lb</MenuItem>
                    <MenuItem value="kg">kg</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Price"
                  size="small"
                  type="number"
                  sx={{ width: "130px", marginLeft: "10px" }}
                  value={Number(updatedRow.price)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    const newVal =
                      event.target.value < 0
                        ? (event.target.value = 0)
                        : event.target.value;
                    handleUpdateRow("price", newVal);
                  }}
                  onBlur={() => {
                    updateParentRowState();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Quantity"
                  size="small"
                  type="number"
                  sx={{ width: "80px", marginLeft: "10px" }}
                  value={updatedRow.quantity}
                  onChange={(event) => {
                    const newVal =
                      event.target.value < 0
                        ? (event.target.value = 0)
                        : event.target.value;
                    handleUpdateRow("quantity", newVal);
                  }}
                  onBlur={() => {
                    updateParentRowState();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
              <TextField
                label="Description"
                multiline
                value={updatedRow.description}
                size="small"
                rows={4}
                onChange={(event) => {
                  handleUpdateRow("description", event.target.value);
                }}
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                onBlur={() => {
                  updateParentRowState();
                }}
                sx={{ width: "380px", marginLeft: "20px", marginTop: "20px" }}
                InputLabelProps={{ shrink: true }}
              />
              <Stack direction="row">
                <TextField
                  label="Link"
                  size="small"
                  value={updatedRow.url}
                  onChange={(event) => {
                    handleUpdateRow("url", event.target.value);
                  }}
                  onBlur={() => {
                    updateParentRowState();
                  }}
                  sx={{
                    width: isValidUrl(updatedRow.url) ? "330px" : "380px",
                    marginLeft: "20px",
                    marginTop: "20px",
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                {isValidUrl(updatedRow.url) ? (
                  <IconButton
                    sx={{
                      width: "40px",
                      height: "40px",
                      marginTop: "20px",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      externalLinkWrapper(updatedRow.url, affiliateLinksAreOff);
                    }}
                  >
                    <LinkIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          </Stack>
          <TextField
            label="Image Link"
            size="small"
            value={updatedRow.imageUrl}
            onChange={(event) => {
              handleUpdateRow("imageUrl", event.target.value);
            }}
            onBlur={() => {
              updateParentRowState();
            }}
            sx={{ width: "285px", marginTop: "23px" }}
            InputLabelProps={{ shrink: true }}
          />
          <Typography
            variant="caption"
            sx={{ paddingTop: "5px", paddingLeft: "5px" }}
          >
            Tip: Choosing an item type from the dropdown allows us to generate
            item suggestions for your pack.
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default DetailPanelContent;
