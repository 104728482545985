import React from "react";
import Dialog from "@mui/material/Dialog";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import RequestForgotPasswordForm from "./RequestForgotPasswordForm";

const AuthenticationDialog = ({
  authenticationIsOpen,
  setAuthenticationIsOpen,
  setIsLogin,
  isLogin,
  handleSetUser,
}) => {
  const [forgotPasswordFormIsOpen, setForgotPasswordFormIsOpen] =
    React.useState(false);

  const handleClose = () => {
    setAuthenticationIsOpen(false);
    setForgotPasswordFormIsOpen(false);
  };

  const handleSetUserAndClose = (user) => {
    handleSetUser(user);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={authenticationIsOpen} disableScrollLock>
      {isLogin ? (
        forgotPasswordFormIsOpen ? (
          <RequestForgotPasswordForm
            setForgotPasswordFormIsOpen={setForgotPasswordFormIsOpen}
          />
        ) : (
          <SignInForm
            handleSetUserAndClose={handleSetUserAndClose}
            setIsLogin={setIsLogin}
            setForgotPasswordFormIsOpen={setForgotPasswordFormIsOpen}
            setAuthenticationIsOpen={setAuthenticationIsOpen}
          />
        )
      ) : (
        <SignUpForm
          handleSetUserAndClose={handleSetUserAndClose}
          setIsLogin={setIsLogin}
          setAuthenticationIsOpen={setAuthenticationIsOpen}
        />
      )}
    </Dialog>
  );
};

export default AuthenticationDialog;
