import React from "react";
import { Helmet } from "react-helmet";
import { Stack, Container, Typography, Paper, Box } from "@mui/material";
import SubNavBar from "./SubNavBar";
import { getColorWithMode } from "../constants/colors";

const AboutUsContainer = ({ isDarkMode }) => {
  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <SubNavBar darkMode={isDarkMode} />
      <Container
        sx={{
          width: "100%",
          height: "80px",
          textAlign: "center",
          backgroundColor: getColorWithMode(isDarkMode, "title"),
          padding: "0px 0px 0px 0px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "700",
            fontFamily: "Helvetica",
            lineHeight: "80px",
          }}
        >
          About Us
        </Typography>
      </Container>
      <Container
        sx={{
          width: "100%",
          minHeight: `calc(100vh - 130px)`,
          height: "auto",
          bottom: "0px",
          backgroundColor: getColorWithMode(isDarkMode, "base"),
          textAlign: "center",
        }}
      >
        <Stack spacing={2} sx={{ padding: "20px 20px 20px 20px" }}>
          <Paper
            elevation={3}
            sx={{ width: "100%", padding: "20px 20px 20px 20px" }}
          >
            <Stack spacing={2}>
              <Typography textAlign="left" variant="h5">
                About PackWizard
              </Typography>
              <Typography
                textAlign="left"
                variant="body1"
                sx={{ fontSize: "18px" }}
              >
                Researching gear can be time consuming and frustrating. We
                started PackWizard to help people research gear and put together
                gear lists that will help them be more confident and comfortable
                on their outdoor adventures.
              </Typography>
              <Typography
                textAlign="left"
                variant="body1"
                sx={{ fontSize: "18px" }}
              >
                We have been in the position where we don't have the right gear
                for a trip or have spent hundreds of dollars on a product that
                doesn't work for us. With PackWizard, we want to help people
                with selecting the right gear through research tools like the
                Gear Explorer and by being able to see what other people have
                brought on similar trips through the Pack Browser.
              </Typography>
            </Stack>
          </Paper>
          <Typography
            textAlign="left"
            variant="h5"
            sx={{ padding: "20px 20px 0px 15px" }}
          >
            Who We Are
          </Typography>
          <Paper
            elevation={4}
            sx={{ width: "100%", padding: "20px 20px 20px 20px" }}
          >
            <Stack spacing={2} direction="row">
              <Box
                sx={{
                  minHeight: "200px",
                  maxHeight: "200px",
                  minWidth: "200px",
                  maxWidth: "200px",
                  backgroundPosition: "center",
                  backgroundImage: "url('/thomas.jpg')",
                  backgroundSize: "250px 240px",
                }}
              ></Box>
              <Typography
                textAlign="left"
                variant="body1"
                sx={{ fontSize: "18px" }}
              >
                My name is Thomas and I've been an outdoorsman for as long as I
                can remember, whether it be hiking, camping, fly-fishing or
                surfing, if it puts you in nature, you can sign me up. I'm a
                certified gear junkie in all of my hobbies, but backpacking gear
                has always felt incredibly overwhelming to me. I'd spend weeks
                of my free time researching a new piece of gear, only to buy it
                and find out shortly after that an even better lighter and maybe
                even cheaper alternative existed. At the beginning of 2023, I
                set out to build PackWizard to handle all of that research to
                allow you and I to quicky get back outside (with no buyers
                remorse &#x1F601;).
              </Typography>
            </Stack>
          </Paper>
          <Paper
            elevation={4}
            sx={{ width: "100%", padding: "20px 20px 20px 20px" }}
          >
            <Stack spacing={2} direction="row">
              <Box
                sx={{
                  minHeight: "200px",
                  maxHeight: "200px",
                  minWidth: "200px",
                  maxWidth: "200px",
                  backgroundImage: "url('/justin.jpg')",
                  backgroundSize: "200px 200px",
                }}
              ></Box>
              <Typography
                textAlign="left"
                variant="body1"
                sx={{ fontSize: "18px" }}
              >
                My name is Justin and I have been camping, hiking, backpacking,
                and canoe tripping for over 25 years. I have been backpacking in
                the Canadian Rockies for the past 10 years and am in love with
                the mountains! It doesn't matter what the activity is or for how
                long, I love getting outdoors. One of my driving goals for
                PackWizard as well as my other content avenues (Justin Outdoors
                YouTube, Podcast, Instagram, and TikTok) is to help people enjoy
                their outdoor experiences as much as possible. Whether it's
                going for a walk in the local park or doing a 7 day backpacking
                trip, I think it's important for everyone to be getting
                outdoors.
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </Stack>
  );
};

export default AboutUsContainer;
