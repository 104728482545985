import React from "react";
import Stack from "@mui/system/Stack";
import Typography from "@mui/material/Typography";
import { Divider, MenuItem, Select, Slider, Tooltip } from "@mui/material";

const marks = [
  {
    value: 20,
    label: "$20",
  },
  {
    value: 1000,
    label: "None",
  },
];

const SuggestionSettings = () => {
  const [userSettings, setUserSettings] = React.useState({
    maxPrice: 1000,
    packSizes: 5,
  });

  React.useEffect(() => {
    (async () => {
      const localUserSettings = localStorage.getItem("suggestionSettings");
      if (!localUserSettings) {
        await localStorage.setItem(
          "suggestionSettings",
          JSON.stringify({ maxPrice: 1000, packSizes: 5 })
        );
        setUserSettings({ maxPrice: 1000, packSizes: 5 });
      } else {
        setUserSettings(JSON.parse(localUserSettings));
      }
    })();
  }, []);

  const handleChangeSettings = async (type, value) => {
    setUserSettings({ ...userSettings, [type]: value });
    await localStorage.setItem(
      "suggestionSettings",
      JSON.stringify({ ...userSettings, [type]: value })
    );
  };

  return (
    <Stack
      sx={{
        width: "240px",
        margin: "0 auto",
        marginTop: "10px",
      }}
    >
      <Typography sx={{ margin: "0 auto", paddingBottom: "7px" }}>
        Suggestion Settings
      </Typography>
      <Stack
        direction="row"
        sx={{
          width: "220px",
          margin: "0 auto",
          marginTop: "10px",
        }}
      >
        <Tooltip title={"The maximum price a suggested item can be"}>
          <Typography
            sx={{
              verticalAlign: "middle",
              lineHeight: "40px",
              width: "80px",
            }}
          >
            Max Price:
          </Typography>
        </Tooltip>
        <Slider
          sx={{ width: "110px", marginLeft: "20px", paddingBottom: "20px" }}
          size="small"
          value={userSettings.maxPrice}
          marks={marks}
          max={1000}
          min={20}
          onChange={(e) => {
            handleChangeSettings("maxPrice", e.target.value);
          }}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) =>
            value === 1000 ? <div>No Limit</div> : <div>{`$${value}`}</div>
          }
        />
      </Stack>
      <Divider />
      <Stack
        direction="row"
        sx={{
          width: "220px",
          margin: "0 auto",
          marginTop: "10px",
          paddingBottom: "5px",
        }}
      >
        <Tooltip
          title={
            "The range of packs we will suggest based on your pack size. Ex: If you have a 40L pack, and set this to 5L (+/-) we will suggest packs from 35L to 45L"
          }
        >
          <Typography
            sx={{
              verticalAlign: "middle",
              lineHeight: "40px",
              width: "100px",
            }}
          >
            Pack Sizes:
          </Typography>
        </Tooltip>
        <Select
          sx={{ width: "130px", marginLeft: "-10px" }}
          size="small"
          value={userSettings.packSizes}
          onChange={(e) => {
            handleChangeSettings("packSizes", e.target.value);
          }}
        >
          <MenuItem value={5}>{"5L (+/-)"}</MenuItem>
          <MenuItem value={7}>{"7L (+/-)"}</MenuItem>
          <MenuItem value={10}>{"10L (+/-)"}</MenuItem>
        </Select>
      </Stack>
      <Divider />
    </Stack>
  );
};

export default SuggestionSettings;
