import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import WEIGHT_TYPES from "../constants/weightTypes.json";
import ItemLookupAutocomplete from "./ItemLookupAutocomplete";
import DEFAULT_CATEGORIES from "../constants/defaultCategories";
import { Typography } from "@mui/material";
import uniqid from "uniqid";

const EMPTY_ITEM = {
  weight: 0,
  unit: "g",
  item: "",
  category: "",
  brand: "",
  quantity: 1,
  price: 0,
  weightType: WEIGHT_TYPES.BASE,
  imageUrl: "",
  url: "",
  description: "",
};

const createEmptyItem = (id, tableWeightType) => {
  return {
    ...EMPTY_ITEM,
    weightType: tableWeightType ? tableWeightType : WEIGHT_TYPES.BASE,
    id,
  };
};

const AddRowForm = ({
  setIsAddItemOpen,
  handleAddNewItemToTable,
  tableWeightType,
  darkMode,
}) => {
  const [itemToBeAdded, setItemToBeAdded] = React.useState(
    createEmptyItem(uniqid("row_"), tableWeightType)
  );

  const handleUpdateItemToBeAdded = (key, value) => {
    const currentItemToBeAdded =
      itemToBeAdded && Object.keys(itemToBeAdded).length
        ? itemToBeAdded
        : createEmptyItem(uniqid("row_"), tableWeightType);
    setItemToBeAdded({
      ...currentItemToBeAdded,
      [key]: value,
    });
  };

  const handleSetItemWithAutocomplete = (item) => {
    if (!item) return;
    const currentItemToBeAdded =
      itemToBeAdded && Object.keys(itemToBeAdded).length
        ? itemToBeAdded
        : createEmptyItem(uniqid("row_"), tableWeightType);

    if (typeof item === "string") {
      setItemToBeAdded({
        ...currentItemToBeAdded,
        gearClosetId: "",
        item,
      });
    } else {
      setItemToBeAdded({
        ...currentItemToBeAdded,
        brand: item.brand,
        item: item.item,
        weight: item.displayWeight,
        description: item.description,
        unit: item.unit,
        category: item.category,
        price: item.price,
        url: item.url,
        reviews: item.reviews,
        imageUrl: item.imageUrl ? `${item.imageUrl}` : "",
        affiliateLink: item.affiliateLink ? item.affiliateLink : "",
        gearClosetId: item.gearClosetId ? item.gearClosetId : "",
      });
    }
  };

  return (
    <Stack sx={{ width: "100%" }} alignItems="center">
      <Box
        sx={{
          marginLeft: "50px",
          marginTop: "-20px",
        }}
      >
        <IconButton
          sx={{ marginLeft: "calc(100% - 40px)" }}
          color="primary"
          onClick={() => setIsAddItemOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} sx={{ marginTop: "10px" }}>
            <ItemLookupAutocomplete
              handleSetItemWithAutocomplete={handleSetItemWithAutocomplete}
              handleUpdateItemToBeAdded={handleUpdateItemToBeAdded}
            />
            <TextField
              label="Brand"
              size="small"
              value={itemToBeAdded.brand}
              sx={{ width: "252px" }}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                handleUpdateItemToBeAdded("brand", event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.target.blur();
                }
                event.stopPropagation();
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ marginTop: "10px" }}>
            <Autocomplete
              freeSolo
              sx={{ width: "200px" }}
              disableClearable
              options={DEFAULT_CATEGORIES.map((option) => ({
                id: option,
                label: option,
              }))}
              value={itemToBeAdded.category}
              size="small"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.target.blur();
                }
                event.stopPropagation();
              }}
              onChange={(event, value) => {
                handleUpdateItemToBeAdded(
                  "category",
                  value.id ? value.id : value
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(event) => {
                    handleUpdateItemToBeAdded("category", event.target.value);
                  }}
                  label="Item Type"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
            <TextField
              label="Weight"
              size="small"
              type="number"
              sx={{ width: "100px" }}
              value={itemToBeAdded.weight}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.target.blur();
                }
                event.stopPropagation();
              }}
              onChange={(event) => {
                const newVal =
                  event.target.value < 0
                    ? (event.target.value = 0)
                    : event.target.value;
                handleUpdateItemToBeAdded("weight", newVal);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl sx={{ width: "65px" }} size="small">
              <InputLabel>Unit</InputLabel>
              <Select
                value={itemToBeAdded.unit}
                label="Unit"
                onChange={(event) => {
                  handleUpdateItemToBeAdded("unit", event.target.value);
                }}
              >
                <MenuItem value="g">g</MenuItem>
                <MenuItem value="oz">oz</MenuItem>
                <MenuItem value="lb">lb</MenuItem>
                <MenuItem value="kg">kg</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Price"
              size="small"
              type="number"
              sx={{ width: "100px" }}
              value={itemToBeAdded.price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.target.blur();
                }
              }}
              onChange={(event) => {
                const newVal =
                  event.target.value < 0
                    ? (event.target.value = 0)
                    : event.target.value;
                handleUpdateItemToBeAdded("price", newVal);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Quantity"
              size="small"
              type="number"
              sx={{ width: "80px" }}
              value={itemToBeAdded.quantity}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.target.blur();
                }
                event.stopPropagation();
              }}
              onChange={(event) => {
                const newVal =
                  event.target.value < 0
                    ? (event.target.value = 0)
                    : event.target.value;
                handleUpdateItemToBeAdded("quantity", newVal);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
          <Stack spacing={2}>
            <TextField
              label="Description"
              multiline
              size="small"
              rows={3}
              sx={{ width: "608px" }}
              value={itemToBeAdded.description}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.target.blur();
                }
                event.stopPropagation();
              }}
              onChange={(event) => {
                handleUpdateItemToBeAdded("description", event.target.value);
              }}
            />
            <TextField
              label="Link"
              size="small"
              value={itemToBeAdded.url}
              sx={{ width: "608px" }}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                handleUpdateItemToBeAdded("url", event.target.value);
              }}
            />
            <TextField
              label="Image Link"
              size="small"
              sx={{ width: "608px" }}
              value={itemToBeAdded.imageUrl}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.target.blur();
                }
                event.stopPropagation();
              }}
              onChange={(event) => {
                handleUpdateItemToBeAdded("imageUrl", event.target.value);
              }}
            />
            <Typography variant="caption">
              Tip: Choosing an item type from the dropdown allows us to generate
              item suggestions for your pack.
            </Typography>
            <Button
              sx={{
                marginLeft: "calc(50% - 100px) !important",
                width: "200px",
              }}
              variant={darkMode ? "outlined" : "contained"}
              onClick={() => {
                setIsAddItemOpen(false);
                handleAddNewItemToTable(itemToBeAdded);
                setItemToBeAdded(createEmptyItem(itemToBeAdded.id + 1));
              }}
            >
              Add Item to Pack
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default AddRowForm;
