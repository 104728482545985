import React from "react";

import { Divider, Paper, Stack, Typography } from "@mui/material";
import { getUsersPackAnalytics } from "../utils";
import { Line } from "react-chartjs-2";

import { interpolateColors } from "../utils/colorGenerator";
import { interpolateTurbo } from "d3-scale-chromatic";

const colorScale = interpolateTurbo;
const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: true,
};

const createViewHistoryDataSets = (analytics) => {
  const colors = interpolateColors(
    analytics.length,
    colorScale,
    colorRangeInfo
  );
  return analytics.reduce((acc, curr, index) => {
    acc.push({
      label: curr.packName,
      data: curr.viewHistoryAsTimeline
        ? curr.viewHistoryAsTimeline.map(({ views }) => views)
        : [],
      borderColor: colors[index],
      backgroundColor: colors[index],
    });
    return acc;
  }, []);
};

const UserProfileAnalyticsModule = () => {
  const [userPackAnalytics, setUserPackAnalytics] = React.useState([]);
  const [totalPackAnalytics, setTotalPackAnalytics] = React.useState({});
  const [formattedViewHistory, setFormattedViewHistory] = React.useState({});

  React.useEffect(() => {
    (async () => {
      const { packAnalytics, totalAnalytics } = await getUsersPackAnalytics();
      setUserPackAnalytics(packAnalytics);
      setTotalPackAnalytics(totalAnalytics);

      setFormattedViewHistory({
        labels: packAnalytics.length
          ? packAnalytics[0].viewHistoryAsTimeline.map(({ date }) => date)
          : [],
        datasets: createViewHistoryDataSets(packAnalytics),
      });
    })();
  }, []);

  return userPackAnalytics && userPackAnalytics.length ? (
    <>
      <Typography variant="h5" textAlign="left">
        Pack Analytics
      </Typography>
      <Stack alignItems="center" sx={{ width: "700px" }}>
        <Paper
          sx={{
            marginTop: "10px",
            padding: "10px 10px 10px 10px",
            height: "100%",
            width: "100%",
          }}
          elevation={2}
        >
          <Stack direction="row" spacing={3} justifyContent="center">
            <Paper sx={{ padding: "5px 5px 5px 5px" }}>
              <Stack alignItems="center">
                <Typography>{totalPackAnalytics.viewCount}</Typography>
                <Divider />
                <Typography>Total Views</Typography>
              </Stack>
            </Paper>
            <Paper sx={{ padding: "5px 5px 5px 5px" }}>
              <Stack alignItems="center">
                <Typography>{totalPackAnalytics.voteCount}</Typography>
                <Divider />
                <Typography>Total Votes</Typography>
              </Stack>
            </Paper>
            <Paper sx={{ padding: "5px 5px 5px 5px" }}>
              <Stack alignItems="center">
                <Typography>{totalPackAnalytics.upvoteCount}</Typography>
                <Divider />
                <Typography>Total Upvotes</Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack>
            <Typography
              variant="h8"
              sx={{ paddingTop: "10px", textAlign: "center" }}
            >
              Views for the Last 30 days
            </Typography>
            <Line data={formattedViewHistory} />
          </Stack>
        </Paper>
      </Stack>
    </>
  ) : (
    <></>
  );
};

export default UserProfileAnalyticsModule;
