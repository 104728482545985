export const fetchSleepingPads = () => {
  return fetch(`/api/gear/sleepingPads`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchTents = () => {
  return fetch(`/api/gear/tents`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchSleepingBags = () => {
  return fetch(`/api/gear/sleepingBags`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchBackpacks = () => {
  return fetch(`/api/gear/backpacks`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchInsulatedJackets = () => {
  return fetch(`/api/gear/insulatedJackets`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchStoves = () => {
  return fetch(`/api/gear/stoves`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchItemForItemDisplay = (gearType, id) => {
  return fetch(`/api/gear/item?type=${gearType}&id=${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getProductsForComparison = ({ productIds, type }) => {
  return fetch(`/api/gear/products?productIds=${productIds}&type=${type}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getGearMetrics = ({ gearType, productId }) => {
  return fetch(
    `/api/gear/metrics?gearType=${gearType}&productId=${productId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDeals = () => {
  return fetch(`/api/gear/dashboard`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDailyDealsByGearType = ({ gearType }) => {
  return fetch(`/api/gear/dealsByGearType?gearType=${gearType}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchFilteredGearModuleData = async ({ filters }) => {
  return fetch(`/api/gear/fetchGearWithFilters`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST", // just using for body
    body: JSON.stringify({ filters }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
