import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useMediaQuery } from "react-responsive";

const urlMap = {
  PACKWIZARD_SP: "sleeping-pad",
  PACKWIZARD_TENT: "tent",
  PACKWIZARD_PACK: "backpacks",
  PACKWIZARD_SB: "sleeping-bag",
  PACKWIZARD_IJ: "insulated-jacket",
  PACKWIZARD_ST: "stove",
};

const PackWizardCompareLinkButton = ({
  id,
  width,
  isDesktopOrLaptop,
  compareType,
  text,
  useSmall,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const PWIcon = (
    <Icon
      sx={{
        textAlign: "center",
        width: isMobile ? "40px" : "25px",
        height: isMobile ? "40px" : "32px",
        marginRight: "-10px",
        marginTop: "-5px",
        color: "white",
      }}
    >
      <img
        style={{ height: "100%" }}
        src="/pack_wizard.png"
        alt="Pack Wizard"
      />
    </Icon>
  );

  return (
    <Button
      variant="filled"
      sx={{
        backgroundColor: "#213236",
        width,
        color: "white",
        textTransform: "none",
        padding: "0px",
        height:
          isMobile && !useSmall ? "55px" : isDesktopOrLaptop ? "40px" : "35px",
        marginTop: "10px",
        paddingTop: "5px",
        fontFamily: "Roboto, sans-serif",
        fontSize: isMobile && !useSmall ? "20px" : "",
        "&:hover": {
          opacity: 0.8,
          backgroundColor: "#213236",
        },
      }}
      endIcon={PWIcon}
      onClick={() => {
        window.open(`/gear/${urlMap[compareType]}/${id}`, "_blank").focus();
      }}
    >
      {text ? text : "Compare Prices"}
    </Button>
  );
};
export default PackWizardCompareLinkButton;
