export const comparisonMap = {
  sleepingPad: [
    {
      key: "R-Value",
      colorCode: "greater", // greater means higher is better so show green
    },
    {
      key: "Weight (Standard)",
      colorCode: "less", // less means lower value is better so show green
    },
    {
      key: "Weight (Metric)",
      colorCode: "less", // less means lower value is better so show green
    },
    {
      key: "Packed dimension (Standard)",
      colorCode: "none",
    },
    {
      key: "Packed dimension (Metric)",
      colorCode: "none",
    },
    {
      key: "Length (Standard)",
      colorCode: "none",
    },
    {
      key: "Length (Metric)",
      colorCode: "none",
    },
    {
      key: "Width (Standard)",
      colorCode: "none",
    },
    {
      key: "Width (Metric)",
      colorCode: "none",
    },
    {
      key: "Thickness (Standard)",
      colorCode: "none",
    },
    {
      key: "Thickness (Metric)",
      colorCode: "none",
    },
    {
      key: "What's Included",
      colorCode: "none",
    },
  ],
  tent: [
    { key: "Trail Weight", colorCode: "none" },
    { key: "Packed weight", colorCode: "none" },
    { key: "Capacity", colorCode: "none" },
    { key: "Setup", colorCode: "none" },
    { key: "Double Walled", colorCode: "none" },
    { key: "Width", colorCode: "none" },
    { key: "Length", colorCode: "none" },
    { key: "Peak Height", colorCode: "none" },
    { key: "Interior Space", colorCode: "none" },
    { key: "Symmetrical Floor", colorCode: "none" },
    { key: "Vestibule Space", colorCode: "none" },
    { key: "Fly Material", colorCode: "none" },
    { key: "Fly Fabric Denier", colorCode: "none" },
    { key: "Fly First", colorCode: "none" },
    { key: "Vestibule Count", colorCode: "none" },
    { key: "Seasons", colorCode: "none" },
    { key: "Floor Fabric Denier", colorCode: "none" },
  ],
};
