import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Table,
  Button,
  Divider,
  Skeleton,
} from "@mui/material";

import { refreshPricingData } from "../../../utils";

const cellStyles = {
  paddingTop: "5px",
  paddingBottom: "5px",
};

const DataRefreshDialog = ({
  rowToRefresh,
  isOpen,
  handleCloseDataRefreshDialog,
  handleUpdateRowAfterRefresh,
}) => {
  const [refreshIsLoading, setRefreshIsLoading] = React.useState(false);
  const [updatedRow, setUpdatedRow] = React.useState(null);
  const [dataWasRefreshed, setDataWasRefreshed] = React.useState(false);

  const handleRefreshPricingData = async () => {
    setRefreshIsLoading(true);
    const response = await refreshPricingData(rowToRefresh);
    handleUpdateRowAfterRefresh(response);
    setUpdatedRow(response);
    setDataWasRefreshed(true);
    setRefreshIsLoading(false);
  };

  const handleClose = () => {
    setUpdatedRow(null);
    setDataWasRefreshed(false);
    handleCloseDataRefreshDialog();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Retailer Data</DialogTitle>
      {rowToRefresh ? (
        <>
          <DialogContent sx={{ width: "600px" }}>
            <Typography>{`${rowToRefresh.formattedBrand} ${rowToRefresh.name}`}</Typography>
          </DialogContent>
          <DialogContent sx={{ width: "100%" }}>
            <Stack sx={{ width: "100%" }} alignItems="center">
              <Typography>Current Retailers</Typography>
              <TableContainer size="small" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={cellStyles}>Retailer</TableCell>
                      <TableCell sx={cellStyles}>Sale Price</TableCell>
                      <TableCell sx={cellStyles}>Retail Price</TableCell>
                      <TableCell sx={cellStyles}>Last Updated</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowToRefresh.retailers.map(
                      ({ retailer, lastUpdated, retailPrice, salePrice }) => (
                        <TableRow>
                          <TableCell sx={cellStyles}>{retailer}</TableCell>
                          <TableCell sx={cellStyles}>{salePrice}</TableCell>
                          <TableCell sx={cellStyles}>{retailPrice}</TableCell>
                          <TableCell sx={cellStyles}>
                            {lastUpdated.split("T")[0]}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography sx={{ paddingTop: "20px" }}>Refresh Data</Typography>
              <Divider sx={{ width: "100%" }} />
              {refreshIsLoading ? (
                <>
                  <Skeleton
                    width={600}
                    height={40}
                    sx={{ margin: "0 auto" }}
                  ></Skeleton>
                  <Skeleton
                    width={600}
                    height={40}
                    sx={{ margin: "0 auto" }}
                  ></Skeleton>
                  <Skeleton
                    width={600}
                    height={40}
                    sx={{ margin: "0 auto" }}
                  ></Skeleton>
                  <Skeleton
                    width={600}
                    height={40}
                    sx={{ margin: "0 auto" }}
                  ></Skeleton>
                </>
              ) : dataWasRefreshed ? (
                updatedRow ? (
                  <>
                    <Typography>New data</Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={cellStyles}>Retailer</TableCell>
                            <TableCell sx={cellStyles}>Sale Price</TableCell>
                            <TableCell sx={cellStyles}>Retail Price</TableCell>
                            <TableCell sx={cellStyles}>Last Updated</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {updatedRow.retailers.map(
                            ({
                              retailer,
                              lastUpdated,
                              retailPrice,
                              salePrice,
                            }) => (
                              <TableRow>
                                <TableCell sx={cellStyles}>
                                  {retailer}
                                </TableCell>
                                <TableCell sx={cellStyles}>
                                  {salePrice}
                                </TableCell>
                                <TableCell sx={cellStyles}>
                                  {retailPrice}
                                </TableCell>
                                <TableCell sx={cellStyles}>
                                  {lastUpdated.split("T")[0]}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                          <TableRow sx={{}}>
                            <TableCell
                              sx={{
                                ...cellStyles,
                                fontWeight: "bold",
                                textDecoration: "underline",
                              }}
                            >
                              Ignored Retailers
                            </TableCell>
                            <TableCell sx={cellStyles}></TableCell>
                            <TableCell sx={cellStyles}></TableCell>
                            <TableCell sx={cellStyles}></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={4}>
                              These are retailers that have this item, but we do
                              not have affiliate status with and therefore we do
                              not add them to the live pricing data
                            </TableCell>
                          </TableRow>
                          {updatedRow.ignoredRetailers.map((name) => (
                            <TableRow>
                              <TableCell sx={cellStyles}>{name}</TableCell>
                              <TableCell sx={cellStyles}></TableCell>
                              <TableCell sx={cellStyles}></TableCell>
                              <TableCell sx={cellStyles}></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <Typography>Error in refresh</Typography>
                )
              ) : (
                <>
                  <Typography
                    fontStyle="italic"
                    color="error"
                    sx={{ width: "600px" }}
                  >
                    Be mindful of our SerpAPI quota and try not to overuse the
                    ad-hoc data fetch
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleRefreshPricingData}
                  >
                    Refresh Pricing Data
                  </Button>
                </>
              )}
            </Stack>
          </DialogContent>
        </>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default DataRefreshDialog;
