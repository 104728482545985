import React from "react";

import { getColorWithMode } from "../constants/colors";
import {
  Stack,
  Container,
  Avatar,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Helmet } from "react-helmet";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTokIcon from "./TikTokIcon";
import { useParams } from "react-router-dom";
import { fetchUserProfile } from "../utils";
import SubNavBar from "./SubNavBar";
import UserPublicPacksModule from "./UserPublicPacksModule";

const UserProfileContainer = ({ darkMode }) => {
  const [userData, setUserData] = React.useState({
    username: "",
    bio: "",
    facebook: "",
    tiktok: "",
    youtube: "",
    instagram: "",
    avatar: "",
    coverPhoto: "",
  });

  const { username } = useParams();

  React.useEffect(() => {
    (async () => {
      const data = await fetchUserProfile(username);
      setUserData(data);
    })();
  }, [username]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>{`@${username}`}</title>
      </Helmet>
      <SubNavBar darkMode={darkMode} />
      <Container
        sx={{
          width: "100%",
          height: "350px",
          textAlign: "center",
          backgroundColor: getColorWithMode(darkMode, "title"),
          padding: "0px 0px 0px 0px",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            overflow: "hidden",
          }}
          alt=""
          src={
            userData.coverPhoto
              ? userData.coverPhoto
              : "https://storage.googleapis.com/pack_wizard/yosemite.jpg"
          }
        ></img>
      </Container>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          height: "300px",
          marginTop: "-60px",
          paddingLeft: "20px",
          paddingBottom: "20px",
          background: `linear-gradient(transparent 20%, ${getColorWithMode(
            darkMode,
            "secondary"
          )} 0%)`,
        }}
      >
        <Avatar
          alt="User"
          src={userData.avatar}
          sx={{ width: "200px", height: "200px" }}
        />
        <Box sx={{ maxWidth: "600px", paddingTop: "85px" }}>
          <Stack spacing={1}>
            <Typography variant="h4">{`@${userData.username}`}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              {userData.facebook ? (
                <IconButton
                  onClick={() => {
                    let url = userData.facebook;
                    if (!url.match(/^https?:\/\//i)) {
                      url = "http://" + url;
                    }
                    window.open(url, "_blank").focus();
                  }}
                >
                  <FacebookIcon />
                </IconButton>
              ) : (
                <></>
              )}
              {userData.instagram ? (
                <IconButton
                  onClick={() => {
                    let url = userData.instagram;
                    if (!url.match(/^https?:\/\//i)) {
                      url = "http://" + url;
                    }
                    window.open(url, "_blank").focus();
                  }}
                >
                  <InstagramIcon />
                </IconButton>
              ) : (
                <></>
              )}
              {userData.youtube ? (
                <IconButton
                  onClick={() => {
                    let url = userData.youtube;
                    if (!url.match(/^https?:\/\//i)) {
                      url = "http://" + url;
                    }
                    window.open(url, "_blank").focus();
                  }}
                >
                  <YouTubeIcon />
                </IconButton>
              ) : (
                <></>
              )}
              {userData.tiktok ? (
                <IconButton
                  onClick={() => {
                    let url = userData.tiktok;
                    if (!url.match(/^https?:\/\//i)) {
                      url = "http://" + url;
                    }
                    window.open(url, "_blank").focus();
                  }}
                >
                  <TikTokIcon
                    width="22px"
                    color={getColorWithMode(darkMode, "text")}
                  />
                </IconButton>
              ) : (
                <></>
              )}
            </Stack>
            <Box sx={{ height: "120px" }}>
              {userData.bio ? (
                <Typography
                  sx={{ maxWidth: "100%", overflowWrap: "break-word" }}
                >{`${userData.bio}`}</Typography>
              ) : (
                <Typography sx={{ color: "grey" }}>
                  No profile description
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Container
        sx={{
          height: "100%",
          minHeight: "calc(100vh - 550px)",
          backgroundColor: getColorWithMode(darkMode, "base"),
          padding: "10px 10px 10px 10px",
        }}
      >
        <Stack alignItems="center" spacing={2}>
          <UserPublicPacksModule username={userData.username} />
        </Stack>
      </Container>
    </Stack>
  );
};

export default UserProfileContainer;
