import React from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import { getColorWithMode } from "../constants/colors";
import { useMediaQuery } from "react-responsive";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CustomToolbar = ({ packName }) => {
  return (
    <GridToolbarContainer sx={{ height: "60px" }}>
      <GridToolbarColumnsButton />
      <GridToolbarExport sx={{ width: "100px", left: "calc(100% - 200px)" }} />
      <Typography sx={{ width: "100%", height: "50px" }} textAlign="center">
        {packName}
      </Typography>
    </GridToolbarContainer>
  );
};

const createTableStructure = (tableData) => {
  return tableData.reduce((acc, curr, index) => {
    const { title, rows } = curr;
    const sectionRows = Object.values(rows).map(
      ({ id, item, price, weight, unit, quantity, category, isSelected }) => {
        return {
          id,
          item,
          price,
          weight,
          unit,
          quantity,
          hierarchy: [title, id],
          category,
          categoryIndex: index,
          selected: isSelected ? isSelected : false,
        };
      }
    );
    return [...acc, ...sectionRows];
  }, []);
};

const getTreeDataPath = (row) => row.hierarchy;

const CustomGridTreeDataGroupingCell = (props) => {
  const { row, rowNode } = props;
  return (
    <Box
      sx={{
        fontSize: rowNode.isAutoGenerated ? "18px" : "",
      }}
    >
      {rowNode.isAutoGenerated ? rowNode.groupingKey : row.item}
    </Box>
  );
};

const PackChecklistContainer = ({
  packName,
  tableData,
  darkMode,
  handleLeaveChecklist,
  modifyPack,
}) => {
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const isMobile = useMediaQuery({ maxWidth: 898 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const apiRef = useGridApiRef();

  React.useEffect(() => {
    const newRows = createTableStructure(tableData);
    const selectedRowInitialState = newRows.reduce((acc, curr) => {
      if (curr.selected) acc.push(curr.id);
      return acc;
    }, []);
    setRows(newRows);
    setRowSelectionModel(selectedRowInitialState);
  }, [tableData]);

  const columns = [
    {
      field: "category",
      headerName: "Item Type",
      width: 120,
      maxWidth: 200,
      flex: 1,
      editable: false,
      headerAlign: "left",
    },
    {
      field: "quantity",
      headerName: "Qty",
      width: 50,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 100,
      editable: false,
      headerClassName: "lastcolumnSeparator",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return `${params.row.weight || ""}${
          params.row.weight && params.row.unit ? params.row.unit : ""
        }`;
      },
    },
  ];

  const handleSetRowSelectionModel = (newRowSelectionModel) => {
    const selectedRows = rows.filter(({ id }) =>
      newRowSelectionModel.includes(id)
    );
    const rowsToUpdate = selectedRows.map(({ categoryIndex, id }) => ({
      categoryIndex,
      id,
    }));
    const newData = [...tableData];
    const formattedSelection = rowsToUpdate.reduce((acc, curr) => {
      if (acc[curr.categoryIndex]) {
        acc[curr.categoryIndex].push(String(curr.id));
      } else {
        acc[curr.categoryIndex] = [String(curr.id)];
      }
      return acc;
    }, {});
    for (let i = 0; i < newData.length; i++) {
      const keys = Object.keys(newData[i].rows);
      for (let j = 0; j < keys.length; j++) {
        if (newData[i].rows[keys[j]]) {
          if (formattedSelection[i]) {
            newData[i].rows[String(keys[j])].isSelected =
              formattedSelection[i] &&
              formattedSelection[i].includes(String(keys[j]));
          } else {
            newData[i].rows[String(keys[j])].isSelected = false;
          }
        }
      }
    }
    modifyPack(newData);
    setRowSelectionModel(newRowSelectionModel);
  };

  return (
    <Container
      fixed
      style={{
        backgroundColor: getColorWithMode(darkMode, "base"),
        height: !isMobile ? "calc(100vh - 50px)" : "calc(100vh - 260px)",
        width: isDesktopOrLaptop ? "1140px" : isMobile ? "100%" : "880px",
        minHeight: "920px",
        maxHeight: "calc(100vh - 50px)",
        overflowY: "scroll",
        marginLeft: "0px",
        paddingBottom: "20px",
        paddingLeft: "0px",
        margin: "0 auto",
      }}
    >
      <Container sx={{ marginTop: "5px" }}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleLeaveChecklist}>
          Back to Pack
        </Button>
        <DataGridPro
          sx={{
            margin: "0 auto",
            width: "770px",
            marginTop: "5px",
            ".Mui-disabled": {
              color: "transparent !important",
            },
            ".MuiDataGrid-row.shouldStrikeThrough": {
              textDecoration: "line-through",
            },
            "@media print": {
              padding: "5px 5px 5px 5px",
              color: "rgba(0, 0, 0, 0.87)",
              border: "0px !important",
              ".MuiDataGrid-virtualScrollerContent": {
                minHeight: `calc(${rows.length} * 50px) !important`,
                color: "rgba(0, 0, 0, 0.87)",
              },
              ".MuiDataGrid-row": {
                minHeight: "40px !important",
              },
              ".MuiCheckbox-root": {
                color: "rgba(0, 0, 0, 0.87)",
              },
              ".Mui-disabled": {
                color: "transparent",
              },
            },
          }}
          apiRef={apiRef}
          density="compact"
          autoHeight
          disableSelectionOnClick
          rows={rows}
          getRowClassName={(params) =>
            rowSelectionModel.find((id) => id === params.row.id)
              ? "shouldStrikeThrough"
              : ""
          }
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          treeData
          hideFooter
          checkboxSelection
          defaultGroupingExpansionDepth={-1}
          slots={{
            toolbar: () => <CustomToolbar packName={packName} />,
          }}
          groupingColDef={{
            headerName: "Name",
            hideDescendantCount: true,
            flex: 1,
            valueFormatter: (params) => {
              return params.value;
            },
            valueGetter: (params) => {
              return params.rowNode.isAutoGenerated
                ? params.rowNode.groupingKey
                : params.row.item;
            },
            renderCell: (params) => (
              <CustomGridTreeDataGroupingCell {...params} />
            ),
          }}
          isRowSelectable={(params) => Object.keys(params.row).length}
          onSelectionModelChange={(newRowSelectionModel) => {
            handleSetRowSelectionModel(newRowSelectionModel);
          }}
          selectionModel={rowSelectionModel}
          components={{
            Toolbar: () => <CustomToolbar packName={packName} />,
            NoRowsOverlay: () => (
              <Box
                sx={{
                  textAlign: "center",
                  verticalAlign: "center",
                  lineHeight: "72px",
                  color: "#999",
                }}
              >
                No Items
              </Box>
            ),
          }}
        />
      </Container>
    </Container>
  );
};

export default PackChecklistContainer;
