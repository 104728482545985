import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ScaleIcon from "@mui/icons-material/Scale";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import {
  addItemToGearCloset,
  convertWeightInUnitToNewUnit,
  getUserIsLoggedIn,
} from "../utils";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const DetailPanelContentActions = ({ updatedRow, setUpdatedRow }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConvertRow = (unitToConvertTo) => {
    const newWeight = convertWeightInUnitToNewUnit(
      updatedRow.weight,
      updatedRow.unit,
      unitToConvertTo
    );
    const fixedDecimalWeight = newWeight.toFixed(2);
    const weightAsNumber = Number(fixedDecimalWeight);
    const newRow = {
      ...updatedRow,
      weight: weightAsNumber,
      unit: unitToConvertTo,
    };
    setUpdatedRow(newRow);
    handleClose();
  };

  const handleAddItemToGearCloset = async () => {
    await addItemToGearCloset(updatedRow);
    handleClose();
  };

  return (
    <div style={{ width: "100%" }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          marginLeft: "565px",
        }}
      >
        Options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleConvertRow("g")}
          disabled={updatedRow.unit === "g"}
        >
          <ScaleIcon />
          Convert to grams
        </MenuItem>
        <MenuItem
          onClick={() => handleConvertRow("oz")}
          disabled={updatedRow.unit === "oz"}
        >
          <ScaleIcon />
          Convert to ounces
        </MenuItem>
        <MenuItem
          onClick={() => handleConvertRow("lb")}
          disabled={updatedRow.unit === "lb"}
        >
          <ScaleIcon />
          Convert to pounds
        </MenuItem>
        <MenuItem
          onClick={() => handleConvertRow("kg")}
          disabled={updatedRow.unit === "kg"}
        >
          <ScaleIcon />
          Convert to kilos
        </MenuItem>
        <MenuItem
          onClick={handleAddItemToGearCloset}
          disabled={!getUserIsLoggedIn()}
        >
          <MeetingRoomIcon />
          Add to Gear Closet
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default DetailPanelContentActions;
