import * as React from "react";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { handleSignUp } from "../auth";
import {
  isValidPassword,
  isValidEmail,
  isValidUsername,
} from "../utils/validationUtils";
import FormHelperText from "@mui/material/FormHelperText";
import { debounce } from "lodash";
import { Stack, Typography } from "@mui/material";

const SignUpForm = ({ setIsLogin, handleSetUserAndClose }) => {
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidPassword, setInvalidPassword] = React.useState(false);
  const [invalidUsername, setInvalidUsername] = React.useState(false);

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    username: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    if (prop === "email") {
      setInvalidEmail(false);
    }
    if (prop === "password") {
      setInvalidPassword(false);
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleValidateAndSetUsername = async (username) => {
    if (username.length === 0) {
      setValues({ ...values, username: "" });
      setInvalidUsername(false);
      return;
    }
    const usernameIsValid = await isValidUsername(username, values.email);
    setInvalidUsername(!usernameIsValid);

    setValues({ ...values, username });
  };

  const debouncedUsernameValidator = debounce(
    handleValidateAndSetUsername,
    300
  );

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickSignIn = () => {
    setIsLogin(true);
  };

  const handleSubmit = async () => {
    const { email, password, username } = values;
    const usernameIsValid = await isValidUsername(username, email);
    if (isValidEmail(email)) {
      if (usernameIsValid) {
        if (isValidPassword(password)) {
          const signUpResponse = await handleSignUp(values);
          if (
            signUpResponse.message === "User already exists" ||
            !signUpResponse.token
          ) {
            setInvalidEmail(true);
          } else {
            handleSetUserAndClose(signUpResponse);
          }
          return;
        } else {
          setInvalidPassword(true);
        }
      } else {
        setInvalidUsername(true);
      }
    } else {
      setInvalidEmail(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <DialogTitle
        style={{
          width: "400px",
          textAlign: "center",
          paddingTop: "30px",
          fontWeight: "bold",
        }}
      >
        SIGN UP
      </DialogTitle>
      <DialogContent
        style={{ width: "400px", height: "470px", paddingTop: "30px" }}
      >
        <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
          <TextField
            error={invalidEmail}
            required
            label="Email"
            defaultValue=""
            style={{ width: "336px" }}
            variant="standard"
            onChange={handleChange("email")}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
          <TextField
            error={invalidUsername}
            helperText={
              <Stack>
                <Typography variant="subtitle2" sx={{ color: "grey" }}>
                  Leave this blank to choose your username later
                </Typography>
                {invalidUsername ? (
                  <Typography variant="subtitle2">
                    Username is already taken
                  </Typography>
                ) : (
                  <></>
                )}
              </Stack>
            }
            inputProps={{ maxLength: 20 }}
            label="Username"
            defaultValue=""
            style={{ width: "336px" }}
            variant="standard"
            onChange={(e) => {
              debouncedUsernameValidator(e.target.value);
            }}
          />
        </FormControl>
        <FormControl
          sx={{ m: 1, width: "25ch", marginTop: "0px" }}
          variant="standard"
        >
          <InputLabel htmlFor="standard-adornment-password">
            Password
          </InputLabel>
          <Input
            required
            error={invalidPassword}
            style={{ width: "336px" }}
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            onKeyPress={handleKeyPress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {invalidPassword ? (
            <FormHelperText
              style={{ width: "330px" }}
              id="component-error-text"
            >
              Passwords must be 8 and 72 characters long and contain at least 1
              uppercase letter, one lowercase letter and a number
            </FormHelperText>
          ) : (
            <></>
          )}
        </FormControl>
        <Button
          variant="contained"
          style={{ width: "340px", marginLeft: "5px", marginTop: "30px" }}
          onClick={() => {
            handleSubmit();
          }}
        >
          SIGN UP
        </Button>
        {/*
                <Divider style={{ marginTop: '30px', marginLeft: '4px', width: '340px', fontStyle: 'italic' }}>or</Divider>
                <GoogleSignInButton styles={{ width: '330px'}} handleSetUserAndClose={handleSetUserAndClose} />
                */}
        <Button
          variant="text"
          style={{
            width: "336px",
            textAlign: "center",
            marginTop: "76px",
            color: "white",
            textTransform: "none",
            marginLeft: "5px",
          }}
          onClick={handleClickSignIn}
        >
          Already have an account? Login
        </Button>
      </DialogContent>
    </>
  );
};

export default SignUpForm;
