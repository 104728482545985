export const getDailyPacks = () => {
  return fetch(`/api/daily/packs`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPriceHistory = ({ itemId, gearType }) => {
  return fetch(
    `/api/daily/priceHistory?itemId=${itemId}&gearType=${gearType}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
