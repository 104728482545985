import { Stack } from "@mui/system";
import PackDisplayPaperModule from "./PackDisplayPaperModule";

const FeaturedGearList = ({ isDarkMode }) => {
  return (
    <Stack
      direction="row"
      sx={{ padding: "0px 10px 10px 10px" }}
      gap={1}
      justifyContent="center"
    >
      <PackDisplayPaperModule
        isDarkMode={isDarkMode}
        key={"-ZgbluU"}
        shareId="-ZgbluU"
      ></PackDisplayPaperModule>
      <PackDisplayPaperModule
        isDarkMode={isDarkMode}
        key={"41vlGyM"}
        shareId="41vlGyM"
      ></PackDisplayPaperModule>
      <PackDisplayPaperModule
        isDarkMode={isDarkMode}
        key={"AFsRHqE"}
        shareId="AFsRHqE"
      ></PackDisplayPaperModule>
    </Stack>
  );
};

export default FeaturedGearList;
