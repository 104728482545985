const sharedSpecs = [
  {
    key: "brand",
    label: "Brand",
    type: "String",
  },
  {
    key: "reviewValue",
    label: "Review Value",
    type: "Number",
  },
  {
    key: "imageUrl",
    label: "Image Url",
    type: "Url",
  },
  {
    key: "url",
    label: "URL",
    type: "Url",
  },
  {
    key: "price",
    label: "Price",
    type: "Number",
  },
  {
    key: "description",
    label: "Description",
    type: "String",
  },
];

const gearExploreSharedSpecs = [
  {
    key: "name",
    label: "Item",
    type: "String",
  },
  {
    key: "weight",
    label: "Weight (g)",
    type: "Number",
  },
  {
    key: "googleId",
    label: "GTIN",
    type: "Number",
  },
  ...sharedSpecs,
];

const sleepingBagSpecs = [
  ...gearExploreSharedSpecs,
  {
    key: "comfortRating",
    label: "Comfort Rating",
    type: "Number",
  },
  {
    key: "limitRating",
    label: "Limit Rating",
    type: "Number",
  },
  {
    key: "variant",
    label: "Variant",
    type: "String",
  },
  {
    key: "length",
    label: "Length",
    type: "Number",
  },
  {
    key: "fitsTo",
    label: "Fits To",
    type: "Number",
  },
  {
    key: "type",
    label: "Shape",
    type: "Multi",
    values: ["Mummy", "Tapered", "Rectangular", "Quilt", "Other"],
  },
  {
    key: "insulationType",
    label: "Insulation Type",
    type: "Multi",
    values: ["Synthetic", "Down"],
  },
  {
    key: "fillPower",
    label: "Fill Power",
    type: "String",
  },
  {
    key: "fillWeight",
    label: "Fill Weight(g)",
    type: "String",
  },
];
const tentSpecs = [
  ...gearExploreSharedSpecs,
  {
    key: "packedWeight",
    label: "Packed Weight (g)",
    type: "Number",
  },
  {
    key: "capacity",
    label: "Capacity",
    type: "Multi",
    values: ["", "1", "2", "3", "4"],
  },
  {
    key: "setup",
    label: "Setup",
    type: "Multi",
    values: [
      "Freestanding",
      "Pyramid",
      "Trekking Pole",
      "Semi-Freestanding",
      "Tarp",
    ],
  },
  {
    key: "category",
    label: "Category",
    type: "Multi",
    values: [
      "1-person tent",
      "2-person tent",
      "3-person tent",
      "4-person tent",
      "tarp",
    ],
  },
  {
    key: "length",
    label: "Length (Inches)",
    type: "Number",
  },
  {
    key: "width",
    label: "Width (Inches)",
    type: "String",
  },
  {
    key: "height",
    label: "Height (Inches)",
    type: "Number",
  },
  {
    key: "minimumWidth",
    label: "Minimum Width (Inches)",
    type: "Number",
  },
  {
    key: "interiorArea",
    label: "Interior Area",
    type: "String",
  },
  {
    key: "vestibuleCount",
    label: "Vestibule Count",
    type: "String",
  },
  {
    key: "vestibuleArea",
    label: "Vestibule Area",
    type: "String",
  },
  {
    key: "flyMaterial",
    label: "Fly Material",
    type: "String",
  },
  {
    key: "season",
    label: "Season",
    type: "String",
  },
  {
    key: "symmetricalFloor",
    label: "Symmetrical Floor",
    type: "Multi",
    values: ["", "Yes", "No"],
  },
  {
    key: "flyFirst",
    label: "Fly First",
    type: "Multi",
    values: ["", "Yes", "No"],
  },
  {
    key: "doubleWalled",
    label: "Double Walled",
    type: "Multi",
    values: ["", "Yes", "No"],
  },
  {
    key: "flyFabric",
    label: "Fly Fabric Denier",
    type: "String",
  },
  {
    key: "floorFabric",
    label: "Floor Fabric Denier",
    type: "String",
  },
];

const sleepingPadSpecs = [
  ...gearExploreSharedSpecs,
  {
    key: "variant",
    label: "Variant",
    type: "String",
  },
  {
    key: "length",
    label: "Length (inches)",
    type: "Number",
  },
  {
    key: "width",
    label: "Width (inches)",
    type: "Number",
  },
  {
    key: "rValue",
    label: "R-Value",
    type: "Number",
  },
  {
    key: "thickness",
    label: "Thickness",
    type: "Number",
  },
  {
    key: "type",
    label: "Type",
    type: "Multi",
    values: ["Inflatable", "Self-Inflating", "Closed-Cell Foam"],
  },
];
const packSpecs = [
  ...gearExploreSharedSpecs,
  {
    key: "volume",
    label: "Volume",
    type: "Number",
  },
  {
    key: "ventilatedBack",
    label: "Ventilated Back",
    type: "Multi",
    values: ["", "Yes", "No"],
  },
  {
    key: "adjustableTorso",
    label: "Adjustable Torso",
    type: "Multi",
    values: ["", "Yes", "No"],
  },
  {
    key: "maxLoad",
    label: "Max Load (lbs)",
    type: "String",
  },
  {
    key: "framed",
    label: "Framed",
    type: "Multi",
    values: ["", "Yes", "No"],
  },
  {
    key: "rollTop",
    label: "Roll Top",
    type: "Multi",
    values: ["", "Yes", "No"],
  },
  {
    key: "width",
    label: "Width",
    type: "Number",
  },
  {
    key: "height",
    label: "Height",
    type: "Number",
  },
  {
    key: "bottomPocket",
    label: "Bottom Pocket",
    type: "Multi",
    values: ["", "Yes", "No"],
  },
  {
    key: "shoulderStrapStyle",
    label: "Shoulder Strap Style",
    type: "String",
  },
  {
    key: "mainFabric",
    label: "Main Fabric",
    type: "String",
  },
  {
    key: "secondaryFabric",
    label: "Secondary Fabric",
    type: "String",
  },
];

const autocompleteSpecs = [
  {
    key: "displayWeight",
    label: "Weight (g)",
    type: "Number",
  },
  ...sharedSpecs,
  {
    key: "purchaseWebsite",
    label: "Purchase Website",
    type: "Multi",
    values: ["GGG", "REI"],
  },
];

const insulatedJacketSpecs = [
  ...gearExploreSharedSpecs,
  {
    key: "variant",
    label: "Variant",
    type: "String",
  },
  {
    key: "specSize",
    label: "Spec Size",
    type: "String",
  },
  {
    key: "gender",
    label: "Gender",
    type: "Multi",
    values: ["Mens", "Womens", "Unisex"],
  },
  {
    key: "hasHood",
    label: "Has Hood",
    type: "Multi",
    values: ["Yes", "No"],
  },
  {
    key: "zipperStyle",
    label: "Zipper Style",
    type: "Multi",
    values: ["Full-Zip", "None", "Quarter"],
  },
  { key: "fillWeightInGrams", label: "Fill Weight (g)", type: "Number" },
  { key: "fillPower", label: "Fill Power", type: "Number" },
  { key: "shellFabric", label: "Shell Fabric", type: "String" },
  { key: "shellDenier", label: "Shell Denier", type: "String" },
  { key: "baffle", label: "Baffle", type: "Multi", values: ["Sewn", "Box"] },
  { key: "rdsDown", label: "RDS Down", type: "String" },
  { key: "handPockets", label: "Hand Pockets", type: "Number" },
  { key: "chestPockets", label: "Chest Pockets", type: "Number" },
  { key: "backLength", label: "Back Length", type: "Number" },
  { key: "insulationType", label: "Insulation Type", type: "String" },
];

export const gearSpecMap = {
  sleepingBags: sleepingBagSpecs,
  tents: tentSpecs,
  sleepingPads: sleepingPadSpecs,
  packs: packSpecs,
  insulatedJackets: insulatedJacketSpecs,
  autocomplete: autocompleteSpecs,
};
