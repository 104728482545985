import React from "react";
import { Collapse, Paper, Typography } from "@mui/material";
import SuggestedItemCarousel from "./SuggestedItemCarousel";
import { generatePackSuggestionsFromTableData } from "../utils/packSuggestionUtils";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import LoadingItemSuggestion from "./LoadingItemSuggestion";
import CloseIcon from "@mui/icons-material/Close";
import SuggestionSettings from "./SuggestionSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import { useMediaQuery } from "react-responsive";

const ItemSuggestion = ({
  tableData,
  weightsInGrams,
  packIsLoading,
  itemCategoryList,
  darkMode,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [suggestedItems, setSuggestedItems] = React.useState([]);
  const [suggestedItemsAreLoading, setSuggestedItemsAreLoading] =
    React.useState(false);
  const [timeOfLastSettingsUpdate, setTimeOfLastSettingsUpdate] =
    React.useState(Date.now());
  const [suggestionSettingsOpen, setSuggestionSettingsOpen] =
    React.useState(false);

  React.useEffect(() => {
    (async () => {
      setSuggestedItemsAreLoading(true);
      const newSuggestedItems = await generatePackSuggestionsFromTableData(
        tableData
      );
      setSuggestedItems(newSuggestedItems);
      setSuggestedItemsAreLoading(false);
    })();
  }, [itemCategoryList, weightsInGrams, tableData, timeOfLastSettingsUpdate]);

  return packIsLoading || suggestedItemsAreLoading ? (
    <Paper
      sx={{
        width: isMobile ? "700px" : "380px",
        minHeight: isMobile ? "400px" : "272px",
      }}
    >
      <LoadingItemSuggestion />
    </Paper>
  ) : suggestedItems.length ? (
    <Paper
      sx={{
        width: isMobile ? "700px" : "380px",
        height: isMobile ? "400px" : "272px",
      }}
    >
      <SuggestedItemCarousel
        suggestedItems={suggestedItems}
        weightsInGrams={weightsInGrams}
        setTimeOfLastSettingsUpdate={setTimeOfLastSettingsUpdate}
        suggestionSettingsOpen={suggestionSettingsOpen}
        setSuggestionSettingsOpen={setSuggestionSettingsOpen}
        darkMode={darkMode}
      />
    </Paper>
  ) : (
    <Paper
      sx={{
        width: isMobile ? "700px" : "380px",
        minHeight: isMobile ? "400px" : "220px",
        marginTop: "20px",
        marginLeft: "10px",
      }}
    >
      <Tooltip
        title={
          <>
            Suggestions are based on the item types of the items in your pack
            and your gears weight. If you have no suggestions, you may need to
            reconfigure your item types or you might just have the lightest item
            available!
            <p
              style={{
                fontStyle: "italic",
                textDecoration: "underline",
                paddingTop: "0px",
                paddingBottom: "-5px",
                color: "#BBB",
              }}
            >
              Note
            </p>
            <p style={{ marginTop: "-10px" }}>
              For the best suggestions, try to use the provided item types in
              the dropdown as opposed to entering your own, when possible.
            </p>
            <p>
              You should always do your due diligence on suggested items. The
              item suggested to you might be a variant that is not your size or
              does not serve your needs appropriately.
            </p>
          </>
        }
      >
        <IconButton
          sx={{
            height: isMobile ? "40px" : "36px",
            width: isMobile ? "40px" : "36px",
            marginTop: isMobile ? "20px" : "0px",
            marginLeft: isMobile ? "10px" : "0px",
          }}
        >
          <HelpOutlineIcon
            sx={{
              height: isMobile ? "40px" : "20px",
              width: isMobile ? "40px" : "20px",
            }}
          />
        </IconButton>
      </Tooltip>
      <IconButton
        sx={{
          height: "40px",
          width: "40px",
          marginTop: isMobile ? "20px" : "0px",
          marginLeft: isMobile ? "600px" : "300px",
        }}
        onClick={() => {
          setSuggestionSettingsOpen(!suggestionSettingsOpen);
        }}
      >
        <SettingsIcon
          sx={{
            height: isMobile ? "40px" : "20px",
            width: isMobile ? "40px" : "20px",
          }}
        />
      </IconButton>
      <Collapse in={suggestionSettingsOpen} orientation="horizontal">
        <Paper
          elevation={5}
          sx={{
            zIndex: "2",
            width: "375px",
            marginLeft: "2px",
            height: "220px",
            position: "absolute",
          }}
        >
          <IconButton
            sx={{ right: "0px", top: "0px", position: "absolute" }}
            onClick={() => {
              setSuggestionSettingsOpen(false);
              setTimeOfLastSettingsUpdate(Date.now());
            }}
          >
            <CloseIcon />
          </IconButton>
          <SuggestionSettings
            setTimeOfLastSettingsUpdate={setTimeOfLastSettingsUpdate}
          />
        </Paper>
      </Collapse>
      <Box
        sx={{
          width: isMobile ? "700px" : "380px",
          textAlign: "center",
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            height: isMobile ? "220px" : "150px",
            lineHeight: "150px",
          }}
        >
          <Typography sx={{ fontSize: isMobile ? "20px" : "16px" }}>
            No suggestions for this Pack
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default ItemSuggestion;
