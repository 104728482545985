import React from "react";
import "./App.css";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import queryString from "querystring";
import { useMediaQuery } from "react-responsive";
import uniqid from "uniqid";

import AboutUsContainer from "./components/AboutUsContainer";
import AuthenticationDialog from "./components/AuthenticationDialog";
import MainContainer from "./components/MainContainer";
import ResetPasswordDialog from "./components/ResetPasswordDialog";
import ImportDialog from "./components/ImportDialog";
import SideNav from "./components/SideNav";
import TopNav from "./components/TopNav";
import { LicenseInfo } from "@mui/x-license-pro";
import {
  getUserIsLoggedIn,
  importFromLighterPack,
  getPackWithId,
  setUserLocalTable,
  updatePack,
  updatePackSettings,
} from "./utils";
import { Route, Routes, useNavigate } from "react-router-dom";
import ShareContainer from "./components/ShareContainer";
import ShareableLinkDialog from "./components/ShareableLinkDialog";
import GearCloset from "./components/GearCloset";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import PrivacyContainer from "./components/PrivacyContainer";
import ContactContainer from "./components/ContactContainer";
import TermsOfServiceContainer from "./components/TermsOfServiceContainer";
import BottomNav from "./components/BottomNav";
import ArticleRouter from "./components/ArticleRouter";
import ReleaseNotesContainer from "./components/ReleaseNotesContainer";
import GearComparisonContainer from "./components/GearComparisonRouter";
import PackExplorerContainer from "./components/PackExplorerContainer";
import AdminRouter from "./components/AdminRouter";
import HomeContainer from "./components/HomeContainer";
import UserProfileContainer from "./components/UserProfileContainer";
import UserAccountContainer from "./components/UserAccountContainer";
import NewFeaturePopup from "./components/NewFeaturePopup";
import { Helmet } from "react-helmet";

const licenseKey =
  "f533e09ede60a8279917eb334c4e0f25Tz04MTY2OSxFPTE3MzYxMTM2OTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";

LicenseInfo.setLicenseKey(licenseKey);

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem("darkMode") === null ||
      localStorage.getItem("darkMode") === "true"
  );
  const [isSideNavOpen, setIsSideNavOpen] = React.useState(!isMobile);
  const [authenticationIsOpen, setAuthenticationIsOpen] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    getUserIsLoggedIn() ? true : false
  );
  const [tableId, setTableId] = React.useState(null);
  const [packName, setPackName] = React.useState("");
  const [packDescription, setPackDescription] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [defaultPackId, setDefaultPackId] = React.useState(null);
  const [importDialogIsOpen, setImportDialogIsOpen] = React.useState(false);
  const [shareDialogIsOpen, setShareDialogIsOpen] = React.useState(false);
  const [shareableLink, setShareableLink] = React.useState("");
  const [gearClosetIsOpen, setGearClosetIsOpen] = React.useState(false);
  const [mainAlertDetails, setMainAlertDetails] = React.useState({
    type: "success",
    message: "",
  });
  const [mainAlertIsOpen, setMainAlertIsOpen] = React.useState(false);
  const [packIsLoading, setPackIsLoading] = React.useState(true);
  const [unit, setUnit] = React.useState(
    localStorage.getItem("preferredPackWeight") || "lb"
  );
  const [packVisibility, setPackVisibility] = React.useState(false);
  const [packSettingsTags, setPackSettingsTags] = React.useState([]);
  const [packAnalytics, setPackAnalytics] = React.useState({});
  const [affiliateLinksAreOff, setAffiliateLinksAreOff] = React.useState(false);
  const [theme, setTheme] = React.useState(
    createTheme({
      palette: {
        mode: darkMode ? "dark" : "light",
        background: {
          default: darkMode ? "#222021" : "#EEE",
        },
      },
      breakpoints: {
        values: {
          xs: false,
          sm: false,
          md: false,
          lg: false,
          xl: false,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              /* scrollbarColor: darkMode ? "#202020 #202020" : "#F0F0F0 #F0F0F0",*/
              "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                backgroundColor: "transparent",
                width: "12px",
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                borderRadius: 8,
                backgroundColor: darkMode ? "#FFF" : "#C0C0C0",
                minHeight: 24,
                border: `3px solid ${darkMode ? "#FFF" : "#C0C0C0"}`,
              },
              "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                {
                  backgroundColor: darkMode ? "#202020" : "#C0C0C0",
                },
              "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                {
                  backgroundColor: darkMode ? "#202020" : "#C0C0C0",
                },
              "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                {
                  backgroundColor: darkMode ? "#202020" : "#C0C0C0",
                },
              "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                backgroundColor: "#2b2b2b",
              },
            },
          },
        },
      },
    })
  );
  const pathname = window.location.pathname.split("?")[0];
  const querystring = queryString.parse(window.location.search.slice(1));

  React.useEffect(() => {
    if (
      getUserIsLoggedIn() &&
      !querystring.navigatedToHome &&
      pathname === "/"
    ) {
      navigate("/packs", { replace: true });
    }
  }, [navigate, querystring]);

  const handleRefresh = () => {
    window.location = window.location.origin;
  };

  const handleSetUser = async (user) => {
    await localStorage.setItem("profile", JSON.stringify(user));
    setIsLoggedIn(true);
    handleRefresh();
  };

  const handleSetAuthenticationIsOpen = (type) => {
    setIsLogin(type === "LOGIN");
    setAuthenticationIsOpen(true);
  };

  const handleLogOutUser = () => {
    localStorage.setItem("profile", null);
    setIsLoggedIn(false);
    handleRefresh();
  };

  const handleChangeDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("darkMode", !darkMode);
  };

  const handleUpdatePackSettings = (packVisibility, packSettings) => {
    updatePackSettings(tableId, packVisibility, packSettings);
    setPackVisibility(packVisibility);
    setPackSettingsTags(packSettings);
  };

  const handleImportFromLighterPack = async (
    link,
    lpItemColumn,
    lpDescriptionColumn,
    importToGC
  ) => {
    const lighterPackData = await importFromLighterPack(
      link,
      lpItemColumn,
      lpDescriptionColumn,
      importToGC
    );
    if (lighterPackData.error) {
      setMainAlertDetails({
        type: "error",
        message:
          "Failed to import from LighterPack, please confirm the link you provided actually exists",
      });
      setMainAlertIsOpen(true);
    } else {
      if (!getUserIsLoggedIn()) {
        setUserLocalTable(
          lighterPackData.packName,
          lighterPackData.tableData,
          ""
        );
      }
      setImportDialogIsOpen(false);
      setTableId(lighterPackData.tableId);
      setTableData(lighterPackData.tableData);
      setPackName(lighterPackData.packName);
      setPackDescription(lighterPackData.packDescription);
      setUnit(lighterPackData.packUnit);
      setPackSettingsTags(lighterPackData.tags || []);
      setPackVisibility(false);
      setPackAnalytics(lighterPackData.analytics);
      setAffiliateLinksAreOff(
        lighterPackData.hasAffiliateLinksTurnedOff || false
      );
      setMainAlertDetails({
        type: "success",
        message: `Imported ${lighterPackData.packName} from LighterPack`,
      });
      setMainAlertIsOpen(true);
      navigate("/packs", { replace: true });
    }
  };

  React.useEffect(() => {
    setTheme(
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
          background: {
            default: darkMode ? "#222021" : "#EEE",
          },
        },
        breakpoints: {
          values: {
            xs: false,
            sm: false,
            md: false,
            lg: false,
            xl: false,
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                /* scrollbarColor: darkMode ? "#202020 #202020" : "#F0F0F0 #F0F0F0",*/
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "transparent",
                  width: "12px",
                  height: "10px",
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: darkMode ? "#2F2F2F" : "#C0C0C0",
                  minHeight: 24,
                  border: `3px solid ${darkMode ? "#2A2A2A" : "#C0C0C0"}`,
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: darkMode ? "#444" : "#C0C0C0",
                  },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    backgroundColor: darkMode ? "#444" : "#C0C0C0",
                  },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: darkMode ? "#444" : "#C0C0C0",
                  },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "#444",
                },
              },
            },
          },
        },
      })
    );
  }, [darkMode]);

  const handleSelectNewPack = async (newPackId) => {
    if (!newPackId) return;
    if (getUserIsLoggedIn()) {
      setPackIsLoading(true);
      const {
        packName,
        tableId,
        tableData,
        packDescription,
        packUnit,
        isPublic,
        tags,
        analytics,
        hasAffiliateLinksTurnedOff,
      } = await getPackWithId(newPackId);
      setPackSettingsTags(tags);
      setPackVisibility(isPublic);
      setTableId(tableId);
      setTableData(tableData);
      setPackName(packName);
      setPackDescription(packDescription);
      setPackAnalytics(analytics || {});
      setAffiliateLinksAreOff(hasAffiliateLinksTurnedOff || false);
      if (packUnit) setUnit(packUnit);
      setPackIsLoading(false);
      navigate("/packs", { replace: true });
    }
  };

  const handleAddGearFromGearCloset = (categoryIndex, items) => {
    const newTableData = [...tableData];
    const itemsById = items.reduce((acc, gearClosetItem) => {
      const uid = uniqid("gc_");
      const item = {
        ...gearClosetItem,
        weight: gearClosetItem.weight ? Number(gearClosetItem.weight) : 0,
        price: gearClosetItem.price ? Number(gearClosetItem.price) : 0,
        quantity: 1,
        id: uid,
      };
      delete item._id;
      acc[uid] = item;
      return acc;
    }, {});
    newTableData[categoryIndex].rows = {
      ...newTableData[categoryIndex].rows,
      ...itemsById,
    };
    setTableData(newTableData);
    if (tableId) {
      updatePack(tableId, { data: newTableData });
    }
    setMainAlertDetails({
      type: "success",
      message: `Added item${items.length > 1 ? "s" : ""} from gear closet`,
    });
    setMainAlertIsOpen(true);
  };

  const handleAddBinToPack = (bin) => {
    const newTableData = [...tableData];
    const itemsById = bin.contents.reduce((acc, gearClosetItem) => {
      const uid = uniqid("gc_");
      const item = {
        ...gearClosetItem,
        weight: gearClosetItem.weight ? Number(gearClosetItem.weight) : 0,
        price: gearClosetItem.price ? Number(gearClosetItem.price) : 0,
        quantity: 1,
        id: uid,
      };
      delete item._id;
      acc[uid] = item;
      return acc;
    }, {});
    newTableData.push({
      defaultCategory: "",
      markedAsWeightType: 0,
      subtitle: bin.subtitle,
      title: bin.title,
      summaryRowUnit: "kg",
      rows: itemsById,
    });
    setTableData(newTableData);
    if (tableId) {
      updatePack(tableId, { data: newTableData });
    }
    setMainAlertDetails({
      type: "success",
      message: `Added ${bin.title} to your pack`,
    });
    setMainAlertIsOpen(true);
  };

  const email = querystring.email;
  const resetCode = querystring.resetCode;

  const isResetPage = () => {
    if (pathname === "/resetPassword") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <meta property="og:title" content="PackWizard" />
        <meta property="og:url" content="PackWizard.com" />
        <meta
          property="og:description"
          content="PackWizard helps you refine your backpacking gear by giving you a platform to share your gear as well as research and find new gear."
        />
        <meta property="og:image" content="/pack_wizard.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="PackWizard" />
        <meta
          name="twitter:description"
          content="PackWizard helps you refine your backpacking gear by giving you a platform to share and review your gear as well as research and find new gear."
        />
        <meta name="twitter:image" content="OG_IMAGE" />
        <meta
          name="keywords"
          content="Backpacking Gear Research Tool, Live Pricing and Reviews For Backpacking Gear"
        />
        <meta
          name="description"
          content="PackWizard helps you refine your backpacking gear by giving you a platform to share your gear as well as research and find new gear."
        />
      </Helmet>
      <Container
        fixed
        style={{
          maxWidth: "1420px",
          width: "100vw",
          height: "100vh",
          padding: "0px",
          margin: "0 auto",
        }}
      >
        <Stack>
          <Stack
            direction="row"
            sx={{
              maxWidth: "1440px",
              width: "100vw",
            }}
          >
            {/* Side Nav Routes */}
            <Routes>
              <Route path="/" element={<></>}></Route>
              <Route
                path="/*"
                element={
                  <SideNav
                    tableId={tableId}
                    setTableId={setTableId}
                    setTableData={setTableData}
                    packName={packName}
                    setPackName={setPackName}
                    defaultPackId={defaultPackId}
                    setDefaultPackId={setDefaultPackId}
                    setImportDialogIsOpen={setImportDialogIsOpen}
                    setGearClosetIsOpen={setGearClosetIsOpen}
                    setPackDescription={setPackDescription}
                    setPackIsLoading={setPackIsLoading}
                    packIsLoading={packIsLoading}
                    handleSelectNewPack={handleSelectNewPack}
                    isSideNavOpen={isSideNavOpen}
                    darkMode={darkMode}
                    handleChangeDarkMode={handleChangeDarkMode}
                    setPackSettingsTags={setPackSettingsTags}
                    setPackAnalytics={setPackAnalytics}
                    setPackVisibility={setPackVisibility}
                    setAffiliateLinksAreOff={setAffiliateLinksAreOff}
                  />
                }
              ></Route>
              <Route path="/gear/*" element={<></>}></Route>
              <Route path="/user/*" element={<></>}></Route>
              <Route path="/account/*" element={<></>}></Route>
              <Route path="/browse/*" element={<></>}></Route>
              <Route path="/admin/*" element={<></>}></Route>
              <Route path="/resetPassword/*" element={<></>}></Route>
              <Route path="/about" element={<></>}></Route>
              <Route path="/article/*" element={<></>}></Route>
            </Routes>
            <Routes>
              <Route
                path="/*"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      showHamburger
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <Routes>
                      <Route
                        path="/packs"
                        element={
                          <MainContainer
                            tableId={tableId}
                            setTableId={setTableId}
                            tableData={tableData}
                            setTableData={setTableData}
                            defaultPackId={defaultPackId}
                            setDefaultPackId={setDefaultPackId}
                            packName={packName}
                            setPackName={setPackName}
                            setShareableLink={setShareableLink}
                            setShareDialogIsOpen={setShareDialogIsOpen}
                            packDescription={packDescription}
                            setPackDescription={setPackDescription}
                            handleSetAuthenticationIsOpen={
                              handleSetAuthenticationIsOpen
                            }
                            packIsLoading={packIsLoading}
                            setPackIsLoading={setPackIsLoading}
                            setMainAlertDetails={setMainAlertDetails}
                            setMainAlertIsOpen={setMainAlertIsOpen}
                            handleSelectNewPack={handleSelectNewPack}
                            darkMode={darkMode}
                            unit={unit}
                            setUnit={setUnit}
                            packVisibility={packVisibility}
                            setPackVisibility={setPackVisibility}
                            packSettingsTags={packSettingsTags}
                            setPackSettingsTags={setPackSettingsTags}
                            handleUpdatePackSettings={handleUpdatePackSettings}
                            packAnalytics={packAnalytics}
                            setPackAnalytics={setPackAnalytics}
                            setAffiliateLinksAreOff={setAffiliateLinksAreOff}
                            affiliateLinksAreOff={affiliateLinksAreOff}
                          />
                        }
                      ></Route>
                      <Route
                        path="/s/:id"
                        element={
                          <ShareContainer
                            setMainAlertDetails={setMainAlertDetails}
                            setMainAlertIsOpen={setMainAlertIsOpen}
                            isDarkMode={darkMode}
                            handleSelectNewPack={handleSelectNewPack}
                          />
                        }
                      ></Route>
                      <Route
                        path="/releaseNotes"
                        element={
                          <ReleaseNotesContainer
                            isSideNavOpen={isSideNavOpen}
                            isDarkMode={darkMode}
                          />
                        }
                      />
                      <Route
                        path="/privacy"
                        element={<PrivacyContainer isDarkMode={darkMode} />}
                      ></Route>
                      <Route
                        path="/contact"
                        element={
                          <ContactContainer
                            setMainAlertDetails={setMainAlertDetails}
                            setMainAlertIsOpen={setMainAlertIsOpen}
                            isDarkMode={darkMode}
                          />
                        }
                      ></Route>
                      <Route
                        path="/terms"
                        element={
                          <TermsOfServiceContainer isDarkMode={darkMode} />
                        }
                      ></Route>
                    </Routes>
                    <BottomNav isSideNavOpen={isSideNavOpen} />
                  </Stack>
                }
              ></Route>
              <Route
                path="/browse/*"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <PackExplorerContainer darkMode={darkMode} />
                    <BottomNav />
                  </Stack>
                }
              ></Route>
              <Route
                path="/user/:username"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <UserProfileContainer darkMode={darkMode} />
                    <BottomNav />
                  </Stack>
                }
              ></Route>
              <Route
                path="/account/*"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <UserAccountContainer darkMode={darkMode} />
                    <BottomNav />
                  </Stack>
                }
              ></Route>
              <Route
                path="/gear/*"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <GearComparisonContainer darkMode={darkMode} />
                    <BottomNav />
                  </Stack>
                }
              ></Route>
              <Route
                path="/about"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <AboutUsContainer isDarkMode={darkMode} />
                    <BottomNav hideAffiliateWarning />
                  </Stack>
                }
              ></Route>
              <Route
                path="/"
                element={
                  <Stack
                    sx={{
                      width: "100%",
                      minHeight: "100svh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <HomeContainer
                      darkMode={darkMode}
                      packName={packName}
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                    />
                    <BottomNav hideAffiliateWarning />
                  </Stack>
                }
              ></Route>
              <Route
                path="/resetPassword"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <HomeContainer
                      darkMode={darkMode}
                      tableId={tableId}
                      packName={packName}
                      setTableId={setTableId}
                      setTableData={setTableData}
                      setPackName={setPackName}
                      setPackDescription={setPackDescription}
                      defaultPackId={defaultPackId}
                      setPackIsLoading={setPackIsLoading}
                      handleSelectNewPack={handleSelectNewPack}
                    />
                    <BottomNav />
                  </Stack>
                }
              ></Route>
              <Route
                path="/admin/*"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <AdminRouter darkMode={darkMode} />
                    <BottomNav />
                  </Stack>
                }
              ></Route>
              <Route
                path="/article/*"
                element={
                  <Stack
                    sx={{
                      minHeight: "100vh",
                    }}
                  >
                    <TopNav
                      handleSetAuthenticationIsOpen={
                        handleSetAuthenticationIsOpen
                      }
                      isLoggedIn={isLoggedIn}
                      handleLogOutUser={handleLogOutUser}
                      isSideNavOpen={isSideNavOpen}
                      setIsSideNavOpen={setIsSideNavOpen}
                      darkMode={darkMode}
                      handleChangeDarkMode={handleChangeDarkMode}
                    />
                    <ArticleRouter
                      isSideNavOpen={isSideNavOpen}
                      isDarkMode={darkMode}
                    />
                    <BottomNav />
                  </Stack>
                }
              ></Route>
            </Routes>
          </Stack>
        </Stack>
        <ImportDialog
          importDialogIsOpen={importDialogIsOpen}
          setImportDialogIsOpen={setImportDialogIsOpen}
          handleImportFromLighterPack={handleImportFromLighterPack}
          mainAlertIsOpen={mainAlertIsOpen}
          setMainAlertIsOpen={setMainAlertIsOpen}
          darkMode={darkMode}
        />
        <AuthenticationDialog
          handleSetUser={handleSetUser}
          isLogin={isLogin}
          setIsLogin={setIsLogin}
          authenticationIsOpen={authenticationIsOpen}
          setAuthenticationIsOpen={setAuthenticationIsOpen}
        />
        <ResetPasswordDialog
          resetCode={resetCode}
          email={email}
          resetPasswordIsOpen={isResetPage()}
          handleSetUser={handleSetUser}
        />
        <ShareableLinkDialog
          shareDialogIsOpen={shareDialogIsOpen}
          setShareDialogIsOpen={setShareDialogIsOpen}
          shareableLink={shareableLink}
          key={shareableLink}
          handleSetAuthenticationIsOpen={handleSetAuthenticationIsOpen}
        />
        <GearCloset
          gearClosetIsOpen={gearClosetIsOpen}
          setGearClosetIsOpen={setGearClosetIsOpen}
          handleSetAuthenticationIsOpen={handleSetAuthenticationIsOpen}
          handleAddGearFromGearCloset={handleAddGearFromGearCloset}
          handleAddBinToPack={handleAddBinToPack}
          tableData={tableData}
          darkMode={darkMode}
        />
        <Snackbar
          open={mainAlertIsOpen}
          autoHideDuration={5000}
          onClose={() => {
            setMainAlertIsOpen(false);
          }}
        >
          <Alert
            onClose={() => {
              setMainAlertIsOpen(false);
            }}
            severity={mainAlertDetails.type}
            sx={{ color: "white", width: "100%" }}
          >
            {mainAlertDetails.message}
          </Alert>
        </Snackbar>
        <NewFeaturePopup />
      </Container>
    </ThemeProvider>
  );
};

export default App;
