import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import { Container, Stack, Box } from "@mui/system";
import { TextField } from "@mui/material";
import DEFAULT_CATEGORIES from "../constants/defaultCategories";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

const GearClosetDetailPanelContent = ({
  row,
  gearRows,
  setGearRows,
  handleRowUpdate,
  darkMode,
}) => {
  const [updatedRow, setUpdatedRow] = React.useState({ ...row });
  const [imageUrl, setImageUrl] = React.useState(row.imageUrl);

  const updateParentRowState = async () => {
    const index = gearRows.findIndex(({ id }) => row.id === id);
    const newGearRows = [...gearRows];
    newGearRows[index] = { ...updatedRow };
    setGearRows(newGearRows);
    await handleRowUpdate(updatedRow);
  };

  const handleUpdateRow = (key, value) => {
    const newUpdatedRow = { ...updatedRow, [key]: value };
    setUpdatedRow(newUpdatedRow);
    if (key === "imageUrl") setImageUrl(value);
  };

  function useComponentWillUnmount(cleanupCallback = () => {}) {
    const callbackRef = React.useRef(cleanupCallback);
    callbackRef.current = cleanupCallback; // always up to date
    React.useEffect(() => {
      return () => callbackRef.current();
    }, []);
  }

  useComponentWillUnmount(() => {
    updateParentRowState();
  });

  return (
    <Container
      sx={{
        height: "400px",
        backgroundColor: darkMode ? "#202020" : "#F0F0F0",
        paddingLeft: "0px",
      }}
      key={row.id}
    >
      <Stack
        sx={{
          marginLeft: "50px",
          height: "400px",
        }}
        alignItems="center"
      >
        <Stack direction="row">
          <Stack>
            {imageUrl ? (
              <img
                style={{ height: "285px", width: "285px", marginTop: "22px" }}
                src={imageUrl}
              />
            ) : (
              <Box
                style={{
                  height: "285px",
                  width: "285px",
                  backgroundColor: darkMode ? "#333" : "white",
                  textAlign: "center",
                  lineHeight: "285px",
                  marginTop: "20px",
                }}
              >
                No Image Available
              </Box>
            )}
            <TextField
              label="Image Link"
              size="small"
              value={updatedRow.imageUrl}
              onChange={(event) => {
                handleUpdateRow("imageUrl", event.target.value);
              }}
              onBlur={() => {
                updateParentRowState();
              }}
              sx={{ width: "285px", marginTop: "23px" }}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
          <Stack sx={{ height: "300px", width: "450px" }}>
            <TextField
              label="Item"
              variant="outlined"
              size="small"
              sx={{ width: "400px", marginLeft: "20px", marginTop: "20px" }}
              defaultValue={updatedRow.item}
              onChange={(event) => {
                handleUpdateRow("item", event.target.value);
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              onBlur={() => {
                updateParentRowState();
              }}
              InputLabelProps={{ shrink: true }}
            />
            <Stack direction="row" sx={{ width: "500px", marginTop: "20px" }}>
              <TextField
                label="Brand"
                variant="outlined"
                size="small"
                sx={{ width: "195px", marginLeft: "20px" }}
                value={updatedRow.brand}
                onChange={(event) => {
                  handleUpdateRow("brand", event.target.value);
                }}
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                onBlur={() => {
                  updateParentRowState();
                }}
                InputLabelProps={{ shrink: true }}
              />
              <Autocomplete
                freeSolo
                sx={{ width: "195px", marginLeft: "10px" }}
                disableClearable
                options={DEFAULT_CATEGORIES.map((option) => ({
                  id: option,
                  label: option,
                }))}
                value={updatedRow.category}
                size="small"
                onChange={(event, value) => {
                  const valueToUpdate = value.label ? value.label : value;
                  handleUpdateRow("category", valueToUpdate);
                }}
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                onBlur={() => {
                  updateParentRowState();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Item Type"
                    onChange={(e) => {
                      handleUpdateRow("category", e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" sx={{ marginTop: "20px", width: "450px" }}>
              <TextField
                label="Weight"
                size="small"
                type="number"
                sx={{ width: "130px", marginLeft: "20px" }}
                value={updatedRow.weight}
                onChange={(event) => {
                  const newVal =
                    event.target.value < 0
                      ? (event.target.value = 0)
                      : event.target.value;
                  handleUpdateRow("weight", newVal);
                }}
                onBlur={() => {
                  updateParentRowState();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControl
                sx={{ width: "70px", marginLeft: "10px" }}
                size="small"
              >
                <InputLabel>Unit</InputLabel>
                <Select
                  value={updatedRow.unit}
                  label="Unit"
                  onChange={(event) => {
                    handleUpdateRow("unit", event.target.value);
                  }}
                  onBlur={() => {
                    updateParentRowState();
                  }}
                >
                  <MenuItem value="g">g</MenuItem>
                  <MenuItem value="oz">oz</MenuItem>
                  <MenuItem value="lb">lb</MenuItem>
                  <MenuItem value="kg">kg</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Price"
                size="small"
                type="number"
                sx={{ width: "180px", marginLeft: "10px" }}
                value={updatedRow.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  const newVal =
                    event.target.value < 0
                      ? (event.target.value = 0)
                      : event.target.value;
                  handleUpdateRow("price", newVal);
                }}
                onBlur={() => {
                  updateParentRowState();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
            <TextField
              label="Description"
              multiline
              value={updatedRow.description}
              size="small"
              rows={4}
              onChange={(event) => {
                handleUpdateRow("description", event.target.value);
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              onBlur={() => {
                updateParentRowState();
              }}
              sx={{ width: "400px", marginLeft: "20px", marginTop: "20px" }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Link"
              size="small"
              value={updatedRow.url}
              onChange={(event) => {
                handleUpdateRow("url", event.target.value);
              }}
              onBlur={() => {
                updateParentRowState();
              }}
              sx={{ width: "400px", marginLeft: "20px", marginTop: "20px" }}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default GearClosetDetailPanelContent;
