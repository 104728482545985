import React from "react";

import {
  Button,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReportableSpecsMap from "../../constants/reportableSpecsMap.js";
import { useMediaQuery } from "react-responsive";
import { flagItem } from "../../api/report";

const ReportSpecsDialog = ({
  gearType,
  itemToReportId,
  reportIsOpen,
  setReportIsOpen,
  setReportSnackBarIsOpen,
}) => {
  const [incorrectSpecs, setIncorrectSpecs] = React.useState([]);
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIncorrectSpecs(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSubmitReport = (gearType, itemId, incorrectSpecs) => {
    flagItem(itemId, gearType, incorrectSpecs);
    setReportIsOpen(false);
    setReportSnackBarIsOpen(true);
  };

  return (
    <Dialog
      open={reportIsOpen}
      onClose={() => {
        setReportIsOpen(false);
      }}
    >
      <DialogTitle>Report Incorrect Data</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: isMobile ? "20px" : "16px" }}>
          Noticed some incorrect data?
        </DialogContentText>
        <DialogContentText
          sx={{
            maxWidth: "400px",
            fontSize: isMobile ? "18px" : "14px",
            marginTop: "5px",
          }}
        >
          With thousands of items in our database, sometimes some specs slip
          through the cracks. Please let us know which spec is incorrect and
          we'll verify and fix it ASAP. Thank you!
        </DialogContentText>
        <FormControl sx={{ m: 1, width: 300, marginTop: "10px" }}>
          <InputLabel>Incorrect Specs (Required)</InputLabel>
          <Select
            multiple
            value={incorrectSpecs}
            onChange={handleChange}
            input={<OutlinedInput label="Incorrect Specs (Required)" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            label="Incorrect Specs (Required)"
          >
            {ReportableSpecsMap[gearType].map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={incorrectSpecs.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setReportIsOpen(false);
            setIncorrectSpecs([]);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!incorrectSpecs.length}
          onClick={() => {
            handleSubmitReport(gearType, itemToReportId, incorrectSpecs);
            setIncorrectSpecs([]);
          }}
        >
          Report Issue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportSpecsDialog;
