import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { debounce } from "lodash";
import { updateUserBin } from "../utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import { getColorWithMode } from "../constants/colors";

const GearBin = ({
  bin,
  handleDeleteFromBin,
  handleDeleteBin,
  binIndex,
  handleFilterToBin,
  handleAddBinToPack,
  handleUpdateBinText,
  darkMode,
}) => {
  const [actionsAnchorEl, setActionsAnchorEl] = React.useState(null);

  const handleActionsClick = (e) => {
    setActionsAnchorEl(e.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const columns = [
    {
      field: "item",
      headerName: "Item",
      headerAlign: "left",
      headerClassName: "firstcolumnSeparator",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Item Type",
      minWidth: 95,
      flex: 0.65,
      headerAlign: "left",
    },
    {
      field: "weight",
      headerName: "Weight",
      type: "number",
      width: 80,
      headerAlign: "right",
      headerClassName: "lastcolumnSeparator",
      sortable: false,
    },
    {
      field: "unit",
      headerName: "",
      type: "singleSelect",
      width: 69,
      height: "36px",
      valueOptions: ["g", "oz", "lb", "kg"],
      headerAlign: "left",
      sortable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 92,
      type: "number",
      headerAlign: "right",
      renderCell: (params) => {
        return Number(params.value).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      },
    },
    {
      field: "action",
      headerName: "",
      width: 40,
      headerClassName: "lastcolumnSeparator",
      headerAlign: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              sx={{
                marginLeft: "5px",
                height: "30px",
                width: "30px",
              }}
              onClick={() => {
                handleDeleteFromBin(binIndex, params.row.id);
              }}
            >
              <Tooltip title="Delete from this bin">
                <RemoveCircleOutlineIcon color="warning" />
              </Tooltip>
            </IconButton>
          </>
        );
      },
    },
  ];

  const debouncedTitleChange = debounce((e) => {
    (async () => {
      await updateUserBin(bin.id, { title: e.target.value });
      handleUpdateBinText(binIndex, "title", e.target.value);
    })();
  }, 1000);

  const debouncedSubtitleChange = debounce((e) => {
    (async () => {
      await updateUserBin(bin.id, { subtitle: e.target.value });
      handleUpdateBinText(binIndex, "subtitle", e.target.value);
    })();
  }, 1000);

  return (
    <Paper sx={{ padding: "10px 10px 10px 10px" }}>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            defaultValue={bin.title}
            key={bin.title}
            size="small"
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            onChange={debouncedTitleChange}
            sx={{
              width: "100%",
              height: "30px",
              paddingLeft: "5px",
              "& .MuiInputBase-input": {
                fontSize: "20px",
                height: "24px",
              },
              "& fieldset": {
                borderColor: "#2F2F2F",
              },
              "&:hover": {
                backgroundColor: getColorWithMode(darkMode, "shadow"),
              },
            }}
          />
          <Stack direction="row" spacing={0.5}>
            <Button
              onClick={handleActionsClick}
              endIcon={<KeyboardArrowDownIcon />}
              variant="contained"
              size="small"
              sx={{ width: "100" }}
            >
              {"Actions"}
            </Button>
            <Menu
              anchorEl={actionsAnchorEl}
              open={Boolean(actionsAnchorEl)}
              onClose={handleActionsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleFilterToBin(bin.title, bin.contents);
                }}
              >
                <ListItemIcon>
                  <FilterAltIcon />
                </ListItemIcon>
                <ListItemText primary="Filter Closet to this Bin" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAddBinToPack(bin);
                }}
              >
                <ListItemIcon>
                  <MoveDownIcon />
                </ListItemIcon>
                <ListItemText primary="Add bin to pack as category" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDeleteBin(bin.id);
                }}
              >
                <ListItemIcon>
                  <DeleteForeverIcon color="error" />
                </ListItemIcon>
                <ListItemText primary="Delete Bin" />
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
        <TextField
          defaultValue={bin.subtitle}
          placeholder="No Description"
          key={bin.subtitle}
          size="small"
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          onChange={debouncedSubtitleChange}
          sx={{
            width: "100%",
            paddingLeft: "5px",
            "& .MuiInputBase-input": {
              fontSize: "16px",
              paddingLeft: "0px",
              fontStyle: "italic",
              color: getColorWithMode(darkMode, "lightText"),
            },
            "& fieldset": {
              borderColor: "#2F2F2F",
            },
            "&:hover": {
              backgroundColor: getColorWithMode(darkMode, "shadow"),
            },
          }}
        />
        <DataGridPro
          columns={columns}
          rows={bin.contents}
          density="compact"
          hideFooter
          autoHeight
        />
      </Stack>
    </Paper>
  );
};

export default GearBin;
