const itemAddColumns = {
  sleepingBags: [
    {
      label: "Brand",
      key: "brand",
      mustBeNumber: false,
    },
    {
      label: "Name",
      key: "name",
      mustBeNumber: false,
    },
    {
      label: "Url",
      key: "url",
      mustBeNumber: false,
    },
    {
      label: "Image Url",
      key: "imageUrl",
      mustBeNumber: false,
    },
    {
      label: "Description",
      key: "description",
      mustBeNumber: false,
    },
    {
      label: "UPC",
      key: "UPC",
      mustBeNumber: false,
    },
    {
      label: "GTIN",
      key: "GTIN",
      mustBeNumber: true,
    },
    {
      label: "ASIN",
      key: "ASIN",
    },
    {
      label: "Review Value",
      key: "reviewValue",
      mustBeNumber: true,
    },
    {
      label: "Price",
      key: "price",
      mustBeNumber: true,
    },
    {
      label: "Weight In Grams",
      key: "weightInGrams",
      mustBeNumber: true,
    },
    {
      label: "Variant",
      key: "variant",
      mustBeNumber: false,
    },
    {
      label: "Length",
      key: "length",
      mustBeNumber: true,
    },
    {
      label: "Fits To",
      key: "fitsTo",
      mustBeNumber: true,
    },
    {
      label: "Type",
      key: "type",
      mustBeNumber: false,
    },
    {
      label: "Insulation Type",
      key: "insulationType",
      mustBeNumber: false,
    },
    {
      label: "Fill Power",
      key: "fillPower",
      mustBeNumber: false,
    },
    {
      label: "Fill Weight",
      key: "fillWeight",
      mustBeNumber: true,
    },
    {
      label: "Comfort Rating",
      key: "comfortRating",
      mustBeNumber: true,
    },
    {
      label: "Limit Rating",
      key: "limitRating",
      mustBeNumber: true,
    },
  ],
  packs: [
    {
      label: "Brand",
      key: "brand",
      mustBeNumber: false,
    },
    {
      label: "Name",
      key: "name",
      mustBeNumber: false,
    },
    {
      label: "Url",
      key: "url",
      mustBeNumber: false,
    },
    {
      label: "Image Url",
      key: "imageUrl",
      mustBeNumber: false,
    },
    {
      label: "Description",
      key: "description",
      mustBeNumber: false,
    },
    {
      label: "UPC",
      key: "UPC",
      mustBeNumber: false,
    },
    {
      label: "GTIN",
      key: "GTIN",
      mustBeNumber: true,
    },
    {
      label: "ASIN",
      key: "ASIN",
    },
    {
      label: "Review Value",
      key: "reviewValue",
      mustBeNumber: true,
    },
    {
      label: "Price",
      key: "price",
      mustBeNumber: true,
    },
    {
      label: "Weight In Grams",
      key: "weightInGrams",
      mustBeNumber: true,
    },
    {
      label: "Volume",
      key: "volume",
      mustBeNumber: true,
    },
    {
      label: "Ventilated Back",
      key: "ventilatedBack",
      mustBeNumber: false,
    },
    {
      label: "Adjustable Torso",
      key: "adjustableTorso",
      mustBeNumber: false,
    },
    {
      label: "Hipbelt Pockets",
      key: "hipBeltPockets",
      mustBeNumber: false,
    },
    {
      label: "Max Load",
      key: "maxLoad",
      mustBeNumber: true,
    },
    {
      label: "framed",
      key: "framed",
      mustBeNumber: false,
    },
    {
      label: "Roll Top",
      key: "rollTop",
      mustBeNumber: false,
    },
    {
      label: "Width",
      key: "width",
      mustBeNumber: true,
    },
    {
      label: "Height",
      key: "height",
      mustBeNumber: true,
    },
    {
      label: "Bottom Pocket",
      key: "bottomPocket",
      mustBeNumber: false,
    },
    {
      label: "Shoulder Strap Style",
      key: "shoulderStrapStyle",
      mustBeNumber: false,
    },
    {
      label: "Main Fabric",
      key: "mainFabric",
      mustBeNumber: false,
    },
    {
      label: "Secondary Fabric",
      key: "secondaryFabric",
      mustBeNumber: false,
    },
  ],
  tents: [
    {
      label: "Brand",
      key: "brand",
      mustBeNumber: false,
    },
    {
      label: "Name",
      key: "name",
      mustBeNumber: false,
    },
    {
      label: "Url",
      key: "url",
      mustBeNumber: false,
    },
    {
      label: "Image Url",
      key: "imageUrl",
      mustBeNumber: false,
    },
    {
      label: "Description",
      key: "description",
      mustBeNumber: false,
    },
    {
      label: "UPC",
      key: "UPC",
      mustBeNumber: false,
    },
    {
      label: "GTIN",
      key: "GTIN",
      mustBeNumber: true,
    },
    {
      label: "ASIN",
      key: "ASIN",
    },
    {
      label: "Review Value",
      key: "reviewValue",
      mustBeNumber: true,
    },
    {
      label: "Price",
      key: "price",
      mustBeNumber: true,
    },
    {
      label: "Setup",
      key: "setup",
      mustBeNumber: false,
    },
    {
      label: "Capacity",
      key: "capacity",
      mustBeNumber: false,
    },
    {
      label: "Weight In Grams",
      key: "weightInGrams",
      mustBeNumber: true,
    },
    {
      label: "Packed Weight",
      key: "packedWeight",
      mustBeNumber: true,
    },
    {
      label: "Min. Width",
      key: "minimumWidth",
      mustBeNumber: true,
    },
    {
      label: "Width",
      key: "width",
      mustBeNumber: false,
    },
    {
      label: "Length",
      key: "length",
      mustBeNumber: true,
    },
    {
      label: "Height",
      key: "height",
      mustBeNumber: true,
    },
    {
      label: "Interior Area",
      key: "interiorArea",
      mustBeNumber: false,
    },
    {
      label: "Vestibule Count",
      key: "vestibuleCount",
      mustBeNumber: true,
    },
    {
      label: "Vestibule Area",
      key: "vestibuleArea",
      mustBeNumber: false,
    },
    {
      label: "Fly Material",
      key: "flyMaterial",
      mustBeNumber: false,
    },
    {
      label: "Season",
      key: "season",
      mustBeNumber: false,
    },
    {
      label: "Symmetrical Floor",
      key: "symmetricalFloor",
      mustBeNumber: false,
    },
    {
      label: "Fly First",
      key: "flyFirst",
      mustBeNumber: false,
    },
    {
      label: "Double Walled",
      key: "doubleWalled",
      mustBeNumber: false,
    },
    {
      label: "Fly Fabric",
      key: "flyFabric",
      mustBeNumber: false,
    },
    {
      label: "Floor Fabric",
      key: "floorFabric",
      mustBeNumber: false,
    },
  ],
  sleepingPads: [
    {
      label: "Brand",
      key: "brand",
      mustBeNumber: false,
    },
    {
      label: "Name",
      key: "name",
      mustBeNumber: false,
    },
    {
      label: "Url",
      key: "url",
      mustBeNumber: false,
    },
    {
      label: "Image Url",
      key: "imageUrl",
      mustBeNumber: false,
    },
    {
      label: "Description",
      key: "description",
      mustBeNumber: false,
    },
    {
      label: "UPC",
      key: "UPC",
      mustBeNumber: false,
    },
    {
      label: "GTIN",
      key: "GTIN",
      mustBeNumber: true,
    },
    {
      label: "ASIN",
      key: "ASIN",
    },
    {
      label: "Review Value",
      key: "reviewValue",
      mustBeNumber: true,
    },
    {
      label: "Price",
      key: "price",
      mustBeNumber: true,
    },
    {
      label: "Weight In Grams",
      key: "weightInGrams",
      mustBeNumber: true,
    },
    {
      label: "Variant",
      key: "variant",
      mustBeNumber: false,
    },
    {
      label: "Width",
      key: "width",
      mustBeNumber: true,
    },
    {
      label: "Length",
      key: "length",
      mustBeNumber: true,
    },
    {
      label: "Type",
      key: "type",
      mustBeNumber: false,
    },
    {
      label: "Thickness",
      key: "thickness",
      mustBeNumber: true,
    },
    {
      label: "R-Value",
      key: "rValue",
      mustBeNumber: true,
    },
  ],
  insulatedJackets: [
    {
      label: "Brand",
      key: "brand",
      mustBeNumber: false,
    },
    {
      label: "Name",
      key: "name",
      mustBeNumber: false,
    },
    {
      label: "Url",
      key: "url",
      mustBeNumber: false,
    },
    {
      label: "Image Url",
      key: "imageUrl",
      mustBeNumber: false,
    },
    {
      label: "Description",
      key: "description",
      mustBeNumber: false,
    },
    {
      label: "UPC",
      key: "UPC",
      mustBeNumber: false,
    },
    {
      label: "GTIN",
      key: "GTIN",
      mustBeNumber: true,
    },
    {
      label: "ASIN",
      key: "ASIN",
    },
    {
      label: "Review Value",
      key: "reviewValue",
      mustBeNumber: true,
    },
    {
      label: "Price",
      key: "price",
      mustBeNumber: true,
    },
    {
      label: "Weight In Grams",
      key: "weightInGrams",
      mustBeNumber: true,
    },
    {
      key: "variant",
      label: "Variant",
      mustBeNumber: false,
    },
    {
      key: "specSize",
      label: "Spec Size",
      mustBeNumber: false,
    },
    {
      key: "gender",
      label: "Gender",
      type: "Multi",
      mustBeNumber: false,
    },
    {
      key: "hasHood",
      label: "Has Hood",
      type: "Multi",
      mustBeNumber: false,
    },
    {
      key: "zipperStyle",
      label: "Zipper Style",
      type: "Multi",
      mustBeNumber: false,
    },
    { key: "fillWeightInGrams", label: "Fill Weight (g)", mustBeNumber: true },
    { key: "fillPower", label: "Fill Power", mustBeNumber: true },
    { key: "shellFabric", label: "Shell Fabric", mustBeNumber: false },
    { key: "shellDenier", label: "Shell Denier", mustBeNumber: false },
    { key: "baffle", label: "Baffle", mustBeNumber: false },
    { key: "rdsDown", label: "RDS Down", mustBeNumber: false },
    { key: "handPockets", label: "Hand Pockets", mustBeNumber: true },
    { key: "chestPockets", label: "Chest Pockets", mustBeNumber: true },
    { key: "backLength", label: "Back Length", mustBeNumber: true },
    { key: "insulationType", label: "Insulation Type", mustBeNumber: false },
  ],
};

export default itemAddColumns;
