import React from "react";

import { getColorWithMode } from "../constants/colors";
import {
  Stack,
  Container,
  Avatar,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Alert,
  InputAdornment,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Helmet } from "react-helmet";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTokIcon from "./TikTokIcon";
import SubNavBar from "./SubNavBar";
import SaveIcon from "@mui/icons-material/Save";
import {
  fetchUserProfile,
  getLocalUsername,
  updateUserPhoto,
  updateUserProfile,
  validateUsername,
  permanentlyUpdateUsername,
  updateUsernameInLocalSession,
  importLocalPack,
} from "../utils";
import { validateSocialUrl } from "../utils/validationUtils";
import { debounce } from "lodash";
import UserAccountAnalyticsModule from "./UserAccountAnalyticsModule";

const UserAccountContainer = ({ darkMode }) => {
  const [accountSettings, setAccountSettings] = React.useState({
    bio: "",
  });
  const [tempBio, setTempBio] = React.useState("");
  const [socials, setSocials] = React.useState({
    facebook: "",
    instagram: "",
    tiktok: "",
    youtube: "",
  });
  const [socialErrors, setSocialErrors] = React.useState({
    facebook: false,
    instagram: false,
    tiktok: false,
    youtube: false,
  });
  const [bioIsSaved, setBioIsSaved] = React.useState(true);
  const [socialsAreSaved, setSocialsAreSaved] = React.useState(true);
  const [socialHasError, setSocialHasError] = React.useState(false);
  const [isChangingUsername, setIsChangingUsername] = React.useState(false);
  const [newUsername, setNewUsername] = React.useState("");
  const [usernameIsValid, setUsernameIsValid] = React.useState(false);
  const [useAffiliateLinksIsChecked, setUseAffiliateLinksIsChecked] =
    React.useState(false);
  const [didImportLocalPack, setDidImportLocalPack] = React.useState(false);

  const handleValidateUsername = async (username) => {
    if (!username || username === "") {
      setUsernameIsValid(false);
      return;
    }
    const { isValid } = await validateUsername(username);
    setUsernameIsValid(isValid);
  };

  const debouncedUsernameValidator = React.useCallback(
    debounce(handleValidateUsername, 300),
    []
  );

  const handleImportLocalPack = async () => {
    await importLocalPack();
    setDidImportLocalPack(true);
  };

  React.useEffect(() => {
    (async () => {
      const username = getLocalUsername();
      const data = await fetchUserProfile(username);
      if (data.error) {
        return;
      }
      setTempBio(data.bio);
      setBioIsSaved(true);
      setSocialsAreSaved(true);
      const socials = {
        facebook: data.facebook || "",
        tiktok: data.tiktok || "",
        instagram: data.instagram || "",
        youtube: data.youtube || "",
      };
      setSocials({
        facebook: data.facebook || "",
        tiktok: data.tiktok || "",
        instagram: data.instagram || "",
        youtube: data.youtube || "",
      });
      setAccountSettings({
        ...data,
        ...socials,
        hasAffiliateLinksTurnedOff: data.hasAffiliateLinksTurnedOff || false,
      });
      setUseAffiliateLinksIsChecked(data.hasAffiliateLinksTurnedOff || false);
    })();
  }, []);

  const handleUpdateUsernamePermanently = async () => {
    const usernameDidUpdate = await permanentlyUpdateUsername(newUsername);
    if (usernameDidUpdate.error) return;
    await updateUsernameInLocalSession(newUsername);
    window.location.reload(false);
  };

  const handleSaveBio = async () => {
    const newAccountSettings = { ...accountSettings, bio: tempBio };
    setAccountSettings(newAccountSettings);
    setBioIsSaved(true);
    await updateUserProfile(newAccountSettings);
  };

  const handleDiscardBio = () => {
    setBioIsSaved(true);
    setTempBio(accountSettings.bio);
  };

  const handleSetSocials = (socialType, value) => {
    const newSocials = { ...socials };
    const trimmedValue = value.replaceAll(" ", "");
    newSocials[socialType] = trimmedValue;
    const newSocialErrors = {
      ...socialErrors,
      [socialType]: !validateSocialUrl(socialType, trimmedValue),
    };
    setSocialHasError(
      Object.values(newSocialErrors).some((hasError) => hasError)
    );
    setSocialErrors(newSocialErrors);
    setSocials(newSocials);
    setSocialsAreSaved(false);
  };

  const handleSaveSocials = async () => {
    const newAccountSettings = { ...accountSettings, ...socials };
    setAccountSettings(newAccountSettings);
    setSocialsAreSaved(true);
    await updateUserProfile(newAccountSettings);
  };

  const handleDiscardSocials = () => {
    setSocials(accountSettings.socials);
    setSocialsAreSaved(true);
  };

  const handleAvatarChange = async (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    const newData = { ...accountSettings };
    newData.avatar = img.preview;
    setAccountSettings(newData);
    await updateUserPhoto(img, "avatar");
  };

  const handleCoverPhotoChange = async (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    const newData = { ...accountSettings };
    newData.coverPhoto = img.preview;
    setAccountSettings(newData);
    await updateUserPhoto(img, "cover");
  };

  const handleUpdateNewUsername = (e) => {
    const input = e.target.value;
    const username = input.replace(/\W/g, "");
    setNewUsername(username);
    debouncedUsernameValidator(username);
  };

  const handleChangeUseAffiliateLinks = async () => {
    const newAffiliateLinkState = !useAffiliateLinksIsChecked;
    const newAccountSettings = {
      ...accountSettings,
      hasAffiliateLinksTurnedOff: newAffiliateLinkState,
    };
    setAccountSettings(newAccountSettings);
    setUseAffiliateLinksIsChecked(newAffiliateLinkState);
    await updateUserProfile(newAccountSettings);
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <SubNavBar darkMode={darkMode} />
      <Container
        sx={{
          width: "100%",
          height: "80px",
          textAlign: "center",
          backgroundColor: getColorWithMode(darkMode, "title"),
          padding: "0px 0px 0px 0px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "700",
            fontFamily: "Helvetica",
            lineHeight: "80px",
          }}
        >
          Account Settings
        </Typography>
      </Container>
      <Container
        sx={{
          height: "100%",
          minHeight: "calc(100vh - 130px)",
          backgroundColor: getColorWithMode(darkMode, "base"),
          paddingTop: "20px",
        }}
      >
        <Stack spacing={2} alignItems="center">
          <Container sx={{ maxWidth: "700px" }}>
            <Stack spacing={2}>
              <Typography variant="h5">Profile Settings</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: "200px", fontSize: "16px" }}>
                      Username
                    </TableCell>
                    <TableCell>
                      {isChangingUsername ? (
                        <Box>
                          <Stack spacing={2}>
                            <Alert severity="warning">
                              You can only change your username once. Changing
                              your username is irreversible.
                            </Alert>
                            <Stack direction="row" spacing={1}>
                              <Typography sx={{ color: "grey" }}>
                                Current:
                              </Typography>{" "}
                              <Typography>{`@${accountSettings.username}`}</Typography>
                            </Stack>
                            <TextField
                              placeholder="New Username"
                              size="small"
                              label="New Username"
                              value={newUsername}
                              onChange={handleUpdateNewUsername}
                              helperText={
                                <>
                                  {newUsername ? (
                                    usernameIsValid ? (
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          color: getColorWithMode(
                                            darkMode,
                                            "success"
                                          ),
                                        }}
                                      >
                                        Username is available
                                      </Typography>
                                    ) : (
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          color: getColorWithMode(
                                            darkMode,
                                            "danger"
                                          ),
                                        }}
                                      >
                                        Username is unavailable
                                      </Typography>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  Usernames can only contain letters, numbers,
                                  and underscores, and cannot exceed 20
                                  characters.
                                </>
                              }
                              inputProps={{ maxLength: 20 }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    @
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Stack alignItems="center">
                              <Button
                                disabled={!usernameIsValid}
                                sx={{ width: "200px" }}
                                startIcon={<SaveIcon />}
                                onClick={handleUpdateUsernamePermanently}
                              >
                                Update Username
                              </Button>
                            </Stack>
                          </Stack>
                        </Box>
                      ) : (
                        <Box>
                          {accountSettings.hasUpdatedUsername ? (
                            <Typography>{`@${accountSettings.username}`}</Typography>
                          ) : (
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography>{`@${accountSettings.username}`}</Typography>
                              <Button
                                onClick={() => {
                                  setIsChangingUsername(true);
                                }}
                              >
                                Change
                              </Button>
                            </Stack>
                          )}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "200px", fontSize: "16px" }}>
                      Profile Picture
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Avatar
                            alt="User"
                            src={accountSettings.avatar}
                            sx={{ width: "75px", height: "75px" }}
                          />
                          <input
                            type="file"
                            name="file"
                            accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                            onChange={handleAvatarChange}
                          />
                        </Stack>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "200px", fontSize: "16px" }}>
                      Cover Photo
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            alt="Cover"
                            sx={{
                              minWidth: "125px",
                              height: "75px",
                              border: "1px solid grey",
                              borderRadius: "2px",
                              backgroundImage: accountSettings.coverPhoto
                                ? `url(${accountSettings.coverPhoto})`
                                : null,
                            }}
                          >
                            {accountSettings.coverPhoto ? (
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  overflow: "hidden",
                                }}
                                alt=""
                                src={accountSettings.coverPhoto}
                              ></img>
                            ) : (
                              <Typography
                                sx={{
                                  lineHeight: "75px",
                                  textAlign: "center",
                                  color: "grey",
                                }}
                              >
                                No Cover Photo
                              </Typography>
                            )}
                          </Box>
                          <input
                            type="file"
                            name="file"
                            accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                            onChange={handleCoverPhotoChange}
                          />
                        </Stack>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "200px", fontSize: "16px" }}>
                      Profile Description
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        sx={{ width: "100%" }}
                        multiline
                        inputProps={{ maxLength: 200 }}
                        onChange={(e) => {
                          setTempBio(e.target.value);
                          setBioIsSaved(false);
                        }}
                        value={tempBio}
                      />
                      <Typography variant="subtitle2">{`${
                        200 - tempBio.length
                      } character(s) remaining`}</Typography>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ paddingTop: "5px" }}
                        spacing={1}
                      >
                        <Button
                          sx={{ width: "100px" }}
                          startIcon={<SaveIcon />}
                          variant="contained"
                          color="success"
                          onClick={handleSaveBio}
                          disabled={bioIsSaved}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={handleDiscardBio}
                          disabled={bioIsSaved}
                        >
                          Discard Changes
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "200px", fontSize: "16px" }}>
                      Socials
                    </TableCell>
                    <TableCell>
                      <Stack spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <FacebookIcon />
                          <TextField
                            placeholder="Facebook Link"
                            size="small"
                            variant="standard"
                            sx={{ width: "100%" }}
                            value={socials.facebook}
                            error={socialErrors.facebook}
                            type="url"
                            helperText={
                              socialErrors.facebook
                                ? "Invalid Facebook url"
                                : null
                            }
                            onChange={(e) => {
                              handleSetSocials("facebook", e.target.value);
                            }}
                          />
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <InstagramIcon />
                          <TextField
                            placeholder="Instagram Link"
                            size="small"
                            variant="standard"
                            sx={{ width: "100%" }}
                            value={socials.instagram}
                            error={socialErrors.instagram}
                            type="url"
                            helperText={
                              socialErrors.instagram
                                ? "Invalid Instagram url"
                                : null
                            }
                            onChange={(e) => {
                              handleSetSocials("instagram", e.target.value);
                            }}
                          />
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <TikTokIcon
                            color={getColorWithMode(darkMode, "text")}
                            width="26px"
                          />
                          <TextField
                            placeholder="TikTok Link"
                            size="small"
                            variant="standard"
                            sx={{ width: "100%" }}
                            value={socials.tiktok}
                            error={socialErrors.tiktok}
                            type="url"
                            helperText={
                              socialErrors.tiktok ? "Invalid TikTok url" : null
                            }
                            onChange={(e) => {
                              handleSetSocials("tiktok", e.target.value);
                            }}
                          />
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <YouTubeIcon />
                          <TextField
                            placeholder="YouTube Link"
                            size="small"
                            variant="standard"
                            sx={{ width: "100%" }}
                            value={socials.youtube}
                            error={socialErrors.youtube}
                            type="url"
                            helperText={
                              socialErrors.youtube
                                ? "Invalid YouTube url"
                                : null
                            }
                            onChange={(e) => {
                              handleSetSocials("youtube", e.target.value);
                            }}
                          />
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          sx={{ paddingTop: "5px" }}
                          spacing={1}
                        >
                          <Button
                            sx={{ width: "100px" }}
                            startIcon={<SaveIcon />}
                            variant="contained"
                            color="success"
                            onClick={handleSaveSocials}
                            disabled={socialsAreSaved || socialHasError}
                          >
                            Save
                          </Button>
                          <Button
                            onClick={handleDiscardSocials}
                            disabled={socialsAreSaved || socialHasError}
                          >
                            Discard Changes
                          </Button>
                        </Stack>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </Container>
          <Container sx={{ maxWidth: "700px" }}>
            <UserAccountAnalyticsModule />
          </Container>
          <Container sx={{ maxWidth: "700px" }}>
            <Stack spacing={2}>
              <Typography variant="h5">Pack Settings</Typography>
              <FormControl variant="standard">
                <FormLabel disabled>
                  In order to minimize site cost and keep PackWizard ad-free and
                  free-to-use, links to certain retailers may be converted to
                  affiliate links. If you'd like to use your own affiliate links
                  instead, keep this checked.
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useAffiliateLinksIsChecked}
                        onChange={handleChangeUseAffiliateLinks}
                        name="affiliateCheck"
                      />
                    }
                    label="Turn off PackWizard affiliate links on my packs"
                  />
                </FormGroup>
              </FormControl>
            </Stack>
          </Container>
          <Container sx={{ maxWidth: "700px", paddingBottom: "50px" }}>
            <Stack spacing={2}>
              <Typography variant="h5">Import your local pack</Typography>
              <FormLabel disabled>
                Were you working on a pack while logged out, then signed in or
                signed up and now can't find it? No worries! Import it below.
              </FormLabel>
              <FormLabel disabled>
                This will turn any pack saved on your browser into a pack linked
                to your account.
              </FormLabel>
            </Stack>
            {didImportLocalPack ? (
              <Typography color="green">
                Sweet! Your local pack has been imported. Navigate to your packs
                to check it out.
              </Typography>
            ) : (
              <Button variant="outlined" onClick={handleImportLocalPack}>
                Import Local Pack
              </Button>
            )}
          </Container>
        </Stack>
      </Container>
    </Stack>
  );
};

export default UserAccountContainer;
