import { Skeleton, Stack } from "@mui/material";

const widthToUse = 180;
const heightToUse = 40;

const SharedPackLoader = () => {
  return (
    <Stack sx={{ width: "100%" }} alignItems="center">
      <Skeleton width={widthToUse} height={heightToUse} />
      <Skeleton width={widthToUse} height={heightToUse} />
      <Skeleton width={widthToUse} height={heightToUse} />
      <Skeleton width={widthToUse} height={heightToUse} />
      <Skeleton width={widthToUse} height={heightToUse} />
      <Skeleton width={widthToUse} height={heightToUse} />
    </Stack>
  );
};

export default SharedPackLoader;
