import WEIGHT_TYPES from "./weightTypes.json";

const NEW_TABLE = {
  title: "New Category",
  subtitle: "description",
  defaultCategory: "",
  rows: {},
  markedAsWeightType: WEIGHT_TYPES.BASE,
};

export default NEW_TABLE;
